import React, { Component } from 'react';
import './Provocation.scss';

class Provocation extends Component {
  render() {
    var fillColor = this.props.colorLight;
    
    return (
      <div className="provocation-hexagon">
        <div className="provocation-content">
          <div className="provocation-title">What If</div>
          <div className="provocation-copy">{this.props.data}</div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.07 191.75">
            <g id="ff769a97-2527-423c-8e7a-d100d99c0b99" data-name="Layer 2">
                <g id="8ef8ec20-240b-4f27-a9c2-5f46d9047696" data-name="category template content">
                    <polygon points="83.04 0.45 0.39 48.17 0.39 143.58 83.04 191.29 165.68 143.58 165.68 48.17 83.04 0.45" fill={fillColor} stroke={this.props.colorDark} strokeMiterlimit="10" strokeWidth="0.78" strokeDasharray="2.35 2.35" />
                </g>
            </g>
        </svg>

      </div>
    );
  }
}

export default Provocation;
