import React, { Component } from 'react';
import Lottie from 'react-lottie';
import './Anim.scss';

class Anim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopped: true,
      isPaused: true,
      direction: 1
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentCategoryName !== this.props.currentCategoryName) {
      if (this.props.currentCategoryName === 'individual') {
        this.setState({direction: 1});
        this.setState({isPaused: false});
      } else if (this.props.currentCategoryName !== 'individual') {
        // close house back up.
        this.setState({direction: -1});
        this.setState({isPaused: false});
      }
    }
  }

  render() {
    let defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: require('./anim/home-to-individual'),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div>
        <Lottie
          options={defaultOptions}
          isPaused={this.state.isPaused}
          width={this.props.mapWidthPx}
          direction={this.state.direction}
        />
      </div>
    );
  }
}

export default Anim;
