import React, { Component } from 'react';
import { TweenLite } from "gsap";
import Intro from './Intro';
import About from './About';
import MobileMenu from './MobileMenu';
import Anim from './Anim';
import AnimAmbient from './AnimAmbient';
import { Route } from 'react-router-dom'
import MapSvg from './MapSvg'
import Grid from './Grid'
import Section from './Section'
import Tooltips from './Tooltips'
import { withRouter } from 'react-router-dom'
import classNames from "classnames";
import './Map.scss';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';

const zoomSpeed = 1;

const maxWidth = 1366;
const breakPoint = 768;

const landscapeWidth = 1366;
const landscapeHeight = 768;

const portraitWidth = 375;
const portraitHeight = 667;

const scrollStepInPx = 50;
const delayInMs = 25;

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapHeight: 0,
      mapWidth: 0,
      orientation: 'landscape',
      categories: [],
      currentCategoryName: 'map',
      currentPath: '',
      intervalId: 0,
      animateDoor: true,

      introOn: true,
      aboutOn: false,
      mobileMenuOn: false,
      sectionOn: false,
      lottieOn: true,
      miniMapOn: false,
      shareLinksOn: false,
      currentTooltip: 'none',

      homeColored: false,
      communityColored: false,
      workColored: false,
      schoolColored: false,
      careColored: false,
      retirementColored: false,
      allColored: false
    };

    this.animateDoor = this.animateDoor.bind(this);

    this.hideIntro = this.hideIntro.bind(this);
    this.showIntro = this.showIntro.bind(this);

    this.hideAbout = this.hideAbout.bind(this);
    this.showAbout = this.showAbout.bind(this);

    this.hideMobileMenu = this.hideMobileMenu.bind(this);
    this.showMobileMenu = this.showMobileMenu.bind(this);

    this.mapClick = this.mapClick.bind(this);
    this.zoomBackToMap = this.zoomBackToMap.bind(this);

    this.toolTipOnMouseEnter = this.toolTipOnMouseEnter.bind(this);
    this.toolTipOnMouseLeave = this.toolTipOnMouseLeave.bind(this);
  }

  zoom() {
    if (this.state.currentCategoryName === 'map') this.zoomBackToMap();
    else this.zoomToCategory();
  }

  calculateMapDimensions() {
    var dimensions = {
      width: 0,
      height: 0,
      orientation: ''
    };

    // window.visualViewport only works in Chrome
    if (window.visualViewport) {
      dimensions.width = window.visualViewport.width;
    } else {
      dimensions.width = window.innerWidth;
    }

    // Width shouldn't go wider than 1366 (maxwidth)
    if (dimensions.width > maxWidth) {
      dimensions.width = maxWidth;
    }

    // Need to take care of mobile (breakPoint)
    if (dimensions.width < breakPoint) {
      dimensions.height = dimensions.width/portraitWidth*portraitHeight;
      dimensions.orientation = 'portrait';
    }
    else {
      dimensions.height = dimensions.width/landscapeWidth*landscapeHeight;
      dimensions.orientation = 'landscape';
    }
    return dimensions;
  }

  updateMapDimensions() {
    var dimensions = this.calculateMapDimensions();
    var width = dimensions.width;
    var height = dimensions.height;
    var orientation = dimensions.orientation;

    let scaleNum = 2.2;
    let xOffSet = width/4;
    let yOffSet = height/5;

    // Individual should be the same as home since they're overlaid

    if (orientation === 'landscape') {
      this.setState({
        mapHeight: dimensions.height,
        mapWidth: dimensions.width,
        orientation: dimensions.orientation,
        categories: [
          {name: "home", top: (height/landscapeHeight*555), left: (width/landscapeWidth*773),
            height: (height/landscapeHeight*198), width: (width/landscapeWidth*279),
            scaleNum: 1.8, xOffSet: xOffSet, yOffSet: yOffSet},
          {name: "individual", top: (height/landscapeHeight*555), left: (width/landscapeWidth*773),
            height: (height/landscapeHeight*198), width: (width/landscapeWidth*279),
            scaleNum: 1.8, xOffSet: xOffSet, yOffSet: yOffSet},
          {name: "community", top: (height/landscapeHeight*271), left: (width/landscapeWidth*682),
            height: (height/landscapeHeight*277), width: (width/landscapeWidth*342),
            scaleNum: 2, xOffSet: xOffSet, yOffSet: yOffSet},
          {name: "work", top: (height/landscapeHeight*262), left: (width/landscapeWidth*961),
            height: (height/landscapeHeight*160), width: (width/landscapeWidth*185),
            scaleNum: scaleNum, xOffSet: xOffSet, yOffSet: yOffSet},
          {name: "school", top: (height/landscapeHeight*291), left: (width/landscapeWidth*340),
            height: (height/landscapeHeight*152), width: (width/landscapeWidth*212),
            scaleNum: scaleNum, xOffSet: xOffSet, yOffSet: yOffSet},
          {name: "care", top: (height/landscapeHeight*409), left: (width/landscapeWidth*1184),
            height: (height/landscapeHeight*128), width: (width/landscapeWidth*207),
            scaleNum: scaleNum, xOffSet: xOffSet, yOffSet: yOffSet},
          {name: "retirement", top: (height/landscapeHeight*513), left: (width/landscapeWidth*201),
            height: (height/landscapeHeight*114), width: (width/landscapeWidth*237),
            scaleNum: scaleNum, xOffSet: xOffSet, yOffSet: yOffSet}
        ]
      }, () => this.zoom());
    }
    else if (orientation === 'portrait') {
      xOffSet = 0;
      yOffSet = height/7;
      scaleNum = 2.7;

      this.setState({
        mapHeight: dimensions.height,
        mapWidth: dimensions.width,
        orientation: dimensions.orientation,
        categories: [
          {name: "home", top: (height/portraitHeight*539), left: (width/portraitWidth*229),
            height: (height/portraitHeight*146), width: (width/portraitWidth*196),
            scaleNum: 1.2, xOffSet: xOffSet, yOffSet: yOffSet},
          {name: "individual", top: (height/portraitHeight*539), left: (width/portraitWidth*229),
            height: (height/portraitHeight*160), width: (width/portraitWidth*243),
            scaleNum: 1.2, xOffSet: xOffSet, yOffSet: yOffSet},
          {name: "community", top: (height/portraitHeight*266), left: (width/portraitWidth*188),
            height: (height/portraitHeight*145), width: (width/portraitWidth*241),
            scaleNum: 1.5, xOffSet: xOffSet, yOffSet: height/6},
          {name: "work", top: (height/portraitHeight*126), left: (width/portraitWidth*292),
            height: (height/portraitHeight*97), width: (width/portraitWidth*109),
            scaleNum: scaleNum, xOffSet: xOffSet, yOffSet: height/4},
          {name: "school", top: (height/portraitHeight*123), left: (width/portraitWidth*100),
            height: (height/portraitHeight*97), width: (width/portraitWidth*144),
            scaleNum: scaleNum, xOffSet: xOffSet, yOffSet: height/4},
          {name: "care", top: (height/portraitHeight*379), left: (width/portraitWidth*282),
            height: (height/portraitHeight*85), width: (width/portraitWidth*118),
            scaleNum: scaleNum, xOffSet: width/10, yOffSet: height/6},
          {name: "retirement", top: (height/portraitHeight*392), left: (width/portraitWidth*89),
            height: (height/portraitHeight*81), width: (width/portraitWidth*142),
            scaleNum: scaleNum, xOffSet: xOffSet, yOffSet: yOffSet}
        ]
      }, () => this.zoom());
    }
  }

  // Change "pages" using React Router and listening for route changes
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.loadFromRoute();
    }
  }

  componentDidMount() {
    this.loadFromRoute();
    window.addEventListener("resize", this.updateMapDimensions.bind(this));
    document.addEventListener("keydown", (e) => this.handleKeyDown(e), false);
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.updateMapDimensions.bind(this));
    document.removeEventListener("keydown", (e) => this.handleKeyDown(e), false);
  }

  // 27 is the 'esc' key
  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.props.history.push('/')
    }
  }

  // Load the correct category (home, retirement, etc) if category is in url
  // This uses React Routes
  loadFromRoute() {
    var currentPath = this.props.history.location.pathname;
    while(currentPath.charAt(0) === '/') { currentPath = currentPath.substr(1) };

    var currentCategoryName = currentPath === '' ? 'map' : currentPath;

    if (currentCategoryName === 'home') {this.setState({homeColored: true})};
    if (currentCategoryName === 'community') {this.setState({communityColored: true})};
    if (currentCategoryName === 'work') {this.setState({workColored: true})};
    if (currentCategoryName === 'school') {this.setState({schoolColored: true})};
    if (currentCategoryName === 'care') {this.setState({careColored: true})};
    if (currentCategoryName === 'retirement') {this.setState({retirementColored: true})};

    if (this.state.homeColored
      && this.state.communityColored
      && this.state.workColored
      && this.state.schoolColored
      && this.state.careColored
      && this.state.retirementColored) {this.setState({allColored: true})};

    this.scrollToTop();
    this.setState({currentPath: currentPath,
      currentCategoryName: currentCategoryName}, () => this.updateMapDimensions());
  }

  mapClick(name, e) {
    this.setState({animateDoor: true});

    if (name === 'map') {
      this.props.history.push('/')
    } else {
      this.props.history.push('/' + name)
    }
  }

  zoomBackToMap() {
    this.setState({sectionOn: false});
    TweenLite.to([this.mapElement, this.lottieElement, this.animAmbientElement], 
      zoomSpeed, {scaleX: 1, scaleY: 1, x: 0, y: 0});
  }

  zoomToCategory() {
    let currentCategory = this.state.categories.find(currentCategory => currentCategory.name === this.state.currentCategoryName);
    
    if (currentCategory) {
      if (this.state.currentPath === 'home' || this.state.currentPath === 'individual') {
        this.setState({lottieOn: true});
      } else {
        this.setState({lottieOn: false});
      }

      var xOffSet = currentCategory.xOffSet;
      var yOffSet = currentCategory.yOffSet;
      var scaleNum = currentCategory.scaleNum;

      var moveX = ((this.state.mapWidth/2)-currentCategory.left)*scaleNum-xOffSet;
      var moveY = ((this.state.mapHeight/2)-currentCategory.top)*scaleNum-yOffSet;

      TweenLite.to([this.mapElement, this.lottieElement, this.individualElement, this.animAmbientElement],
        zoomSpeed, {scaleX: scaleNum, scaleY: scaleNum, x: moveX, y: moveY, onComplete: () => {
        this.setState({sectionOn: true});
      }});
    }
  }

  hideAbout() {
    this.setState({aboutOn: false});
  }

  showAbout() {
    this.setState({aboutOn: true});
  }

  hideIntro() {
    this.setState({introOn: false});
  }

  showIntro() {
    this.setState({introOn: true});
  }

  hideMobileMenu() {
    this.setState({mobileMenuOn: false});
  }

  showMobileMenu() {
    this.setState({mobileMenuOn: true});
  }

  getCurrentCategoryData(category) {
    if (category === 'map') {
      return;
    }
    var currentCategoryData = this.props.appState.categoriesData.find(arrayItem => arrayItem.id === category);
    return currentCategoryData;
  }

  getIntroData() {
    var introData = this.props.appState.introData.find(arrayItem => arrayItem.id === 'intro');
    return introData;
  }

  // TODO: The gsap way?

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
    this.setState({ intervalId: intervalId });
  }

  toolTipOnMouseEnter(section) {
    if (this.props.appState.orientation === 'portrait') return;
    this.setState({currentTooltip: section});
  }

  toolTipOnMouseLeave(section) {
    if (this.props.appState.orientation === 'portrait') return;
    this.setState({currentTooltip: 'none'});
  }

  animateDoor() {
    this.setState({animateDoor: !this.state.animateDoor});
  }

  siteTitleText() {
    if (this.state.currentCategoryName === 'map') {
      return "The Care System"
    } else {
      return this.state.currentCategoryName;
    }
  }

  render() {
    var mapHeightPx = this.state.mapHeight + 'px';
    var mapWidthPx = this.state.mapWidth + 'px';

    var mapDimensions = {
      width: mapWidthPx,
      height: mapHeightPx
    }

    const shareUrl = "https://likewe.care/";
    const shareQuote = "Let's do care like we care."
    const shareIconSize = 35;

    var lottieClassNames = classNames(this.state.lottieOn ? "lottie-on" : "lottie-off",
      this.state.currentCategoryName === 'map' ? "lottie-unclickable" : "",
      "lottie-container");

    var animAmbientClassNames = classNames(this.state.sectionOn ? "ambient-off" : "",
      "anim-ambient-container");      

    var mapClassNames = classNames('main-container',
      this.state.sectionOn ? "section-on" : "section-off",
      this.state.mobileMenuOn ? "section-fixed" : "",
      this.state.currentCategoryName,
      this.state.orientation,
      (this.state.currentCategoryName === 'map' && this.state.homeColored) ? 'show-home-visited' : '',
      (this.state.currentCategoryName === 'map' && this.state.careColored) ? 'show-care-visited' : '',
      (this.state.currentCategoryName === 'map' && this.state.schoolColored) ? 'show-school-visited' : '',
      (this.state.currentCategoryName === 'map' && this.state.communityColored) ? 'show-community-visited' : '',
      (this.state.currentCategoryName === 'map' && this.state.workColored) ? 'show-work-visited' : '',
      (this.state.currentCategoryName === 'map' && this.state.retirementColored) ? 'show-retirement-visited' : '',
      (this.state.currentCategoryName === 'map' && this.state.allColored) ? 'show-all-visited' : '',
      (this.state.currentCategoryName === 'individual') ? 'show-individual-visited' : '');

    var menuIconClassNames = classNames(this.state.mobileMenuOn ? "show" : "show", "menu-icon");
    var mobileMenuClassNames = classNames(this.state.mobileMenuOn ? "show" : "show", "menu-container");

    var mobileBackToMapClassNames = classNames(this.state.currentCategoryName === 'map' ? "mobile-back-to-map-off": "", "mobile-back-to-map");

    var systemClassNames = classNames("menu-item", "menu-item-system",
      this.state.currentCategoryName === 'map' ? "active" : "",
      'all-visited', 'visited-container');
    var homeClassNames = classNames("menu-item", "menu-item-home",
      this.state.currentCategoryName === 'home' ? "active" : "",
      'home-visited', 'visited-container');
    var communityClassNames = classNames("menu-item", "menu-item-community",
      this.state.currentCategoryName === 'community' ? "active" : "",
      'community-visited', 'visited-container');
    var workClassNames = classNames("menu-item", "menu-item-work",
      this.state.currentCategoryName === 'work' ? "active" : "",
      'work-visited', 'visited-container');
    var schoolClassNames = classNames("menu-item", "menu-item-school",
      this.state.currentCategoryName === 'school' ? "active" : "",
      'school-visited', 'visited-container');
    var careClassNames = classNames("menu-item", "menu-item-care",
      this.state.currentCategoryName === 'care' ? "active" : "",
      'care-visited', 'visited-container');
    var retirementClassNames = classNames("menu-item", "menu-item-retirement",
      this.state.currentCategoryName === 'retirement' ? "active" : "",
      'retirement-visited', 'visited-container');
    var individualClassNames = classNames("menu-item", "menu-item-individual",
      this.state.currentCategoryName === 'individual' ? "active" : "",
      'individual-visited', 'visited-container');

    var miniMapClassNames = classNames("mini-map",
      this.state.currentCategoryName === 'map' ? "mini-map-off" : "");

    // TODO: Turn mobile menu into component
    // TODO: Clean up icon SVGs
    // TODO: Scroll to top when intro on

    return (
      <div className={mapClassNames} style={mapDimensions}>
        <Tooltips
          data={this.props.appState.categoriesData}
          currentTooltipToShow={this.state.currentTooltip}
        >
        </Tooltips>

        <div className="nav">
          <div className={miniMapClassNames}>
            <div className="zoom-icon" onClick={() => this.mapClick('map')}>
              <svg title="back to map" id="3b621ef6-e146-476c-92d3-6ea07f08b5f2" data-name="v1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path d="M1.83,17.9a1.8,1.8,0,0,0,1.76-1.79v0h0v-10L14.82,17.35a1.78,1.78,0,0,0,3-1.26,1.81,1.81,0,0,0-.52-1.27L6.11,3.59h10A1.79,1.79,0,1,0,16.1,0H1.78A1.78,1.78,0,0,0,0,1.79a2.28,2.28,0,0,0,0,.26H0V16.11A1.79,1.79,0,0,0,1.8,17.9Zm46.39,0A1.79,1.79,0,0,0,50,16.11v0h0V2a1.66,1.66,0,0,0,0-.24A1.79,1.79,0,0,0,48.21,0h-.05L48,0H33.9a1.79,1.79,0,1,0,0,3.57h10L32.66,14.83a1.78,1.78,0,0,0,1.26,3,1.74,1.74,0,0,0,1.26-.52L46.41,6.12v10A1.79,1.79,0,0,0,48.2,17.9h0Zm0,32.09a1.91,1.91,0,0,0,.54-.08h0l.18-.06h0l.16-.08h0a1.43,1.43,0,0,0,.24-.16h0l.07-.06h0l.12-.11h0s0,0,0,0h0a1.39,1.39,0,0,0,.21-.27h0A.53.53,0,0,0,49.8,49h0a1.75,1.75,0,0,0,.12-.32h0a1.49,1.49,0,0,0,.06-.43h0v0h0V33.9a1.79,1.79,0,0,0-1.79-1.78h0a1.79,1.79,0,0,0-1.76,1.79v0h0v10L35.2,32.67a1.77,1.77,0,0,0-1.28-.54h0a1.78,1.78,0,0,0-1.78,1.78,1.75,1.75,0,0,0,.54,1.28L43.9,46.42h-10a1.79,1.79,0,0,0,0,3.57h14.3ZM16.08,50h0a1.79,1.79,0,0,0,0-3.57h-10L17.34,35.19a1.77,1.77,0,0,0,.54-1.28,1.79,1.79,0,0,0-1.78-1.79h-.05a1.76,1.76,0,0,0-1.23.53L3.59,43.89v-10A1.79,1.79,0,0,0,1.8,32.12h0A1.78,1.78,0,0,0,0,33.9v0H0V48.2H0a.86.86,0,0,0,0,.16H0l0,.14H0a.18.18,0,0,1,0,.06h0A2.17,2.17,0,0,0,.22,49h0l0,0h0l.07.12h0l0,0h0a2,2,0,0,0,.34.37h0l0,0h0A1.75,1.75,0,0,0,1.76,50H16.08Z" fill="#4d4d4d" />
              </svg>
            </div>
            { this.state.currentCategoryName !== 'map' &&
              <div className="mini-map-svg">
                <MapSvg
                  type="mini"
                  orientation={this.state.orientation}
                  currentCategoryName={this.state.currentCategoryName}
                  mapClick={this.mapClick}
                  introOn={this.state.introOn}
                  homeColored={this.state.homeColored}
                  communityColored={this.state.communityColored}
                  workColored={this.state.workColored}
                  schoolColored={this.state.schoolColored}
                  careColored={this.state.careColored}
                  retirementColored={this.state.retirementColored}>
                </MapSvg>
              </div>
            }
          </div>

          <div className="mobile-nav-container">
            <div className={mobileBackToMapClassNames} onClick={() => this.mapClick('map')}>
              <svg className="mobile-back-to-map-svg" viewBox="0 0 72 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.0319 0.0789173L1.05321 7.71737C0.44283 7.91728 -0.00297231 8.53491 1.49232e-05 9.17341V53.4917C0.011983 54.4465 1.10108 55.2223 2.01065 54.9239L24.5106 47.4526L47.0106 54.9239C47.3188 55.0254 47.6599 55.0254 47.9681 54.9239L70.9468 47.2855C71.5482 47.0885 71.991 46.4858 72 45.8533V1.53496C72 0.571215 70.9079 -0.222496 69.9894 0.0789173L47.4894 7.55024L24.9894 0.0789173C24.6423 -0.0553514 24.2862 0.00730679 24.0319 0.0789173V0.0789173ZM22.9787 3.63553V44.7549L3.06384 51.3668V10.2475L22.9787 3.63553ZM26.0426 3.63553L45.9574 10.2475V51.3668L26.0426 44.7549V3.63553ZM68.9362 3.63553V44.7549L49.0213 51.3668V10.2475L68.9362 3.63553Z" fill="#7f7f7f"/>
              </svg>          
            </div>

            <div className="website-title">{this.siteTitleText()}</div>

            <div className={menuIconClassNames} onClick={this.showMobileMenu}>
              <svg id="bf506151-8e7b-4c37-8f18-37cddc625144" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 43.66"><title>Hamburger Icon</title><path d="M73.59,50.21l-4.82-1.74A5.73,5.73,0,0,0,69,46.7a18.73,18.73,0,0,0-37.45,0,5.89,5.89,0,0,0,.21,1.56L26.4,50.2a2.14,2.14,0,0,0-1.4,2,2.12,2.12,0,0,0,1.4,2l3,1.06c0,.17,0,.33,0,.49a6.76,6.76,0,0,0,2.51,5.17,5.88,5.88,0,0,0-.25,1.7c0,2.16,1.14,5.15,6.58,7.15a36.28,36.28,0,0,0,12.15,1.85,36.26,36.26,0,0,0,12.14-1.85c5.44-2,6.59-5,6.59-7.15a5.91,5.91,0,0,0-.26-1.7,6.77,6.77,0,0,0,2.52-5.17,5.78,5.78,0,0,0,0-.71l2.33-.84a2.12,2.12,0,0,0,0-4ZM33.72,46.7a16.6,16.6,0,0,1,33.2,0,3.59,3.59,0,0,1-.16,1,4.13,4.13,0,0,1-.54,1.12,5.9,5.9,0,0,1-.61.73,7.13,7.13,0,0,1-1,.84c-.19.13-.39.26-.6.38-5.88,3.45-19.14,3.67-26,.68a11.21,11.21,0,0,1-2-1.06,7.45,7.45,0,0,1-1.19-1,4.71,4.71,0,0,1-.44-.56,3.94,3.94,0,0,1-.6-1.32,3.33,3.33,0,0,1-.1-.84ZM66.91,62.63c0,4.58-8.29,6.87-16.59,6.87s-16.6-2.29-16.6-6.87c0-.12,0-.23,0-.35a18.15,18.15,0,0,0,2.88,1.33,41,41,0,0,0,13.7,2.09A41,41,0,0,0,64,63.61a18.15,18.15,0,0,0,2.88-1.33c0,.12,0,.23,0,.35ZM70.68,53l-2,.73-5.54,2-3.22,1.16L50,60.46,40.4,57l-3.17-1.14-5.35-1.93-2-.72-2.74-1L32,50.45l.74-.26A8.18,8.18,0,0,0,34,51.57a13.7,13.7,0,0,0,4.14,2.29,26.12,26.12,0,0,0,2.7.8,40.49,40.49,0,0,0,9.45,1.05,40.49,40.49,0,0,0,9.45-1.05,25.21,25.21,0,0,0,2.69-.8,13.57,13.57,0,0,0,4.14-2.29,8.19,8.19,0,0,0,1.17-1.2l1.3.47,3.8,1.37Z" transform="translate(-25 -27.98)" fill="#7f7f7f"/></svg>
            </div>
          </div>

          <div className="share-container">
            <div className="share-icon">
              <svg id="bd85f5a8-c08d-4606-9acf-a3e62a4b31cc" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 78"><title>Share Icon</title><path d="M57,5.09a11,11,0,1,1-7.5,19l-.5-.4-.7.4-28.2,16.5-.6.4.2.7a10.9,10.9,0,0,1,.3,2.6,20.61,20.61,0,0,1-.3,2.6l-.2.7.6.4,28.5,16.7.6.4.5-.5a10.68,10.68,0,0,1,17.9,7.89A10.54,10.54,0,0,1,57,83.09a10.71,10.71,0,0,1-10.7-10.7,9.86,9.86,0,0,1,.3-2.4l.2-.7-.6-.4-28.5-16.7-.7-.4-.5.5a11.12,11.12,0,0,1-7.5,3,11,11,0,0,1,0-22,11.12,11.12,0,0,1,7.5,3l.5.5.6-.4,28.2-16.5.6-.4-.2-.7c0-.9-.2-1.7-.2-2.7a11,11,0,0,1,11-11Z" transform="translate(1.97 -5.09)" fill="#4a4a4a"/></svg>
            </div>

            <div className="share-links">
              <FacebookShareButton
                url={shareUrl}
                quote={shareQuote}
                className="share-link share-link-one">
                <FacebookIcon
                  size={shareIconSize}
                  square />
              </FacebookShareButton>

              <TwitterShareButton
                url={shareUrl}
                title={shareQuote}
                className="share-link share-link-two">
                <TwitterIcon
                  size={shareIconSize}
                  square />
              </TwitterShareButton>

              <LinkedinShareButton
                url={shareUrl}
                title={shareQuote}
                className="share-link share-link-three">
                <LinkedinIcon
                  size={shareIconSize}
                  square />
              </LinkedinShareButton>

              <EmailShareButton
                url={shareUrl}
                subject={shareQuote}
                body={shareUrl}
                className="share-link share-link-four">
                <EmailIcon
                  size={shareIconSize}
                  square />
              </EmailShareButton>
            </div>
          </div>

          <MobileMenu mobileMenuOn={this.state.mobileMenuOn}
            hideMobileMenu={this.hideMobileMenu}
            showIntro={this.showIntro}
            showAbout={this.showAbout}
            mapClick={this.mapClick}
            mobileMenuClassNames={mobileMenuClassNames}
            systemClassNames={systemClassNames}
            homeClassNames={homeClassNames}
            careClassNames={careClassNames}
            schoolClassNames={schoolClassNames}
            workClassNames={workClassNames}
            retirementClassNames={retirementClassNames}
            communityClassNames={communityClassNames}
            individualClassNames={individualClassNames}
            shareUrl={shareUrl}
            shareQuote={shareQuote}
            shareIconSize={shareIconSize}>
          </MobileMenu>

          <div className="site-info-container">
            <div className="info-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.28 87.33"><title>Info Icon</title><path d="M76.14,77.06v16.6H23.86V77.06H41.94V55.66H23.86V39.05H61v38ZM50.19,29.7A11.69,11.69,0,1,0,38.38,18,11.75,11.75,0,0,0,50.19,29.7Z" transform="translate(-23.86 -6.33)" fill="#4a4a4a"/></svg>
            </div>

            <div className="site-info-links">
              <div className="intro-link" onClick={this.showIntro}>Introduction</div>
              <div className="about-link" onClick={this.showAbout}>About This Project</div>
              <div className="copyright">The Care System &#169;</div>
            </div>
          </div>
        </div>

        <Intro introData={this.props.appState.introData}
          introOn={this.state.introOn}
          hideIntro={this.hideIntro}>
        </Intro>

        <About aboutData={this.props.appState.aboutData}
          aboutOn={this.state.aboutOn}
          hideAbout={this.hideAbout}>
        </About>

        {this.getCurrentCategoryData('home') &&
          <Route path='/home' render={(props) => <Section {...props} data={this.getCurrentCategoryData('home')}
          mapClick={this.mapClick} animateDoor={this.animateDoor} />} />
        }
        {this.getCurrentCategoryData('individual') &&
          <Route path='/individual' render={(props) => <Section {...props} data={this.getCurrentCategoryData('individual')} />} />
        }
        {this.getCurrentCategoryData('retirement') &&
          <Route path='/retirement' render={(props) => <Section {...props} data={this.getCurrentCategoryData('retirement')} />} />
        }
        {this.getCurrentCategoryData('school') &&
          <Route path='/school' render={(props) => <Section {...props} data={this.getCurrentCategoryData('school')} />} />
        }
        {this.getCurrentCategoryData('work') &&
          <Route path='/work' render={(props) => <Section {...props} data={this.getCurrentCategoryData('work')} />} />
        }
        {this.getCurrentCategoryData('care') &&
          <Route path='/care' render={(props) => <Section {...props} data={this.getCurrentCategoryData('care')} />} />
        }
        {this.getCurrentCategoryData('community') &&
          <Route path='/community' render={(props) => <Section {...props} data={this.getCurrentCategoryData('community')} />} />
        }

        <div className="maxi-map-container" style={mapDimensions}>
        <div className="maxi-map"
          style={mapDimensions}
          ref={div => this.mapElement = div}>
          <MapSvg
            type="maxi"
            toolTipOnMouseEnter={this.toolTipOnMouseEnter}
            toolTipOnMouseLeave={this.toolTipOnMouseLeave}
            data={this.props.appState.categoriesData}
            orientation={this.state.orientation}
            currentCategoryName={this.state.currentCategoryName}
            animateDoor={this.state.animateDoor}
            mapClick={this.mapClick}
            introOn={this.state.introOn}
            homeColored={this.state.homeColored}
            communityColored={this.state.communityColored}
            workColored={this.state.workColored}
            schoolColored={this.state.schoolColored}
            careColored={this.state.careColored}
            retirementColored={this.state.retirementColored}/>
        </div>
        </div>

        { this.state.orientation === "landscape" && 
          <div className="png-container" style={mapDimensions}>
            <div className={homeClassNames} style={mapDimensions}></div>
            <div className={careClassNames} style={mapDimensions}></div>
            <div className={schoolClassNames} style={mapDimensions}></div>
            <div className={communityClassNames} style={mapDimensions}></div>
            <div className={retirementClassNames} style={mapDimensions}></div>
            <div className={workClassNames} style={mapDimensions}></div>
            <div className={systemClassNames} style={mapDimensions}></div>
            <div ref={div => this.individualElement = div} className={individualClassNames} style={mapDimensions}></div>
          </div>
        }

        { this.state.orientation === "portrait" && 
          <div className="png-container portrait" style={mapDimensions}>
            <div className={homeClassNames} style={mapDimensions}></div>
            <div className={careClassNames} style={mapDimensions}></div>
            <div className={schoolClassNames} style={mapDimensions}></div>
            <div className={communityClassNames} style={mapDimensions}></div>
            <div className={retirementClassNames} style={mapDimensions}></div>
            <div className={workClassNames} style={mapDimensions}></div>
            <div className={systemClassNames} style={mapDimensions}></div>
            <div ref={div => this.individualElement = div} className={individualClassNames} style={mapDimensions}></div>
          </div>
        }

        <div ref={div => this.lottieElement = div}
          className={lottieClassNames}
          style={mapDimensions}>
          <Anim
            lottieOn={this.state.lottieOn}
            currentCategoryName={this.state.currentCategoryName}
            mapWidth={mapWidthPx}>
          </Anim>
        </div>

        <div className="ambient-container-container" style={mapDimensions}>
          <div ref={div => this.animAmbientElement = div}
            className={animAmbientClassNames}
            style={mapDimensions}>
            <AnimAmbient
              orientation={this.state.orientation}
              currentCategoryName={this.state.currentCategoryName}
              mapWidth={mapWidthPx}>
            </AnimAmbient>
          </div>
        </div>

        <Grid introOn={this.state.introOn}
          sectionOn={this.state.sectionOn}
          currentCategoryName={this.state.currentCategoryName}>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Map);
