import React, { Component } from 'react';
import Map from './Map'
import firebase from "./firebase";
import './App.css';

// TODO: Uninstall packages (e.g. Lottie)
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoriesData: [],
      introData: [],
      aboutData: [],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      orientation: 'landscape'
    };

    this.getDataFromFirebase = this.getDataFromFirebase.bind(this);
  }

  getDataFromFirebase() {
    const db = firebase.firestore();
    db.collection("categories").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var oldStateDataPlusNewStateData = this.state.categoriesData.concat(doc.data());
        this.setState({categoriesData: oldStateDataPlusNewStateData});
      });
    });

    db.collection("intro").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var oldStateDataPlusNewStateData = this.state.introData.concat(doc.data());
        this.setState({introData: oldStateDataPlusNewStateData});
      });
    });

    db.collection("about").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var oldStateDataPlusNewStateData = this.state.aboutData.concat(doc.data());
        this.setState({aboutData: oldStateDataPlusNewStateData});
      });
    });

    //this.makeNewFirebaseDocument('retirement');
  }

  // Call this function to copy the home document (and all its contents)
  // into a new category 'document' in firebase
  makeNewFirebaseDocument(docName) {
    const db = firebase.firestore();
    db.collection("categories").doc('home').get().then((doc) => {
      if (doc && doc.exists) {
        var data = doc.data();
        db.collection("categories").doc(docName).set(data).then(() => {
          console.log('Created a new doc :)');
        });
      }
    });
  }

  updateDimensions() {
    this.setState({windowHeight: window.innerHeight});
    if (window.visualViewport) {
      this.setState({windowWidth: window.visualViewport.width});
    } else {
      this.setState({windowWidth: window.innerWidth});
    }
  }

  componentDidMount(){
    this.getDataFromFirebase();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <div className="App">
        <Map navChange={this.navChange}
        windowHeight={this.state.windowHeight}
        windowWidth={this.state.windowWidth}
        appState={this.state} />
      </div>
    );
  }
}

export default App;
