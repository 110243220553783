import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal';
import './Intro.scss';

// For documentation about react-player
// Visit https://www.npmjs.com/package/react-player

const tanOpacity = "#fff5d995";

class Intro extends Component {
  render() {
    var videoTitle;
    var videoUrl;
    var title;
    var copy = [];
    var buttonText;

    // Firebase contains an "intro" collection with as single "intro" document
    // Check to make sure that App has received the data
    if (this.props.introData[0]) {
      var introData = this.props.introData[0];
      videoTitle = introData.videoTitle;
      videoUrl = introData.videoUrl;
      title = introData.title;
      copy = introData.copy;
      buttonText = introData.buttonText;
    }

    return (
      <div id="care-intro">
        <Modal
          size="lg"
          centered
          show={this.props.introOn}
          onHide={this.props.hideIntro}
          dialogClassName="care-intro-modal">
          <Container fluid={true}>
            <Row noGutters={true}>
            <Col xl="1"></Col>
            <Col xl="5">
            <div className="hex-group video-hex-group">
                <svg className="hex-background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 534.19 616.79"><title>The Care System</title><polygon points="267.13 0.58 0.5 154.51 0.5 462.29 267.13 616.21 533.69 462.29 533.69 154.51 267.13 0.58" fill={tanOpacity}/><polyline points="268.42 1.33 267.13 0.58 265.83 1.33" fill="none" stroke="#4a4a4a" strokeMiterlimit="10"/><line x1="263.21" y1="2.84" x2="3.11" y2="153.01" fill="none" stroke="#4a4a4a" strokeMiterlimit="10" strokeDasharray="3.02 3.02"/><polyline points="1.8 153.76 0.5 154.51 0.5 156.01" fill="none" stroke="#4a4a4a" strokeMiterlimit="10"/><line x1="0.5" y1="159.03" x2="0.5" y2="459.28" fill="none" stroke="#4a4a4a" strokeMiterlimit="10" strokeDasharray="3.02 3.02"/><polyline points="0.5 460.79 0.5 462.29 1.8 463.04" fill="none" stroke="#4a4a4a" strokeMiterlimit="10"/><line x1="4.41" y1="464.55" x2="264.52" y2="614.71" fill="none" stroke="#4a4a4a" strokeMiterlimit="10" strokeDasharray="3.02 3.02"/><polyline points="265.83 615.46 267.13 616.21 268.42 615.46" fill="none" stroke="#4a4a4a" strokeMiterlimit="10"/><line x1="271.04" y1="613.95" x2="531.08" y2="463.79" fill="none" stroke="#4a4a4a" strokeMiterlimit="10" strokeDasharray="3.02 3.02"/><polyline points="532.39 463.04 533.69 462.29 533.69 460.79" fill="none" stroke="#4a4a4a" strokeMiterlimit="10"/><line x1="533.69" y1="457.77" x2="533.69" y2="157.52" fill="none" stroke="#4a4a4a" strokeMiterlimit="10" strokeDasharray="3.02 3.02"/><polyline points="533.69 156.01 533.69 154.51 532.39 153.76" fill="none" stroke="#4a4a4a" strokeMiterlimit="10"/><line x1="529.78" y1="152.25" x2="269.73" y2="2.08" fill="none" stroke="#4a4a4a" strokeMiterlimit="10" strokeDasharray="3.02 3.02"/></svg>
                <div className="care-intro-video">

                  {/* <div className="intro-title">{videoTitle}</div> */}
                  <div className="intro-video">
                    <div className="embed-responsive embed-responsive-16by9">
                      {/* <iframe title="The care system video" src={"https://player.vimeo.com/video/195701078?title=0&byline=0&portrait=0"}></iframe> */}
                      <iframe title="The care system video" allow="fullscreen" allowFullScreen src={videoUrl}></iframe>
                      
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl="1"></Col>
            <Col className="d-flex align-items-center" xl="5">
              <div className="hex-group content-hex-group">
                <div className="care-intro-content">
                  <div className="intro-title">{title}</div>
                  <div className="care-intro-copy">
                    {copy.map((copyItem, index) => (
                      <div className="intro-copy" key={index.toString()}>{copyItem}</div>
                    ))}
                  </div>
                  <div className="intro-button" onClick={this.props.hideIntro}>{buttonText}</div>
                </div>
              </div>
            </Col>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}

export default Intro;
