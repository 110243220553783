// src/firebase.js
import firebase from 'firebase/app';
import '@firebase/firestore'
const config = {
    apiKey: "AIzaSyBRx2cbkjVnuvmtPt1JfJ8BxczU10wDwiM",
    authDomain: "care-website-39e6b.firebaseapp.com",
    databaseURL: "https://care-website-39e6b.firebaseio.com",
    projectId: "care-website-39e6b",
    storageBucket: "care-website-39e6b.appspot.com",
    messagingSenderId: "947083742090"
};
firebase.initializeApp(config);
export default firebase;