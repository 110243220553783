import React, { Component } from 'react';
import Lottie from 'react-lottie';
import './AnimAmbient.scss';

class AnimAmbient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
      isPaused: false,
      direction: 1
    };
  }

  render() {
    let ambientOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    if (this.props.orientation === "portrait") {
      ambientOptions.animationData = require('./anim/vert-ambient');
    } else {
      ambientOptions.animationData = require('./anim/ambient');
    }

    return (
      <div>
        <Lottie
          options={ambientOptions}
          isPaused={this.state.isPaused}
          width={this.props.mapWidthPx}
          direction={this.state.direction}
        />
      </div>
    );
  }
}

export default AnimAmbient;
