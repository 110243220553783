import React, { Component } from 'react';
import './Hex.scss';

class Hex extends Component {

  render() {
    let hexStyle = {fill: this.props.color};
    let sizeClass = this.props.size ? ('hex-container ' + this.props.size) : 'hex-container small';

    return (
      <div className={sizeClass}>
        <div className="hex-title">{this.props.copy}</div>
        <svg className="hex-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.64 95.42">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <polygon style={hexStyle} className="cls-1" points="41.33 0 0 23.86 0 71.56 41.33 95.42 82.64 71.56 82.64 23.86 41.33 0" />
                </g>
            </g>
        </svg>
      </div>
    );
  }
}

export default Hex;
