import React, { Component } from 'react';
import './Grid.scss';
import { TweenMax, TimelineMax, Power1 } from "gsap";

const animSpeed = 2;

class Grid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gridOn: this.props.introOn,
      animatedConnections: false
    }

    this.lines = [];
  }

  gridPulse() {
    var tl = new TimelineMax();
    tl.to(this.gridContainer, animSpeed,
      {
        opacity: .5,
        ease: Power1.easeInOut
      })
      .to(this.gridContainer, animSpeed, {
        opacity: 0,
        ease: Power1.easeInOut
      });
  }

  gridOn() {
    TweenMax.to(this.gridContainer, animSpeed,
      {
        opacity: .25,
        ease: Power1.easeInOut
      }
    );
  }

  gridOff() {
    TweenMax.to(this.gridContainer, animSpeed,
      {
        opacity: 0,
        ease: Power1.easeInOut
      }
    );
  }

  // TODO: What do we want to happen here and when?

  componentDidUpdate(prevProps, prevState) {
    if (this.props.introOn) {
      this.gridOff();
    }
    else if (prevProps.introOn !== this.props.introOn
      && !this.props.introOn) {
      if (this.props.currentCategoryName === "map") {
        this.gridPulse();
      }
      else {
        this.gridOn();
      }
    }
    else if (prevProps.currentCategoryName !== this.props.currentCategoryName) {

      if (this.props.currentCategoryName === "map") {
        this.gridOff();
      }
      else {
        this.gridOn();
      }
    }
  };

  render() {
    return (
      <div className="grid" ref={ div => this.gridContainer = div }>
        <svg className="grid-svg" ref={ svg => this.gridSvg = svg } id="e9c85056-a531-4657-ac03-34c134772f87" data-name="layer1" viewBox="0 0 1366 768">
            <defs>
                <pattern id="5435be24-e96b-4913-b5b8-76f6364e9636" data-name="Isometric_pattern_large" width="82.76" height="143.35" patternUnits="userSpaceOnUse" viewBox="0 0 82.76 143.35">
                    <rect width="82.76" height="143.35" fill="none" />
                    <polygon ref={ line => this.lines[5] = line } points="20.69 203.08 20.69 155.29 62.07 131.4 103.45 155.29 103.45 203.08 62.07 226.97 20.69 203.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[0] = line } x1="62.07" y1="131.4" x2="62.07" y2="226.97" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[6] = line } points="-62.07 203.08 -62.07 155.29 -20.69 131.4 20.69 155.29 20.69 203.08 -20.69 226.97 -62.07 203.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon  ref={ line => this.lines[7] = line } points="62.07 131.4 62.07 83.62 103.45 59.73 144.83 83.62 144.83 131.4 103.45 155.29 62.07 131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[1] = line } x1="62.07" y1="83.62" x2="144.83" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[2] = line } x1="144.83" y1="83.62" x2="62.07" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[8] = line }  points="-20.69 131.4 -20.69 83.62 20.69 59.73 62.07 83.62 62.07 131.4 20.69 155.29 -20.69 131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[3] = line } x1="20.69" y1="59.73" x2="20.69" y2="155.29" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[4] = line }x1="-20.69" y1="83.62" x2="62.07" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[9] = line } x1="62.07" y1="83.62" x2="-20.69" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[10] = line } points="20.69 59.73 20.69 11.95 62.07 -11.95 103.45 11.95 103.45 59.73 62.07 83.62 20.69 59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[11] = line } x1="62.07" y1="-11.95" x2="62.07" y2="83.62" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[12] = line } x1="20.69" y1="11.95" x2="103.45" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line x1="103.45" y1="11.95" x2="20.69" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[13] = line } points="-62.07 59.73 -62.07 11.95 -20.69 -11.95 20.69 11.95 20.69 59.73 -20.69 83.62 -62.07 59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[14] = line } x1="-62.07" y1="11.95" x2="20.69" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[15] = line } x1="20.69" y1="11.95" x2="-62.07" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[16] = line } points="62.07 -11.95 62.07 -59.73 103.45 -83.62 144.83 -59.73 144.83 -11.95 103.45 11.95 62.07 -11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[17] = line } points="-20.69 -11.95 -20.69 -59.73 20.69 -83.62 62.07 -59.73 62.07 -11.95 20.69 11.95 -20.69 -11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[18] = line } x1="20.69" y1="-83.62" x2="20.69" y2="11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                </pattern>
            </defs>
            <title>Isometric Grid</title>
            <rect width="1366" height="768" fill="url(#5435be24-e96b-4913-b5b8-76f6364e9636)" />
        </svg>
        <svg className="grid-svg" ref={ svg => this.gridSvg = svg } id="e9c85056-a531-4657-ac03-34c134772f87" data-name="layer1" viewBox="0 0 1366 768">
            <defs>
                <pattern id="5435be24-e96b-4913-b5b8-76f6364e9636" data-name="Isometric_pattern_large" width="82.76" height="143.35" patternUnits="userSpaceOnUse" viewBox="0 0 82.76 143.35">
                    <rect width="82.76" height="143.35" fill="none" />
                    <polygon ref={ line => this.lines[5] = line } points="20.69 203.08 20.69 155.29 62.07 131.4 103.45 155.29 103.45 203.08 62.07 226.97 20.69 203.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[0] = line } x1="62.07" y1="131.4" x2="62.07" y2="226.97" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[6] = line } points="-62.07 203.08 -62.07 155.29 -20.69 131.4 20.69 155.29 20.69 203.08 -20.69 226.97 -62.07 203.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon  ref={ line => this.lines[7] = line } points="62.07 131.4 62.07 83.62 103.45 59.73 144.83 83.62 144.83 131.4 103.45 155.29 62.07 131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[1] = line } x1="62.07" y1="83.62" x2="144.83" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[2] = line } x1="144.83" y1="83.62" x2="62.07" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[8] = line }  points="-20.69 131.4 -20.69 83.62 20.69 59.73 62.07 83.62 62.07 131.4 20.69 155.29 -20.69 131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[3] = line } x1="20.69" y1="59.73" x2="20.69" y2="155.29" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[4] = line }x1="-20.69" y1="83.62" x2="62.07" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[9] = line } x1="62.07" y1="83.62" x2="-20.69" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[10] = line } points="20.69 59.73 20.69 11.95 62.07 -11.95 103.45 11.95 103.45 59.73 62.07 83.62 20.69 59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[11] = line } x1="62.07" y1="-11.95" x2="62.07" y2="83.62" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[12] = line } x1="20.69" y1="11.95" x2="103.45" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line x1="103.45" y1="11.95" x2="20.69" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[13] = line } points="-62.07 59.73 -62.07 11.95 -20.69 -11.95 20.69 11.95 20.69 59.73 -20.69 83.62 -62.07 59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[14] = line } x1="-62.07" y1="11.95" x2="20.69" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[15] = line } x1="20.69" y1="11.95" x2="-62.07" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[16] = line } points="62.07 -11.95 62.07 -59.73 103.45 -83.62 144.83 -59.73 144.83 -11.95 103.45 11.95 62.07 -11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[17] = line } points="-20.69 -11.95 -20.69 -59.73 20.69 -83.62 62.07 -59.73 62.07 -11.95 20.69 11.95 -20.69 -11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[18] = line } x1="20.69" y1="-83.62" x2="20.69" y2="11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                </pattern>
            </defs>
            <title>Isometric Grid</title>
            <rect width="1366" height="768" fill="url(#5435be24-e96b-4913-b5b8-76f6364e9636)" />
        </svg>
        <svg className="grid-svg" ref={ svg => this.gridSvg = svg } id="e9c85056-a531-4657-ac03-34c134772f87" data-name="layer1" viewBox="0 0 1366 768">
            <defs>
                <pattern id="5435be24-e96b-4913-b5b8-76f6364e9636" data-name="Isometric_pattern_large" width="82.76" height="143.35" patternUnits="userSpaceOnUse" viewBox="0 0 82.76 143.35">
                    <rect width="82.76" height="143.35" fill="none" />
                    <polygon ref={ line => this.lines[5] = line } points="20.69 203.08 20.69 155.29 62.07 131.4 103.45 155.29 103.45 203.08 62.07 226.97 20.69 203.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[0] = line } x1="62.07" y1="131.4" x2="62.07" y2="226.97" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[6] = line } points="-62.07 203.08 -62.07 155.29 -20.69 131.4 20.69 155.29 20.69 203.08 -20.69 226.97 -62.07 203.08" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon  ref={ line => this.lines[7] = line } points="62.07 131.4 62.07 83.62 103.45 59.73 144.83 83.62 144.83 131.4 103.45 155.29 62.07 131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[1] = line } x1="62.07" y1="83.62" x2="144.83" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[2] = line } x1="144.83" y1="83.62" x2="62.07" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[8] = line }  points="-20.69 131.4 -20.69 83.62 20.69 59.73 62.07 83.62 62.07 131.4 20.69 155.29 -20.69 131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[3] = line } x1="20.69" y1="59.73" x2="20.69" y2="155.29" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[4] = line }x1="-20.69" y1="83.62" x2="62.07" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[9] = line } x1="62.07" y1="83.62" x2="-20.69" y2="131.4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[10] = line } points="20.69 59.73 20.69 11.95 62.07 -11.95 103.45 11.95 103.45 59.73 62.07 83.62 20.69 59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[11] = line } x1="62.07" y1="-11.95" x2="62.07" y2="83.62" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[12] = line } x1="20.69" y1="11.95" x2="103.45" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line x1="103.45" y1="11.95" x2="20.69" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[13] = line } points="-62.07 59.73 -62.07 11.95 -20.69 -11.95 20.69 11.95 20.69 59.73 -20.69 83.62 -62.07 59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[14] = line } x1="-62.07" y1="11.95" x2="20.69" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[15] = line } x1="20.69" y1="11.95" x2="-62.07" y2="59.73" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[16] = line } points="62.07 -11.95 62.07 -59.73 103.45 -83.62 144.83 -59.73 144.83 -11.95 103.45 11.95 62.07 -11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <polygon ref={ line => this.lines[17] = line } points="-20.69 -11.95 -20.69 -59.73 20.69 -83.62 62.07 -59.73 62.07 -11.95 20.69 11.95 -20.69 -11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                    <line ref={ line => this.lines[18] = line } x1="20.69" y1="-83.62" x2="20.69" y2="11.95" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.13" />
                </pattern>
            </defs>
            <title>Isometric Grid</title>
            <rect width="1366" height="768" fill="url(#5435be24-e96b-4913-b5b8-76f6364e9636)" />
        </svg>
      </div>
    );
  }
}

export default Grid;
