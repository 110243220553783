import React, { Component } from 'react';
import './Tooltips.scss';
import classNames from "classnames";
import HexOverlay from './HexOverlay';

class Tooltips extends Component {

  getTitle(section) {
    if (!this.props.data) return;
    var currentSectionObj = this.props.data.find(arrayItem => arrayItem.id === section);
    if (!currentSectionObj) return;
    var title = currentSectionObj.title;
    return title;
  }

  render() {
    var schoolTooltipClassNames = classNames(this.props.currentTooltipToShow==='school' ? "show-me" : "", "care-site-tooltip school-tooltip");
    var retirementTooltipClassNames = classNames(this.props.currentTooltipToShow==='retirement' ? "show-me" : "", "care-site-tooltip retirement-tooltip");
    var workTooltipClassNames = classNames(this.props.currentTooltipToShow==='work' ? "show-me" : "", "care-site-tooltip work-tooltip");
    var communityTooltipClassNames = classNames(this.props.currentTooltipToShow==='community' ? "show-me" : "", "care-site-tooltip community-tooltip");
    var careTooltipClassNames = classNames(this.props.currentTooltipToShow==='care' ? "show-me" : "", "care-site-tooltip care-tooltip");
    var homeTooltipClassNames = classNames(this.props.currentTooltipToShow==='home' ? "show-me" : "", "care-site-tooltip home-tooltip");

    return (
      <div className="tooltips-container">
        <div className={schoolTooltipClassNames}>
          <HexOverlay copy={this.getTitle('school')} size="medium" color='#2cb34b' outlineColor="#008343" title="School"></HexOverlay>
        </div>

        <div className={retirementTooltipClassNames}>
          <HexOverlay copy={this.getTitle('retirement')} size="medium" color='#9f87e8' outlineColor="#57448d" title="Retirement"></HexOverlay>
        </div>

        <div className={careTooltipClassNames}>
          <HexOverlay copy={this.getTitle('care')} size="medium" color='#f16f8e' outlineColor="#ed175e" title="Care"></HexOverlay>
        </div>

        <div className={communityTooltipClassNames}>
          <HexOverlay copy={this.getTitle('community')} size="medium" color='#e0c200' outlineColor="#a18b00" title="Community"></HexOverlay>
        </div>

        <div className={workTooltipClassNames}>
          <HexOverlay copy={this.getTitle('work')} size="medium" color='#fbb163' outlineColor="#f15a22" title="Work"></HexOverlay>
        </div>

        <div className={homeTooltipClassNames}>
          <HexOverlay copy={this.getTitle('home')} size="medium" color='#328fce' outlineColor="#0060a0" title="Home"></HexOverlay>
        </div>

      </div>
    );
  }
}

export default Tooltips;
