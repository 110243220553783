import React, { Component } from 'react';
import './MapSvg.scss';
import { TimelineMax, Power1 } from "gsap";
import classNames from "classnames";

const connectionSpeed = 2;
const dotSpeed = .2;

class MapSvg extends Component {
  constructor(props) {
    super(props);

    this.schoolRef = schoolTarget => this.setState({ schoolTarget });
    this.careRef = careTarget => this.setState({ careTarget });
    this.retirementRef = retirementTarget => this.setState({ retirementTarget });
    this.workRef = workTarget => this.setState({ workTarget });
    this.communityRef = communityTarget => this.setState({ communityTarget });
    this.homeRef = homeTarget => this.setState({ homeTarget });

    this.state = {
      introOn: this.props.introOn,
      animatedConnections: false,
      showSchoolTooltip: false,
      showRetirementTooltip: false,
      showCareTooltip: false,
      showWorkTooltip: false,
      showCommunityTooltip: false,
      showHomeTooltip: false,
    }
  }

  getTitle(section) {
    if (!this.props.data) return;
    var currentSectionObj = this.props.data.find(arrayItem => arrayItem.id === section);
    if (!currentSectionObj) return;
    var title = currentSectionObj.title;
    return title;
  }

  onMouseEnterLogic(section) {
    if (this.props.type === 'maxi') {
      this.props.toolTipOnMouseEnter(section);
    }
  }

  onMouseLeaveLogic(section) {
    if (this.props.type === 'maxi') {
      this.props.toolTipOnMouseLeave(section);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.introOn !== prevProps.introOn
      && !this.props.introOn
      && !this.animatedConnections
      && this.props.currentCategoryName === "map") {
        this.setState({animatedConnections: true});
        this.animateConnections();
    }
  }

  animateConnections() {
    return;

    if (this.props.type === "mini") return;

    var tlHome = new TimelineMax();
    var tlWork = new TimelineMax();
    var tlSchool = new TimelineMax();
    var tlRetirement = new TimelineMax();
    var tlCare = new TimelineMax();

    if (this.props.orientation === "portrait") {
      tlHome.from(this.homeConnection, connectionSpeed, { drawSVG:"0%", delay:.5, ease:Power1.easeOut });
      tlHome.from(this.homeDot, dotSpeed, { opacity: 0 });

      tlWork.from(this.workConnection, connectionSpeed, { drawSVG:"0%", delay:.5, ease:Power1.easeOut });
      tlWork.from(this.workDot, dotSpeed, { opacity: 0 });

      tlSchool.fromTo(this.schoolConnection, connectionSpeed,
        { drawSVG:"100% 100%" },
        { drawSVG:"0% 100%", delay:.5, ease:Power1.easeOut });
      tlSchool.from(this.schoolDot, dotSpeed, { opacity: 0 });

      tlRetirement.from(this.retirementConnection, connectionSpeed, { drawSVG:"0%", delay:.5, ease:Power1.easeOut });
      tlRetirement.from(this.retirementDot, dotSpeed, { opacity: 0 });

      tlCare.fromTo(this.careConnection, connectionSpeed,
        { drawSVG:"100% 100%" },
        { drawSVG:"0% 100%", delay:.5, ease:Power1.easeOut });
      tlCare.from(this.careDot, dotSpeed, { opacity: 0 });
    }

    else if (this.props.orientation === "landscape") {
      tlHome.from(this.homeConnection, connectionSpeed, { drawSVG:"0%", delay:.5, ease:Power1.easeOut });
      tlHome.from(this.homeDot, dotSpeed, { opacity: 0 });

      tlWork.fromTo(this.workConnection, connectionSpeed,
        { drawSVG:"100% 100%" },
        { drawSVG:"0% 100%", delay:.5, ease:Power1.easeOut });
      tlWork.from(this.workDot, dotSpeed, { opacity: 0 });

      tlSchool.from(this.schoolConnection, connectionSpeed, { drawSVG:"0%", delay:.5, ease:Power1.easeOut });
      tlSchool.from(this.schoolDot, dotSpeed, { opacity: 0 });

      tlRetirement.fromTo(this.retirementConnection, connectionSpeed,
        { drawSVG:"100% 100%" },
        { drawSVG:"0% 100%", delay:.5, ease:Power1.easeOut });
      tlRetirement.from(this.retirementDot, dotSpeed, { opacity: 0 });

      tlCare.from(this.careConnection, connectionSpeed, { drawSVG:"0%", delay:.5, ease:Power1.easeOut });
      tlCare.from(this.careDot, dotSpeed, { opacity: 0 });
    }
  }

  render() {
    let homeClassNames = classNames(this.props.homeColored ? 'color' : 'gray', 'category',
      (this.props.currentCategoryName === "map" || this.props.currentCategoryName === "home") ? '' : 'hide-svg',
      this.props.animateDoor ? "animate-door" : "");
    let communityClassNames = classNames(this.props.communityColored ? 'color' : 'gray', 'category',
      (this.props.currentCategoryName === "map" || this.props.currentCategoryName === "community") ? '' : 'hide-svg');
    let workClassNames = classNames(this.props.workColored ? 'color' : 'gray', 'category',
      (this.props.currentCategoryName === "map" || this.props.currentCategoryName === "work") ? '' : 'hide-svg');
    let schoolClassNames = classNames(this.props.schoolColored ? 'color' : 'gray', 'category',
      (this.props.currentCategoryName === "map" || this.props.currentCategoryName === "school") ? '' : 'hide-svg');
    let careClassNames = classNames(this.props.careColored ? 'color' : 'gray', 'category',
      (this.props.currentCategoryName === "map" || this.props.currentCategoryName === "care") ? '' : 'hide-svg');
    let retirementClassNames = classNames(this.props.retirementColored ? 'color' : 'gray', 'category',
      (this.props.currentCategoryName === "map" || this.props.currentCategoryName === "retirement") ? '' : 'hide-svg');

    if (this.props.orientation === 'portrait') {
      return (
        <div className="svg-container portrait-svg">







          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 375 667">
            <title>mapportraitfinal_v3-01-01</title>
            <g id="mask">
              <g className="cls-2">
              <g id="care"
                 onClick={() => this.props.mapClick('care')}
                 className={careClassNames}>
                  <path className="cls-3" d="M275.83,291.62l83.25,47.46v78.43L303.6,449.4l-49.05-27.92,46.21-26.78"/>
                  <circle id="care_dot" data-name="care dot" className="cls-4" cx="275.79" cy="291.63" r="1"/>
                  <g id="care_color" data-name="care color">
                    <path className="cls-5" d="M271.1,378v-23.9l19.75,11.12a.28.28,0,0,0,.15,0,.29.29,0,0,0,.16,0,.32.32,0,0,0,.16-.27V351l17.94,10.16v38.33Z"/>
                    <path className="cls-6" d="M291.63,351.58l17.32,9.8V399L271.41,377.8V354.62l19.29,10.85a.54.54,0,0,0,.3.08.72.72,0,0,0,.32-.08.63.63,0,0,0,.31-.54V351.58m-.63-1.07v14.42l-20.21-11.38v24.61l38.78,21.9V361L291,350.51Z"/>
                    <polygon className="cls-4" points="309.57 361.02 291 350.51 291 364.93 270.79 353.55 289.32 343.03 328.13 364.94 309.57 375.45 309.57 361.02"/>
                    <polygon className="cls-4" points="309.54 339.99 328.13 350.51 309.57 361.02 291 350.51 309.54 339.99"/>
                    <polygon className="cls-4" points="328.13 350.51 328.13 364.94 328.13 389.55 309.57 400.06 309.57 375.45 309.57 361.02 328.13 350.51"/>
                    <polygon className="cls-7" points="297.68 393.01 297.68 384.8 302.15 387.33 302.15 395.54 297.68 393.01"/>
                    <path className="cls-4" d="M297.83,385.07l4.16,2.35v7.85l-4.16-2.35v-7.85m-.31-.53v8.56l4.78,2.71v-8.57l-4.78-2.7Z"/>
                    <polygon className="cls-7" points="286.89 383 286.89 378.43 290.94 380.72 290.94 385.29 286.89 383"/>
                    <path className="cls-4" d="M287.05,378.7l3.73,2.11V385l-3.73-2.11V378.7m-.31-.54v4.93l4.35,2.46v-4.92l-4.35-2.47Z"/>
                    <polygon className="cls-7" points="276.83 377.19 276.83 372.62 280.87 374.91 280.87 379.48 276.83 377.19"/>
                    <path className="cls-4" d="M277,372.89l3.73,2.11v4.21L277,377.1v-4.21m-.31-.54v4.93l4.35,2.47v-4.93l-4.35-2.47Z"/>
                    <polygon className="cls-7" points="287.8 373.68 287.8 369.11 291.84 371.4 291.84 375.97 287.8 373.68"/>
                    <path className="cls-4" d="M288,369.38l3.73,2.11v4.21L288,373.59v-4.21m-.31-.53v4.92l4.35,2.47v-4.93l-4.35-2.46Z"/>
                    <polygon className="cls-7" points="298.31 379.7 298.31 375.13 302.35 377.42 302.35 381.99 298.31 379.7"/>
                    <path className="cls-4" d="M298.46,375.4l3.73,2.11v4.21l-3.73-2.11V375.4m-.31-.54v4.93l4.35,2.46v-4.92l-4.35-2.47Z"/>
                    <polygon className="cls-7" points="277.28 367.67 277.28 363.1 281.32 365.38 281.32 369.95 277.28 367.67"/>
                    <path className="cls-4" d="M277.44,363.36l3.73,2.11v4.22l-3.73-2.12v-4.21m-.31-.53v4.93l4.35,2.46v-4.93l-4.35-2.46Z"/>
                    <path className="cls-8" d="M296.8,358.91c1.3.16,2.77,1.71,3.29,3.47.51-1.18,2-1.07,3.28.25s1.94,3.34,1.43,4.53a2.54,2.54,0,0,1-.27.41,1.18,1.18,0,0,1-.28.23l-4.16,2.42h0l-4.17-7.13c-.1-.17-.28-.54-.28-.54-.1-.24-.19-.48-.27-.73-.51-1.76.13-3.07,1.43-2.91"/>
                  </g>
                  <g id="care_gray" data-name="care gray">
                    <path className="cls-9" d="M271,378V354l19.84,11.17a.27.27,0,0,0,.12,0,.28.28,0,0,0,.13,0,.25.25,0,0,0,.12-.22v-14l18.07,10.22v38.47Z"/>
                    <path className="cls-10" d="M291.5,351.37l17.57,9.94V399.2l-37.78-21.33V354.41l19.47,11a.53.53,0,0,0,.24.06.48.48,0,0,0,.26-.07.5.5,0,0,0,.24-.43V351.37m-.5-.86v14.42l-20.21-11.38v24.61l38.78,21.9V361L291,350.51Z"/>
                    <path className="cls-10" d="M309.83,361a.25.25,0,0,0-.13-.23l-18.57-10.51a.28.28,0,0,0-.13,0,.33.33,0,0,0-.13,0,.26.26,0,0,0-.13.23v14l-19.41-10.93,18-10.21,38.27,21.6L309.83,375Z"/>
                    <path className="cls-11" d="M289.33,343.64l37.72,21.3-16.95,9.6V361a.55.55,0,0,0-.27-.47l-18.56-10.5A.54.54,0,0,0,291,350a.48.48,0,0,0-.26.07.53.53,0,0,0-.27.46V364l-18.61-10.47,17.47-9.91m0-.61-18.53,10.52L291,364.93V350.51L309.57,361v14.43l18.56-10.51L289.32,343Z"/>
                    <polygon className="cls-10" points="291.54 350.51 309.54 340.29 327.59 350.51 309.57 360.71 291.54 350.51"/>
                    <path className="cls-11" d="M309.54,340.6l17.51,9.91-17.48,9.9-17.49-9.9,17.46-9.91m0-.61L291,350.51,309.57,361l18.56-10.51L309.54,340Z"/>
                    <polygon className="cls-10" points="309.83 361.17 327.87 350.96 327.87 389.4 309.83 399.61 309.83 361.17"/>
                    <path className="cls-11" d="M327.6,351.42v37.82l-17.5,9.91V361.32l17.5-9.9m.53-.91L309.57,361v39l18.56-10.51v-39Z"/>
                    <polygon className="cls-9" points="297.7 393 297.7 384.83 302.13 387.34 302.13 395.51 297.7 393"/>
                    <path className="cls-11" d="M297.87,385.14l4.08,2.31v7.76l-4.08-2.31v-7.76m-.35-.6v8.56l4.78,2.71v-8.57l-4.78-2.7Z"/>
                    <polygon className="cls-9" points="286.91 382.99 286.91 378.46 290.92 380.73 290.92 385.25 286.91 382.99"/>
                    <path className="cls-11" d="M287.09,378.76l3.65,2.07V385l-3.65-2.06v-4.13m-.35-.6v4.93l4.35,2.46v-4.92l-4.35-2.47Z"/>
                    <polygon className="cls-9" points="276.85 377.18 276.85 372.65 280.85 374.92 280.85 379.45 276.85 377.18"/>
                    <path className="cls-11" d="M277,373l3.65,2.06v4.13L277,377.08V373m-.35-.6v4.92l4.35,2.47v-4.93l-4.35-2.46Z"/>
                    <polygon className="cls-9" points="287.81 373.67 287.81 369.15 291.82 371.41 291.82 375.94 287.81 373.67"/>
                    <path className="cls-11" d="M288,369.45l3.65,2.06v4.13L288,373.57v-4.12m-.35-.6v4.92l4.35,2.47v-4.93l-4.35-2.46Z"/>
                    <polygon className="cls-9" points="298.33 379.69 298.33 375.16 302.33 377.43 302.33 381.95 298.33 379.69"/>
                    <path className="cls-11" d="M298.5,375.46l3.65,2.07v4.12l-3.65-2.06v-4.13m-.35-.6v4.93l4.35,2.46v-4.92l-4.35-2.47Z"/>
                    <polygon className="cls-9" points="277.3 367.65 277.3 363.13 281.31 365.39 281.31 369.92 277.3 367.65"/>
                    <path className="cls-11" d="M277.48,363.43l3.65,2.07v4.12l-3.65-2.07v-4.12m-.35-.6v4.93l4.35,2.46v-4.93l-4.35-2.46Z"/>
                    <path className="cls-12" d="M296.8,358.91c1.3.16,2.77,1.71,3.29,3.47.51-1.18,2-1.07,3.28.25s1.94,3.34,1.43,4.53a2.54,2.54,0,0,1-.27.41,1.18,1.18,0,0,1-.28.23l-4.16,2.42h0l-4.17-7.13c-.1-.17-.28-.54-.28-.54-.1-.24-.19-.48-.27-.73-.51-1.76.13-3.07,1.43-2.91"/>
                  </g>
                  <g id="care_title" data-name="care title">
                    <path className="cls-4" d="M239.66,364.9h-2a2.43,2.43,0,0,1-1.53-.41,1.5,1.5,0,0,1-.49-1.24v-2.51a1.52,1.52,0,0,1,.49-1.25,2.43,2.43,0,0,1,1.53-.41h2a.3.3,0,0,1,.23.07.28.28,0,0,1,.07.22v1a.29.29,0,0,1-.07.22.3.3,0,0,1-.23.07H238a.58.58,0,0,0-.38.1.38.38,0,0,0-.13.3V363a.35.35,0,0,0,.13.29.64.64,0,0,0,.38.09h1.65a.35.35,0,0,1,.23.07.29.29,0,0,1,.07.22v1a.3.3,0,0,1-.07.23A.35.35,0,0,1,239.66,364.9Z"/>
                    <path className="cls-4" d="M242.26,364.9H241a.37.37,0,0,1-.23-.07.35.35,0,0,1-.06-.23v-5.23a.32.32,0,0,1,.06-.22.32.32,0,0,1,.23-.07h1.28a.25.25,0,0,1,.29.29v1.75h1.38v-1.75a.28.28,0,0,1,.07-.22.3.3,0,0,1,.23-.07h1.27a.3.3,0,0,1,.23.07.28.28,0,0,1,.07.22v5.23a.3.3,0,0,1-.07.23.35.35,0,0,1-.23.07h-1.27a.35.35,0,0,1-.23-.07.3.3,0,0,1-.07-.23v-1.79h-1.38v1.79a.3.3,0,0,1-.07.23A.32.32,0,0,1,242.26,364.9Z"/>
                    <path className="cls-4" d="M246.9,364.9a.35.35,0,0,1-.23-.07.3.3,0,0,1-.07-.23v-1a.29.29,0,0,1,.07-.22.35.35,0,0,1,.23-.07h.81v-2.71h-.81a.3.3,0,0,1-.23-.07.29.29,0,0,1-.07-.22v-1a.28.28,0,0,1,.07-.22.3.3,0,0,1,.23-.07h3.51a.25.25,0,0,1,.29.29v1a.29.29,0,0,1-.07.22.28.28,0,0,1-.22.07h-.8v2.71h.8a.32.32,0,0,1,.22.07.29.29,0,0,1,.07.22v1a.3.3,0,0,1-.07.23.32.32,0,0,1-.22.07Z"/>
                    <path className="cls-4" d="M256,364.9h-4.19a.35.35,0,0,1-.23-.07.3.3,0,0,1-.07-.23v-5.23a.28.28,0,0,1,.07-.22.3.3,0,0,1,.23-.07h1.27a.3.3,0,0,1,.23.07.28.28,0,0,1,.07.22v4h1.13v-1.31a.28.28,0,0,1,.07-.22.29.29,0,0,1,.22-.07H256a.25.25,0,0,1,.3.29v2.54a.35.35,0,0,1-.07.23A.37.37,0,0,1,256,364.9Z"/>
                    <path className="cls-4" d="M260.1,364.9h-2.77a.35.35,0,0,1-.23-.07.3.3,0,0,1-.07-.23v-5.23a.28.28,0,0,1,.07-.22.3.3,0,0,1,.23-.07h2.77a2.46,2.46,0,0,1,1.53.4,1.53,1.53,0,0,1,.5,1.25v2.52a1.51,1.51,0,0,1-.5,1.24A2.39,2.39,0,0,1,260.1,364.9Zm-1.2-4.28v2.75h1a.37.37,0,0,0,.29-.1.41.41,0,0,0,.1-.3v-2a.4.4,0,0,0-.1-.29.37.37,0,0,0-.29-.1Z"/>
                    <path className="cls-4" d="M245,373.59h-2a2.43,2.43,0,0,1-1.53-.4A1.5,1.5,0,0,1,241,372v-2.52a1.5,1.5,0,0,1,.49-1.24,2.37,2.37,0,0,1,1.53-.41h2a.3.3,0,0,1,.23.07.28.28,0,0,1,.07.22v1a.29.29,0,0,1-.07.22.35.35,0,0,1-.23.07h-1.65a.58.58,0,0,0-.38.1.38.38,0,0,0-.13.3v1.92a.36.36,0,0,0,.13.3.64.64,0,0,0,.38.09H245a.3.3,0,0,1,.23.07.29.29,0,0,1,.07.22v1a.26.26,0,0,1-.07.22A.3.3,0,0,1,245,373.59Z"/>
                    <path className="cls-4" d="M247.74,373.3a.26.26,0,0,1-.07.22.28.28,0,0,1-.22.07h-1.21a.3.3,0,0,1-.23-.07.26.26,0,0,1-.07-.22v-2.13a2.51,2.51,0,0,1,.08-.56,5.57,5.57,0,0,1,.24-.73L247,368a.38.38,0,0,1,.15-.2.54.54,0,0,1,.27-.06h2.21a.48.48,0,0,1,.26.06.46.46,0,0,1,.16.2l.71,1.84c.11.28.19.52.25.73a2.44,2.44,0,0,1,.07.56v2.13a.26.26,0,0,1-.07.22.28.28,0,0,1-.22.07h-1.25a.33.33,0,0,1-.24-.07.27.27,0,0,1-.08-.22v-1.13h-1.46Zm.47-3.83-.38,1.23h1.29l-.39-1.23a.26.26,0,0,0-.07-.1.11.11,0,0,0-.08,0h-.21l-.09,0Z"/>
                    <path className="cls-4" d="M253.44,373.59h-1.27a.3.3,0,0,1-.23-.07.26.26,0,0,1-.07-.22v-5.23a.28.28,0,0,1,.07-.22.3.3,0,0,1,.23-.07h2.74a1.69,1.69,0,0,1,1.27.37,1.36,1.36,0,0,1,.35,1v.37a1.47,1.47,0,0,1-.1.58.63.63,0,0,1-.36.34,1.08,1.08,0,0,1,.72.35,1.17,1.17,0,0,1,.28.82v1.71a.26.26,0,0,1-.07.22.3.3,0,0,1-.23.07H255.5a.3.3,0,0,1-.23-.07.26.26,0,0,1-.07-.22v-1.23a.41.41,0,0,0-.07-.27.33.33,0,0,0-.24-.08h-1.16v1.58a.26.26,0,0,1-.07.22A.28.28,0,0,1,253.44,373.59Zm.29-4.36v1h.62a.34.34,0,0,0,.29-.11.5.5,0,0,0,.07-.27v-.28a.45.45,0,0,0-.07-.27.34.34,0,0,0-.29-.11Z"/>
                    <path className="cls-4" d="M261.91,373.59h-3.83a.28.28,0,0,1-.22-.07.26.26,0,0,1-.07-.22v-5.23a.25.25,0,0,1,.29-.29h3.83a.25.25,0,0,1,.29.29V369a.3.3,0,0,1-.07.23.32.32,0,0,1-.22.06h-2.28v.63h1.61a.32.32,0,0,1,.23.07.3.3,0,0,1,.07.22v.86a.25.25,0,0,1-.3.29h-1.61v.7h2.28a.25.25,0,0,1,.29.29v.94a.26.26,0,0,1-.07.22A.28.28,0,0,1,261.91,373.59Z"/>
                  </g>
                </g>
                <g id="community"
                   onClick={() => this.props.mapClick('community')}
                   className={communityClassNames}>
                  <rect id="community_bg" data-name="community bg" className="cls-13" x="67.19" y="193.6" width="240.62" height="145.22"/>
                  <g id="community_color" data-name="community color">
                    <polygon className="cls-14" points="192.95 298.4 204.16 291.92 204.16 284.1 192.95 290.29 192.95 298.4"/>
                    <polygon className="cls-15" points="181.99 291.78 181.99 284.19 187.45 281.13 192.7 290.35 192.7 297.96 181.99 291.78"/>
                    <path className="cls-16" d="M187.36,281.46l5.09,9v7.11l-10.21-5.9v-7.3l5.12-2.87m.19-.67L181.74,284v7.88L193,298.4v-8.12l-5.4-9.49Z"/>
                    <polygon className="cls-14" points="192.95 290.29 204.16 284.1 198.41 274.6 187.55 280.79 192.95 290.29"/>
                    <polygon className="cls-17" points="188.88 293.34 188.88 291.25 190.73 292.3 190.73 294.38 188.88 293.34"/>
                    <path className="cls-16" d="M189.06,291.55l1.5.85v1.68l-1.5-.84v-1.69m-.35-.6v2.49l2.2,1.24V292.2l-2.2-1.25Z"/>
                    <polygon className="cls-17" points="184.15 293.12 184.15 289.02 186.31 290.25 186.31 294.34 184.15 293.12"/>
                    <path className="cls-16" d="M184.33,289.32l1.81,1V294l-1.81-1v-3.7m-.35-.6v4.5l2.51,1.42v-4.5L184,288.72Z"/>
                    <polygon className="cls-14" points="111.22 264.08 121.94 257.89 121.94 250.41 111.22 256.32 111.22 264.08"/>
                    <polygon className="cls-15" points="100.75 257.75 100.75 250.5 105.97 247.58 110.97 256.39 110.97 263.65 100.75 257.75"/>
                    <path className="cls-16" d="M105.87,247.92l4.85,8.54v6.75L101,257.6v-7l4.87-2.73m.19-.68-5.56,3.11v7.54l10.72,6.19v-7.75l-5.16-9.09Z"/>
                    <polygon className="cls-14" points="111.22 256.32 121.94 250.41 116.45 241.33 106.06 247.24 111.22 256.32"/>
                    <polygon className="cls-17" points="107.35 259.24 107.35 257.26 109.1 258.25 109.1 260.23 107.35 259.24"/>
                    <path className="cls-16" d="M107.52,257.56l1.4.8v1.57l-1.4-.79v-1.58m-.35-.6v2.38l2.1,1.19v-2.38l-2.1-1.19Z"/>
                    <polygon className="cls-17" points="102.82 259.03 102.82 255.13 104.87 256.29 104.87 260.19 102.82 259.03"/>
                    <path className="cls-16" d="M103,255.43l1.7,1v3.5l-1.7-1v-3.5m-.35-.6v4.3l2.4,1.36v-4.3l-2.4-1.36Z"/>
                    <polygon className="cls-15" points="141.19 307.34 141.19 299.05 155.37 307.07 155.37 315.36 141.19 307.34"/>
                    <path className="cls-16" d="M141.44,299.48l13.67,7.74v7.71l-13.67-7.74v-7.71m-.5-.86v8.86l14.67,8.31v-8.87l-14.67-8.3Z"/>
                    <polygon className="cls-14" points="170.28 307.48 155.61 315.79 155.61 306.92 170.28 298.62 170.28 307.48"/>
                    <polygon className="cls-17" points="150.32 312.53 150.32 307.73 152.87 309.18 152.87 313.98 150.32 312.53"/>
                    <path className="cls-16" d="M150.49,308l2.21,1.25v4.4l-2.21-1.25V308m-.35-.6v5.2l2.91,1.65v-5.21l-2.91-1.64Z"/>
                    <polygon className="cls-14" points="155.59 290.31 170.28 298.62 155.62 306.93 140.94 298.62 155.59 290.31"/>
                    <polygon className="cls-17" points="143.1 306.26 143.1 303.96 147.97 306.69 147.97 309 143.1 306.26"/>
                    <path className="cls-16" d="M143.28,304.26l4.51,2.54v1.9l-4.51-2.54v-1.9m-.35-.59v2.7l5.21,2.92v-2.7l-5.21-2.92Z"/>
                    <polygon className="cls-15" points="240.65 259.3 240.65 256.27 245.96 259.28 245.96 262.31 240.65 259.3"/>
                    <path className="cls-16" d="M240.81,256.55l5,2.82V262l-5-2.82v-2.66m-.33-.56v3.41l5.65,3.19v-3.41L240.48,256Z"/>
                    <polygon className="cls-14" points="251.77 259.4 246.13 262.59 246.13 259.18 251.77 255.99 251.77 259.4"/>
                    <polygon className="cls-17" points="244.06 261.3 244.1 259.52 245.1 260.08 245.06 261.86 244.06 261.3"/>
                    <path className="cls-16" d="M244.2,259.67l.77.44v1.6l-.77-.43v-1.61m-.18-.29v2l1.12.63v-2l-1.12-.63Z"/>
                    <polygon className="cls-14" points="246.12 252.79 251.77 255.99 246.13 259.18 240.48 255.99 246.12 252.79"/>
                    <polygon className="cls-17" points="241.29 258.89 241.33 258.07 243.21 259.13 243.17 259.95 241.29 258.89"/>
                    <path className="cls-16" d="M241.42,258.22l1.66.94v.64l-1.66-.93v-.65m-.17-.29v1l2,1.12v-1l-2-1.12Z"/>
                    <polygon className="cls-15" points="151.62 282.67 151.62 273.16 160.14 277.98 160.14 287.5 151.62 282.67"/>
                    <path className="cls-16" d="M151.86,273.59l8,4.54v8.94l-8-4.54v-8.94m-.5-.86v10.09l9,5.11V277.84l-9-5.11Z"/>
                    <polygon className="cls-17" points="156.97 280.02 156.97 278.58 158.25 279.3 158.25 280.74 156.97 280.02"/>
                    <path className="cls-16" d="M157.15,278.88l.93.53v1l-.93-.52v-1m-.35-.6v1.84l1.63.92V279.2l-1.63-.92Z"/>
                    <polygon className="cls-17" points="153.5 278.05 153.5 276.62 154.78 277.34 154.78 278.78 153.5 278.05"/>
                    <path className="cls-16" d="M153.68,276.92l.92.52v1l-.92-.53v-1m-.35-.6v1.84l1.62.92v-1.84l-1.62-.92Z"/>
                    <polygon className="cls-14" points="169.41 282.82 160.39 287.93 160.39 277.84 169.41 272.73 169.41 282.82"/>
                    <polygon className="cls-17" points="155.16 284.76 155.16 281.96 156.59 282.77 156.59 285.57 155.16 284.76"/>
                    <path className="cls-16" d="M155.33,282.26l1.09.61v2.4l-1.09-.62v-2.39m-.35-.6v3.2l1.79,1v-3.2l-1.79-1Z"/>
                    <polygon className="cls-14" points="160.37 267.62 169.41 272.73 160.39 277.84 151.36 272.73 160.37 267.62"/>
                    <polygon className="cls-15" points="244.58 247.39 244.63 241.9 249.62 244.72 249.57 250.21 244.58 247.39"/>
                    <path className="cls-16" d="M244.73,242.07l4.74,2.68V250l-4.74-2.68v-5.29m-.19-.34v5.74l5.13,2.91v-5.74l-5.13-2.91Z"/>
                    <polygon className="cls-17" points="247.75 245.87 247.75 245.1 248.43 245.49 248.43 246.25 247.75 245.87"/>
                    <path className="cls-16" d="M247.88,245.32l.42.24V246l-.42-.24v-.47m-.25-.43v1.05l.92.52v-1.05l-.92-.52Z"/>
                    <polygon className="cls-17" points="245.78 244.75 245.78 243.99 246.45 244.37 246.45 245.13 245.78 244.75"/>
                    <path className="cls-16" d="M245.9,244.2l.43.24v.48l-.43-.25v-.47m-.25-.43v1l.93.52v-1l-.93-.53Z"/>
                    <polygon className="cls-14" points="254.8 247.47 249.67 250.38 249.67 244.64 254.8 241.73 254.8 247.47"/>
                    <polygon className="cls-17" points="246.72 248.56 246.72 247.03 247.49 247.46 247.49 248.99 246.72 248.56"/>
                    <path className="cls-16" d="M246.84,247.24l.52.29v1.25l-.52-.29v-1.25m-.25-.43v1.82l1,.58v-1.82l-1-.58Z"/>
                    <polygon className="cls-14" points="249.66 238.82 254.8 241.73 249.67 244.64 244.54 241.73 249.66 238.82"/>
                    <polygon className="cls-14" points="191.29 336.53 208.48 326.61 208.48 314.61 191.29 324.1 191.29 336.53"/>
                    <polygon className="cls-15" points="174.35 326.46 174.35 314.67 182.92 309.88 191.04 324.16 191.04 336.1 174.35 326.46"/>
                    <path className="cls-16" d="M182.82,310.21l8,14v11.44l-16.19-9.35v-11.5l8.22-4.61m.19-.67-8.91,5v12.09l17.19,9.92V324.1L183,309.54Z"/>
                    <polygon className="cls-14" points="191.29 324.1 208.48 314.61 199.66 300.05 183.01 309.54 191.29 324.1"/>
                    <polygon className="cls-17" points="184.97 328.83 184.97 325.42 187.99 327.13 187.99 330.54 184.97 328.83"/>
                    <path className="cls-16" d="M185.14,325.72l2.67,1.51v3l-2.67-1.51v-3m-.35-.6v3.81l3.37,1.91V327l-3.37-1.91Z"/>
                    <polygon className="cls-17" points="177.71 328.5 177.71 322 181.21 323.98 181.21 330.48 177.71 328.5"/>
                    <path className="cls-16" d="M177.89,322.3l3.15,1.79v6.09l-3.15-1.78v-6.1m-.35-.6v6.9l3.85,2.18v-6.9l-3.85-2.18Z"/>
                    <path className="cls-15" d="M245.12,280.7V267.4l11,6.2.12,0a.28.28,0,0,0,.13,0,.26.26,0,0,0,.12-.22v-7.69l10,5.63v21.43Z"/>
                    <path className="cls-16" d="M256.76,266.12l9.46,5.35v20.86l-20.85-11.78V267.83l10.65,6a.5.5,0,0,0,.24.06.51.51,0,0,0,.25-.06.5.5,0,0,0,.25-.44v-7.26m-.5-.86v8.12L244.87,267v13.87l21.85,12.34v-22l-10.46-5.92Z"/>
                    <polygon className="cls-14" points="266.72 271.18 256.26 265.26 256.26 273.38 244.87 266.97 255.31 261.04 277.18 273.39 266.72 279.31 266.72 271.18"/>
                    <polygon className="cls-14" points="266.71 259.33 277.18 265.26 266.72 271.18 256.26 265.26 266.71 259.33"/>
                    <polygon className="cls-14" points="277.18 265.26 277.18 273.39 277.18 287.26 266.72 293.18 266.72 279.31 266.72 271.18 277.18 265.26"/>
                    <polygon className="cls-18" points="260.11 289.16 260.11 284.73 262.45 286.06 262.45 290.49 260.11 289.16"/>
                    <path className="cls-16" d="M260.28,285l2,1.13v4l-2-1.13v-4m-.35-.6v4.83l2.7,1.53V286l-2.7-1.53Z"/>
                    <polygon className="cls-18" points="254.03 283.52 254.03 281.14 256.13 282.33 256.13 284.71 254.03 283.52"/>
                    <path className="cls-16" d="M254.21,281.44l1.75,1v2l-1.75-1v-2m-.35-.6v2.78l2.45,1.39v-2.78l-2.45-1.39Z"/>
                    <polygon className="cls-18" points="248.36 280.24 248.36 277.87 250.47 279.06 250.47 281.43 248.36 280.24"/>
                    <path className="cls-16" d="M248.54,278.17l1.75,1v2l-1.75-1v-2m-.35-.6v2.78l2.45,1.38V279l-2.45-1.39Z"/>
                    <polygon className="cls-18" points="254.54 278.27 254.54 275.89 256.64 277.08 256.64 279.46 254.54 278.27"/>
                    <path className="cls-16" d="M254.72,276.19l1.75,1v2l-1.75-1v-2m-.35-.6v2.78l2.45,1.39V277l-2.45-1.39Z"/>
                    <polygon className="cls-18" points="260.46 281.66 260.46 279.28 262.57 280.47 262.57 282.85 260.46 281.66"/>
                    <path className="cls-16" d="M260.64,279.58l1.75,1v2l-1.75-1v-2m-.35-.6v2.78l2.45,1.39v-2.78L260.29,279Z"/>
                    <polygon className="cls-18" points="248.62 274.88 248.62 272.5 250.72 273.69 250.72 276.06 248.62 274.88"/>
                    <path className="cls-16" d="M248.79,272.8l1.76,1v2l-1.76-1v-2m-.35-.6V275l2.46,1.39v-2.78l-2.46-1.39Z"/>
                    <path className="cls-19" d="M259.53,270a2.74,2.74,0,0,1,1.85,2c.29-.67,1.12-.6,1.85.14a2.69,2.69,0,0,1,.81,2.55,1.32,1.32,0,0,1-.16.23.45.45,0,0,1-.15.13l-2.35,1.36h0s-2.29-3.91-2.35-4l-.15-.31a2.9,2.9,0,0,1-.16-.41c-.28-1,.08-1.73.81-1.64"/>
                    <polygon className="cls-15" points="225.32 236.99 225.32 215.63 235.35 221.31 235.35 242.66 225.32 236.99"/>
                    <path className="cls-16" d="M225.57,216.06l9.53,5.4v20.77l-9.53-5.39V216.06m-.5-.86v21.93l10.53,6V221.16l-10.53-6Z"/>
                    <polygon className="cls-18" points="231.59 233.88 231.59 232.13 233.13 233.01 233.13 234.76 231.59 233.88"/>
                    <path className="cls-16" d="M231.76,232.43l1.2.68v1.35l-1.2-.68v-1.35m-.35-.6V234l1.9,1.08v-2.15l-1.9-1.08Z"/>
                    <polygon className="cls-18" points="227.53 231.59 227.53 229.84 229.08 230.72 229.08 232.46 227.53 231.59"/>
                    <path className="cls-16" d="M227.71,230.14l1.19.68v1.34l-1.19-.68v-1.34m-.35-.6v2.15l1.89,1.07v-2.15l-1.89-1.07Z"/>
                    <polygon className="cls-18" points="231.59 228.91 231.59 227.17 233.13 228.04 233.13 229.79 231.59 228.91"/>
                    <path className="cls-16" d="M231.76,227.47l1.2.67v1.35l-1.2-.68v-1.34m-.35-.6V229l1.9,1.08v-2.15l-1.9-1.07Z"/>
                    <polygon className="cls-18" points="227.53 226.62 227.53 224.87 229.08 225.75 229.08 227.49 227.53 226.62"/>
                    <path className="cls-16" d="M227.71,225.17l1.19.68v1.34l-1.19-.68v-1.34m-.35-.6v2.15l1.89,1.07v-2.15l-1.89-1.07Z"/>
                    <polygon className="cls-18" points="231.59 223.94 231.59 222.2 233.13 223.07 233.13 224.82 231.59 223.94"/>
                    <path className="cls-16" d="M231.76,222.5l1.2.67v1.35l-1.2-.68V222.5m-.35-.6V224l1.9,1.08V223l-1.9-1.07Z"/>
                    <polygon className="cls-18" points="227.53 221.65 227.53 219.9 229.08 220.78 229.08 222.52 227.53 221.65"/>
                    <path className="cls-16" d="M227.71,220.2l1.19.68v1.34l-1.19-.67V220.2m-.35-.6v2.15l1.89,1.07v-2.14l-1.89-1.08Z"/>
                    <polygon className="cls-14" points="246.13 237.13 235.6 243.09 235.6 221.16 246.13 215.2 246.13 237.13"/>
                    <polygon className="cls-18" points="229.47 239.41 229.47 236.07 231.2 237.06 231.2 240.39 229.47 239.41"/>
                    <path className="cls-16" d="M229.64,236.37l1.39.79v2.93l-1.39-.78v-2.94m-.35-.6v3.74l2.09,1.18V237l-2.09-1.18Z"/>
                    <polygon className="cls-14" points="235.58 209.24 246.13 215.2 235.6 221.16 225.07 215.2 235.58 209.24"/>
                    <polygon className="cls-14" points="162.49 230.88 153.71 235.95 153.71 223.31 162.53 218.32 162.49 230.88"/>
                    <polygon className="cls-14" points="157.86 215.63 162.53 218.32 153.71 223.31 149.06 220.63 157.86 215.63"/>
                    <polygon className="cls-14" points="144.69 206.6 152.41 202.1 157.85 209.07 157.85 215.63 149.07 220.6 149.07 214.04 144.69 206.6"/>
                    <path className="cls-15" d="M135.78,225.35V213.43l4.39,2.42.12,0a.31.31,0,0,0,.13,0,.27.27,0,0,0,.12-.22V209.2l4.06-2.26,4.22,7.17v6.49a.25.25,0,0,0,.13.22l4.51,2.63v12.07Z"/>
                    <path className="cls-16" d="M144.51,207.27l4.06,6.91v6.42a.52.52,0,0,0,.25.44l4.39,2.56v11.49L136,225.2V213.85l4,2.22a.5.5,0,0,0,.24.06.43.43,0,0,0,.25-.07.48.48,0,0,0,.25-.43v-6.28l3.72-2.08m.18-.67-4.41,2.47h0v6.56L135.53,213v12.49L153.71,236V223.31l-4.64-2.71V214l-4.38-7.44Z"/>
                    <polygon className="cls-18" points="138.62 225.26 138.62 223.19 140.46 224.23 140.46 226.3 138.62 225.26"/>
                    <path className="cls-16" d="M138.79,223.49l1.49.84V226l-1.49-.84v-1.67m-.35-.6v2.47l2.19,1.24v-2.48l-2.19-1.23Z"/>
                    <polygon className="cls-18" points="148.82 230.96 148.82 228.88 150.65 229.92 150.65 232 148.82 230.96"/>
                    <path className="cls-16" d="M149,229.18l1.49.84v1.68l-1.49-.85v-1.67m-.35-.6v2.48l2.19,1.24v-2.48l-2.19-1.24Z"/>
                    <polygon className="cls-18" points="138.62 220.19 138.62 218.12 140.46 219.16 140.46 221.23 138.62 220.19"/>
                    <path className="cls-16" d="M138.79,218.42l1.49.84v1.67l-1.49-.84v-1.67m-.35-.6v2.47l2.19,1.24v-2.48l-2.19-1.23Z"/>
                    <polygon className="cls-18" points="148.82 225.9 148.82 223.83 150.65 224.87 150.65 226.94 148.82 225.9"/>
                    <path className="cls-16" d="M149,224.13l1.49.84v1.67L149,225.8v-1.67m-.35-.6V226l2.19,1.23v-2.47l-2.19-1.24Z"/>
                    <polygon className="cls-18" points="143.72 223.04 143.72 220.97 145.56 222.01 145.56 224.08 143.72 223.04"/>
                    <path className="cls-16" d="M143.89,221.27l1.49.84v1.67l-1.49-.84v-1.67m-.35-.6v2.47l2.19,1.24v-2.47l-2.19-1.24Z"/>
                    <polygon className="cls-18" points="143.6 229.9 143.6 225.94 145.65 227.11 145.65 231.06 143.6 229.9"/>
                    <path className="cls-16" d="M143.77,226.24l1.7,1v3.55l-1.7-1v-3.56m-.35-.6V230l2.4,1.36V227l-2.4-1.36Z"/>
                    <path className="cls-14" d="M144.64,212.48a4.58,4.58,0,0,1,2.11,3.59c0,1.32-.95,1.86-2.11,1.2a4.58,4.58,0,0,1-2.12-3.6C142.52,212.35,143.47,211.82,144.64,212.48Z"/>
                    <polygon className="cls-16" points="143.33 214.43 144.93 215.33 144.93 213.5 144.51 213.26 144.51 214.63 143.33 213.97 143.33 214.43"/>
                    <polygon className="cls-14" points="140.29 210.3 140.29 215.62 140.28 215.63 138.97 214.9 135.53 213 140.29 210.3"/>
                    <polygon className="cls-15" points="100.75 283.97 100.77 272.6 104.83 270.33 109.03 277.48 109.02 288.64 100.75 283.97"/>
                    <path className="cls-16" d="M104.73,270.67l4,6.88v10.67l-7.77-4.4,0-11.07,3.71-2.08m.19-.67-4.4,2.45,0,11.66,8.77,5V277.41L104.92,270Z"/>
                    <polygon className="cls-14" points="109.28 277.41 117.76 272.58 113.4 265.17 104.92 270 109.28 277.41"/>
                    <polygon className="cls-18" points="103.98 277.73 103.98 275.67 105.81 276.71 105.81 278.77 103.98 277.73"/>
                    <path className="cls-16" d="M104.16,276l1.47.84v1.66l-1.47-.84V276m-.35-.6v2.47l2.17,1.23V276.6l-2.17-1.23Z"/>
                    <polygon className="cls-18" points="103.83 285.67 103.83 281.61 105.96 282.82 105.96 286.87 103.83 285.67"/>
                    <path className="cls-16" d="M104,281.91l1.79,1v3.65l-1.79-1v-3.65m-.35-.6v4.46l2.49,1.4v-4.45l-2.49-1.41Z"/>
                    <polygon className="cls-15" points="109.53 288.94 109.55 277.57 113.61 275.3 117.81 282.45 117.8 293.61 109.53 288.94"/>
                    <path className="cls-16" d="M113.51,275.64l4,6.87v10.67l-7.77-4.39,0-11.08,3.71-2.07m.19-.68-4.4,2.46,0,11.66,8.77,5V282.38L113.7,275Z"/>
                    <polygon className="cls-14" points="118.06 282.38 126.54 277.55 122.18 270.13 113.7 274.96 118.06 282.38"/>
                    <polygon className="cls-18" points="112.76 282.7 112.76 280.64 114.59 281.67 114.59 283.73 112.76 282.7"/>
                    <path className="cls-16" d="M112.94,280.94l1.47.84v1.65l-1.47-.83v-1.66m-.35-.6v2.46l2.17,1.23v-2.46l-2.17-1.23Z"/>
                    <polygon className="cls-18" points="112.61 290.63 112.61 286.58 114.74 287.79 114.74 291.84 112.61 290.63"/>
                    <path className="cls-16" d="M112.78,286.88l1.79,1v3.65l-1.79-1v-3.65m-.35-.6v4.45l2.49,1.41v-4.45l-2.49-1.41Z"/>
                    <polygon className="cls-14" points="126.83 299.02 135.31 294.19 135.32 282.53 126.84 287.36 126.83 299.02"/>
                    <polygon className="cls-15" points="118.31 293.91 118.33 282.54 122.39 280.28 126.59 287.42 126.58 298.59 118.31 293.91"/>
                    <path className="cls-16" d="M122.29,280.62l4,6.87v10.67l-7.77-4.39,0-11.08,3.71-2.07m.19-.68-4.4,2.46,0,11.66,8.77,5V287.36l-4.36-7.42Z"/>
                    <polygon className="cls-14" points="126.84 287.36 135.32 282.53 130.96 275.11 122.48 279.94 126.84 287.36"/>
                    <polygon className="cls-18" points="121.54 287.68 121.54 285.62 123.37 286.65 123.37 288.71 121.54 287.68"/>
                    <path className="cls-16" d="M121.72,285.92l1.47.83v1.66l-1.47-.83v-1.66m-.35-.6v2.46l2.17,1.23v-2.46l-2.17-1.23Z"/>
                    <polygon className="cls-18" points="121.39 295.61 121.39 291.56 123.52 292.77 123.52 296.82 121.39 295.61"/>
                    <path className="cls-16" d="M121.56,291.86l1.79,1v3.65l-1.79-1v-3.65m-.35-.6v4.45l2.49,1.41v-4.46l-2.49-1.4Z"/>
                    <polygon className="cls-14" points="196.34 250.87 202.22 247.47 202.22 243.37 196.34 246.62 196.34 250.87"/>
                    <polygon className="cls-15" points="190.5 247.4 190.54 243.34 193.47 241.75 196.26 246.66 196.25 250.72 190.5 247.4"/>
                    <path className="cls-16" d="M193.44,241.88l2.72,4.78v3.9l-5.52-3.19v-3.93l2.8-1.56m.06-.25-3.05,1.71v4.14l5.89,3.39v-4.25l-2.84-5Z"/>
                    <polygon className="cls-14" points="196.34 246.62 202.22 243.37 199.21 238.38 193.5 241.63 196.34 246.62"/>
                    <polygon className="cls-18" points="194.16 248.19 194.2 247.12 195.22 247.7 195.18 248.77 194.16 248.19"/>
                    <path className="cls-16" d="M194.3,247.28l.79.44v.89l-.79-.44v-.89m-.19-.31v1.3l1.16.65v-1.3l-1.16-.65Z"/>
                    <polygon className="cls-18" points="191.68 248.08 191.72 245.95 192.9 246.62 192.86 248.75 191.68 248.08"/>
                    <path className="cls-16" d="M191.81,246.11l1,.54v1.94l-1-.54v-1.94m-.18-.31v2.36l1.32.74v-2.36l-1.32-.74Z"/>
                    <polygon className="cls-14" points="184.8 242.89 189.88 239.96 189.88 236.41 184.8 239.22 184.8 242.89"/>
                    <polygon className="cls-15" points="179.76 239.89 179.8 236.39 182.32 235.02 184.73 239.25 184.72 242.76 179.76 239.89"/>
                    <path className="cls-16" d="M182.3,235.13l2.34,4.13v3.36l-4.76-2.75v-3.39l2.42-1.35m0-.21-2.63,1.47V240l5.08,2.93v-3.67l-2.45-4.3Z"/>
                    <polygon className="cls-14" points="184.8 239.22 189.88 236.41 187.27 232.11 182.35 234.91 184.8 239.22"/>
                    <polygon className="cls-18" points="182.92 240.58 182.95 239.65 183.84 240.15 183.8 241.08 182.92 240.58"/>
                    <path className="cls-16" d="M183,239.79l.68.38v.77l-.68-.39v-.76m-.16-.27v1.13l1,.56v-1.13l-1-.56Z"/>
                    <polygon className="cls-18" points="180.78 240.48 180.81 238.64 181.84 239.22 181.8 241.06 180.78 240.48"/>
                    <path className="cls-16" d="M180.89,238.78l.83.46v1.68l-.83-.46v-1.68m-.15-.27v2l1.13.64v-2l-1.13-.64Z"/>
                    <polygon className="cls-14" points="144.93 267.98 156.14 261.5 156.14 253.68 144.93 259.86 144.93 267.98"/>
                    <polygon className="cls-15" points="133.96 261.36 133.96 253.76 139.43 250.7 144.68 259.93 144.68 267.54 133.96 261.36"/>
                    <path className="cls-16" d="M139.34,251l5.09,9v7.11l-10.22-5.9v-7.3l5.13-2.87m.19-.68-5.82,3.26v7.88L144.93,268v-8.12l-5.4-9.5Z"/>
                    <polygon className="cls-14" points="144.93 259.86 156.14 253.68 150.39 244.18 139.53 250.36 144.93 259.86"/>
                    <polygon className="cls-18" points="140.86 262.92 140.86 260.83 142.71 261.88 142.71 263.96 140.86 262.92"/>
                    <path className="cls-16" d="M141,261.13l1.49.85v1.68l-1.49-.85v-1.68m-.35-.6V263l2.19,1.24v-2.49l-2.19-1.24Z"/>
                    <polygon className="cls-18" points="136.13 262.7 136.13 258.6 138.29 259.82 138.29 263.92 136.13 262.7"/>
                    <path className="cls-16" d="M136.31,258.9l1.81,1v3.69l-1.81-1v-3.7m-.35-.6v4.5l2.51,1.42v-4.5L136,258.3Z"/>
                    <polygon className="cls-14" points="230.33 271.41 236.22 268.01 236.22 263.9 230.33 267.15 230.33 271.41"/>
                    <polygon className="cls-15" points="224.56 267.95 224.56 263.94 227.46 262.31 230.24 267.21 230.23 271.22 224.56 267.95"/>
                    <path className="cls-16" d="M227.42,262.46l2.7,4.75V271l-5.46-3.14V264l2.76-1.54m.08-.29-3.05,1.71V268l5.88,3.4v-4.26l-2.83-5Z"/>
                    <polygon className="cls-14" points="230.33 267.15 236.22 263.9 233.2 258.92 227.5 262.17 230.33 267.15"/>
                    <polygon className="cls-18" points="228.15 268.73 228.19 267.65 229.22 268.24 229.18 269.31 228.15 268.73"/>
                    <path className="cls-16" d="M228.29,267.81l.79.45v.89l-.79-.45v-.89m-.18-.31v1.31l1.15.65v-1.3l-1.15-.66Z"/>
                    <polygon className="cls-18" points="225.67 268.62 225.71 266.49 226.9 267.16 226.85 269.29 225.67 268.62"/>
                    <path className="cls-16" d="M225.81,266.64l.95.54v1.95l-.95-.54v-1.95m-.19-.31v2.36l1.32.75v-2.36l-1.32-.75Z"/>
                    <polygon className="cls-14" points="208.3 255.85 212.81 253.25 212.81 250.1 208.3 252.59 208.3 255.85"/>
                    <polygon className="cls-15" points="203.83 253.18 203.87 250.08 206.09 248.88 208.23 252.63 208.22 255.71 203.83 253.18"/>
                    <path className="cls-16" d="M206.06,249l2.07,3.64v2.94L204,253.15v-3l2.11-1.18m.07-.22-2.34,1.31v3.17l4.51,2.61v-3.27l-2.17-3.82Z"/>
                    <polygon className="cls-14" points="208.3 252.59 212.81 250.1 210.5 246.28 206.13 248.77 208.3 252.59"/>
                    <polygon className="cls-18" points="206.63 253.8 206.66 252.97 207.44 253.42 207.41 254.24 206.63 253.8"/>
                    <path className="cls-16" d="M206.73,253.1l.61.34v.68l-.61-.34v-.68m-.14-.24v1l.89.5v-1l-.89-.5Z"/>
                    <polygon className="cls-18" points="204.72 253.71 204.76 252.08 205.66 252.59 205.63 254.23 204.72 253.71"/>
                    <path className="cls-16" d="M204.83,252.2l.73.41v1.5l-.73-.42V252.2m-.14-.24v1.81l1,.57v-1.81l-1-.57Z"/>
                    <polygon className="cls-14" points="220.83 261.12 225.35 258.51 225.35 255.36 220.83 257.85 220.83 261.12"/>
                    <polygon className="cls-15" points="216.36 258.44 216.4 255.34 218.63 254.14 220.76 257.89 220.75 260.97 216.36 258.44"/>
                    <path className="cls-16" d="M218.6,254.25l2.07,3.64v2.94l-4.18-2.42v-3l2.11-1.18m.06-.22-2.34,1.31v3.17l4.51,2.61v-3.27L218.66,254Z"/>
                    <polygon className="cls-14" points="220.83 257.85 225.35 255.36 223.03 251.53 218.66 254.03 220.83 257.85"/>
                    <polygon className="cls-18" points="219.16 259.06 219.19 258.24 219.98 258.68 219.95 259.5 219.16 259.06"/>
                    <path className="cls-16" d="M219.27,258.36l.6.34v.68l-.6-.34v-.68m-.14-.24v1l.88.5v-1l-.88-.5Z"/>
                    <polygon className="cls-18" points="217.26 258.97 217.29 257.34 218.2 257.85 218.17 259.49 217.26 258.97"/>
                    <path className="cls-16" d="M217.36,257.46l.74.41v1.5l-.74-.42v-1.49m-.14-.24V259l1,.57v-1.81l-1-.57Z"/>
                    <polygon className="cls-14" points="225.73 251.57 229.96 249.13 229.96 246.18 225.73 248.51 225.73 251.57"/>
                    <polygon className="cls-15" points="221.54 249.06 221.58 246.16 223.66 245.03 225.66 248.55 225.65 251.43 221.54 249.06"/>
                    <path className="cls-16" d="M223.63,245.14l1.94,3.41v2.75L221.66,249v-2.79l2-1.11m.06-.21-2.19,1.23v3l4.23,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-14" points="225.73 248.51 229.96 246.18 227.79 242.6 223.69 244.93 225.73 248.51"/>
                    <polygon className="cls-18" points="224.16 249.64 224.19 248.87 224.93 249.29 224.9 250.06 224.16 249.64"/>
                    <path className="cls-16" d="M224.26,249l.57.33V250l-.57-.33V249m-.13-.22v.94l.83.47v-.94l-.83-.47Z"/>
                    <polygon className="cls-18" points="222.38 249.56 222.41 248.03 223.26 248.51 223.23 250.04 222.38 249.56"/>
                    <path className="cls-16" d="M222.48,248.14l.68.39v1.4l-.68-.39v-1.4m-.13-.22v1.7l.94.53v-1.69l-.94-.54Z"/>
                    <polygon className="cls-14" points="244.16 296.69 248.38 294.25 248.38 291.3 244.16 293.63 244.16 296.69"/>
                    <polygon className="cls-15" points="239.97 294.18 240.01 291.28 242.09 290.16 244.09 293.67 244.08 296.55 239.97 294.18"/>
                    <path className="cls-16" d="M242.07,290.26l1.93,3.41v2.75l-3.91-2.26v-2.79l2-1.11m.06-.21-2.19,1.23v3l4.22,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-14" points="244.16 293.63 248.38 291.3 246.22 287.72 242.13 290.05 244.16 293.63"/>
                    <polygon className="cls-18" points="242.6 294.76 242.62 293.99 243.36 294.41 243.33 295.18 242.6 294.76"/>
                    <path className="cls-16" d="M242.69,294.1l.57.33v.63l-.57-.32v-.64m-.13-.22v.94l.83.47v-.94l-.83-.47Z"/>
                    <polygon className="cls-18" points="240.81 294.68 240.84 293.15 241.69 293.63 241.66 295.16 240.81 294.68"/>
                    <path className="cls-16" d="M240.91,293.26l.69.39v1.4l-.69-.39v-1.4m-.13-.22v1.7l.95.53v-1.69l-.95-.54Z"/>
                    <polygon className="cls-14" points="248.38 299.1 252.61 296.66 252.61 293.71 248.38 296.04 248.38 299.1"/>
                    <polygon className="cls-15" points="244.2 296.59 244.24 293.69 246.32 292.57 248.32 296.08 248.31 298.96 244.2 296.59"/>
                    <path className="cls-16" d="M246.29,292.68l1.94,3.41v2.74l-3.91-2.26v-2.79l2-1.1m.06-.22-2.19,1.23v3l4.23,2.44V296l-2-3.58Z"/>
                    <polygon className="cls-14" points="248.38 296.04 252.61 293.71 250.44 290.13 246.35 292.46 248.38 296.04"/>
                    <polygon className="cls-18" points="246.82 297.18 246.85 296.4 247.58 296.82 247.56 297.59 246.82 297.18"/>
                    <path className="cls-16" d="M246.92,296.52l.57.32v.64l-.57-.32v-.64m-.13-.22v.93l.83.47v-.94l-.83-.46Z"/>
                    <polygon className="cls-18" points="245.04 297.09 245.07 295.56 245.92 296.05 245.89 297.58 245.04 297.09"/>
                    <path className="cls-16" d="M245.14,295.68l.68.39v1.39l-.68-.39v-1.39m-.13-.22v1.69l.94.54V296l-.94-.53Z"/>
                    <polygon className="cls-14" points="252.61 301.57 256.83 299.13 256.83 296.18 252.61 298.51 252.61 301.57"/>
                    <polygon className="cls-15" points="248.43 299.06 248.46 296.16 250.54 295.04 252.54 298.55 252.53 301.43 248.43 299.06"/>
                    <path className="cls-16" d="M250.52,295.14l1.93,3.41v2.75L248.54,299v-2.79l2-1.11m.06-.21-2.19,1.23v3l4.22,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-14" points="252.61 298.51 256.83 296.18 254.67 292.6 250.57 294.93 252.61 298.51"/>
                    <polygon className="cls-18" points="251.05 299.64 251.07 298.87 251.81 299.29 251.78 300.06 251.05 299.64"/>
                    <path className="cls-16" d="M251.14,299l.57.33v.63l-.57-.32V299m-.13-.22v.94l.83.47v-.94l-.83-.47Z"/>
                    <polygon className="cls-18" points="249.26 299.56 249.29 298.03 250.14 298.51 250.11 300.04 249.26 299.56"/>
                    <path className="cls-16" d="M249.36,298.14l.69.39v1.4l-.69-.39v-1.4m-.13-.22v1.7l1,.53v-1.69l-1-.54Z"/>
                    <polygon className="cls-14" points="160.2 246.64 165.49 243.59 165.49 239.9 160.2 242.82 160.2 246.64"/>
                    <polygon className="cls-15" points="154.97 243.5 155.02 239.88 157.62 238.47 160.12 242.87 160.11 246.47 154.97 243.5"/>
                    <path className="cls-16" d="M157.59,238.61l2.42,4.26v3.43l-4.89-2.82V240l2.47-1.38m.07-.27-2.74,1.54v3.71l5.28,3v-3.82l-2.54-4.48Z"/>
                    <polygon className="cls-14" points="160.2 242.82 165.49 239.9 162.78 235.43 157.66 238.34 160.2 242.82"/>
                    <polygon className="cls-18" points="158.25 244.23 158.28 243.27 159.2 243.79 159.17 244.75 158.25 244.23"/>
                    <path className="cls-16" d="M158.37,243.41l.71.4v.8l-.71-.4v-.8m-.16-.28v1.17l1,.59v-1.17l-1-.59Z"/>
                    <polygon className="cls-18" points="156.02 244.13 156.06 242.22 157.12 242.82 157.08 244.73 156.02 244.13"/>
                    <path className="cls-16" d="M156.14,242.36l.86.49v1.74l-.86-.48v-1.75m-.16-.28v2.12l1.18.67v-2.12l-1.18-.67Z"/>
                    <polygon className="cls-14" points="165.49 249.66 170.77 246.61 170.77 242.92 165.49 245.84 165.49 249.66"/>
                    <polygon className="cls-15" points="160.25 246.52 160.3 242.89 162.91 241.49 165.4 245.88 165.39 249.49 160.25 246.52"/>
                    <path className="cls-16" d="M162.87,241.62l2.42,4.27v3.43l-4.89-2.83V243l2.47-1.39m.07-.26-2.74,1.53v3.72l5.29,3v-3.82l-2.55-4.48Z"/>
                    <polygon className="cls-14" points="165.49 245.84 170.77 242.92 168.06 238.44 162.94 241.36 165.49 245.84"/>
                    <polygon className="cls-18" points="163.53 247.25 163.57 246.29 164.49 246.81 164.45 247.77 163.53 247.25"/>
                    <path className="cls-16" d="M163.65,246.43l.71.4v.8l-.71-.4v-.8m-.16-.28v1.17l1,.59v-1.17l-1-.59Z"/>
                    <polygon className="cls-18" points="161.3 247.15 161.34 245.24 162.4 245.84 162.37 247.75 161.3 247.15"/>
                    <path className="cls-16" d="M161.42,245.38l.86.48v1.75l-.86-.49v-1.74m-.16-.28v2.12l1.18.67v-2.12l-1.18-.67Z"/>
                    <polygon className="cls-14" points="170.77 252.74 176.05 249.69 176.05 246 170.77 248.92 170.77 252.74"/>
                    <polygon className="cls-15" points="165.54 249.61 165.59 245.98 168.19 244.57 170.69 248.97 170.67 252.57 165.54 249.61"/>
                    <path className="cls-16" d="M168.15,244.71l2.43,4.26v3.43l-4.9-2.82v-3.49l2.47-1.38m.08-.27L165.49,246v3.71l5.28,3.05v-3.82l-2.54-4.48Z"/>
                    <polygon className="cls-14" points="170.77 248.92 176.05 246 173.34 241.53 168.23 244.44 170.77 248.92"/>
                    <polygon className="cls-18" points="168.81 250.33 168.85 249.37 169.77 249.89 169.73 250.85 168.81 250.33"/>
                    <path className="cls-16" d="M168.94,249.51l.71.4v.8l-.71-.4v-.8m-.17-.28v1.17l1,.59v-1.17l-1-.59Z"/>
                    <polygon className="cls-18" points="166.58 250.23 166.62 248.32 167.69 248.92 167.65 250.83 166.58 250.23"/>
                    <path className="cls-16" d="M166.71,248.46l.85.49v1.74l-.85-.48v-1.75m-.17-.28v2.12l1.19.67v-2.12l-1.19-.67Z"/>
                    <polygon className="cls-20" points="221.25 215.36 222.51 214.66 222.51 215.1 221.25 215.83 221.25 215.36"/>
                    <polygon className="cls-20" points="223.01 213.81 220.75 215.06 220.75 216.69 223.01 215.39 223.01 213.81 223.01 213.81"/>
                    <polygon className="cls-20" points="218.54 215.31 218.59 213.8 219.63 213.27 220.67 215.1 220.66 216.54 218.54 215.31"/>
                    <path className="cls-20" d="M219.6,213.39l1,1.72v1.27l-1.9-1.09v-1.38l.93-.52m.06-.24-1.17.65v1.59l2.26,1.3v-1.63l-1.09-1.91Z"/>
                    <polygon className="cls-20" points="220.35 213.34 221.68 212.58 222.31 213.63 220.94 214.38 220.35 213.34"/>
                    <path className="cls-20" d="M221.5,213.25l.11.19-.48.27-.1-.19.47-.27m.35-1.35-2.19,1.25,1.09,1.91,2.26-1.24-1.16-1.92Z"/>
                    <polygon className="cls-20" points="207.65 224.4 210.04 223.09 210.04 224.31 207.65 225.69 207.65 224.4"/>
                    <path className="cls-20" d="M209.54,223.93V224l-1.39.8v-.12l1.39-.77m1-1.69-3.39,1.87v2.45l3.39-2v-2.36Z"/>
                    <polygon className="cls-20" points="203.81 224.52 203.86 222.22 205.49 221.36 207.07 224.15 207.06 226.4 203.81 224.52"/>
                    <path className="cls-20" d="M205.45,221.48l1.52,2.67v2.09l-3-1.74v-2.17l1.5-.85m.07-.24-1.75,1v2.38l3.38,2v-2.45l-1.63-2.87Z"/>
                    <polygon className="cls-20" points="206.2 221.43 208.63 220.04 209.84 222.05 207.34 223.43 206.2 221.43"/>
                    <path className="cls-20" d="M208.45,220.72l.69,1.15-1.61.88-.64-1.14,1.56-.89m.35-1.35-3.28,1.87,1.63,2.87,3.39-1.87-1.74-2.87Z"/>
                    <polygon className="cls-20" points="197.03 231.67 200.26 229.89 200.26 231.71 197.03 233.57 197.03 231.67"/>
                    <path className="cls-20" d="M199.76,230.74v.68l-2.23,1.28V232l2.23-1.23m1-1.7-4.23,2.34v3.05l4.23-2.44v-3Z"/>
                    <polygon className="cls-20" points="192.35 231.92 192.4 229.02 194.46 227.92 196.45 231.42 196.44 234.28 192.35 231.92"/>
                    <path className="cls-20" d="M194.43,228l1.92,3.38v2.7l-3.86-2.23v-2.76l1.94-1.09m.07-.24L192.31,229v3l4.22,2.44v-3.05l-2-3.58Z"/>
                    <polygon className="cls-20" points="195.18 227.98 198.41 226.14 200.06 228.86 196.72 230.7 195.18 227.98"/>
                    <path className="cls-20" d="M198.24,226.82l1.12,1.85L196.91,230l-1-1.85,2.38-1.35m.35-1.35-4.09,2.33,2,3.58,4.23-2.34-2.17-3.57Z"/>
                    <polygon className="cls-20" points="214.17 231.01 214.22 229.05 217.71 231.03 217.66 232.99 214.17 231.01"/>
                    <path className="cls-20" d="M214.31,229.21l3.26,1.85v1.77L214.31,231v-1.77m-.18-.31v2.19l3.62,2V231l-3.62-2Z"/>
                    <polygon className="cls-20" points="218.25 231.24 220.87 229.76 220.87 230.8 218.25 232.28 218.25 231.24"/>
                    <polygon className="cls-20" points="221.37 228.9 217.75 230.95 217.75 233.14 221.37 231.09 221.37 228.9 221.37 228.9"/>
                    <polygon className="cls-20" points="215.14 228.9 217.75 227.42 220.36 228.9 217.75 230.38 215.14 228.9"/>
                    <path className="cls-20" d="M217.75,228l1.59.9-1.59.9-1.59-.9,1.59-.9m0-1.15-3.62,2.05,3.62,2,3.62-2-3.62-2.05Z"/>
                    <polygon className="cls-20" points="174.98 219.4 178.21 217.62 178.21 219.43 174.98 221.29 174.98 219.4"/>
                    <path className="cls-20" d="M177.7,218.47v.67l-2.22,1.29v-.74l2.22-1.22m1-1.7-4.22,2.33v3.06l4.22-2.44v-2.95Z"/>
                    <polygon className="cls-20" points="170.3 219.64 170.34 216.75 172.41 215.65 174.4 219.15 174.39 222 170.3 219.64"/>
                    <path className="cls-20" d="M172.38,215.77l1.92,3.38v2.7l-3.87-2.23v-2.76l1.95-1.09m.06-.25-2.19,1.23v3l4.23,2.44V219.1l-2-3.58Z"/>
                    <polygon className="cls-20" points="173.13 215.71 176.36 213.87 178.01 216.59 174.67 218.43 173.13 215.71"/>
                    <path className="cls-20" d="M176.19,214.54l1.12,1.86-2.45,1.35-1.05-1.85,2.38-1.36m.35-1.35-4.1,2.33,2,3.58,4.22-2.33-2.16-3.58Z"/>
                    <polygon className="cls-20" points="197.32 218.47 199.65 217.18 199.65 218.37 197.32 219.71 197.32 218.47"/>
                    <path className="cls-20" d="M199.14,218v.05l-1.32.77v-.09l1.32-.73m1-1.69-3.32,1.83v2.41l3.32-1.92v-2.32Z"/>
                    <polygon className="cls-20" points="193.54 218.58 193.58 216.32 195.18 215.47 196.74 218.22 196.73 220.42 193.54 218.58"/>
                    <path className="cls-20" d="M195.15,215.6l1.49,2.62v2.05l-3-1.72v-2.12l1.48-.83m.07-.24-1.73,1v2.34l3.33,1.92v-2.41l-1.6-2.81Z"/>
                    <polygon className="cls-20" points="195.9 215.54 198.26 214.19 199.45 216.15 197.01 217.5 195.9 215.54"/>
                    <path className="cls-20" d="M198.09,214.87l.66,1.09-1.55.86-.62-1.09,1.51-.86m.35-1.35-3.22,1.84,1.6,2.81,3.32-1.83-1.7-2.82Z"/>
                    <polygon className="cls-20" points="182.02 226.34 182.06 221.53 186.44 224.01 186.4 228.82 182.02 226.34"/>
                    <path className="cls-20" d="M182.15,221.68,186.3,224v4.64l-4.15-2.35v-4.64m-.18-.31v5l4.51,2.56v-5L182,221.37Z"/>
                    <polygon className="cls-20" points="186.99 224.22 190.5 222.23 190.5 226.13 186.99 228.12 186.99 224.22"/>
                    <path className="cls-20" d="M190,223.09v2.75l-2.52,1.42v-2.75l2.52-1.42m1-1.72-4.52,2.56v5l4.52-2.56v-5Z"/>
                    <polygon className="cls-20" points="182.99 221.37 186.48 219.39 189.98 221.38 186.49 223.35 182.99 221.37"/>
                    <path className="cls-20" d="M186.48,220l2.49,1.4-2.49,1.41L184,221.37l2.48-1.4m0-1.15L182,221.37l4.51,2.56,4.52-2.56-4.52-2.55Z"/>
                    <polygon className="cls-20" points="165.67 214.12 168.62 212.49 168.62 214.12 165.67 215.82 165.67 214.12"/>
                    <path className="cls-20" d="M168.12,213.34v.49l-2,1.12v-.53l2-1.08m1-1.69-4,2.18v2.85l4-2.28v-2.75Z"/>
                    <polygon className="cls-20" points="161.27 214.32 161.31 211.63 163.23 210.6 165.09 213.87 165.08 216.53 161.27 214.32"/>
                    <path className="cls-20" d="M163.2,210.73l1.79,3.14v2.5l-3.59-2.07v-2.57l1.8-1m.07-.25-2.05,1.15v2.77l3.95,2.28v-2.85l-1.9-3.35Z"/>
                    <polygon className="cls-20" points="163.95 210.67 166.92 208.98 168.42 211.46 165.36 213.15 163.95 210.67"/>
                    <path className="cls-20" d="M166.74,209.65l1,1.62-2.18,1.2-.92-1.62,2.11-1.2m.35-1.35-3.82,2.18,1.9,3.35,4-2.18-2-3.35Z"/>
                    <polygon className="cls-20" points="215.43 219.42 217.25 218.42 217.25 219.25 215.43 220.3 215.43 219.42"/>
                    <polygon className="cls-20" points="217.75 217.57 214.93 219.13 214.93 221.17 217.75 219.54 217.75 217.57 217.75 217.57"/>
                    <polygon className="cls-20" points="212.15 219.46 212.2 217.55 213.53 216.86 214.85 219.17 214.84 221.01 212.15 219.46"/>
                    <path className="cls-20" d="M213.5,217l1.25,2.19v1.68l-2.46-1.42v-1.77l1.21-.68m.07-.24-1.46.81v2l2.82,1.63v-2l-1.36-2.39Z"/>
                    <polygon className="cls-20" points="214.25 216.92 216.13 215.85 217.05 217.38 215.12 218.45 214.25 216.92"/>
                    <path className="cls-20" d="M216,216.53l.41.67-1.05.57-.38-.66,1-.58m.35-1.35-2.73,1.56,1.36,2.39,2.82-1.56-1.45-2.39Z"/>
                    <polygon className="cls-20" points="183.88 207.69 185.24 206.94 185.24 207.45 183.88 208.24 183.88 207.69"/>
                    <polygon className="cls-20" points="185.74 206.09 183.38 207.4 183.38 209.1 185.74 207.74 185.74 206.09 185.74 206.09"/>
                    <polygon className="cls-20" points="181.06 207.66 181.11 206.08 182.21 205.52 183.3 207.44 183.29 208.95 181.06 207.66"/>
                    <path className="cls-20" d="M182.17,205.64l1,1.8v1.35l-2-1.15v-1.45l1-.55m.07-.24-1.22.68v1.66l2.36,1.36v-1.7l-1.14-2Z"/>
                    <polygon className="cls-20" points="182.92 205.59 184.35 204.77 185.04 205.91 183.57 206.72 182.92 205.59"/>
                    <path className="cls-20" d="M184.18,205.45l.16.27-.58.32-.16-.27.58-.32m.35-1.35-2.29,1.3,1.14,2,2.36-1.31-1.21-2Z"/>
                    <polygon className="cls-20" points="212.33 209.58 213.69 208.83 213.69 209.34 212.33 210.12 212.33 209.58"/>
                    <polygon className="cls-20" points="214.19 207.98 211.83 209.28 211.83 210.99 214.19 209.63 214.19 207.98 214.19 207.98"/>
                    <polygon className="cls-20" points="209.51 209.55 209.56 207.97 210.66 207.4 211.75 209.33 211.74 210.83 209.51 209.55"/>
                    <path className="cls-20" d="M210.62,207.53l1,1.8v1.35l-2-1.16v-1.45l1-.54m.07-.25-1.22.69v1.66l2.36,1.36v-1.71l-1.14-2Z"/>
                    <polygon className="cls-20" points="211.37 207.47 212.8 206.66 213.49 207.79 212.02 208.6 211.37 207.47"/>
                    <path className="cls-20" d="M212.63,207.33l.16.28-.58.32-.15-.27.57-.33M213,206l-2.29,1.3,1.14,2,2.36-1.3L213,206Z"/>
                    <polygon className="cls-20" points="206.85 212.96 208.68 211.96 208.68 212.79 206.85 213.84 206.85 212.96"/>
                    <polygon className="cls-20" points="209.18 211.11 206.35 212.67 206.35 214.71 209.18 213.08 209.18 211.11 209.18 211.11"/>
                    <polygon className="cls-20" points="203.58 213 203.62 211.09 204.96 210.4 206.28 212.71 206.26 214.55 203.58 213"/>
                    <path className="cls-20" d="M204.93,210.52l1.24,2.19v1.69L203.71,213V211.2l1.22-.68m.06-.24-1.46.82v2l2.82,1.63v-2L205,210.28Z"/>
                    <polygon className="cls-20" points="205.68 210.46 207.55 209.4 208.48 210.92 206.54 211.99 205.68 210.46"/>
                    <path className="cls-20" d="M207.38,210.07l.4.67-1.05.57-.37-.66,1-.58m.35-1.35L205,210.28l1.36,2.39,2.82-1.56-1.44-2.39Z"/>
                    <polygon className="cls-20" points="185.64 215.22 187.59 214.15 187.59 215.07 185.64 216.19 185.64 215.22"/>
                    <polygon className="cls-20" points="188.09 213.3 185.14 214.93 185.14 217.06 188.09 215.36 188.09 213.3 188.09 213.3"/>
                    <polygon className="cls-20" points="182.24 215.28 182.28 213.28 183.69 212.55 185.06 214.97 185.05 216.9 182.24 215.28"/>
                    <path className="cls-20" d="M183.65,212.67,185,215v1.78l-2.59-1.5v-1.86l1.28-.72m.07-.24-1.53.85v2.08l2.95,1.7v-2.13l-1.42-2.5Z"/>
                    <polygon className="cls-20" points="184.4 212.62 186.4 211.48 187.4 213.11 185.33 214.25 184.4 212.62"/>
                    <path className="cls-20" d="M186.23,212.15l.47.78-1.18.64-.43-.77,1.14-.65m.35-1.35-2.86,1.63,1.42,2.5,3-1.63-1.51-2.5Z"/>
                    <polygon className="cls-20" points="174.6 207.96 176.55 206.88 176.55 207.81 174.6 208.93 174.6 207.96"/>
                    <polygon className="cls-20" points="177.05 206.04 174.1 207.66 174.1 209.8 177.05 208.1 177.05 206.04 177.05 206.04"/>
                    <polygon className="cls-20" points="171.2 208.02 171.24 206.02 172.65 205.29 174.02 207.71 174.01 209.64 171.2 208.02"/>
                    <path className="cls-20" d="M172.61,205.41l1.31,2.3v1.78l-2.59-1.5v-1.86l1.28-.72m.07-.24-1.53.85v2.08l2.95,1.7v-2.13l-1.42-2.5Z"/>
                    <polygon className="cls-20" points="173.36 205.35 175.36 204.22 176.35 205.85 174.29 206.99 173.36 205.35"/>
                    <path className="cls-20" d="M175.19,204.89l.47.78-1.18.64-.44-.77,1.15-.65m.35-1.35-2.86,1.63,1.42,2.5,3-1.63-1.51-2.5Z"/>
                    <polygon className="cls-20" points="189.44 211.01 189.48 209.66 191.96 211.07 191.92 212.41 189.44 211.01"/>
                    <path className="cls-20" d="M189.57,209.82l2.25,1.27v1.17L189.57,211v-1.16m-.18-.31v1.58l2.61,1.48V211l-2.61-1.48Z"/>
                    <polygon className="cls-20" points="192.5 211.28 194.11 210.37 194.11 210.8 192.5 211.71 192.5 211.28"/>
                    <polygon className="cls-20" points="194.61 209.51 192 210.99 192 212.56 194.61 211.09 194.61 209.51 194.61 209.51"/>
                    <polygon className="cls-20" points="190.4 209.51 192 208.6 193.6 209.51 192 210.41 190.4 209.51"/>
                    <path className="cls-20" d="M192,209.18l.58.33-.58.33-.58-.33.58-.33m0-1.15-2.61,1.48L192,211l2.61-1.48L192,208Z"/>
                    <polygon className="cls-20" points="198.06 207.69 199.42 206.94 199.42 207.45 198.06 208.24 198.06 207.69"/>
                    <polygon className="cls-20" points="199.92 206.09 197.56 207.4 197.56 209.1 199.92 207.74 199.92 206.09 199.92 206.09"/>
                    <polygon className="cls-20" points="195.24 207.66 195.29 206.08 196.39 205.52 197.48 207.44 197.47 208.95 195.24 207.66"/>
                    <path className="cls-20" d="M196.35,205.64l1,1.8v1.35l-2-1.15v-1.45l1-.55m.07-.24-1.22.68v1.66l2.36,1.36v-1.7l-1.14-2Z"/>
                    <polygon className="cls-20" points="197.1 205.59 198.53 204.77 199.22 205.91 197.75 206.72 197.1 205.59"/>
                    <path className="cls-20" d="M198.36,205.45l.16.27-.58.32-.15-.27.57-.32m.35-1.35-2.29,1.3,1.14,2,2.36-1.31-1.21-2Z"/>
                    <polygon className="cls-20" points="181.16 202.66 182.03 202.18 182.03 202.35 181.16 202.86 181.16 202.66"/>
                    <polygon className="cls-20" points="182.53 201.34 180.66 202.37 180.66 203.72 182.53 202.64 182.53 201.34 182.53 201.34"/>
                    <polygon className="cls-20" points="178.83 202.56 178.88 201.32 179.72 200.9 180.58 202.41 180.57 203.57 178.83 202.56"/>
                    <path className="cls-20" d="M179.69,201l.79,1.39v1l-1.51-.87v-1.11l.72-.4m.07-.25-1,.55v1.31l1.87,1.08v-1.35l-.9-1.59Z"/>
                    <polygon className="cls-20" points="180.44 200.97 181.4 200.42 181.84 201.15 180.85 201.69 180.44 200.97"/>
                    <polygon className="cls-20" points="181.57 199.75 179.76 200.78 180.66 202.37 182.53 201.34 181.57 199.75 181.57 199.75"/>
                    <polygon className="cls-20" points="189.39 196.77 190.74 195.99 190.74 195.04 189.39 195.79 189.39 196.77"/>
                    <polygon className="cls-20" points="190.75 195.04 189.39 195.79 189.39 196.77 190.75 195.99 190.75 195.04 190.75 195.04"/>
                    <polygon className="cls-20" points="188.08 195.91 188.13 195.03 188.7 194.76 189.31 195.83 189.3 196.61 188.08 195.91"/>
                    <path className="cls-20" d="M188.67,194.89l.54,1v.62l-1-.58v-.74l.45-.25m.07-.25-.7.39v1l1.35.78v-1l-.65-1.15Z"/>
                    <polygon className="cls-20" points="189.42 194.83 189.87 194.57 190.05 194.85 189.58 195.11 189.42 194.83"/>
                    <polygon className="cls-20" points="190.05 193.89 188.74 194.64 189.39 195.79 190.75 195.04 190.05 193.89 190.05 193.89"/>
                    <polygon className="cls-20" points="198.06 201.44 198.93 200.96 198.93 201.13 198.06 201.63 198.06 201.44"/>
                    <polygon className="cls-20" points="199.43 200.11 197.56 201.15 197.56 202.5 199.43 201.42 199.43 200.11 199.43 200.11"/>
                    <polygon className="cls-20" points="195.73 201.34 195.78 200.1 196.62 199.68 197.48 201.19 197.47 202.35 195.73 201.34"/>
                    <path className="cls-20" d="M196.59,199.8l.79,1.39v1l-1.51-.87v-1.11l.72-.41m.07-.24-1,.54v1.32l1.87,1.08v-1.35l-.9-1.59Z"/>
                    <polygon className="cls-20" points="197.34 199.75 198.29 199.2 198.73 199.93 197.75 200.47 197.34 199.75"/>
                    <polygon className="cls-20" points="198.47 198.53 196.66 199.56 197.56 201.15 199.43 200.11 198.47 198.53 198.47 198.53"/>
                    <polygon className="cls-20" points="193.31 199.15 194.89 198.24 194.89 197.13 193.31 198 193.31 199.15"/>
                    <polygon className="cls-20" points="194.89 197.13 193.31 198 193.31 199.15 194.89 198.24 194.89 197.13 194.89 197.13"/>
                    <polygon className="cls-20" points="191.77 198.16 191.82 197.13 192.51 196.78 193.23 198.05 193.22 198.99 191.77 198.16"/>
                    <path className="cls-20" d="M192.48,196.91l.65,1.14v.79l-1.22-.71v-.9l.57-.32m.07-.24-.82.46v1.11l1.58.91V198l-.76-1.34Z"/>
                    <polygon className="cls-20" points="193.23 196.85 193.9 196.47 194.19 196.95 193.5 197.33 193.23 196.85"/>
                    <polygon className="cls-20" points="194.08 195.79 192.55 196.67 193.31 198 194.89 197.13 194.08 195.79 194.08 195.79"/>
                    <polygon className="cls-20" points="189.08 203.88 189.12 201.91 190.96 202.95 190.91 204.92 189.08 203.88"/>
                    <path className="cls-20" d="M189.21,202.07l1.61.9v1.79l-1.61-.91v-1.78m-.18-.31V204l2,1.11v-2.2l-2-1.11Z"/>
                    <polygon className="cls-20" points="191.5 203.16 192.47 202.61 192.47 203.66 191.5 204.21 191.5 203.16"/>
                    <polygon className="cls-20" points="192.97 201.76 191 202.87 191 205.07 192.97 203.96 192.97 201.76 192.97 201.76"/>
                    <polygon className="cls-20" points="190.05 201.76 191 201.22 191.95 201.76 191 202.29 190.05 201.76"/>
                    <polygon className="cls-20" points="191 200.64 189.03 201.76 191 202.87 192.97 201.76 191 200.64 191 200.64"/>
                    <polygon className="cls-20" points="183.99 199.01 184.03 198.05 185.86 199.09 185.82 200.05 183.99 199.01"/>
                    <path className="cls-20" d="M184.12,198.21l1.61.91v.77l-1.61-.91v-.77m-.18-.31v1.19l2,1.11V199l-2-1.11Z"/>
                    <polygon className="cls-20" points="186.41 199.31 187.37 198.76 187.37 198.8 186.41 199.34 186.41 199.31"/>
                    <polygon className="cls-20" points="187.87 197.9 185.91 199.01 185.91 200.2 187.87 199.09 187.87 197.9 187.87 197.9"/>
                    <polygon className="cls-20" points="184.96 197.9 185.91 197.36 186.86 197.9 185.91 198.44 184.96 197.9"/>
                    <polygon className="cls-20" points="185.9 196.79 183.94 197.9 185.91 199.01 187.87 197.9 185.9 196.79 185.9 196.79"/>
                    <polygon className="cls-20" points="201.05 204.61 201.09 203.42 202.13 203.32 203.14 204.71 203.12 205.82 201.05 204.61"/>
                    <path className="cls-20" d="M202,203.42l1,1.3v.94l-1.84-1.07V203.5l.85-.08m.08-.19-1.11.11v1.35l2.2,1.29v-1.32l-1.09-1.43Z"/>
                    <polygon className="cls-20" points="203.18 203.83 203.55 203.62 203.55 202.45 204.18 202.09 204.97 203.11 203.35 204.05 203.18 203.83"/>
                    <path className="cls-20" d="M204.05,202.74l.18.23-.18.1v-.33m-1.44-1.38-.52,0-.49.28.07.18.44,0,.44.55.5-.29v1.24l-.5.29-.45-.55-.44.05V203l-.66.39h0l1.1-.11,1.12,1.44,2.5-1.44-1.41-1.82-.92.53-.22.12-.56-.73Z"/>
                    <polygon className="cls-20" points="203.71 204.98 205.09 204.18 205.09 204.3 203.71 205.11 203.71 204.98"/>
                    <polygon className="cls-20" points="205.59 203.32 203.21 204.69 203.21 205.97 205.59 204.59 205.59 203.32 205.59 203.32"/>
                    <polyline className="cls-21" points="202.11 201.83 202.55 202.38 203.05 202.09 203.05 203.33 202.55 203.62 202.1 203.07"/>
                    <polygon className="cls-21" points="202.55 202.37 203.07 202.07 203.07 203.34 202.55 203.64 202.55 202.37"/>
                    <polyline className="cls-21" points="202.11 203.07 201.67 203.12 201.67 201.87 202.11 201.84"/>
                    <polygon className="cls-14" points="262.63 252.81 266.85 250.37 266.85 247.42 262.63 249.75 262.63 252.81"/>
                    <polygon className="cls-15" points="258.44 250.3 258.48 247.4 260.56 246.28 262.56 249.79 262.55 252.68 258.44 250.3"/>
                    <path className="cls-16" d="M260.53,246.39l1.94,3.41v2.74l-3.91-2.26v-2.79l2-1.1m.06-.22-2.19,1.23v3l4.23,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-14" points="262.63 249.75 266.85 247.42 264.69 243.84 260.59 246.18 262.63 249.75"/>
                    <polygon className="cls-18" points="261.06 250.89 261.09 250.11 261.82 250.53 261.8 251.3 261.06 250.89"/>
                    <path className="cls-16" d="M261.16,250.23l.57.32v.64l-.57-.32v-.64M261,250v.93l.83.47v-.94L261,250Z"/>
                    <polygon className="cls-18" points="259.28 250.8 259.31 249.28 260.16 249.76 260.13 251.29 259.28 250.8"/>
                    <path className="cls-16" d="M259.38,249.39l.68.39v1.39l-.68-.39v-1.39m-.13-.22v1.69l.94.54v-1.7l-.94-.53Z"/>
                    <polygon className="cls-14" points="260.75 240.05 264.38 237.96 264.38 235.43 260.75 237.43 260.75 240.05"/>
                    <polygon className="cls-15" points="257.16 237.9 257.19 235.41 259.04 234.42 260.69 237.46 260.69 239.94 257.16 237.9"/>
                    <path className="cls-16" d="M259,234.54l1.66,2.93v2.35l-3.36-1.94v-2.39l1.7-1m.05-.18-1.88,1V238l3.62,2.09v-2.62L259,234.36Z"/>
                    <polygon className="cls-14" points="260.75 237.43 264.38 235.43 262.52 232.36 259.01 234.36 260.75 237.43"/>
                    <polygon className="cls-18" points="259.41 238.4 259.44 237.65 260.06 238.1 260.04 238.76 259.41 238.4"/>
                    <path className="cls-16" d="M259.49,237.84l.49.27v.55l-.49-.27v-.55m-.11-.19v.8l.71.4v-.8l-.71-.4Z"/>
                    <polygon className="cls-18" points="257.88 238.33 257.91 236.93 258.64 237.43 258.61 238.75 257.88 238.33"/>
                    <path className="cls-16" d="M258,237.12l.58.33v1.2l-.58-.34v-1.19m-.12-.19v1.45l.81.46v-1.45l-.81-.46Z"/>
                    <polygon className="cls-14" points="272.81 246.93 276.43 244.84 276.43 242.31 272.81 244.31 272.81 246.93"/>
                    <polygon className="cls-15" points="269.22 244.78 269.25 242.29 271.09 241.3 272.75 244.34 272.74 246.81 269.22 244.78"/>
                    <path className="cls-16" d="M271,241.42l1.66,2.92v2.36l-3.35-1.94v-2.39l1.69-1m.05-.18-1.88,1v2.55l3.63,2.09v-2.62l-1.75-3.07Z"/>
                    <polygon className="cls-14" points="272.81 244.31 276.43 242.31 274.57 239.24 271.06 241.24 272.81 244.31"/>
                    <polygon className="cls-18" points="271.46 245.28 271.49 244.52 272.12 244.97 272.09 245.63 271.46 245.28"/>
                    <path className="cls-16" d="M271.55,244.71l.48.28v.55l-.48-.28v-.55m-.11-.19v.81l.71.4v-.8l-.71-.41Z"/>
                    <polygon className="cls-18" points="269.94 245.21 269.96 243.8 270.69 244.31 270.66 245.62 269.94 245.21"/>
                    <path className="cls-16" d="M270,244l.59.34v1.19l-.59-.33V244m-.11-.19v1.46l.81.46v-1.46l-.81-.46Z"/>
                    <polygon className="cls-14" points="272.81 256.35 276.43 254.26 276.43 251.73 272.81 253.73 272.81 256.35"/>
                    <polygon className="cls-15" points="269.22 254.2 269.25 251.71 271.09 250.72 272.75 253.76 272.74 256.23 269.22 254.2"/>
                    <path className="cls-16" d="M271,250.84l1.66,2.92v2.36l-3.35-1.94v-2.39l1.69-.95m.05-.18-1.88,1.05v2.55l3.63,2.09v-2.62l-1.75-3.07Z"/>
                    <polygon className="cls-14" points="272.81 253.73 276.43 251.73 274.57 248.66 271.06 250.66 272.81 253.73"/>
                    <polygon className="cls-18" points="271.46 254.7 271.49 253.94 272.12 254.4 272.09 255.06 271.46 254.7"/>
                    <path className="cls-16" d="M271.55,254.14l.48.27V255l-.48-.28v-.54m-.11-.2v.81l.71.4v-.8l-.71-.41Z"/>
                    <polygon className="cls-18" points="269.94 254.63 269.96 253.22 270.69 253.73 270.66 255.04 269.94 254.63"/>
                    <path className="cls-16" d="M270,253.42l.59.33V255l-.59-.34v-1.19m-.11-.2v1.46l.81.46v-1.46l-.81-.46Z"/>
                    <polygon className="cls-14" points="235.72 256.43 239.94 253.99 239.94 251.04 235.72 253.37 235.72 256.43"/>
                    <polygon className="cls-15" points="231.53 253.92 231.57 251.02 233.65 249.9 235.65 253.41 235.64 256.3 231.53 253.92"/>
                    <path className="cls-16" d="M233.62,250l1.94,3.41v2.75l-3.91-2.26v-2.79l2-1.11m.06-.21L231.49,251v3l4.23,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-14" points="235.72 253.37 239.94 251.04 237.78 247.46 233.68 249.79 235.72 253.37"/>
                    <polygon className="cls-18" points="234.15 254.5 234.18 253.73 234.91 254.15 234.89 254.92 234.15 254.5"/>
                    <path className="cls-16" d="M234.25,253.85l.57.32v.64l-.57-.32v-.64m-.13-.23v.94l.83.47v-.94l-.83-.47Z"/>
                    <polygon className="cls-18" points="232.37 254.42 232.4 252.89 233.25 253.38 233.22 254.91 232.37 254.42"/>
                    <path className="cls-16" d="M232.47,253l.68.39v1.39l-.68-.39V253m-.13-.23v1.7l.94.54v-1.7l-.94-.54Z"/>
                    <polygon className="cls-14" points="216.3 245.81 220.53 243.37 220.53 240.42 216.3 242.75 216.3 245.81"/>
                    <polygon className="cls-15" points="212.11 243.31 212.15 240.4 214.23 239.28 216.23 242.79 216.22 245.68 212.11 243.31"/>
                    <path className="cls-16" d="M214.2,239.39l1.94,3.41v2.74l-3.91-2.26v-2.79l2-1.1m.06-.22-2.19,1.23v3l4.23,2.44v-3.05l-2-3.59Z"/>
                    <polygon className="cls-14" points="216.3 242.75 220.53 240.42 218.36 236.84 214.26 239.18 216.3 242.75"/>
                    <polygon className="cls-18" points="214.73 243.89 214.76 243.12 215.5 243.53 215.47 244.3 214.73 243.89"/>
                    <path className="cls-16" d="M214.83,243.23l.57.32v.64l-.57-.32v-.64m-.13-.22v.93l.83.47v-.93l-.83-.47Z"/>
                    <polygon className="cls-18" points="212.95 243.81 212.98 242.28 213.83 242.76 213.8 244.29 212.95 243.81"/>
                    <path className="cls-16" d="M213.05,242.39l.68.39v1.39l-.68-.38v-1.4m-.13-.22v1.69l.94.54v-1.7l-.94-.53Z"/>
                    <polygon className="cls-14" points="207.53 240.82 211.76 238.38 211.76 235.43 207.53 237.76 207.53 240.82"/>
                    <polygon className="cls-15" points="203.35 238.31 203.39 235.41 205.47 234.29 207.47 237.8 207.46 240.69 203.35 238.31"/>
                    <path className="cls-16" d="M205.44,234.4l1.94,3.41v2.74l-3.92-2.26V235.5l2-1.1m.06-.22-2.19,1.23v3l4.22,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-14" points="207.53 237.76 211.76 235.43 209.59 231.85 205.5 234.18 207.53 237.76"/>
                    <polygon className="cls-15" points="212.38 308.99 212.38 301.31 218.69 300.69 225.06 309.04 225.06 316.39 212.38 308.99"/>
                    <path className="cls-16" d="M218.58,301l6.23,8.18V316l-12.18-7.11v-7.31l6-.59m.23-.52-6.68.65v8.06l13.18,7.69V309l-6.5-8.53Z"/>
                    <path className="cls-17" d="M214,307.25v-3.67a.4.4,0,0,1,.08-.31.24.24,0,0,1,.19-.08.54.54,0,0,1,.27.09,1.67,1.67,0,0,1,.75,1.26V308Z"/>
                    <path className="cls-16" d="M214.23,303.37a.39.39,0,0,1,.18.06,1.5,1.5,0,0,1,.67,1.11v3.16l-1-.55v-3.6a.24.24,0,0,1,0-.16s0,0,.06,0m0-.35c-.28,0-.47.21-.45.56v3.77l1.65,1v-3.77a1.83,1.83,0,0,0-.84-1.41.68.68,0,0,0-.36-.11Z"/>
                    <polygon className="cls-17" points="217.21 311.87 217.21 308.16 220.18 309.86 220.15 313.6 217.21 311.87"/>
                    <path className="cls-16" d="M217.39,308.47,220,310l0,3.33-2.59-1.52v-3.3m-.35-.61V312l3.28,1.93,0-4.14-3.31-1.9Z"/>
                    <path className="cls-17" d="M222.17,312v-3.67c0-.17.05-.38.27-.38a.67.67,0,0,1,.31.09,1.61,1.61,0,0,1,.71,1.26v3.46Z"/>
                    <path className="cls-16" d="M222.44,308.13a.46.46,0,0,1,.22.07,1.43,1.43,0,0,1,.63,1.11v3.16l-.94-.56v-3.57c0-.21.07-.21.09-.21m0-.35c-.27,0-.44.2-.44.56v3.77l1.64,1v-3.77a1.8,1.8,0,0,0-.81-1.42.89.89,0,0,0-.39-.11Z"/>
                    <polygon className="cls-14" points="231.87 289.73 226.38 292.86 225.09 293.6 221.69 289.26 218.63 289.52 215.66 291.23 215.66 291.23 216.08 292.29 216.08 292.29 218.72 292.07 221.37 295.35 224.36 293.61 224.36 301.02 221.33 302.76 218.69 299.48 216.05 299.73 216.01 298.94 212.08 301.23 212.07 301.24 218.69 300.57 225.39 309.23 240.29 300.57 231.87 289.73"/>
                    <path className="cls-14" d="M225.31,309.14v7.69l14.25-8.27v-7.62Zm3.81,3.49-1.65.93v-3.77a1.79,1.79,0,0,1,.81-1.41c.45-.26.84-.07.84.48Zm4.12-2.38-1.64,1v-3.77a1.83,1.83,0,0,1,.8-1.45c.45-.25.84-.06.84.49Zm4.12-2.39-1.64.94V305a1.72,1.72,0,0,1,.8-1.42c.46-.26.84-.06.84.48Z"/>
                    <polygon className="cls-14" points="221.37 295.26 224.46 293.52 224.46 301.11 221.33 302.85 221.37 295.26"/>
                    <polygon className="cls-15" points="216.1 299.74 216.1 292.3 218.72 292.08 218.72 292.07 221.37 295.35 224.36 293.61 224.36 301.02 221.33 302.76 218.69 299.48 216.1 299.74"/>
                    <polygon className="cls-15" points="167.01 232.9 167.01 229.8 169.54 229.55 172.15 232.98 172.15 235.89 167.01 232.9"/>
                    <path className="cls-16" d="M169.46,229.74,172,233v2.54l-4.77-2.79V230l2.26-.22m.16-.38-2.78.28V233l5.49,3.21v-3.29l-2.71-3.56Z"/>
                    <path className="cls-17" d="M167.56,232.2l0-1.52a.2.2,0,0,1,0-.14l.12,0,.09,0a.72.72,0,0,1,.32.54v1.46Z"/>
                    <path className="cls-16" d="M167.71,230.57l.09,0a.64.64,0,0,1,.28.48v1.35l-.43-.25v-1.51a.13.13,0,0,1,0-.08s0,0,0,0m0-.13c-.11,0-.19.09-.18.24v1.57l.68.4v-1.57a.77.77,0,0,0-.35-.59.3.3,0,0,0-.15-.05Z"/>
                    <polygon className="cls-17" points="168.93 234.1 168.97 232.63 170.22 233.34 170.16 234.82 168.93 234.1"/>
                    <path className="cls-16" d="M169.07,232.79l1,.58v1.28l-1-.58v-1.28m-.19-.32v1.71l1.37.81,0-1.73-1.39-.79Z"/>
                    <path className="cls-17" d="M171,234.18l0-1.51c0-.07,0-.18.12-.18a.38.38,0,0,1,.13,0,.68.68,0,0,1,.3.54v1.46Z"/>
                    <path className="cls-16" d="M171.14,232.56l.1,0a.64.64,0,0,1,.27.48v1.35l-.43-.25v-1.5c0-.11,0-.11.06-.11m0-.13c-.12,0-.19.09-.19.24v1.57l.69.4v-1.57a.76.76,0,0,0-.34-.59.37.37,0,0,0-.16,0Z"/>
                    <polygon className="cls-14" points="175.07 224.9 172.78 226.21 172.24 226.52 170.82 224.71 169.55 224.81 168.31 225.53 168.31 225.53 168.49 225.97 168.49 225.97 169.59 225.88 170.69 227.25 171.94 226.52 171.94 229.61 170.68 230.34 169.57 228.97 168.47 229.07 168.46 228.74 166.81 229.7 166.81 229.7 169.57 229.42 172.37 233.04 178.58 229.43 175.07 224.9"/>
                    <path className="cls-14" d="M172.33,233v3.21l5.95-3.45v-3.18Z"/>
                    <polygon className="cls-14" points="170.69 227.21 171.98 226.48 171.98 229.65 170.68 230.37 170.69 227.21"/>
                    <polygon className="cls-15" points="168.49 229.08 168.49 225.97 169.58 225.88 169.59 225.88 170.69 227.25 171.94 226.52 171.94 229.61 170.68 230.34 169.57 228.97 168.49 229.08"/>
                    <polygon className="cls-14" points="128.32 248.04 138.25 242.31 138.25 235.38 128.32 240.86 128.32 248.04"/>
                    <polygon className="cls-15" points="118.64 242.16 118.64 235.47 123.44 232.78 128.07 240.92 128.07 247.61 118.64 242.16"/>
                    <path className="cls-16" d="M123.35,233.12l4.47,7.87v6.18L118.89,242v-6.4l4.46-2.5m.19-.68-5.15,2.88v7l9.93,5.73v-7.18l-4.78-8.42Z"/>
                    <polygon className="cls-14" points="128.32 240.86 138.25 235.38 133.16 226.96 123.54 232.44 128.32 240.86"/>
                    <polygon className="cls-18" points="124.74 243.55 124.74 241.75 126.34 242.65 126.34 244.45 124.74 243.55"/>
                    <path className="cls-16" d="M124.92,242.05l1.24.7v1.4l-1.24-.71v-1.39m-.35-.6v2.2l1.94,1.1v-2.2l-1.94-1.1Z"/>
                    <polygon className="cls-18" points="120.55 243.35 120.55 239.77 122.43 240.83 122.43 244.41 120.55 243.35"/>
                    <path className="cls-16" d="M120.73,240.07l1.52.86v3.18l-1.52-.86v-3.18m-.35-.6v4l2.22,1.25v-4l-2.22-1.26Z"/>
                    <polygon className="cls-14" points="213.83 283.28 221.28 278.98 221.28 273.78 213.83 277.89 213.83 283.28"/>
                    <polygon className="cls-15" points="206.63 278.83 206.63 273.89 210.15 271.92 213.58 277.96 213.58 282.84 206.63 278.83"/>
                    <path className="cls-16" d="M210.05,272.26l3.28,5.76v4.39l-6.45-3.72V274l3.17-1.77m.19-.68-3.86,2.16V279l7.45,4.3v-5.39l-3.59-6.31Z"/>
                    <polygon className="cls-14" points="213.83 277.89 221.28 273.78 217.46 267.47 210.24 271.58 213.83 277.89"/>
                    <polygon className="cls-18" points="211.13 279.92 211.13 278.53 212.36 279.23 212.36 280.61 211.13 279.92"/>
                    <path className="cls-16" d="M211.24,278.73l1,.56v1.13l-1-.57v-1.12m-.23-.4V280l1.46.83v-1.65l-1.46-.83Z"/>
                    <polygon className="cls-18" points="207.98 279.77 207.98 277.05 209.42 277.86 209.42 280.59 207.98 279.77"/>
                    <path className="cls-16" d="M208.1,277.24l1.21.69v2.46l-1.21-.68v-2.47m-.23-.39v3l1.67.94v-3l-1.67-.95Z"/>
                    <g className="cls-22">
                      <polygon className="cls-14" points="220.75 216.69 223.01 215.39 223.01 213.81 220.75 215.06 220.75 216.69"/>
                      <polygon className="cls-15" points="218.54 215.31 218.59 213.8 219.63 213.27 220.67 215.1 220.66 216.54 218.54 215.31"/>
                      <path className="cls-16" d="M219.6,213.39l1,1.72v1.27l-1.9-1.09v-1.38l.93-.52m.06-.24-1.17.65v1.59l2.26,1.3v-1.63l-1.09-1.91Z"/>
                      <polygon className="cls-14" points="220.75 215.06 223.01 213.81 221.85 211.9 219.66 213.15 220.75 215.06"/>
                      <polygon className="cls-14" points="207.15 226.56 210.54 224.6 210.54 222.24 207.15 224.11 207.15 226.56"/>
                      <polygon className="cls-15" points="203.81 224.52 203.86 222.22 205.49 221.36 207.07 224.15 207.06 226.4 203.81 224.52"/>
                      <path className="cls-16" d="M205.45,221.48l1.52,2.67v2.09l-3-1.74v-2.17l1.5-.85m.07-.24-1.75,1v2.38l3.38,2v-2.45l-1.63-2.87Z"/>
                      <polygon className="cls-14" points="207.15 224.11 210.54 222.24 208.8 219.37 205.52 221.24 207.15 224.11"/>
                      <polygon className="cls-14" points="196.53 234.43 200.76 231.99 200.76 229.04 196.53 231.38 196.53 234.43"/>
                      <polygon className="cls-15" points="192.35 231.92 192.4 229.02 194.46 227.92 196.45 231.42 196.44 234.28 192.35 231.92"/>
                      <path className="cls-16" d="M194.43,228l1.92,3.38v2.7l-3.86-2.23v-2.76l1.94-1.09m.07-.24L192.31,229v3l4.22,2.44v-3.05l-2-3.58Z"/>
                      <polygon className="cls-14" points="196.53 231.38 200.76 229.04 198.59 225.47 194.5 227.8 196.53 231.38"/>
                      <polygon className="cls-15" points="214.17 231.01 214.22 229.05 217.71 231.03 217.66 232.99 214.17 231.01"/>
                      <path className="cls-16" d="M214.31,229.21l3.26,1.85v1.77L214.31,231v-1.77m-.18-.31v2.19l3.62,2V231l-3.62-2Z"/>
                      <polygon className="cls-14" points="221.37 231.09 217.75 233.14 217.75 230.95 221.37 228.9 221.37 231.09"/>
                      <polygon className="cls-14" points="217.75 226.85 221.37 228.9 217.75 230.95 214.13 228.9 217.75 226.85"/>
                      <polygon className="cls-14" points="174.48 222.16 178.71 219.72 178.71 216.77 174.48 219.1 174.48 222.16"/>
                      <polygon className="cls-15" points="170.3 219.64 170.34 216.75 172.41 215.65 174.4 219.15 174.39 222 170.3 219.64"/>
                      <path className="cls-16" d="M172.38,215.77l1.92,3.38v2.7l-3.87-2.23v-2.76l1.95-1.09m.06-.25-2.19,1.23v3l4.23,2.44V219.1l-2-3.58Z"/>
                      <polygon className="cls-14" points="174.48 219.1 178.71 216.77 176.54 213.19 172.44 215.52 174.48 219.1"/>
                      <polygon className="cls-14" points="196.82 220.58 200.14 218.66 200.14 216.34 196.82 218.17 196.82 220.58"/>
                      <polygon className="cls-15" points="193.54 218.58 193.58 216.32 195.18 215.47 196.74 218.22 196.73 220.42 193.54 218.58"/>
                      <path className="cls-16" d="M195.15,215.6l1.49,2.62v2.05l-3-1.72v-2.12l1.48-.83m.07-.24-1.73,1v2.34l3.33,1.92v-2.41l-1.6-2.81Z"/>
                      <polygon className="cls-14" points="196.82 218.17 200.14 216.34 198.44 213.52 195.22 215.35 196.82 218.17"/>
                      <polygon className="cls-15" points="182.02 226.34 182.06 221.53 186.44 224.01 186.4 228.82 182.02 226.34"/>
                      <path className="cls-16" d="M182.15,221.68,186.3,224v4.64l-4.15-2.35v-4.64m-.18-.31v5l4.51,2.56v-5L182,221.37Z"/>
                      <polygon className="cls-14" points="191 226.42 186.49 228.98 186.49 223.93 191 221.37 191 226.42"/>
                      <polygon className="cls-14" points="186.48 218.82 191 221.37 186.49 223.93 181.97 221.37 186.48 218.82"/>
                      <polygon className="cls-14" points="165.17 216.68 169.12 214.4 169.12 211.65 165.17 213.83 165.17 216.68"/>
                      <polygon className="cls-15" points="161.27 214.32 161.31 211.63 163.23 210.6 165.09 213.87 165.08 216.53 161.27 214.32"/>
                      <path className="cls-16" d="M163.2,210.73l1.79,3.14v2.5l-3.59-2.07v-2.57l1.8-1m.07-.25-2.05,1.15v2.77l3.95,2.28v-2.85l-1.9-3.35Z"/>
                      <polygon className="cls-14" points="165.17 213.83 169.12 211.65 167.09 208.3 163.27 210.48 165.17 213.83"/>
                      <polygon className="cls-14" points="214.93 221.17 217.75 219.54 217.75 217.57 214.93 219.13 214.93 221.17"/>
                      <polygon className="cls-15" points="212.15 219.46 212.2 217.55 213.53 216.86 214.85 219.17 214.84 221.01 212.15 219.46"/>
                      <path className="cls-16" d="M213.5,217l1.25,2.19v1.68l-2.46-1.42v-1.77l1.21-.68m.07-.24-1.46.81v2l2.82,1.63v-2l-1.36-2.39Z"/>
                      <polygon className="cls-14" points="214.93 219.13 217.75 217.57 216.3 215.18 213.57 216.74 214.93 219.13"/>
                    </g>
                    <g className="cls-23">
                      <polygon className="cls-14" points="183.38 209.1 185.74 207.74 185.74 206.09 183.38 207.4 183.38 209.1"/>
                      <polygon className="cls-15" points="181.06 207.66 181.11 206.08 182.21 205.52 183.3 207.44 183.29 208.95 181.06 207.66"/>
                      <path className="cls-16" d="M182.17,205.64l1,1.8v1.35l-2-1.15v-1.45l1-.55m.07-.24-1.22.68v1.66l2.36,1.36v-1.7l-1.14-2Z"/>
                      <polygon className="cls-14" points="183.38 207.4 185.74 206.09 184.53 204.1 182.24 205.4 183.38 207.4"/>
                      <polygon className="cls-14" points="211.83 210.99 214.19 209.63 214.19 207.98 211.83 209.28 211.83 210.99"/>
                      <polygon className="cls-15" points="209.51 209.55 209.56 207.97 210.66 207.4 211.75 209.33 211.74 210.83 209.51 209.55"/>
                      <path className="cls-16" d="M210.62,207.53l1,1.8v1.35l-2-1.16v-1.45l1-.54m.07-.25-1.22.69v1.66l2.36,1.36v-1.71l-1.14-2Z"/>
                      <polygon className="cls-14" points="211.83 209.28 214.19 207.98 212.98 205.98 210.69 207.28 211.83 209.28"/>
                      <polygon className="cls-14" points="206.35 214.71 209.18 213.08 209.18 211.11 206.35 212.67 206.35 214.71"/>
                      <polygon className="cls-15" points="203.58 213 203.62 211.09 204.96 210.4 206.28 212.71 206.26 214.55 203.58 213"/>
                      <path className="cls-16" d="M204.93,210.52l1.24,2.19v1.69L203.71,213V211.2l1.22-.68m.06-.24-1.46.82v2l2.82,1.63v-2L205,210.28Z"/>
                      <polygon className="cls-14" points="206.35 212.67 209.18 211.11 207.73 208.72 205 210.28 206.35 212.67"/>
                      <polygon className="cls-14" points="185.14 217.06 188.09 215.36 188.09 213.3 185.14 214.93 185.14 217.06"/>
                      <polygon className="cls-15" points="182.24 215.28 182.28 213.28 183.69 212.55 185.06 214.97 185.05 216.9 182.24 215.28"/>
                      <path className="cls-16" d="M183.65,212.67,185,215v1.78l-2.59-1.5v-1.86l1.28-.72m.07-.24-1.53.85v2.08l2.95,1.7v-2.13l-1.42-2.5Z"/>
                      <polygon className="cls-14" points="185.14 214.93 188.09 213.3 186.58 210.8 183.72 212.43 185.14 214.93"/>
                      <polygon className="cls-14" points="174.1 209.8 177.05 208.1 177.05 206.04 174.1 207.66 174.1 209.8"/>
                      <polygon className="cls-15" points="171.2 208.02 171.24 206.02 172.65 205.29 174.02 207.71 174.01 209.64 171.2 208.02"/>
                      <path className="cls-16" d="M172.61,205.41l1.31,2.3v1.78l-2.59-1.5v-1.86l1.28-.72m.07-.24-1.53.85v2.08l2.95,1.7v-2.13l-1.42-2.5Z"/>
                      <polygon className="cls-14" points="174.1 207.66 177.05 206.04 175.54 203.54 172.68 205.17 174.1 207.66"/>
                      <polygon className="cls-15" points="189.44 211.01 189.48 209.66 191.96 211.07 191.92 212.41 189.44 211.01"/>
                      <path className="cls-16" d="M189.57,209.82l2.25,1.27v1.17L189.57,211v-1.16m-.18-.31v1.58l2.61,1.48V211l-2.61-1.48Z"/>
                      <polygon className="cls-14" points="194.61 211.09 192 212.56 192 210.99 194.61 209.51 194.61 211.09"/>
                      <polygon className="cls-14" points="192 208.03 194.61 209.51 192 210.99 189.39 209.51 192 208.03"/>
                      <polygon className="cls-14" points="197.56 209.1 199.92 207.74 199.92 206.09 197.56 207.4 197.56 209.1"/>
                      <polygon className="cls-15" points="195.24 207.66 195.29 206.08 196.39 205.52 197.48 207.44 197.47 208.95 195.24 207.66"/>
                      <path className="cls-16" d="M196.35,205.64l1,1.8v1.35l-2-1.15v-1.45l1-.55m.07-.24-1.22.68v1.66l2.36,1.36v-1.7l-1.14-2Z"/>
                      <polygon className="cls-14" points="197.56 207.4 199.92 206.09 198.71 204.1 196.42 205.4 197.56 207.4"/>
                    </g>
                    <g className="cls-24">
                      <polygon className="cls-14" points="180.66 203.72 182.53 202.64 182.53 201.34 180.66 202.37 180.66 203.72"/>
                      <polygon className="cls-15" points="178.83 202.56 178.88 201.32 179.72 200.9 180.58 202.41 180.57 203.57 178.83 202.56"/>
                      <path className="cls-16" d="M179.69,201l.79,1.39v1l-1.51-.87v-1.11l.72-.4m.07-.25-1,.55v1.31l1.87,1.08v-1.35l-.9-1.59Z"/>
                      <polygon className="cls-14" points="180.66 202.37 182.53 201.34 181.57 199.75 179.76 200.78 180.66 202.37"/>
                      <polygon className="cls-14" points="189.39 196.77 190.74 195.99 190.74 195.04 189.39 195.79 189.39 196.77"/>
                      <polygon className="cls-15" points="188.08 195.91 188.13 195.03 188.7 194.76 189.31 195.83 189.3 196.61 188.08 195.91"/>
                      <path className="cls-16" d="M188.67,194.89l.54,1v.62l-1-.58v-.74l.45-.25m.07-.25-.7.39v1l1.35.78v-1l-.65-1.15Z"/>
                      <polygon className="cls-14" points="189.39 195.79 190.74 195.04 190.05 193.89 188.74 194.64 189.39 195.79"/>
                      <polygon className="cls-14" points="197.56 202.5 199.43 201.42 199.43 200.11 197.56 201.15 197.56 202.5"/>
                      <polygon className="cls-15" points="195.73 201.34 195.78 200.1 196.62 199.68 197.48 201.19 197.47 202.35 195.73 201.34"/>
                      <path className="cls-16" d="M196.59,199.8l.79,1.39v1l-1.51-.87v-1.11l.72-.41m.07-.24-1,.54v1.32l1.87,1.08v-1.35l-.9-1.59Z"/>
                      <polygon className="cls-14" points="197.56 201.15 199.43 200.11 198.47 198.53 196.66 199.56 197.56 201.15"/>
                      <polygon className="cls-14" points="193.31 199.15 194.89 198.24 194.89 197.13 193.31 198 193.31 199.15"/>
                      <polygon className="cls-15" points="191.77 198.16 191.82 197.13 192.51 196.78 193.23 198.05 193.22 198.99 191.77 198.16"/>
                      <path className="cls-16" d="M192.48,196.91l.65,1.14v.79l-1.22-.71v-.9l.57-.32m.07-.24-.82.46v1.11l1.58.91V198l-.76-1.34Z"/>
                      <polygon className="cls-14" points="193.31 198 194.89 197.13 194.08 195.79 192.55 196.67 193.31 198"/>
                      <polygon className="cls-15" points="189.08 203.88 189.12 201.91 190.96 202.95 190.91 204.92 189.08 203.88"/>
                      <path className="cls-16" d="M189.21,202.07l1.61.9v1.79l-1.61-.91v-1.78m-.18-.31V204l2,1.11v-2.2l-2-1.11Z"/>
                      <polygon className="cls-14" points="192.97 203.96 191 205.07 191 202.87 192.97 201.76 192.97 203.96"/>
                      <polygon className="cls-14" points="191 200.64 192.97 201.76 191 202.87 189.03 201.76 191 200.64"/>
                      <polygon className="cls-15" points="183.99 199.01 184.03 198.05 185.86 199.09 185.82 200.05 183.99 199.01"/>
                      <path className="cls-16" d="M184.12,198.21l1.61.91v.77l-1.61-.91v-.77m-.18-.31v1.19l2,1.11V199l-2-1.11Z"/>
                      <polygon className="cls-14" points="187.87 199.09 185.91 200.2 185.91 199.01 187.87 197.9 187.87 199.09"/>
                      <polygon className="cls-14" points="185.9 196.79 187.87 197.9 185.91 199.01 183.94 197.9 185.9 196.79"/>
                      <polygon className="cls-15" points="201.05 204.61 201.09 203.42 202.13 203.32 203.14 204.71 203.12 205.82 201.05 204.61"/>
                      <path className="cls-16" d="M202,203.42l1,1.3v.94l-1.84-1.07V203.5l.85-.08m.08-.19-1.11.11v1.35l2.2,1.29v-1.32l-1.09-1.43Z"/>
                      <polygon className="cls-14" points="204.31 201.44 203.39 201.97 203.17 202.09 202.6 201.36 202.09 201.41 201.6 201.69 201.6 201.69 201.67 201.87 201.67 201.87 202.11 201.83 202.55 202.38 203.05 202.09 203.05 203.33 202.55 203.62 202.1 203.07 201.66 203.12 201.66 202.98 201 203.37 201 203.37 202.1 203.25 203.22 204.7 205.72 203.26 204.31 201.44"/>
                      <path className="cls-14" d="M203.21,204.69V206l2.38-1.38v-1.27Z"/>
                      <polyline className="cls-15" points="202.11 201.83 202.55 202.38 203.05 202.09 203.05 203.33 202.55 203.62 202.1 203.07"/>
                      <polygon className="cls-15" points="202.55 202.37 203.07 202.07 203.07 203.34 202.55 203.64 202.55 202.37"/>
                      <polyline className="cls-15" points="202.11 203.07 201.67 203.12 201.67 201.87 202.11 201.84"/>
                    </g>
                  </g>
                  <g id="community_gray" data-name="community gray">
                    <g className="cls-22">
                      <polygon className="cls-20" points="206.54 212.78 208.99 211.43 208.99 212.97 206.54 214.38 206.54 212.78"/>
                      <path className="cls-20" d="M208.8,211.74v1.12l-2.07,1.2v-1.17l2.07-1.15m.37-.63-2.82,1.56v2l2.82-1.63v-2Z"/>
                      <polygon className="cls-20" points="203.56 213.02 203.6 211.09 205.03 210.33 206.3 212.7 206.29 214.59 203.56 213.02"/>
                      <path className="cls-20" d="M205,210.46l1.27,2.24v1.78L203.66,213v-1.83l1.29-.71m0-.18-1.46.82v2l2.82,1.63v-2L205,210.28Z"/>
                      <polygon className="cls-20" points="205.25 210.35 207.66 208.97 208.91 211.04 206.43 212.41 205.25 210.35"/>
                      <path className="cls-20" d="M207.6,209.23l1.05,1.74-2.15,1.19-1-1.74,2.1-1.19m.13-.51L205,210.28l1.36,2.39,2.82-1.56-1.44-2.39Z"/>
                      <polygon className="cls-20" points="189.41 211.05 189.43 209.51 191.98 211.02 191.96 212.5 189.41 211.05"/>
                      <path className="cls-20" d="M189.47,209.65l2.45,1.39v1.39L189.47,211v-1.39m-.08-.14v1.58l2.61,1.48V211l-2.61-1.48Z"/>
                      <polygon className="cls-20" points="192.19 211.1 194.43 209.83 194.43 210.98 192.19 212.25 192.19 211.1"/>
                      <path className="cls-20" d="M194.24,210.15v.72l-1.86,1v-.71l1.86-1.06m.37-.64L192,211v1.58l2.61-1.48v-1.58Z"/>
                      <polygon className="cls-20" points="189.77 209.51 192 208.25 194.23 209.51 192 210.77 189.77 209.51"/>
                      <path className="cls-20" d="M192,208.46l1.85,1L192,210.56l-1.85-1.05,1.85-1m0-.43-2.61,1.48L192,211l2.61-1.48L192,208Z"/>
                      <polygon className="cls-20" points="185.33 215.04 187.91 213.62 187.91 215.25 185.33 216.74 185.33 215.04"/>
                      <path className="cls-20" d="M187.72,213.93v1.21l-2.2,1.27v-1.26l2.2-1.22m.37-.63-3,1.63v2.13l3-1.7V213.3Z"/>
                      <polygon className="cls-20" points="182.22 215.32 182.24 213.28 183.75 212.47 185.1 214.95 185.1 216.98 182.22 215.32"/>
                      <path className="cls-20" d="M183.69,212.55l1.36,2.4v2l-2.76-1.6v-2l1.4-.79m0-.12-1.53.85v2.08l2.95,1.7v-2.13l-1.42-2.5Z"/>
                      <polygon className="cls-20" points="183.98 212.5 186.51 211.05 187.83 213.23 185.21 214.67 183.98 212.5"/>
                      <path className="cls-20" d="M186.45,211.31l1.12,1.85-2.28,1.26-1.06-1.85,2.22-1.26m.13-.51-2.86,1.63,1.42,2.5,3-1.63-1.51-2.5Z"/>
                      <polygon className="cls-20" points="183.56 207.51 185.55 206.41 185.55 207.63 183.56 208.78 183.56 207.51"/>
                      <path className="cls-20" d="M185.36,206.73v.8l-1.61.93v-.84l1.61-.89m.38-.64-2.36,1.31v1.7l2.36-1.36v-1.65Z"/>
                      <polygon className="cls-20" points="181.04 207.71 181.06 206.08 182.26 205.43 183.35 207.41 183.34 209.04 181.04 207.71"/>
                      <path className="cls-20" d="M182.21,205.5l1.09,1.92V209l-2.21-1.28v-1.57l1.12-.63m0-.1-1.22.68v1.66l2.36,1.36v-1.7l-1.14-2Z"/>
                      <polygon className="cls-20" points="182.5 205.47 184.46 204.35 185.48 206.03 183.45 207.14 182.5 205.47"/>
                      <path className="cls-20" d="M184.4,204.6l.82,1.36-1.7.93-.77-1.35,1.65-.94m.13-.5-2.29,1.3,1.14,2,2.36-1.31-1.21-2Z"/>
                      <polygon className="cls-20" points="197.75 207.51 199.73 206.41 199.73 207.63 197.75 208.78 197.75 207.51"/>
                      <path className="cls-20" d="M199.54,206.73v.8l-1.61.93v-.84l1.61-.89m.38-.64-2.36,1.31v1.7l2.36-1.36v-1.65Z"/>
                      <polygon className="cls-20" points="195.22 207.71 195.24 206.08 196.44 205.43 197.53 207.41 197.52 209.04 195.22 207.71"/>
                      <path className="cls-20" d="M196.39,205.5l1.09,1.92V209l-2.21-1.28v-1.57l1.12-.63m0-.1-1.22.68v1.66l2.36,1.36v-1.7l-1.14-2Z"/>
                      <polygon className="cls-20" points="196.68 205.47 198.64 204.35 199.66 206.03 197.63 207.14 196.68 205.47"/>
                      <path className="cls-20" d="M198.58,204.6l.82,1.36-1.7.93-.77-1.35,1.65-.94m.13-.5-2.29,1.3,1.14,2,2.36-1.31-1.21-2Z"/>
                      <polygon className="cls-20" points="212.02 209.39 214 208.3 214 209.52 212.02 210.66 212.02 209.39"/>
                      <path className="cls-20" d="M213.81,208.61v.8l-1.61.93v-.84l1.61-.89m.38-.63-2.36,1.3V211l2.36-1.36V208Z"/>
                      <polygon className="cls-20" points="209.49 209.59 209.51 207.97 210.71 207.31 211.8 209.3 211.79 210.93 209.49 209.59"/>
                      <path className="cls-20" d="M210.66,207.38l1.1,1.92v1.56l-2.22-1.28V208l1.12-.63m0-.1-1.22.69v1.66l2.36,1.36v-1.71l-1.14-2Z"/>
                      <polygon className="cls-20" points="210.95 207.35 212.91 206.23 213.93 207.91 211.9 209.03 210.95 207.35"/>
                      <path className="cls-20" d="M212.85,206.49l.82,1.35-1.7.94-.77-1.36,1.65-.93M213,206l-2.29,1.3,1.14,2,2.36-1.3L213,206Z"/>
                      <polygon className="cls-20" points="174.29 207.78 176.86 206.35 176.86 207.99 174.29 209.47 174.29 207.78"/>
                      <path className="cls-20" d="M176.68,206.67v1.21l-2.21,1.27v-1.26l2.21-1.22m.37-.63-3,1.63v2.13l3-1.7V206Z"/>
                      <polygon className="cls-20" points="171.18 208.06 171.2 206.02 172.7 205.21 174.06 207.69 174.06 209.72 171.18 208.06"/>
                      <path className="cls-20" d="M172.65,205.29l1.36,2.4v1.95l-2.77-1.6v-2l1.41-.79m0-.12-1.53.85v2.08l2.95,1.7v-2.13l-1.42-2.5Z"/>
                      <polygon className="cls-20" points="172.94 205.24 175.47 203.79 176.79 205.97 174.17 207.41 172.94 205.24"/>
                      <path className="cls-20" d="M175.41,204l1.12,1.86-2.29,1.26-1.05-1.85,2.22-1.27m.13-.5-2.86,1.63,1.42,2.5,3-1.63-1.51-2.5Z"/>
                    </g>
                    <g className="cls-23">
                      <polygon className="cls-20" points="183.96 199.06 183.97 197.9 185.89 199.04 185.88 200.15 183.96 199.06"/>
                      <path className="cls-20" d="M184,198l1.85,1v1l-1.85-1v-1m-.06-.11v1.19l2,1.11V199l-2-1.11Z"/>
                      <polygon className="cls-20" points="186.09 199.12 187.69 198.22 187.69 198.98 186.09 199.88 186.09 199.12"/>
                      <path className="cls-20" d="M187.5,198.54v.33l-1.22.69v-.33l1.22-.69m.37-.64-2,1.11v1.19l2-1.11V197.9Z"/>
                      <polygon className="cls-20" points="184.32 197.9 185.9 197 187.49 197.9 185.91 198.8 184.32 197.9"/>
                      <path className="cls-20" d="M185.9,197.22l1.21.68-1.2.68-1.21-.68,1.2-.68m0-.43-2,1.11,2,1.11,2-1.11-2-1.11Z"/>
                      <polygon className="cls-20" points="197.75 201.26 199.25 200.43 199.25 201.31 197.75 202.18 197.75 201.26"/>
                      <path className="cls-20" d="M199.06,200.75v.45l-1.13.65v-.48l1.13-.62m.37-.64-1.87,1v1.35l1.87-1.08v-1.31Z"/>
                      <polygon className="cls-20" points="195.7 201.39 195.72 200.1 196.68 199.59 197.53 201.16 197.52 202.44 195.7 201.39"/>
                      <path className="cls-20" d="M196.63,199.66l.85,1.51v1.2l-1.72-1v-1.23l.87-.49m0-.1-1,.54v1.32l1.87,1.08v-1.35l-.9-1.59Z"/>
                      <polygon className="cls-20" points="196.91 199.63 198.41 198.78 199.17 200.04 197.63 200.89 196.91 199.63"/>
                      <path className="cls-20" d="M198.34,199l.57.94-1.21.67-.53-.94,1.17-.67m.13-.5-1.81,1,.9,1.59,1.87-1-1-1.58Z"/>
                      <polygon className="cls-20" points="189.58 195.9 190.56 195.36 190.56 195.88 189.58 196.44 189.58 195.9"/>
                      <path className="cls-20" d="M190.37,195.67v.1l-.61.35V196l.61-.34m.37-.63-1.35.75v1l1.35-.78v-1Z"/>
                      <polygon className="cls-20" points="188.05 195.96 188.06 195.03 188.75 194.66 189.37 195.8 189.36 196.72 188.05 195.96"/>
                      <path className="cls-20" d="M188.72,194.71l.62,1.09v.88l-1.25-.72v-.89l.63-.36m0-.07-.7.39v1l1.35.78v-1l-.65-1.15Z"/>
                      <polygon className="cls-20" points="188.99 194.71 189.98 194.15 190.48 194.97 189.46 195.54 188.99 194.71"/>
                      <path className="cls-20" d="M189.92,194.4l.3.5-.69.38-.28-.5.67-.38m.13-.51-1.31.75.65,1.15,1.35-.75-.69-1.15Z"/>
                      <polygon className="cls-20" points="193.49 198.12 194.7 197.45 194.7 198.13 193.49 198.82 193.49 198.12"/>
                      <path className="cls-20" d="M194.51,197.77V198l-.83.48v-.27l.83-.46m.38-.64-1.58.88v1.14l1.58-.91v-1.11Z"/>
                      <polygon className="cls-20" points="191.74 198.21 191.76 197.13 192.56 196.69 193.28 198.02 193.28 199.09 191.74 198.21"/>
                      <path className="cls-20" d="M192.52,196.75l.72,1.27v1l-1.45-.84v-1l.73-.41m0-.08-.82.46v1.11l1.58.91V198l-.76-1.34Z"/>
                      <polygon className="cls-20" points="192.8 196.74 194.01 196.05 194.63 197.06 193.38 197.75 192.8 196.74"/>
                      <path className="cls-20" d="M194,196.3l.42.69-.92.51-.39-.69.89-.51m.13-.5-1.53.87.76,1.34,1.58-.88-.81-1.33Z"/>
                      <polygon className="cls-20" points="180.85 202.48 182.35 201.65 182.35 202.53 180.85 203.4 180.85 202.48"/>
                      <path className="cls-20" d="M182.16,202v.46l-1.12.65v-.49l1.12-.62m.38-.64-1.88,1v1.35l1.88-1.08v-1.31Z"/>
                      <polygon className="cls-20" points="178.81 202.61 178.83 201.32 179.78 200.81 180.63 202.39 180.63 203.66 178.81 202.61"/>
                      <path className="cls-20" d="M179.73,200.88l.86,1.51v1.21l-1.73-1v-1.23l.87-.49m0-.1-1,.55v1.31l1.87,1.08v-1.35l-.9-1.59Z"/>
                      <polygon className="cls-20" points="180.01 200.85 181.51 200 182.28 201.26 180.73 202.12 180.01 200.85"/>
                      <path className="cls-20" d="M181.44,200.25l.57.95-1.21.66-.53-.94,1.17-.67m.13-.5-1.81,1,.9,1.59,1.88-1-1-1.58Z"/>
                      <polygon className="cls-20" points="189.05 203.92 189.07 201.76 190.98 202.91 190.96 205 189.05 203.92"/>
                      <path className="cls-20" d="M189.12,201.9l1.8,1v2l-1.8-1v-2m-.09-.14V204l2,1.11v-2.2l-2-1.11Z"/>
                      <polygon className="cls-20" points="191.19 202.98 192.78 202.08 192.78 203.85 191.19 204.75 191.19 202.98"/>
                      <path className="cls-20" d="M192.59,202.4v1.34l-1.22.69v-1.34l1.22-.69m.37-.64-2,1.11v2.2l2-1.11v-2.2Z"/>
                      <polygon className="cls-20" points="189.41 201.76 191 200.86 192.59 201.76 191 202.66 189.41 201.76"/>
                      <path className="cls-20" d="M191,201.07l1.21.69-1.21.68-1.21-.68,1.21-.69m0-.43-2,1.12,2,1.11,2-1.11-2-1.12Z"/>
                      <polygon className="cls-20" points="201.04 204.63 201.08 203.34 202.13 203.3 203.15 204.7 203.14 205.85 201.04 204.63"/>
                      <path className="cls-20" d="M202.06,203.38l1,1.33v1l-1.92-1.11v-1.14l.91-.09m.06-.15-1.11.11v1.35l2.2,1.29v-1.32l-1.09-1.43Z"/>
                      <path className="cls-20" d="M202.72,203.74l.52-.3v-1.17l1-.59,1.18,1.52-2.17,1.26Zm-.53-2.1H202l.15-.08h.33l.34.43-.26.15Z"/>
                      <path className="cls-20" d="M204.21,201.93l.95,1.22-1.84,1.07-.33-.42.25-.15.19-.1v-1.17l.15-.09.63-.36m-1.6-.57-.52,0-.49.28.07.18.44,0,.44.55.5-.29v1.24l-.5.29-.45-.55-.44.05V203l-.66.39h0l1.1-.11,1.12,1.44,2.5-1.44-1.41-1.82-.92.53-.22.12-.56-.73Z"/>
                      <polygon className="cls-20" points="203.4 204.8 205.41 203.64 205.41 204.48 203.4 205.65 203.4 204.8"/>
                      <path className="cls-20" d="M205.22,204v.42l-1.64,1v-.43l1.64-.94m.37-.64-2.38,1.37V206l2.38-1.39v-1.27Z"/>
                      <polygon className="cls-20" points="202.74 202.48 202.88 202.4 202.88 203.24 202.73 203.32 202.74 202.48"/>
                      <polygon className="cls-20" points="203.07 202.07 202.55 202.37 202.55 203.64 203.07 203.34 203.07 202.07 203.07 202.07"/>
                      <polygon className="cls-20" points="202.19 202.88 201.86 202.91 201.86 202.04 202.03 202.03 202.51 202.63 202.87 202.42 202.87 203.22 202.59 203.38 202.19 202.88"/>
                      <path className="cls-20" d="M202.05,202.35l.21.27.2.25.22-.13v.37l0,0-.24-.3-.13-.16-.2,0h0v-.35m.06-.52h0l-.44,0v1.25l.43-.05.45.55.5-.29v-1.24l-.5.29-.44-.55Z"/>
                    </g>
                    <g className="cls-25">
                      <polygon className="cls-20" points="174.67 219.21 178.52 217.09 178.52 219.61 174.67 221.84 174.67 219.21"/>
                      <path className="cls-20" d="M178.33,217.4v2.1l-3.48,2v-2.19l3.48-1.92m.37-.63-4.22,2.33v3.06l4.22-2.44v-2.95Z"/>
                      <polygon className="cls-20" points="170.29 219.66 170.32 216.75 172.48 215.58 174.42 219.14 174.41 222.05 170.29 219.66"/>
                      <path className="cls-20" d="M172.39,215.7l2,3.44v2.79l-4-2.29v-2.81l2-1.13m.05-.18-2.19,1.23v3l4.23,2.44V219.1l-2-3.58Z"/>
                      <polygon className="cls-20" points="172.7 215.59 176.47 213.44 178.44 216.7 174.55 218.85 172.7 215.59"/>
                      <path className="cls-20" d="M176.41,213.7l1.77,2.93-3.56,2L173,215.66l3.46-2m.13-.51-4.1,2.33,2,3.58,4.22-2.33-2.16-3.58Z"/>
                      <polygon className="cls-20" points="220.94 215.17 222.82 214.13 222.82 215.28 220.94 216.37 220.94 215.17"/>
                      <path className="cls-20" d="M222.63,214.45v.72l-1.5.88v-.77l1.5-.83m.38-.63-2.26,1.24v1.63l2.26-1.3v-1.57Z"/>
                      <polygon className="cls-20" points="218.53 215.33 218.56 213.8 219.7 213.21 220.69 215.09 220.69 216.58 218.53 215.33"/>
                      <path className="cls-20" d="M219.61,213.33l1,1.77v1.37l-2-1.16v-1.43l1-.55m0-.18-1.17.65v1.59l2.26,1.3v-1.63l-1.09-1.91Z"/>
                      <polygon className="cls-20" points="219.92 213.22 221.78 212.16 222.75 213.75 220.82 214.81 219.92 213.22"/>
                      <path className="cls-20" d="M221.72,212.41l.77,1.27-1.6.88-.72-1.27,1.55-.88m.13-.51-2.19,1.25,1.09,1.91,2.26-1.24-1.16-1.92Z"/>
                      <polygon className="cls-20" points="207.34 224.22 210.35 222.55 210.35 224.49 207.34 226.23 207.34 224.22"/>
                      <path className="cls-20" d="M210.17,222.87v1.51l-2.64,1.53v-1.58l2.64-1.46m.37-.63-3.39,1.87v2.45l3.39-2v-2.36Z"/>
                      <polygon className="cls-20" points="203.8 224.54 203.83 222.22 205.56 221.3 207.1 224.14 207.09 226.44 203.8 224.54"/>
                      <path className="cls-20" d="M205.47,221.42l1.55,2.72v2.19l-3.12-1.81V222.3l1.57-.88m.05-.18-1.75,1v2.38l3.38,2v-2.45l-1.63-2.87Z"/>
                      <polygon className="cls-20" points="205.78 221.31 208.74 219.62 210.28 222.17 207.22 223.85 205.78 221.31"/>
                      <path className="cls-20" d="M208.67,219.87,210,222.1l-2.72,1.5L206,221.38l2.64-1.51m.13-.5-3.28,1.87,1.63,2.87,3.39-1.87-1.74-2.87Z"/>
                      <polygon className="cls-20" points="215.12 219.24 217.56 217.88 217.56 219.43 215.12 220.84 215.12 219.24"/>
                      <path className="cls-20" d="M217.38,218.2v1.12l-2.08,1.2v-1.17l2.08-1.15m.37-.63-2.82,1.56v2l2.82-1.63v-2Z"/>
                      <polygon className="cls-20" points="212.14 219.48 212.17 217.55 213.6 216.79 214.87 219.16 214.86 221.05 212.14 219.48"/>
                      <path className="cls-20" d="M213.52,216.91l1.28,2.25v1.78l-2.56-1.48v-1.83l1.28-.72m0-.17-1.46.81v2l2.82,1.63v-2l-1.36-2.39Z"/>
                      <polygon className="cls-20" points="213.82 216.81 216.24 215.43 217.49 217.5 215 218.87 213.82 216.81"/>
                      <path className="cls-20" d="M216.17,215.68l1.06,1.75-2.16,1.19-1-1.74,2.09-1.2m.13-.5-2.73,1.56,1.36,2.39,2.82-1.56-1.45-2.39Z"/>
                      <polygon className="cls-20" points="165.36 213.94 168.93 211.96 168.93 214.29 165.36 216.36 165.36 213.94"/>
                      <path className="cls-20" d="M168.75,212.28v1.91L165.54,216v-2l3.21-1.77m.37-.63-4,2.18v2.85l4-2.28v-2.75Z"/>
                      <polygon className="cls-20" points="161.25 214.35 161.28 211.63 163.3 210.53 165.12 213.86 165.11 216.58 161.25 214.35"/>
                      <path className="cls-20" d="M163.22,210.65l1.83,3.21v2.61l-3.71-2.14V211.7l1.88-1m.05-.17-2.05,1.15v2.77l3.95,2.28v-2.85l-1.9-3.35Z"/>
                      <polygon className="cls-20" points="163.52 210.55 167.03 208.55 168.86 211.58 165.24 213.57 163.52 210.55"/>
                      <path className="cls-20" d="M167,208.81l1.64,2.7-3.29,1.81-1.53-2.7,3.18-1.81m.13-.51-3.82,2.18,1.9,3.35,4-2.18-2-3.35Z"/>
                      <polygon className="cls-20" points="197 218.28 199.96 216.65 199.96 218.55 197 220.25 197 218.28"/>
                      <path className="cls-20" d="M199.77,217v1.47l-2.58,1.49v-1.54l2.58-1.42m.37-.63-3.32,1.83v2.41l3.32-1.92v-2.32Z"/>
                      <polygon className="cls-20" points="193.53 218.6 193.56 216.32 195.25 215.41 196.76 218.21 196.75 220.47 193.53 218.6"/>
                      <path className="cls-20" d="M195.17,215.53l1.52,2.68v2.14l-3.07-1.77V216.4l1.55-.87m.05-.17-1.73,1v2.34l3.33,1.92v-2.41l-1.6-2.81Z"/>
                      <polygon className="cls-20" points="195.47 215.43 198.37 213.77 199.88 216.27 196.89 217.92 195.47 215.43"/>
                      <path className="cls-20" d="M198.31,214l1.31,2.18L197,217.67l-1.23-2.18,2.58-1.47m.13-.5-3.22,1.84,1.6,2.81,3.32-1.83-1.7-2.82Z"/>
                      <polygon className="cls-20" points="182.02 226.37 182.06 221.57 186.44 224.04 186.39 228.85 182.02 226.37"/>
                      <path className="cls-20" d="M182.16,221.73l4.13,2.34v4.61l-4.13-2.34v-4.61m-.19-.32v5l4.51,2.56v-5L182,221.41Z"/>
                      <polygon className="cls-20" points="186.67 224.07 190.81 221.73 190.81 226.35 186.67 228.69 186.67 224.07"/>
                      <path className="cls-20" d="M190.63,222.05v4.19l-3.77,2.13v-4.19l3.77-2.13m.37-.64L186.48,224v5l4.52-2.56v-5Z"/>
                      <polygon className="cls-20" points="182.35 221.41 186.48 219.06 190.62 221.41 186.49 223.75 182.35 221.41"/>
                      <path className="cls-20" d="M186.48,219.28l3.76,2.13-3.76,2.12-3.75-2.12,3.75-2.13m0-.43L182,221.41l4.51,2.55,4.52-2.55-4.52-2.56Z"/>
                      <polygon className="cls-20" points="196.72 231.49 200.57 229.36 200.57 231.89 196.72 234.11 196.72 231.49"/>
                      <path className="cls-20" d="M200.38,229.68v2.1l-3.48,2V231.6l3.48-1.92m.38-.63-4.23,2.33v3.05l4.23-2.44v-2.94Z"/>
                      <polygon className="cls-20" points="192.34 231.94 192.37 229.02 194.53 227.85 196.47 231.41 196.47 234.32 192.34 231.94"/>
                      <path className="cls-20" d="M194.45,228l2,3.43v2.8l-4-2.29V229.1l2-1.12m.05-.18L192.31,229v3l4.22,2.44v-3.05l-2-3.58Z"/>
                      <polygon className="cls-20" points="194.75 227.87 198.52 225.72 200.5 228.97 196.6 231.12 194.75 227.87"/>
                      <path className="cls-20" d="M198.46,226l1.78,2.94-3.57,2L195,227.94l3.45-2m.13-.5-4.09,2.33,2,3.58,4.23-2.33-2.17-3.58Z"/>
                    </g>
                    <polygon className="cls-20" points="214.31 230.98 214.31 229.22 217.56 231.06 217.56 232.82 214.31 230.98"/>
                    <path className="cls-20" d="M214.5,229.54l2.88,1.63v1.33l-2.88-1.63v-1.33m-.37-.64v2.19l3.62,2V231l-3.62-2Z"/>
                    <polygon className="cls-20" points="217.94 231.06 221.19 229.22 221.19 230.98 217.94 232.82 217.94 231.06"/>
                    <path className="cls-20" d="M221,229.54v1.33l-2.88,1.63v-1.33l2.88-1.63m.37-.64-3.62,2v2.19l3.62-2V228.9Z"/>
                    <polygon className="cls-20" points="214.51 228.9 217.75 227.06 220.99 228.9 217.75 230.74 214.51 228.9"/>
                    <path className="cls-20" d="M217.75,227.28l2.86,1.62-2.86,1.62-2.86-1.62,2.86-1.62m0-.43-3.62,2.05,3.62,2,3.62-2-3.62-2.05Z"/>
                    <g className="cls-22">
                      <polygon className="cls-10" points="220.79 215.06 223.03 213.85 222.97 215.39 220.79 216.63 220.79 215.06"/>
                      <path className="cls-11" d="M222.94,213.93v1.42l-2.12,1.22V215.1l2.12-1.17m.07-.11-2.26,1.24v1.63l2.26-1.3v-1.57Z"/>
                      <polygon className="cls-9" points="218.51 215.36 218.53 213.8 219.68 213.18 220.72 215.08 220.72 216.63 218.51 215.36"/>
                      <path className="cls-11" d="M219.64,213.24l1,1.84v1.49l-2.12-1.22v-1.51l1.08-.6m0-.09-1.17.65v1.59l2.26,1.3v-1.63l-1.09-1.91Z"/>
                      <polygon className="cls-10" points="219.69 213.13 221.87 211.93 222.98 213.83 220.77 215.01 219.69 213.13"/>
                      <path className="cls-11" d="M221.83,212l1.08,1.79L220.78,215l-1-1.79,2.07-1.18m0-.1-2.19,1.25,1.09,1.91,2.26-1.24-1.16-1.92Z"/>
                      <polygon className="cls-10" points="207.21 224.11 210.56 222.28 210.49 224.6 207.21 226.47 207.21 224.11"/>
                      <path className="cls-11" d="M210.44,222.41v2.13l-3.18,1.83v-2.2l3.18-1.76m.1-.17-3.39,1.87v2.45l3.39-2v-2.36Z"/>
                      <polygon className="cls-9" points="203.79 224.56 203.82 222.22 205.55 221.28 207.11 224.13 207.1 226.47 203.79 224.56"/>
                      <path className="cls-11" d="M205.48,221.38l1.57,2.75v2.24l-3.18-1.83v-2.26l1.61-.9m0-.14-1.75,1v2.38l3.38,2v-2.45l-1.63-2.87Z"/>
                      <polygon className="cls-10" points="205.57 221.21 208.83 219.42 210.49 222.26 207.17 224.04 205.57 221.21"/>
                      <path className="cls-11" d="M208.77,219.51l1.62,2.69-3.2,1.77-1.53-2.69,3.11-1.77m0-.14-3.28,1.87,1.63,2.87,3.39-1.87-1.74-2.87Z"/>
                      <polygon className="cls-10" points="196.6 231.38 200.69 229.15 200.69 231.99 196.6 234.32 196.6 231.38"/>
                      <path className="cls-11" d="M200.63,229.26v2.66l-4,2.29v-2.76l4-2.19m.13-.21-4.23,2.33v3.05l4.23-2.44v-2.94Z"/>
                      <polygon className="cls-9" points="192.34 231.94 192.37 229.02 194.53 227.85 196.47 231.41 196.47 234.32 192.34 231.94"/>
                      <path className="cls-11" d="M194.45,228l2,3.44v2.8l-4-2.29V229.1l2-1.13m.05-.17L192.31,229v3l4.22,2.44v-3.05l-2-3.58Z"/>
                      <polygon className="cls-10" points="194.55 227.77 198.62 225.52 200.7 229.08 196.56 231.29 194.55 227.77"/>
                      <path className="cls-11" d="M198.54,225.64l2,3.36-4,2.2-1.91-3.35,3.87-2.21m.05-.17-4.09,2.33,2,3.58,4.23-2.33-2.17-3.58Z"/>
                      <polygon className="cls-9" points="214.15 231.04 214.18 228.9 217.73 231 217.7 233.05 214.15 231.04"/>
                      <path className="cls-11" d="M214.23,229.08l3.42,1.93v2L214.23,231v-1.95m-.1-.18v2.19l3.62,2V231l-3.62-2Z"/>
                      <polygon className="cls-10" points="217.8 230.95 221.4 228.94 221.32 231.09 217.8 233.05 217.8 230.95"/>
                      <path className="cls-11" d="M221.27,229.08V231L217.85,233v-2l3.42-1.93m.1-.18-3.62,2v2.19l3.62-2V228.9Z"/>
                      <polygon className="cls-10" points="214.15 228.86 217.77 226.89 221.35 228.94 217.75 230.89 214.15 228.86"/>
                      <path className="cls-11" d="M217.75,227l3.42,1.94-3.42,1.93-3.41-1.93,3.41-1.94m0-.11-3.62,2.05,3.62,2,3.62-2-3.62-2.05Z"/>
                      <polygon className="cls-10" points="174.54 219.1 178.64 216.88 178.64 219.72 174.54 222.05 174.54 219.1"/>
                      <path className="cls-11" d="M178.58,217v2.66l-4,2.28v-2.75l4-2.19m.12-.22-4.22,2.33v3.06l4.22-2.44v-2.95Z"/>
                      <polygon className="cls-9" points="170.29 219.66 170.32 216.75 172.48 215.58 174.42 219.13 174.42 222.05 170.29 219.66"/>
                      <path className="cls-11" d="M172.4,215.7l1.95,3.44v2.79l-4-2.28v-2.82l2-1.13m0-.18-2.19,1.23v3l4.23,2.44V219.1l-2-3.58Z"/>
                      <polygon className="cls-10" points="172.5 215.49 176.57 213.25 178.65 216.81 174.5 219.02 172.5 215.49"/>
                      <path className="cls-11" d="M176.49,213.37l2,3.35-4,2.21-1.91-3.36,3.87-2.2m0-.18-4.1,2.33,2,3.58,4.22-2.33-2.16-3.58Z"/>
                      <polygon className="cls-10" points="196.87 218.17 200.17 216.38 200.09 218.66 196.87 220.49 196.87 218.17"/>
                      <path className="cls-11" d="M200,216.51v2.09l-3.12,1.8v-2.17l3.12-1.72m.1-.17-3.32,1.83v2.41l3.32-1.92v-2.32Z"/>
                      <polygon className="cls-9" points="193.52 218.61 193.54 216.32 195.24 215.4 196.77 218.2 196.77 220.49 193.52 218.61"/>
                      <path className="cls-11" d="M195.18,215.49l1.54,2.71v2.2l-3.13-1.8v-2.22l1.59-.89m0-.13-1.73,1v2.34l3.33,1.92v-2.41l-1.6-2.81Z"/>
                      <polygon className="cls-10" points="195.26 215.33 198.46 213.56 200.1 216.36 196.84 218.1 195.26 215.33"/>
                      <path className="cls-11" d="M198.4,213.66l1.6,2.64L196.86,218l-1.5-2.64,3-1.73m0-.14-3.22,1.84,1.6,2.81,3.32-1.83-1.7-2.82Z"/>
                      <polygon className="cls-9" points="182.01 226.35 182.06 221.52 186.44 224 186.4 228.83 182.01 226.35"/>
                      <path className="cls-11" d="M182.14,221.67l4.17,2.36v4.65l-4.17-2.36v-4.65m-.17-.3v5l4.51,2.56v-5L182,221.37Z"/>
                      <polygon className="cls-10" points="186.57 223.93 190.91 221.52 190.91 226.42 186.57 228.83 186.57 223.93"/>
                      <path className="cls-11" d="M190.83,221.67v4.65l-4.17,2.36V224l4.17-2.36m.17-.3-4.51,2.56v5l4.51-2.56v-5Z"/>
                      <polygon className="cls-10" points="182.15 221.38 186.52 218.89 190.82 221.37 186.49 223.83 182.15 221.38"/>
                      <path className="cls-11" d="M186.48,219l4.17,2.36-4.17,2.36-4.16-2.36,4.16-2.36m0-.19L182,221.37l4.51,2.56,4.52-2.56-4.52-2.55Z"/>
                      <polygon className="cls-10" points="165.23 213.83 169.06 211.75 169.06 214.4 165.23 216.58 165.23 213.83"/>
                      <path className="cls-11" d="M169,211.85v2.48l-3.71,2.14V213.9l3.71-2.05m.12-.2-4,2.18v2.85l4-2.28v-2.75Z"/>
                      <polygon className="cls-9" points="161.25 214.35 161.28 211.63 163.3 210.53 165.12 213.86 165.11 216.58 161.25 214.35"/>
                      <path className="cls-11" d="M163.22,210.65l1.83,3.21v2.61l-3.71-2.14V211.7l1.88-1m.05-.17-2.05,1.15v2.77l3.95,2.28v-2.85l-1.9-3.35Z"/>
                      <polygon className="cls-10" points="163.32 210.45 167.12 208.35 169.07 211.68 165.19 213.75 163.32 210.45"/>
                      <path className="cls-11" d="M167.05,208.46l1.9,3.14-3.73,2.06-1.79-3.13,3.62-2.07m0-.16-3.82,2.18,1.9,3.35,4-2.18-2-3.35Z"/>
                      <polygon className="cls-10" points="214.97 219.13 217.77 217.61 217.71 219.54 214.97 221.09 214.97 219.13"/>
                      <path className="cls-11" d="M217.66,217.72v1.77L215,221v-1.84l2.64-1.46m.09-.15-2.82,1.56v2l2.82-1.63v-2Z"/>
                      <polygon className="cls-9" points="212.13 219.5 212.15 217.55 213.59 216.77 214.89 219.15 214.89 221.09 212.13 219.5"/>
                      <path className="cls-11" d="M213.54,216.85l1.3,2.3V221l-2.65-1.53v-1.88l1.35-.76m0-.11-1.46.81v2l2.82,1.63v-2l-1.36-2.39Z"/>
                      <polygon className="cls-10" points="213.61 216.71 216.32 215.22 217.71 217.59 214.95 219.07 213.61 216.71"/>
                      <path className="cls-11" d="M216.27,215.3l1.36,2.24L215,219l-1.27-2.24,2.58-1.47m0-.12-2.73,1.56,1.36,2.39,2.82-1.56-1.45-2.39Z"/>
                    </g>
                    <g className="cls-23">
                      <polygon className="cls-10" points="183.41 207.4 185.75 206.13 185.7 207.74 183.41 209.04 183.41 207.4"/>
                      <path className="cls-11" d="M185.67,206.22v1.48L183.45,209v-1.54l2.22-1.22m.07-.13-2.36,1.31v1.7l2.36-1.36v-1.65Z"/>
                      <polygon className="cls-9" points="181.04 207.71 181.06 206.08 182.26 205.43 183.35 207.41 183.34 209.04 181.04 207.71"/>
                      <path className="cls-11" d="M182.21,205.5l1.1,1.92V209l-2.22-1.28v-1.57l1.12-.63m0-.1-1.22.68v1.66l2.36,1.36v-1.7l-1.14-2Z"/>
                      <polygon className="cls-10" points="182.27 205.38 184.54 204.13 185.71 206.11 183.39 207.35 182.27 205.38"/>
                      <path className="cls-11" d="M184.5,204.19l1.14,1.88-2.23,1.23-1.07-1.87,2.16-1.24m0-.09-2.29,1.3,1.14,2,2.36-1.31-1.21-2Z"/>
                      <polygon className="cls-10" points="211.87 209.28 214.21 208.01 214.15 209.63 211.87 210.93 211.87 209.28"/>
                      <path className="cls-11" d="M214.12,208.1v1.48l-2.22,1.28v-1.54l2.22-1.22m.07-.12-2.36,1.3V211l2.36-1.36V208Z"/>
                      <polygon className="cls-9" points="209.49 209.59 209.5 207.97 210.71 207.31 211.8 209.3 211.79 210.93 209.49 209.59"/>
                      <path className="cls-11" d="M210.66,207.38l1.1,1.92v1.56l-2.22-1.28V208l1.12-.63m0-.1-1.22.69v1.66l2.36,1.36v-1.71l-1.14-2Z"/>
                      <polygon className="cls-10" points="210.72 207.26 213 206.01 214.16 208 211.84 209.23 210.72 207.26"/>
                      <path className="cls-11" d="M213,206.08l1.14,1.87-2.23,1.23-1.07-1.87,2.16-1.23m0-.1-2.29,1.3,1.14,2,2.36-1.3L213,206Z"/>
                      <polygon className="cls-10" points="206.4 212.67 209.19 211.15 209.13 213.08 206.4 214.63 206.4 212.67"/>
                      <path className="cls-11" d="M209.09,211.26V213l-2.65,1.53v-1.84l2.65-1.46m.08-.15-2.82,1.56v2l2.82-1.63v-2Z"/>
                      <polygon className="cls-9" points="203.55 213.04 203.57 211.09 205.02 210.31 206.31 212.69 206.31 214.63 203.55 213.04"/>
                      <path className="cls-11" d="M205,210.39l1.31,2.3v1.87L203.62,213v-1.88l1.34-.76m0-.11-1.46.82v2l2.82,1.63v-2L205,210.28Z"/>
                      <polygon className="cls-10" points="205.03 210.25 207.75 208.76 209.14 211.13 206.37 212.61 205.03 210.25"/>
                      <path className="cls-11" d="M207.7,208.84l1.35,2.24-2.66,1.47-1.28-2.24,2.59-1.47m0-.12L205,210.28l1.36,2.39,2.82-1.56-1.44-2.39Z"/>
                      <polygon className="cls-10" points="185.19 214.93 188.11 213.34 188.05 215.36 185.19 216.98 185.19 214.93"/>
                      <path className="cls-11" d="M188,213.45v1.85l-2.77,1.6V215l2.77-1.53m.09-.15-3,1.63v2.13l3-1.7V213.3Z"/>
                      <polygon className="cls-9" points="182.22 215.32 182.24 213.28 183.74 212.47 185.1 214.95 185.1 216.98 182.22 215.32"/>
                      <path className="cls-11" d="M183.69,212.55l1.36,2.4v2l-2.77-1.6v-2l1.41-.79m0-.12-1.53.85v2.08l2.95,1.7v-2.13l-1.42-2.5Z"/>
                      <polygon className="cls-10" points="183.76 212.41 186.6 210.84 188.05 213.32 185.16 214.87 183.76 212.41"/>
                      <path className="cls-11" d="M186.55,210.92l1.42,2.34-2.79,1.54-1.33-2.34,2.7-1.54m0-.12-2.86,1.63,1.42,2.5,3-1.63-1.51-2.5Z"/>
                      <polygon className="cls-10" points="174.15 207.67 177.07 206.08 177 208.1 174.15 209.72 174.15 207.67"/>
                      <path className="cls-11" d="M177,206.19V208l-2.77,1.6v-1.92l2.77-1.53m.09-.15-3,1.63v2.13l3-1.7V206Z"/>
                      <polygon className="cls-9" points="171.17 208.06 171.2 206.02 172.7 205.21 174.06 207.69 174.06 209.72 171.17 208.06"/>
                      <path className="cls-11" d="M172.65,205.29l1.36,2.4v1.95l-2.77-1.6v-2l1.41-.79m0-.12-1.53.85v2.08l2.95,1.7v-2.13l-1.42-2.5Z"/>
                      <polygon className="cls-10" points="172.72 205.15 175.56 203.58 177.01 206.06 174.12 207.6 172.72 205.15"/>
                      <path className="cls-11" d="M175.51,203.66l1.41,2.34-2.78,1.54-1.34-2.34,2.71-1.54m0-.12-2.86,1.63,1.42,2.5,3-1.63-1.51-2.5Z"/>
                      <polygon className="cls-9" points="189.41 211.06 189.43 209.51 191.98 211.02 191.97 212.5 189.41 211.06"/>
                      <path className="cls-11" d="M189.46,209.64l2.47,1.39v1.41l-2.47-1.4v-1.4m-.07-.13v1.58l2.61,1.48V211l-2.61-1.48Z"/>
                      <polygon className="cls-10" points="192.04 210.99 194.63 209.54 194.57 211.09 192.04 212.5 192.04 210.99"/>
                      <path className="cls-11" d="M194.54,209.64V211l-2.46,1.4V211l2.46-1.39m.07-.13L192,211v1.58l2.61-1.48v-1.58Z"/>
                      <polygon className="cls-10" points="189.41 209.48 192.02 208.06 194.59 209.54 192 210.95 189.41 209.48"/>
                      <path className="cls-11" d="M192,208.11l2.46,1.4L192,210.9l-2.46-1.39,2.46-1.4m0-.08-2.61,1.48L192,211l2.61-1.48L192,208Z"/>
                      <polygon className="cls-10" points="197.59 207.4 199.94 206.13 199.88 207.74 197.59 209.04 197.59 207.4"/>
                      <path className="cls-11" d="M199.85,206.22v1.48L197.63,209v-1.54l2.22-1.22m.07-.13-2.36,1.31v1.7l2.36-1.36v-1.65Z"/>
                      <polygon className="cls-9" points="195.22 207.71 195.24 206.08 196.44 205.43 197.53 207.41 197.52 209.04 195.22 207.71"/>
                      <path className="cls-11" d="M196.39,205.5l1.1,1.92V209l-2.22-1.28v-1.57l1.12-.63m0-.1-1.22.68v1.66l2.36,1.36v-1.7l-1.14-2Z"/>
                      <polygon className="cls-10" points="196.45 205.38 198.72 204.13 199.89 206.11 197.57 207.35 196.45 205.38"/>
                      <path className="cls-11" d="M198.68,204.19l1.14,1.88-2.23,1.23-1.07-1.87,2.16-1.24m0-.09-2.29,1.3,1.14,2,2.36-1.31-1.21-2Z"/>
                    </g>
                    <g className="cls-24">
                      <polygon className="cls-10" points="180.69 202.37 182.55 201.36 182.51 202.64 180.69 203.67 180.69 202.37"/>
                      <path className="cls-11" d="M182.48,201.43v1.18l-1.76,1V202.4l1.76-1m.06-.1-1.88,1v1.35l1.88-1.08v-1.31Z"/>
                      <polygon className="cls-9" points="178.8 202.62 178.82 201.32 179.77 200.81 180.64 202.38 180.63 203.67 178.8 202.62"/>
                      <path className="cls-11" d="M179.74,200.86l.86,1.52v1.24l-1.75-1v-1.25l.89-.5m0-.08-1,.55v1.31l1.87,1.08v-1.35l-.9-1.59Z"/>
                      <polygon className="cls-10" points="179.78 200.77 181.59 199.77 182.51 201.35 180.67 202.33 179.78 200.77"/>
                      <path className="cls-11" d="M181.55,199.83l.91,1.48-1.78,1-.84-1.49,1.71-1m0-.08-1.81,1,.9,1.59,1.88-1-1-1.58Z"/>
                      <polygon className="cls-10" points="189.41 195.79 190.75 195.06 190.72 195.99 189.41 196.73 189.41 195.79"/>
                      <path className="cls-11" d="M190.7,195.11V196l-1.27.74v-.89l1.27-.7m0-.07-1.35.75v1l1.35-.78v-1Z"/>
                      <polygon className="cls-9" points="188.05 195.97 188.06 195.03 188.75 194.66 189.37 195.8 189.37 196.73 188.05 195.97"/>
                      <path className="cls-11" d="M188.72,194.7l.63,1.1v.9l-1.27-.74v-.9l.64-.36m0-.06-.7.39v1l1.35.78v-1l-.65-1.15Z"/>
                      <polygon className="cls-10" points="188.76 194.63 190.06 193.91 190.73 195.05 189.4 195.76 188.76 194.63"/>
                      <path className="cls-11" d="M190,194l.65,1.08-1.28.7-.62-1.07L190,194m0-.06-1.31.75.65,1.15,1.35-.75-.69-1.15Z"/>
                      <polygon className="cls-10" points="197.59 201.15 199.44 200.14 199.4 201.42 197.59 202.45 197.59 201.15"/>
                      <path className="cls-11" d="M199.37,200.21v1.18l-1.75,1v-1.22l1.75-1m.06-.1-1.87,1v1.35l1.87-1.08v-1.31Z"/>
                      <polygon className="cls-9" points="195.7 201.4 195.71 200.1 196.67 199.58 197.53 201.16 197.53 202.45 195.7 201.4"/>
                      <path className="cls-11" d="M196.63,199.64l.87,1.52v1.24l-1.76-1v-1.25l.89-.5m0-.08-1,.54v1.32l1.87,1.08v-1.35l-.9-1.59Z"/>
                      <polygon className="cls-10" points="196.68 199.54 198.49 198.55 199.41 200.13 197.57 201.11 196.68 199.54"/>
                      <path className="cls-11" d="M198.45,198.6l.9,1.49-1.77,1-.85-1.49,1.72-1m0-.07-1.81,1,.9,1.59,1.87-1-1-1.58Z"/>
                      <polygon className="cls-10" points="193.33 198 194.9 197.16 194.86 198.24 193.33 199.11 193.33 198"/>
                      <path className="cls-11" d="M194.84,197.22v1l-1.48.85v-1l1.48-.81m0-.09-1.58.88v1.14l1.58-.91v-1.11Z"/>
                      <polygon className="cls-9" points="191.74 198.22 191.75 197.13 192.56 196.69 193.29 198.02 193.28 199.11 191.74 198.22"/>
                      <path className="cls-11" d="M192.53,196.73l.73,1.29v1l-1.48-.85v-1.06l.75-.42m0-.06-.82.46v1.11l1.58.91V198l-.76-1.34Z"/>
                      <polygon className="cls-10" points="192.57 196.66 194.09 195.82 194.87 197.15 193.32 197.97 192.57 196.66"/>
                      <path className="cls-11" d="M194.06,195.86l.76,1.26-1.49.82-.72-1.25,1.45-.83m0-.06-1.53.87.76,1.34,1.58-.88-.81-1.33Z"/>
                      <polygon className="cls-9" points="189.05 203.92 189.07 201.76 190.98 202.9 190.96 205 189.05 203.92"/>
                      <path className="cls-11" d="M189.11,201.89l1.81,1v2l-1.81-1v-2m-.08-.13V204l2,1.11v-2.2l-2-1.11Z"/>
                      <polygon className="cls-10" points="191.04 202.87 192.98 201.79 192.93 203.96 191.04 205 191.04 202.87"/>
                      <path className="cls-11" d="M192.89,201.89v2l-1.82,1v-2l1.82-1m.07-.13-2,1.11v2.2l2-1.11v-2.2Z"/>
                      <polygon className="cls-10" points="189.05 201.72 191.01 200.68 192.95 201.79 191 202.83 189.05 201.72"/>
                      <path className="cls-11" d="M191,200.73l1.81,1-1.81,1-1.82-1,1.82-1m0-.09-2,1.12,2,1.11,2-1.11-2-1.12Z"/>
                      <polygon className="cls-9" points="183.96 199.06 183.97 197.9 185.89 199.04 185.88 200.15 183.96 199.06"/>
                      <path className="cls-11" d="M184,198l1.85,1.05v1.06L184,199.06V198m-.06-.1v1.19l2,1.11V199l-2-1.11Z"/>
                      <polygon className="cls-10" points="185.94 199.01 187.89 197.93 187.85 199.09 185.94 200.15 185.94 199.01"/>
                      <path className="cls-11" d="M187.82,198v1.06L186,200.11v-1.06l1.86-1.05m.05-.1-2,1.11v1.19l2-1.11V197.9Z"/>
                      <polygon className="cls-10" points="183.96 197.88 185.92 196.81 187.86 197.93 185.91 198.98 183.96 197.88"/>
                      <path className="cls-11" d="M185.9,196.85l1.86,1.05-1.85,1-1.86-1,1.85-1.05m0-.06-2,1.11,2,1.11,2-1.11-2-1.11Z"/>
                      <polygon className="cls-9" points="201.03 204.65 201.06 203.34 202.13 203.28 203.17 204.69 203.16 205.89 201.03 204.65"/>
                      <path className="cls-11" d="M202.08,203.33l1,1.36v1.12l-2-1.18v-1.2l1-.1m0-.1-1.11.11v1.35l2.2,1.29v-1.32l-1.09-1.43Z"/>
                      <polygon className="cls-10" points="202.13 203.24 201 203.34 201.67 203 201.64 203.12 202.11 203.1 202.53 203.64 203.06 203.35 203.08 202.09 202.54 202.36 202.13 201.82 201.67 201.84 201.62 201.68 202.11 201.43 202.61 201.39 203.15 202.1 203.4 201.99 204.32 201.46 205.7 203.27 203.23 204.67 202.13 203.24"/>
                      <path className="cls-11" d="M202.58,201.42l.49.63s0,0,0,0h0l-.47.27-.41-.52,0,0h0l-.41,0,0-.1.45-.26.47,0m1.72.09,1.34,1.73-2.4,1.4-1.1-1.42s0,0,0,0h0l-.88.09.39-.22v.05l0,0,0,0h0l.41,0,.43.52s0,0,0,0h0l.51-.3s0,0,0,0v-1.24h0l0,0,0,0h0l.21-.12.89-.5m-1.69-.15-.52,0-.49.28.07.18.44,0,.44.55.5-.29v1.24l-.5.29-.45-.55-.44.05V203l-.66.39h0l1.1-.11,1.12,1.44,2.5-1.44-1.41-1.82-.92.53-.22.12-.56-.73Z"/>
                      <polygon className="cls-10" points="203.24 204.69 205.61 203.34 205.57 204.59 203.24 205.93 203.24 204.69"/>
                      <path className="cls-11" d="M205.54,203.4v1.16l-2.28,1.33v-1.17l2.28-1.32m.05-.08-2.38,1.37V206l2.38-1.39v-1.27Z"/>
                      <line className="cls-26" x1="202.55" y1="202.26" x2="202.55" y2="203.69"/>
                      <polyline className="cls-9" points="202.11 201.83 202.55 202.38 203.05 202.09 203.05 203.33 202.55 203.62 202.1 203.07"/>
                      <polygon className="cls-10" points="202.58 202.37 203.08 202.1 203.04 203.34 202.57 203.59 202.58 202.37"/>
                      <path className="cls-11" d="M203,202.16v1.16l-.42.23V202.4l.42-.24m0-.08-.52.29v1.27l.52-.29v-1.27Z"/>
                      <polyline className="cls-9" points="202.11 203.07 201.67 203.12 201.67 201.87 202.11 201.84"/>
                      <line className="cls-26" x1="201.67" y1="201.72" x2="201.67" y2="203.14"/>
                    </g>
                    <polygon className="cls-10" points="213.94 277.96 221.16 273.97 221.16 278.91 213.94 283.08 213.94 277.96"/>
                    <path className="cls-11" d="M221.05,274.17v4.68l-7,4V278l7-3.85m.23-.39-7.45,4.11v5.39l7.45-4.3v-5.2Z"/>
                    <polygon className="cls-9" points="206.56 278.88 206.56 273.84 210.18 271.82 213.68 277.98 213.65 282.98 206.56 278.88"/>
                    <path className="cls-11" d="M210.11,272.06l3.37,5.92v4.69l-6.75-3.89V274l3.38-1.89m.13-.48-3.86,2.16V279l7.45,4.3v-5.39l-3.59-6.31Z"/>
                    <polygon className="cls-10" points="210.4 271.62 217.41 267.63 221.12 273.74 213.87 277.74 210.4 271.62"/>
                    <path className="cls-11" d="M217.38,267.78l3.58,5.91-7,3.89-3.37-5.91,6.83-3.89m.08-.31-7.22,4.11,3.59,6.31,7.45-4.11-3.82-6.31Z"/>
                    <polygon className="cls-9" points="211.19 279.88 211.19 278.63 212.3 279.26 212.3 280.51 211.19 279.88"/>
                    <path className="cls-11" d="M211.36,278.93l.76.43v.85l-.76-.43v-.85m-.35-.6V280l1.46.83v-1.65l-1.46-.83Z"/>
                    <polygon className="cls-9" points="208.04 279.74 208.04 277.15 209.36 277.9 209.36 280.48 208.04 279.74"/>
                    <path className="cls-11" d="M208.22,277.45l1,.55v2.18l-1-.54v-2.19m-.35-.6v3l1.67.94v-3l-1.67-.95Z"/>
                    <polygon className="cls-10" points="196.43 246.62 202.13 243.52 202.13 247.48 196.43 250.72 196.43 246.62"/>
                    <path className="cls-11" d="M202,243.67v3.7l-5.52,3.19v-3.84l5.52-3.05m.18-.3-5.88,3.25v4.25l5.88-3.39v-4.11Z"/>
                    <polygon className="cls-9" points="190.5 247.4 190.54 243.34 193.47 241.75 196.26 246.66 196.25 250.72 190.5 247.4"/>
                    <path className="cls-11" d="M193.44,241.88l2.72,4.78v3.9l-5.52-3.19v-3.93l2.8-1.56m.07-.25-3.06,1.71v4.14l5.89,3.39v-4.25l-2.83-5Z"/>
                    <polygon className="cls-10" points="193.63 241.66 199.18 238.5 202.1 243.33 196.37 246.49 193.63 241.66"/>
                    <path className="cls-11" d="M199.14,238.63,202,243.3l-5.56,3.07-2.66-4.67,5.39-3.07m.07-.25-5.7,3.25,2.83,5,5.88-3.25-3-5Z"/>
                    <polygon className="cls-9" points="194.16 248.19 194.2 247.12 195.22 247.7 195.18 248.77 194.16 248.19"/>
                    <path className="cls-11" d="M194.3,247.28l.79.44v.89l-.79-.44v-.89m-.18-.31v1.3l1.15.65v-1.3l-1.15-.65Z"/>
                    <polygon className="cls-9" points="191.68 248.08 191.72 245.95 192.9 246.62 192.86 248.75 191.68 248.08"/>
                    <path className="cls-11" d="M191.81,246.11l1,.54v1.94l-1-.54v-1.94m-.18-.31v2.36l1.32.74v-2.36l-1.32-.74Z"/>
                    <polygon className="cls-10" points="184.88 239.22 189.8 236.54 189.8 239.96 184.88 242.76 184.88 239.22"/>
                    <path className="cls-11" d="M189.72,236.68v3.19L185,242.62v-3.31l4.76-2.63m.16-.27-5.08,2.81v3.67l5.08-2.93v-3.55Z"/>
                    <polygon className="cls-9" points="179.76 239.89 179.8 236.39 182.32 235.02 184.73 239.25 184.72 242.75 179.76 239.89"/>
                    <path className="cls-11" d="M182.3,235.13l2.34,4.13v3.36l-4.76-2.75v-3.39l2.42-1.35m0-.21-2.63,1.47V240l5.08,2.93v-3.67l-2.45-4.3Z"/>
                    <polygon className="cls-10" points="182.46 234.94 187.25 232.22 189.77 236.38 184.83 239.11 182.46 234.94"/>
                    <path className="cls-11" d="M187.22,232.32l2.44,4-4.8,2.65-2.29-4,4.65-2.65m.05-.21-4.92,2.81,2.45,4.3,5.08-2.81-2.61-4.3Z"/>
                    <polygon className="cls-9" points="182.92 240.58 182.95 239.65 183.84 240.15 183.8 241.08 182.92 240.58"/>
                    <path className="cls-11" d="M183,239.79l.68.38v.77l-.68-.39v-.76m-.16-.27v1.13l1,.56v-1.13l-1-.56Z"/>
                    <polygon className="cls-9" points="180.78 240.48 180.81 238.64 181.84 239.22 181.8 241.06 180.78 240.48"/>
                    <path className="cls-11" d="M180.89,238.78l.83.46v1.68l-.83-.46v-1.68m-.15-.27v2l1.13.64v-2l-1.13-.64Z"/>
                    <polygon className="cls-10" points="230.42 267.15 236.13 264.06 236.13 268.01 230.42 271.25 230.42 267.15"/>
                    <path className="cls-11" d="M236,264.21v3.7l-5.53,3.19v-3.84l5.53-3.05m.18-.3-5.89,3.24v4.26l5.89-3.4v-4.1Z"/>
                    <polygon className="cls-9" points="224.49 267.94 224.54 263.88 227.46 262.29 230.25 267.2 230.24 271.25 224.49 267.94"/>
                    <path className="cls-11" d="M227.43,262.41l2.72,4.79v3.9l-5.52-3.19V264l2.8-1.57m.07-.24-3.05,1.71V268l5.88,3.4v-4.26l-2.83-5Z"/>
                    <polygon className="cls-10" points="227.62 262.2 233.17 259.04 236.09 263.87 230.37 267.03 227.62 262.2"/>
                    <path className="cls-11" d="M233.14,259.16l2.83,4.68-5.57,3.07-2.66-4.67,5.4-3.08m.06-.24-5.7,3.25,2.83,5,5.89-3.24-3-5Z"/>
                    <polygon className="cls-9" points="228.15 268.73 228.19 267.65 229.22 268.24 229.18 269.31 228.15 268.73"/>
                    <path className="cls-11" d="M228.29,267.81l.79.45v.89l-.79-.45v-.89m-.18-.31v1.31l1.15.65v-1.3l-1.15-.66Z"/>
                    <polygon className="cls-9" points="225.67 268.62 225.71 266.49 226.9 267.16 226.85 269.29 225.67 268.62"/>
                    <path className="cls-11" d="M225.81,266.64l.95.54v1.95l-.95-.54v-1.95m-.18-.31v2.36l1.31.75v-2.36l-1.31-.75Z"/>
                    <polygon className="cls-10" points="235.78 253.37 239.88 251.15 239.88 253.99 235.78 256.32 235.78 253.37"/>
                    <path className="cls-11" d="M239.81,251.26v2.66l-4,2.29v-2.76l4-2.19m.13-.22-4.22,2.33v3.06l4.22-2.44v-3Z"/>
                    <polygon className="cls-9" points="231.52 253.93 231.56 251.02 233.71 249.85 235.66 253.41 235.65 256.32 231.52 253.93"/>
                    <path className="cls-11" d="M233.63,250l2,3.44v2.8l-4-2.29v-2.83l2-1.12m.05-.18L231.49,251v3l4.23,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-10" points="233.74 249.76 237.81 247.52 239.89 251.07 235.74 253.29 233.74 249.76"/>
                    <path className="cls-11" d="M237.73,247.64l2,3.35-4,2.21-1.91-3.36,3.87-2.2m.05-.18-4.1,2.33,2,3.58,4.22-2.33-2.16-3.58Z"/>
                    <polygon className="cls-9" points="234.15 254.5 234.18 253.73 234.91 254.15 234.89 254.92 234.15 254.5"/>
                    <path className="cls-11" d="M234.25,253.85l.57.32v.64l-.57-.32v-.64m-.13-.23v.94l.83.47v-.94l-.83-.47Z"/>
                    <polygon className="cls-9" points="232.37 254.42 232.4 252.89 233.25 253.38 233.22 254.91 232.37 254.42"/>
                    <path className="cls-11" d="M232.47,253l.68.39v1.39l-.68-.39V253m-.13-.23v1.7l.94.54v-1.7l-.94-.54Z"/>
                    <polygon className="cls-10" points="225.79 248.51 229.89 246.29 229.89 249.13 225.79 251.46 225.79 248.51"/>
                    <path className="cls-11" d="M229.83,246.4v2.65l-4,2.29v-2.75l4-2.19m.13-.22-4.23,2.33v3.06l4.23-2.44v-2.95Z"/>
                    <polygon className="cls-9" points="221.53 249.07 221.57 246.16 223.73 244.99 225.67 248.54 225.66 251.46 221.53 249.07"/>
                    <path className="cls-11" d="M223.64,245.11l2,3.43v2.8l-4-2.29v-2.82l2-1.12m.05-.18-2.19,1.23v3l4.23,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-10" points="223.75 244.9 227.82 242.65 229.9 246.21 225.75 248.42 223.75 244.9"/>
                    <path className="cls-11" d="M227.74,242.77l2,3.36-4,2.2L223.87,245l3.87-2.21m0-.17-4.1,2.33,2,3.58,4.23-2.33-2.17-3.58Z"/>
                    <polygon className="cls-9" points="224.16 249.64 224.19 248.87 224.93 249.29 224.9 250.06 224.16 249.64"/>
                    <path className="cls-11" d="M224.26,249l.57.33V250l-.57-.33V249m-.13-.22v.94l.83.47v-.94l-.83-.47Z"/>
                    <polygon className="cls-9" points="222.38 249.56 222.41 248.03 223.26 248.51 223.23 250.04 222.38 249.56"/>
                    <path className="cls-11" d="M222.48,248.14l.68.39v1.4l-.68-.39v-1.4m-.13-.22v1.7l.94.53v-1.69l-.94-.54Z"/>
                    <polygon className="cls-10" points="208.37 252.59 212.75 250.22 212.75 253.25 208.37 255.74 208.37 252.59"/>
                    <path className="cls-11" d="M212.68,250.33v2.84l-4.24,2.45v-3l4.24-2.34m.13-.23-4.51,2.49v3.27l4.51-2.61V250.1Z"/>
                    <polygon className="cls-9" points="203.82 253.19 203.85 250.08 206.16 248.83 208.24 252.62 208.23 255.74 203.82 253.19"/>
                    <path className="cls-11" d="M206.07,249l2.09,3.68v3l-4.23-2.45v-3l2.14-1.21m.06-.18-2.34,1.31v3.17l4.51,2.61v-3.27l-2.17-3.82Z"/>
                    <polygon className="cls-10" points="206.19 248.73 210.53 246.34 212.75 250.13 208.33 252.5 206.19 248.73"/>
                    <path className="cls-11" d="M210.45,246.46l2.17,3.59-4.27,2.35-2-3.58,4.13-2.36m.05-.18-4.37,2.49,2.17,3.82,4.51-2.49-2.31-3.82Z"/>
                    <polygon className="cls-9" points="206.63 253.8 206.66 252.98 207.44 253.42 207.41 254.24 206.63 253.8"/>
                    <path className="cls-11" d="M206.73,253.1l.61.34v.68l-.61-.34v-.68m-.14-.24v1l.89.5v-1l-.89-.5Z"/>
                    <polygon className="cls-9" points="204.72 253.71 204.76 252.08 205.67 252.59 205.63 254.23 204.72 253.71"/>
                    <path className="cls-11" d="M204.83,252.2l.73.41v1.5l-.73-.42V252.2m-.14-.24v1.81l1,.57v-1.81l-1-.57Z"/>
                    <polygon className="cls-10" points="260.81 237.43 264.4 235.48 264.32 237.96 260.81 239.96 260.81 237.43"/>
                    <path className="cls-11" d="M264.26,235.62v2.28l-3.4,2V237.5l3.4-1.88m.11-.19-3.62,2v2.62l3.62-2.09v-2.53Z"/>
                    <polygon className="cls-9" points="257.16 237.91 257.19 235.41 259.03 234.41 260.7 237.46 260.7 239.96 257.16 237.91"/>
                    <path className="cls-11" d="M259,234.51l1.67,3v2.4l-3.4-2v-2.42l1.73-1m0-.15-1.88,1V238l3.62,2.09v-2.62L259,234.36Z"/>
                    <polygon className="cls-10" points="259.06 234.34 262.54 232.41 264.33 235.46 260.77 237.36 259.06 234.34"/>
                    <path className="cls-11" d="M262.48,232.51l1.74,2.88-3.43,1.89-1.63-2.88,3.32-1.89m0-.15-3.51,2,1.74,3.07,3.62-2-1.85-3.07Z"/>
                    <polygon className="cls-9" points="259.41 238.4 259.44 237.65 260.06 238.1 260.04 238.76 259.41 238.4"/>
                    <path className="cls-11" d="M259.49,237.84l.49.27v.55l-.49-.27v-.55m-.11-.19v.8l.71.4v-.8l-.71-.4Z"/>
                    <polygon className="cls-9" points="257.88 238.33 257.91 236.93 258.64 237.43 258.61 238.75 257.88 238.33"/>
                    <path className="cls-11" d="M258,237.12l.58.33v1.2l-.58-.34v-1.19m-.12-.19v1.45l.81.46v-1.45l-.81-.46Z"/>
                    <polygon className="cls-10" points="216.36 242.75 220.46 240.53 220.46 243.37 216.36 245.7 216.36 242.75"/>
                    <path className="cls-11" d="M220.4,240.64v2.66l-4,2.29v-2.76l4-2.19m.13-.22-4.23,2.34v3.05l4.23-2.44v-3Z"/>
                    <polygon className="cls-9" points="212.1 243.32 212.14 240.4 214.29 239.23 216.24 242.79 216.23 245.7 212.1 243.32"/>
                    <path className="cls-11" d="M214.21,239.35l2,3.44v2.8l-4-2.29v-2.82l2-1.13m0-.18-2.19,1.23v3l4.23,2.44v-3.05l-2-3.59Z"/>
                    <polygon className="cls-10" points="214.32 239.14 218.39 236.9 220.47 240.46 216.32 242.67 214.32 239.14"/>
                    <path className="cls-11" d="M218.31,237l2,3.35-4,2.21-1.91-3.36,3.87-2.2m.05-.18-4.1,2.33,2,3.59,4.23-2.34-2.17-3.58Z"/>
                    <polygon className="cls-9" points="214.73 243.89 214.76 243.12 215.5 243.53 215.47 244.3 214.73 243.89"/>
                    <path className="cls-11" d="M214.83,243.23l.57.32v.64l-.57-.32v-.64m-.13-.22v.93l.83.47v-.93l-.83-.47Z"/>
                    <polygon className="cls-9" points="212.95 243.81 212.98 242.28 213.83 242.76 213.8 244.29 212.95 243.81"/>
                    <path className="cls-11" d="M213.05,242.39l.68.39v1.4l-.68-.39v-1.4m-.13-.22v1.69l.94.54v-1.7l-.94-.53Z"/>
                    <polygon className="cls-10" points="207.6 237.76 211.69 235.54 211.69 238.38 207.6 240.71 207.6 237.76"/>
                    <path className="cls-11" d="M211.63,235.65v2.65l-4,2.29v-2.75l4-2.19m.13-.22-4.23,2.33v3.06l4.23-2.44v-2.95Z"/>
                    <polygon className="cls-9" points="203.34 238.32 203.37 235.41 205.53 234.24 207.48 237.79 207.47 240.71 203.34 238.32"/>
                    <path className="cls-11" d="M205.45,234.36l2,3.44v2.79l-4-2.29v-2.81l2-1.13m.05-.18-2.19,1.23v3l4.22,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-10" points="205.56 234.15 209.62 231.91 211.7 235.47 207.56 237.68 205.56 234.15"/>
                    <path className="cls-11" d="M209.55,232l2,3.35-4,2.21-1.91-3.36,3.88-2.2m0-.18-4.09,2.33,2,3.58,4.23-2.33-2.17-3.58Z"/>
                    <polygon className="cls-9" points="166.96 232.93 166.96 229.75 169.57 229.49 172.24 232.99 172.22 236 166.96 232.93"/>
                    <path className="cls-11" d="M169.51,229.61,172.1,233v2.79l-5-2.93v-3l2.44-.24m.11-.25-2.78.28V233l5.49,3.21v-3.29l-2.71-3.56Z"/>
                    <path className="cls-9" d="M167.56,232.2l0-1.52a.2.2,0,0,1,0-.14l.13,0,.08,0a.72.72,0,0,1,.32.54v1.46Z"/>
                    <path className="cls-11" d="M167.71,230.57l.09,0a.64.64,0,0,1,.28.48v1.35l-.43-.25v-1.51a.13.13,0,0,1,0-.08s0,0,0,0m0-.13c-.11,0-.19.09-.18.24v1.57l.68.4v-1.57a.77.77,0,0,0-.35-.59.3.3,0,0,0-.15-.05Z"/>
                    <polygon className="cls-9" points="168.93 234.1 168.97 232.63 170.22 233.34 170.16 234.82 168.93 234.1"/>
                    <path className="cls-11" d="M169.07,232.79l1,.58v1.28l-1-.58v-1.28m-.19-.32v1.71l1.37.81,0-1.73-1.39-.79Z"/>
                    <path className="cls-9" d="M171,234.18l0-1.51c0-.07,0-.18.12-.18a.38.38,0,0,1,.13,0,.68.68,0,0,1,.3.54v1.46Z"/>
                    <path className="cls-11" d="M171.14,232.56l.1,0a.64.64,0,0,1,.27.48v1.35l-.43-.25v-1.5c0-.11,0-.11.06-.11m0-.13c-.11,0-.19.09-.19.24v1.57l.69.4v-1.57a.76.76,0,0,0-.34-.59.37.37,0,0,0-.16,0Z"/>
                    <path className="cls-10" d="M169.62,229.38l-2.56.24,1.34-.77v.23l1.17-.05,1,1.35,1.34-.71,0-3.15a.05.05,0,0,0,0-.05h-.06l-1.25.72-1-1.35-1.16.07-.11-.41,1.21-.63,1.25-.1,1.36,1.79.62-.3L175.1,225l3.43,4.51L172.39,233Z"/>
                    <path className="cls-11" d="M170.77,224.84l1.22,1.57-.05,0-.06,0-1.16.67-1-1.28a.14.14,0,0,0-.1-.05h0l-1,.08-.1-.25,1.12-.64,1.18-.1m4.27.22,3.35,4.33-6,3.48-2.73-3.52a.1.1,0,0,0-.1,0h0l-2.18.22,1-.56v.12a.11.11,0,0,0,0,.09.11.11,0,0,0,.08,0h0l1-.1,1.06,1.32a.12.12,0,0,0,.1,0h.06l1.26-.73a.12.12,0,0,0,.07-.11v-3.09a0,0,0,0,0,0,0l.08.1a.11.11,0,0,0,.1,0h.07l.53-.31,2.2-1.26m-4.22-.35-1.27.1-1.24.72.18.44,1.1-.09,1.1,1.37,1.25-.73v3.09l-1.26.73L169.57,229l-1.1.1v-.33l-1.65,1h0l2.76-.28,2.8,3.62,6.21-3.61-3.51-4.53-2.29,1.31-.54.31-1.42-1.81Z"/>
                    <polygon className="cls-10" points="172.4 233 178.22 229.69 178.22 232.75 172.4 236.1 172.4 233"/>
                    <path className="cls-11" d="M178.15,229.8v2.88L172.46,236v-2.92l5.69-3.27m.13-.22L172.33,233v3.21l5.95-3.45v-3.18Z"/>
                    <line className="cls-27" x1="170.69" y1="226.95" x2="170.69" y2="230.5"/>
                    <polyline className="cls-9" points="169.59 225.88 170.69 227.25 171.94 226.52 171.94 229.61 170.68 230.34 169.57 228.97"/>
                    <polygon className="cls-10" points="170.75 227.21 171.92 226.59 171.92 229.65 170.74 230.27 170.75 227.21"/>
                    <path className="cls-11" d="M171.85,226.7v2.87l-1,.59,0-2.88,1-.58m.13-.22-1.29.73v3.16l1.3-.72v-3.17Z"/>
                    <polyline className="cls-9" points="169.58 228.95 168.49 229.08 168.49 225.97 169.58 225.88"/>
                    <line className="cls-27" x1="168.49" y1="225.6" x2="168.49" y2="229.15"/>
                    <polygon className="cls-10" points="262.69 249.76 266.79 247.53 266.79 250.37 262.69 252.7 262.69 249.76"/>
                    <path className="cls-11" d="M266.73,247.64v2.66l-4,2.29v-2.76l4-2.19m.13-.22-4.23,2.34v3.05l4.23-2.44v-3Z"/>
                    <polygon className="cls-9" points="258.44 250.32 258.47 247.4 260.62 246.23 262.57 249.79 262.56 252.7 258.44 250.32"/>
                    <path className="cls-11" d="M260.54,246.35l2,3.44v2.8l-4-2.29v-2.82l2-1.13m0-.17-2.19,1.22v3l4.23,2.44v-3.05l-2-3.58Z"/>
                    <polygon className="cls-10" points="260.65 246.14 264.72 243.9 266.8 247.46 262.65 249.67 260.65 246.14"/>
                    <path className="cls-11" d="M264.64,244l2,3.36-4,2.2-1.91-3.36,3.87-2.2m.05-.18-4.1,2.34,2,3.58,4.23-2.34-2.17-3.58Z"/>
                    <polygon className="cls-9" points="261.06 250.89 261.09 250.12 261.83 250.53 261.8 251.31 261.06 250.89"/>
                    <path className="cls-11" d="M261.16,250.23l.57.32v.64l-.57-.32v-.64M261,250v.93l.83.47v-.93L261,250Z"/>
                    <polygon className="cls-9" points="259.28 250.81 259.31 249.28 260.16 249.76 260.13 251.29 259.28 250.81"/>
                    <path className="cls-11" d="M259.38,249.39l.68.39v1.4l-.68-.39v-1.4m-.13-.22v1.69l.94.54v-1.7l-.94-.53Z"/>
                    <polygon className="cls-10" points="272.86 244.31 276.45 242.36 276.37 244.84 272.86 246.83 272.86 244.31"/>
                    <path className="cls-11" d="M276.32,242.5v2.27l-3.4,2v-2.37l3.4-1.87m.11-.19-3.62,2v2.62l3.62-2.09v-2.53Z"/>
                    <polygon className="cls-9" points="269.21 244.79 269.24 242.29 271.09 241.29 272.76 244.34 272.75 246.83 269.21 244.79"/>
                    <path className="cls-11" d="M271,241.39l1.67,3v2.4l-3.4-2v-2.41l1.73-1m0-.15-1.88,1v2.55l3.63,2.09v-2.62l-1.75-3.07Z"/>
                    <polygon className="cls-10" points="271.11 241.21 274.6 239.29 276.38 242.34 272.83 244.23 271.11 241.21"/>
                    <path className="cls-11" d="M274.53,239.39l1.74,2.88-3.42,1.89-1.64-2.88,3.32-1.89m0-.15-3.51,2,1.75,3.07,3.62-2-1.86-3.07Z"/>
                    <polygon className="cls-9" points="271.46 245.28 271.49 244.52 272.12 244.97 272.09 245.63 271.46 245.28"/>
                    <path className="cls-11" d="M271.55,244.71l.48.28v.55l-.48-.28v-.55m-.11-.19v.81l.71.4v-.8l-.71-.41Z"/>
                    <polygon className="cls-9" points="269.94 245.21 269.96 243.8 270.69 244.31 270.66 245.62 269.94 245.21"/>
                    <path className="cls-11" d="M270,244l.59.34v1.19l-.59-.33V244m-.11-.19v1.46l.81.46v-1.46l-.81-.46Z"/>
                    <polygon className="cls-10" points="244.22 293.63 248.32 291.41 248.32 294.25 244.22 296.58 244.22 293.63"/>
                    <path className="cls-11" d="M248.26,291.52v2.65l-4,2.29v-2.75l4-2.19m.13-.22-4.23,2.33v3.06l4.23-2.44V291.3Z"/>
                    <polygon className="cls-9" points="239.97 294.19 240 291.28 242.16 290.11 244.1 293.66 244.1 296.57 239.97 294.19"/>
                    <path className="cls-11" d="M242.08,290.23l1.95,3.44v2.79l-4-2.29v-2.82l2-1.12m0-.18-2.19,1.23v3l4.22,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-10" points="242.18 290.02 246.25 287.78 248.33 291.33 244.19 293.54 242.18 290.02"/>
                    <path className="cls-11" d="M246.17,287.9l2,3.35-4,2.21-1.91-3.36,3.87-2.2m.05-.18-4.09,2.33,2,3.58,4.23-2.33-2.17-3.58Z"/>
                    <polygon className="cls-9" points="242.6 294.76 242.62 293.99 243.36 294.41 243.33 295.18 242.6 294.76"/>
                    <path className="cls-11" d="M242.69,294.11l.57.32v.64l-.57-.33v-.63m-.13-.23v.94l.83.47v-.94l-.83-.47Z"/>
                    <polygon className="cls-9" points="240.81 294.68 240.84 293.15 241.69 293.63 241.66 295.16 240.81 294.68"/>
                    <path className="cls-11" d="M240.91,293.27l.69.38v1.4l-.69-.39v-1.39m-.13-.23v1.7l.95.53v-1.69l-.95-.54Z"/>
                    <polygon className="cls-10" points="248.45 296.04 252.55 293.82 252.55 296.66 248.45 298.99 248.45 296.04"/>
                    <path className="cls-11" d="M252.48,293.93v2.66l-4,2.29v-2.76l4-2.19m.13-.22L248.39,296v3.06l4.22-2.44v-3Z"/>
                    <polygon className="cls-9" points="244.19 296.61 244.22 293.69 246.38 292.52 248.33 296.08 248.32 298.99 244.19 296.61"/>
                    <path className="cls-11" d="M246.3,292.64l2,3.44v2.8l-4-2.29v-2.82l2-1.13m0-.18-2.19,1.23v3l4.23,2.44V296l-2-3.58Z"/>
                    <polygon className="cls-10" points="246.41 292.43 250.48 290.19 252.56 293.75 248.41 295.96 246.41 292.43"/>
                    <path className="cls-11" d="M250.4,290.31l2,3.35-4,2.21-1.91-3.36,3.87-2.2m0-.18-4.09,2.33,2,3.58,4.22-2.33-2.17-3.58Z"/>
                    <polygon className="cls-9" points="246.82 297.18 246.85 296.4 247.58 296.82 247.56 297.59 246.82 297.18"/>
                    <path className="cls-11" d="M246.92,296.52l.57.32v.64l-.57-.32v-.64m-.13-.22v.93l.83.47v-.94l-.83-.46Z"/>
                    <polygon className="cls-9" points="245.04 297.09 245.07 295.57 245.92 296.05 245.89 297.58 245.04 297.09"/>
                    <path className="cls-11" d="M245.14,295.68l.68.39v1.39l-.68-.39v-1.39m-.13-.22v1.69l.94.54V296l-.94-.53Z"/>
                    <polygon className="cls-10" points="272.86 253.73 276.45 251.78 276.37 254.26 272.86 256.25 272.86 253.73"/>
                    <path className="cls-11" d="M276.32,251.92v2.27l-3.4,2v-2.37l3.4-1.87m.11-.19-3.62,2v2.62l3.62-2.09v-2.53Z"/>
                    <polygon className="cls-9" points="269.21 254.21 269.24 251.71 271.09 250.71 272.76 253.76 272.75 256.25 269.21 254.21"/>
                    <path className="cls-11" d="M271,250.81l1.67,2.95v2.4l-3.4-2v-2.41l1.73-1m0-.15-1.88,1.05v2.55l3.63,2.09v-2.62l-1.75-3.07Z"/>
                    <polygon className="cls-10" points="271.11 250.63 274.6 248.71 276.38 251.76 272.83 253.65 271.11 250.63"/>
                    <path className="cls-11" d="M274.53,248.81l1.74,2.88-3.42,1.89-1.64-2.88,3.32-1.89m0-.15-3.51,2,1.75,3.07,3.62-2-1.86-3.07Z"/>
                    <polygon className="cls-9" points="271.46 254.7 271.49 253.94 272.12 254.4 272.09 255.06 271.46 254.7"/>
                    <path className="cls-11" d="M271.55,254.14l.48.27V255l-.48-.28v-.54m-.11-.2v.81l.71.4v-.8l-.71-.41Z"/>
                    <polygon className="cls-9" points="269.94 254.63 269.96 253.22 270.69 253.73 270.66 255.04 269.94 254.63"/>
                    <path className="cls-11" d="M270,253.42l.59.33V255l-.59-.34v-1.19m-.11-.2v1.46l.81.46v-1.46l-.81-.46Z"/>
                    <polygon className="cls-10" points="193.12 290.39 203.99 284.39 203.99 291.82 193.12 298.1 193.12 290.39"/>
                    <path className="cls-11" d="M203.82,284.68v7l-10.53,6.08v-7.31l10.53-5.81m.34-.58L193,290.29v8.11l11.21-6.48V284.1Z"/>
                    <polygon className="cls-9" points="181.99 291.78 181.99 284.19 187.45 281.13 192.7 290.35 192.7 297.96 181.99 291.78"/>
                    <path className="cls-11" d="M187.36,281.46l5.09,9v7.11l-10.21-5.9v-7.3l5.12-2.87m.19-.67L181.74,284v7.88L193,298.4v-8.12l-5.4-9.49Z"/>
                    <polygon className="cls-10" points="187.78 280.85 198.35 274.83 203.92 284.03 193.01 290.05 187.78 280.85"/>
                    <path className="cls-11" d="M198.29,275.06l5.39,8.91-10.6,5.85L188,280.91l10.27-5.85m.12-.46-10.86,6.19,5.4,9.5,11.21-6.19-5.75-9.5Z"/>
                    <polygon className="cls-9" points="188.88 293.34 188.88 291.25 190.73 292.3 190.73 294.38 188.88 293.34"/>
                    <path className="cls-11" d="M189.06,291.55l1.5.85v1.68l-1.5-.84v-1.69m-.35-.6v2.49l2.2,1.24V292.2l-2.2-1.25Z"/>
                    <polygon className="cls-9" points="184.15 293.12 184.15 289.02 186.31 290.25 186.31 294.34 184.15 293.12"/>
                    <path className="cls-11" d="M184.33,289.32l1.81,1V294l-1.81-1v-3.7m-.35-.6v4.5l2.51,1.42v-4.5L184,288.72Z"/>
                    <polygon className="cls-10" points="145.1 259.96 155.97 253.97 155.97 261.4 145.1 267.68 145.1 259.96"/>
                    <path className="cls-11" d="M155.8,254.26v7l-10.53,6.08v-7.31l10.53-5.81m.34-.59-11.21,6.19V268l11.21-6.48v-7.83Z"/>
                    <polygon className="cls-9" points="133.96 261.36 133.96 253.76 139.43 250.7 144.68 259.93 144.68 267.54 133.96 261.36"/>
                    <path className="cls-11" d="M139.34,251l5.09,9v7.11l-10.22-5.9v-7.3l5.13-2.87m.19-.68-5.82,3.26v7.88L144.93,268v-8.12l-5.4-9.5Z"/>
                    <polygon className="cls-10" points="139.76 250.43 150.33 244.41 155.9 253.61 144.99 259.63 139.76 250.43"/>
                    <path className="cls-11" d="M150.27,244.64l5.39,8.91-10.6,5.85L140,250.49l10.28-5.85m.12-.46-10.86,6.18,5.4,9.5,11.21-6.19-5.75-9.49Z"/>
                    <polygon className="cls-9" points="140.86 262.92 140.86 260.83 142.71 261.88 142.71 263.96 140.86 262.92"/>
                    <path className="cls-11" d="M141,261.13l1.49.85v1.68l-1.49-.85v-1.68m-.35-.6V263l2.19,1.24v-2.49l-2.19-1.24Z"/>
                    <polygon className="cls-9" points="136.13 262.7 136.13 258.6 138.29 259.82 138.29 263.92 136.13 262.7"/>
                    <path className="cls-11" d="M136.31,258.9l1.81,1v3.69l-1.81-1v-3.7m-.35-.6v4.5l2.51,1.42v-4.5L136,258.3Z"/>
                    <polygon className="cls-10" points="128.47 240.95 138.1 235.63 138.1 242.22 128.47 247.78 128.47 240.95"/>
                    <path className="cls-11" d="M138,235.89v6.24l-9.32,5.38V241l9.32-5.15m.3-.51-9.93,5.48V248l9.93-5.73v-6.93Z"/>
                    <polygon className="cls-9" points="118.64 242.16 118.64 235.47 123.44 232.78 128.07 240.92 128.07 247.61 118.64 242.16"/>
                    <path className="cls-11" d="M123.35,233.12l4.47,7.87v6.18L118.89,242v-6.4l4.46-2.5m.19-.68-5.15,2.88v7l9.93,5.73v-7.18l-4.78-8.42Z"/>
                    <polygon className="cls-10" points="123.75 232.5 133.1 227.17 138.04 235.32 128.38 240.65 123.75 232.5"/>
                    <path className="cls-11" d="M133.05,227.38l4.78,7.88-9.39,5.18L124,232.56l9.09-5.18m.11-.42-9.62,5.48,4.78,8.42,9.93-5.48L133.16,227Z"/>
                    <polygon className="cls-9" points="124.74 243.55 124.74 241.75 126.34 242.65 126.34 244.45 124.74 243.55"/>
                    <path className="cls-11" d="M124.92,242.05l1.24.7v1.4l-1.24-.7v-1.4m-.35-.6v2.2l1.94,1.1v-2.2l-1.94-1.1Z"/>
                    <polygon className="cls-9" points="120.55 243.35 120.55 239.77 122.43 240.83 122.43 244.41 120.55 243.35"/>
                    <path className="cls-11" d="M120.73,240.07l1.52.87v3.17l-1.52-.86v-3.18m-.35-.6v4l2.22,1.25v-4l-2.22-1.26Z"/>
                    <polygon className="cls-10" points="111.39 256.42 121.78 250.69 121.78 257.8 111.39 263.8 111.39 256.42"/>
                    <path className="cls-11" d="M121.62,251v6.73l-10.07,5.81v-7L121.62,251m.33-.56-10.73,5.92v7.75L122,257.89v-7.48Z"/>
                    <polygon className="cls-9" points="100.75 257.75 100.75 250.5 105.97 247.58 110.97 256.39 110.97 263.65 100.75 257.75"/>
                    <path className="cls-11" d="M105.87,247.92l4.85,8.54v6.75L101,257.6v-7l4.87-2.73m.19-.68-5.56,3.11v7.54l10.72,6.19v-7.75l-5.16-9.09Z"/>
                    <polygon className="cls-10" points="106.29 247.31 116.39 241.55 121.72 250.35 111.29 256.1 106.29 247.31"/>
                    <path className="cls-11" d="M116.33,241.77l5.16,8.52-10.14,5.59-4.84-8.51,9.82-5.6m.12-.44-10.39,5.91,5.16,9.09L122,250.41l-5.5-9.08Z"/>
                    <polygon className="cls-9" points="107.35 259.24 107.35 257.26 109.1 258.25 109.1 260.23 107.35 259.24"/>
                    <path className="cls-11" d="M107.52,257.56l1.4.8v1.57l-1.4-.79v-1.58m-.35-.6v2.38l2.1,1.19v-2.38l-2.1-1.19Z"/>
                    <polygon className="cls-9" points="102.82 259.03 102.82 255.13 104.87 256.29 104.87 260.19 102.82 259.03"/>
                    <path className="cls-11" d="M103,255.43l1.7,1v3.5l-1.7-1v-3.5m-.35-.6v4.3l2.4,1.36v-4.3l-2.4-1.36Z"/>
                    <polygon className="cls-9" points="141.19 307.34 141.19 299.05 155.37 307.07 155.37 315.36 141.19 307.34"/>
                    <path className="cls-11" d="M141.45,299.48l13.66,7.74v7.71l-13.66-7.74v-7.71m-.5-.86v8.86l14.66,8.31v-8.87L141,298.62Z"/>
                    <polygon className="cls-10" points="155.82 307.05 170.08 298.98 170.08 307.36 155.82 315.43 155.82 307.05"/>
                    <path className="cls-11" d="M169.87,299.33v7.91L156,315.08v-7.91l13.84-7.84m.41-.71-14.67,8.3v8.87l14.67-8.31v-8.86Z"/>
                    <polygon className="cls-9" points="150.32 312.53 150.32 307.73 152.87 309.18 152.87 313.98 150.32 312.53"/>
                    <path className="cls-11" d="M150.49,308l2.21,1.25v4.4l-2.21-1.25V308m-.35-.6v5.2l2.91,1.65v-5.21l-2.91-1.64Z"/>
                    <polygon className="cls-10" points="141.37 298.62 155.59 290.54 169.87 298.62 155.62 306.69 141.37 298.62"/>
                    <path className="cls-11" d="M155.6,290.78l13.84,7.84-13.83,7.83-13.83-7.83,13.82-7.84m0-.47-14.66,8.31,14.67,8.31,14.67-8.31-14.68-8.31Z"/>
                    <polygon className="cls-9" points="143.1 306.26 143.1 303.96 147.97 306.69 147.97 309 143.1 306.26"/>
                    <path className="cls-11" d="M143.28,304.26l4.51,2.54v1.9l-4.51-2.54v-1.9m-.35-.59v2.7l5.21,2.92v-2.7l-5.21-2.92Z"/>
                    <polygon className="cls-9" points="212.38 309 212.38 301.31 218.69 300.69 225.06 309.04 225.06 316.39 212.38 309"/>
                    <path className="cls-11" d="M218.58,301l6.23,8.18V316l-12.18-7.11v-7.31l6-.59m.23-.52-6.68.65v8.06l13.18,7.69V309l-6.5-8.53Z"/>
                    <path className="cls-9" d="M214,307.25v-3.67a.4.4,0,0,1,.08-.31.24.24,0,0,1,.19-.08.54.54,0,0,1,.27.09,1.67,1.67,0,0,1,.75,1.26V308Z"/>
                    <path className="cls-11" d="M214.23,303.37a.39.39,0,0,1,.18.06,1.5,1.5,0,0,1,.67,1.11v3.16l-1-.55v-3.6a.24.24,0,0,1,0-.16s0,0,.06,0m0-.35c-.28,0-.47.21-.45.56v3.77l1.65,1v-3.77a1.83,1.83,0,0,0-.84-1.41.68.68,0,0,0-.36-.11Z"/>
                    <polygon className="cls-9" points="217.21 311.87 217.21 308.16 220.18 309.86 220.15 313.6 217.21 311.87"/>
                    <path className="cls-11" d="M217.39,308.47,220,310l0,3.33-2.59-1.52v-3.3m-.35-.61V312l3.28,1.93,0-4.14-3.31-1.9Z"/>
                    <path className="cls-9" d="M222.17,312v-3.67c0-.17.05-.38.27-.38a.67.67,0,0,1,.31.09,1.61,1.61,0,0,1,.71,1.26v3.46Z"/>
                    <path className="cls-11" d="M222.44,308.13a.46.46,0,0,1,.22.07,1.43,1.43,0,0,1,.63,1.11v3.16l-.94-.56v-3.57c0-.21.07-.21.09-.21m0-.35c-.27,0-.44.2-.44.56v3.77l1.64,1v-3.77a1.8,1.8,0,0,0-.81-1.42.89.89,0,0,0-.39-.11Z"/>
                    <path className="cls-10" d="M218.81,300.48a.13.13,0,0,0-.12-.06l-6,.6,3.18-1.82,0,.54a.16.16,0,0,0,0,.11.21.21,0,0,0,.1,0l2.58-.25,2.59,3.22a.16.16,0,0,0,.11.05.11.11,0,0,0,.08,0l3-1.74a.13.13,0,0,0,.07-.13v-7.41a.15.15,0,0,0-.07-.13l-.08,0-.07,0-2.89,1.68L218.84,292a.15.15,0,0,0-.11-.06l-2.55.22-.33-.83,2.86-1.65,2.91-.23L225,293.7a.17.17,0,0,0,.12.05.1.1,0,0,0,.07,0l1.29-.74,5.38-3.07,8.23,10.61L225.43,309Z"/>
                    <path className="cls-11" d="M221.55,289.58l2.94,3.76a.28.28,0,0,0-.13,0,.28.28,0,0,0-.15,0L221.44,295,219,291.88a.28.28,0,0,0-.23-.12h0l-2.42.21-.24-.61,2.68-1.54,2.83-.24m10.24.54,8,10.37-14.36,8.34-6.54-8.45a.29.29,0,0,0-.24-.11h0l-5.23.52,2.3-1.33,0,.29a.28.28,0,0,0,.1.21.32.32,0,0,0,.2.08h0l2.48-.25L221.1,303a.28.28,0,0,0,.23.12.42.42,0,0,0,.16,0l3-1.74a.32.32,0,0,0,.16-.27v-7.41s0,0,0-.06l.19.24a.3.3,0,0,0,.24.12.42.42,0,0,0,.15,0l1.29-.74,5.26-3m-10.1-.86-3.06.26-3,1.71.42,1.06,2.65-.22,2.64,3.28,3-1.74V301l-3,1.74-2.64-3.28-2.64.25,0-.79-3.93,2.29h0l6.61-.67,6.7,8.66,14.9-8.66-8.42-10.84-5.49,3.13-1.29.74-3.4-4.34Z"/>
                    <path className="cls-10" d="M225.59,309.3l13.69-7.87v7l-13.69,7.95Zm3.06-1.32a1,1,0,0,0-.51.15,2.09,2.09,0,0,0-.95,1.66v3.77a.29.29,0,0,0,.14.25.27.27,0,0,0,.28,0l1.65-.93a.31.31,0,0,0,.14-.25v-3.77A.78.78,0,0,0,228.65,308Zm4.13-2.38a1.06,1.06,0,0,0-.52.15,2.09,2.09,0,0,0-.95,1.69v3.77a.29.29,0,0,0,.15.25.27.27,0,0,0,.28,0l1.64-1a.27.27,0,0,0,.14-.24v-3.77C233.52,305.87,233.15,305.6,232.78,305.6Zm4.12-2.39a1.06,1.06,0,0,0-.52.15,2,2,0,0,0-.94,1.68v3.76a.28.28,0,0,0,.15.24.26.26,0,0,0,.28,0l1.64-.93a.29.29,0,0,0,.15-.25v-3.77A.78.78,0,0,0,236.9,303.21Z"/>
                    <path className="cls-11" d="M239,301.92v6.31l-13.11,7.61v-6.37l1.34-.78a2.28,2.28,0,0,0-.32,1.1v3.77a.55.55,0,0,0,.29.49.48.48,0,0,0,.28.08.53.53,0,0,0,.28-.07l1.65-.94a.58.58,0,0,0,.28-.49v-3.77a1.06,1.06,0,0,0-.78-1.13l2.44-1.41a2.42,2.42,0,0,0-.31,1.12v3.77a.56.56,0,0,0,.28.49.6.6,0,0,0,.29.08.54.54,0,0,0,.28-.08l1.65-1a.56.56,0,0,0,.28-.48v-3.77a1.07,1.07,0,0,0-.77-1.13l2.39-1.38a2,2,0,0,0-.28,1.06v3.77a.55.55,0,0,0,.57.56.55.55,0,0,0,.28-.07l1.64-.93a.58.58,0,0,0,.29-.5v-3.77a1.26,1.26,0,0,0-.26-.8.94.94,0,0,0-.49-.32l1.81-1.05m.57-1-14.25,8.2v7.69l14.25-8.27v-7.62Zm-3.84,7.86V305a1.72,1.72,0,0,1,.8-1.42.74.74,0,0,1,.38-.11c.27,0,.46.21.46.59v3.77l-1.64.94Zm-4.12,2.41v-3.77a1.83,1.83,0,0,1,.8-1.45.82.82,0,0,1,.38-.11c.27,0,.46.21.46.6v3.77l-1.64,1Zm-4.13,2.35v-3.77a1.79,1.79,0,0,1,.81-1.41.73.73,0,0,1,.37-.12c.27,0,.47.21.47.6v3.77l-1.65.93Z"/>
                    <line className="cls-28" x1="221.38" y1="294.64" x2="221.38" y2="303.15"/>
                    <polyline className="cls-9" points="218.72 292.07 221.37 295.35 224.36 293.61 224.36 301.02 221.33 302.76 218.69 299.48"/>
                    <polygon className="cls-10" points="221.52 295.35 224.31 293.78 224.31 301.02 221.49 302.59 221.52 295.35"/>
                    <path className="cls-11" d="M224.15,294v6.89l-2.51,1.4,0-6.9,2.48-1.39m.31-.52-3.09,1.74,0,7.59,3.13-1.74v-7.59Z"/>
                    <polyline className="cls-9" points="218.72 299.44 216.1 299.74 216.1 292.3 218.72 292.08"/>
                    <line className="cls-28" x1="216.1" y1="291.4" x2="216.1" y2="299.91"/>
                    <polygon className="cls-9" points="244.59 247.39 244.63 241.9 249.62 244.72 249.57 250.21 244.59 247.39"/>
                    <path className="cls-11" d="M244.73,242.07l4.74,2.68V250l-4.74-2.68v-5.29m-.19-.34v5.74l5.13,2.91v-5.74l-5.13-2.91Z"/>
                    <polygon className="cls-9" points="247.68 245.85 247.72 245.06 248.51 245.5 248.46 246.3 247.68 245.85"/>
                    <path className="cls-11" d="M247.82,245.23l.54.3v.6l-.54-.31v-.59m-.19-.34v1.05l.92.52v-1.05l-.92-.52Z"/>
                    <polygon className="cls-9" points="245.7 244.74 245.74 243.94 246.53 244.38 246.49 245.18 245.7 244.74"/>
                    <path className="cls-11" d="M245.85,244.11l.53.3v.6l-.53-.3v-.6m-.2-.34v1l.93.52v-1l-.93-.53Z"/>
                    <polygon className="cls-10" points="249.77 244.64 254.7 241.9 254.7 247.47 249.77 250.21 249.77 244.64"/>
                    <path className="cls-11" d="M254.6,242.07v5.29L249.86,250v-5.29l4.74-2.68m.2-.34-5.13,2.91v5.74l5.13-2.91v-5.74Z"/>
                    <polygon className="cls-9" points="246.64 248.55 246.69 246.98 247.56 247.47 247.52 249.04 246.64 248.55"/>
                    <path className="cls-11" d="M246.79,247.15l.62.35v1.37l-.62-.35v-1.37m-.2-.34v1.82l1,.58v-1.82l-1-.58Z"/>
                    <polygon className="cls-10" points="244.74 241.73 249.71 238.91 254.6 241.73 249.67 244.53 244.74 241.73"/>
                    <path className="cls-11" d="M249.66,239.05l4.74,2.68-4.73,2.68-4.74-2.68,4.73-2.68m0-.23-5.12,2.91,5.13,2.91,5.13-2.91-5.14-2.91Z"/>
                    <polygon className="cls-9" points="240.52 259.33 240.56 256.13 246.09 259.25 246.05 262.46 240.52 259.33"/>
                    <path className="cls-11" d="M240.64,256.26l5.33,3v3l-5.33-3v-3m-.16-.27v3.41l5.65,3.19v-3.41L240.48,256Z"/>
                    <polygon className="cls-10" points="246.21 259.18 251.69 256.13 251.69 259.4 246.21 262.46 246.21 259.18"/>
                    <path className="cls-11" d="M251.61,256.26v3l-5.32,3v-3l5.32-3m.16-.27-5.64,3.19v3.41l5.64-3.19V256Z"/>
                    <polygon className="cls-9" points="244.06 261.31 244.1 259.51 245.1 260.08 245.06 261.88 244.06 261.31"/>
                    <path className="cls-11" d="M244.18,259.65l.8.45v1.64l-.8-.45v-1.64m-.16-.27v2l1.12.63v-2l-1.12-.63Z"/>
                    <polygon className="cls-10" points="240.65 255.99 246.16 252.86 251.61 255.99 246.13 259.09 240.65 255.99"/>
                    <path className="cls-11" d="M246.12,253l5.33,3-5.32,3-5.32-3,5.31-3m0-.18-5.64,3.2,5.65,3.19,5.64-3.19-5.65-3.2Z"/>
                    <polygon className="cls-9" points="241.29 258.9 241.32 258.06 243.21 259.12 243.18 259.96 241.29 258.9"/>
                    <path className="cls-11" d="M241.41,258.2l1.68.95v.67l-1.68-.95v-.67m-.16-.27v1l2,1.12v-1l-2-1.12Z"/>
                    <polygon className="cls-10" points="191.4 324.17 208.41 314.79 208.41 326.63 191.4 336.39 191.4 324.17"/>
                    <path className="cls-11" d="M208.31,315v11.55l-16.81,9.7v-12L208.31,315m.19-.34-17.2,9.49v12.45l17.2-9.93v-12Z"/>
                    <polygon className="cls-9" points="174.35 326.48 174.35 314.68 182.92 309.88 191.05 324.18 191.05 336.12 174.35 326.48"/>
                    <path className="cls-10" d="M182.83,310.22l8,14v11.44l-16.2-9.35V314.83l8.23-4.61m.19-.68-8.92,5v12.1l17.2,9.93V324.11L183,309.54Z"/>
                    <polygon className="cls-10" points="183.15 309.58 199.65 300.18 208.37 314.58 191.34 323.98 183.15 309.58"/>
                    <path className="cls-11" d="M199.61,300.32l8.62,14.23-16.85,9.3-8.09-14.23,16.32-9.3m.07-.27L183,309.54l8.28,14.57,17.2-9.49-8.82-14.57Z"/>
                    <polygon className="cls-9" points="184.97 328.85 184.97 325.44 188 327.15 188 330.56 184.97 328.85"/>
                    <path className="cls-11" d="M185.15,325.74l2.67,1.51v3l-2.67-1.51v-3m-.35-.6V329l3.37,1.91V327l-3.37-1.9Z"/>
                    <polygon className="cls-9" points="177.72 328.52 177.72 322.02 181.22 324 181.22 330.5 177.72 328.52"/>
                    <path className="cls-11" d="M177.89,322.32,181,324.1v6.1l-3.15-1.79v-6.09m-.35-.6v6.9l3.85,2.18v-6.9l-3.85-2.18Z"/>
                    <path className="cls-9" d="M245.12,280.7V267.4l11,6.2.12,0a.28.28,0,0,0,.13,0,.26.26,0,0,0,.12-.22v-7.69l10,5.64v21.42Z"/>
                    <path className="cls-10" d="M256.76,266.12l9.46,5.35v20.86l-20.85-11.78V267.83l10.65,6a.5.5,0,0,0,.24.06.51.51,0,0,0,.25-.06.5.5,0,0,0,.25-.44v-7.26m-.5-.86v8.12L244.87,267v13.87l21.85,12.34v-22l-10.46-5.92Z"/>
                    <path className="cls-10" d="M266.88,271.18a.16.16,0,0,0-.08-.14l-10.46-5.92-.08,0-.08,0a.17.17,0,0,0-.07.14v7.86L245.18,267l10.14-5.75,21.55,12.17-10,5.66Z"/>
                    <path className="cls-11" d="M255.32,261.4l21.24,12-9.53,5.4v-7.61a.32.32,0,0,0-.16-.27L256.41,265a.3.3,0,0,0-.3,0,.32.32,0,0,0-.16.27v7.6L245.49,267l9.83-5.57m0-.36L244.87,267l11.39,6.41v-8.12l10.46,5.92v8.13l10.46-5.92L255.32,261Z"/>
                    <polygon className="cls-10" points="256.57 265.26 266.71 259.5 276.87 265.26 266.72 271 256.57 265.26"/>
                    <path className="cls-11" d="M266.71,259.68l9.85,5.58-9.84,5.57-9.83-5.57,9.82-5.58m0-.35-10.45,5.93,10.46,5.92,10.46-5.92-10.47-5.93Z"/>
                    <polygon className="cls-10" points="266.88 271.27 277.03 265.52 277.03 287.17 266.88 292.92 266.88 271.27"/>
                    <path className="cls-11" d="M276.88,265.79v21.29L267,292.65V271.36l9.85-5.57m.3-.53-10.46,5.92v22l10.46-5.92v-22Z"/>
                    <polygon className="cls-9" points="260.11 289.16 260.11 284.73 262.45 286.06 262.45 290.49 260.11 289.16"/>
                    <path className="cls-11" d="M260.29,285l2,1.13v4l-2-1.13v-4m-.35-.6v4.83l2.69,1.53V286l-2.69-1.53Z"/>
                    <polygon className="cls-9" points="254.03 283.52 254.03 281.14 256.14 282.33 256.14 284.71 254.03 283.52"/>
                    <path className="cls-11" d="M254.21,281.44l1.75,1v2l-1.75-1v-2m-.35-.6v2.78l2.45,1.39v-2.78l-2.45-1.39Z"/>
                    <polygon className="cls-9" points="248.36 280.24 248.36 277.87 250.47 279.06 250.47 281.44 248.36 280.24"/>
                    <path className="cls-11" d="M248.54,278.17l1.75,1v2l-1.75-1v-2m-.35-.6v2.78l2.45,1.38V279l-2.45-1.39Z"/>
                    <polygon className="cls-9" points="254.54 278.27 254.54 275.89 256.64 277.08 256.64 279.46 254.54 278.27"/>
                    <path className="cls-11" d="M254.72,276.19l1.75,1v2l-1.75-1v-2m-.35-.6v2.78l2.45,1.39V277l-2.45-1.39Z"/>
                    <polygon className="cls-9" points="260.46 281.66 260.46 279.28 262.57 280.47 262.57 282.85 260.46 281.66"/>
                    <path className="cls-11" d="M260.64,279.58l1.75,1v2l-1.75-1v-2m-.35-.6v2.78l2.45,1.39v-2.78L260.29,279Z"/>
                    <polygon className="cls-9" points="248.62 274.88 248.62 272.5 250.72 273.69 250.72 276.06 248.62 274.88"/>
                    <path className="cls-11" d="M248.79,272.8l1.76,1v2l-1.76-1v-2m-.35-.6V275l2.46,1.39v-2.78l-2.46-1.39Z"/>
                    <path className="cls-12" d="M259.53,270a2.74,2.74,0,0,1,1.85,2c.29-.67,1.12-.6,1.85.14a2.69,2.69,0,0,1,.81,2.55,1.32,1.32,0,0,1-.16.23.45.45,0,0,1-.15.13l-2.35,1.37h0s-2.29-3.91-2.35-4l-.15-.31a2.9,2.9,0,0,1-.16-.41c-.28-1,.08-1.73.81-1.64"/>
                    <polygon className="cls-9" points="225.32 236.99 225.32 215.63 235.35 221.31 235.35 242.66 225.32 236.99"/>
                    <path className="cls-10" d="M225.57,216.06l9.53,5.4v20.77l-9.53-5.39V216.06m-.5-.86v21.93l10.53,6V221.16l-10.53-6Z"/>
                    <polygon className="cls-9" points="231.59 233.88 231.59 232.13 233.13 233.01 233.13 234.76 231.59 233.88"/>
                    <path className="cls-11" d="M231.76,232.43l1.2.68v1.35l-1.2-.68v-1.35m-.35-.6V234l1.9,1.08v-2.15l-1.9-1.08Z"/>
                    <polygon className="cls-9" points="227.53 231.59 227.53 229.84 229.08 230.72 229.08 232.46 227.53 231.59"/>
                    <path className="cls-11" d="M227.71,230.14l1.19.68v1.34l-1.19-.68v-1.34m-.35-.6v2.15l1.89,1.07v-2.15l-1.89-1.07Z"/>
                    <polygon className="cls-9" points="231.59 228.91 231.59 227.17 233.13 228.04 233.13 229.79 231.59 228.91"/>
                    <path className="cls-11" d="M231.76,227.47l1.2.67v1.35l-1.2-.68v-1.34m-.35-.6V229l1.9,1.08v-2.15l-1.9-1.07Z"/>
                    <polygon className="cls-9" points="227.53 226.62 227.53 224.87 229.08 225.75 229.08 227.49 227.53 226.62"/>
                    <path className="cls-11" d="M227.71,225.17l1.19.68v1.34l-1.19-.68v-1.34m-.35-.6v2.15l1.89,1.07v-2.15l-1.89-1.07Z"/>
                    <polygon className="cls-9" points="231.59 223.94 231.59 222.2 233.13 223.07 233.13 224.82 231.59 223.94"/>
                    <path className="cls-11" d="M231.76,222.5l1.2.67v1.35l-1.2-.68V222.5m-.35-.6V224l1.9,1.08V223l-1.9-1.07Z"/>
                    <polygon className="cls-9" points="227.53 221.65 227.53 219.9 229.08 220.78 229.08 222.52 227.53 221.65"/>
                    <path className="cls-11" d="M227.71,220.2l1.19.68v1.34l-1.19-.67V220.2m-.35-.6v2.15l1.89,1.07v-2.14l-1.89-1.08Z"/>
                    <polygon className="cls-10" points="235.73 221.24 246 215.43 246 237.06 235.73 242.87 235.73 221.24"/>
                    <path className="cls-11" d="M245.87,215.65V237l-10,5.67V221.31l10-5.66m.26-.45-10.53,6v21.93l10.53-6V215.2Z"/>
                    <polygon className="cls-9" points="229.47 239.41 229.47 236.07 231.2 237.06 231.2 240.39 229.47 239.41"/>
                    <path className="cls-11" d="M229.64,236.37l1.39.79v2.93l-1.39-.78v-2.94m-.35-.6v3.74l2.09,1.18V237l-2.09-1.18Z"/>
                    <polygon className="cls-10" points="225.33 215.2 235.58 209.38 245.87 215.2 235.6 221.02 225.33 215.2"/>
                    <path className="cls-11" d="M235.58,209.53l10,5.67-10,5.67-10-5.67,10-5.67m0-.29-10.51,6,10.53,6,10.53-6-10.55-6Z"/>
                    <polygon className="cls-10" points="153.84 223.38 162.4 218.54 162.36 230.81 153.84 235.73 153.84 223.38"/>
                    <path className="cls-11" d="M162.27,218.76l0,12L154,235.51v-12l8.3-4.7m.26-.44-8.82,5V236l8.78-5.07,0-12.56Z"/>
                    <polygon className="cls-10" points="149.31 220.63 157.86 215.78 162.27 218.32 153.71 223.16 149.31 220.63"/>
                    <path className="cls-11" d="M157.86,215.92l4.15,2.4L153.72,223l-4.15-2.38,8.29-4.71m0-.29-8.81,5,4.66,2.69,8.82-5-4.67-2.69Z"/>
                    <polygon className="cls-10" points="149.2 214.04 144.87 206.65 152.38 202.27 157.75 209.15 157.73 215.56 149.2 220.38 149.2 214.04"/>
                    <path className="cls-11" d="M152.35,202.43l5.25,6.73v6.32l-8.27,4.68V214a.22.22,0,0,0,0-.13L145,206.69l7.31-4.26m.06-.33-7.72,4.5,4.38,7.44v6.57l8.79-5v-6.56l-5.45-7Z"/>
                    <polygon className="cls-9" points="135.78 225.35 135.78 213.43 140.54 216.06 140.54 209.21 144.6 206.94 148.82 214.11 148.82 220.75 153.46 223.45 153.46 235.52 135.78 225.35"/>
                    <path className="cls-10" d="M144.51,207.27l4.06,6.91v6.71l.25.15,4.39,2.56v11.49L136,225.2V213.85l4,2.22.74.41v-7.13l3.72-2.08m.18-.67-4.41,2.47h0v6.56L135.53,213v12.49L153.71,236V223.31l-4.64-2.71V214l-4.38-7.44Z"/>
                    <polygon className="cls-9" points="138.62 225.26 138.62 223.19 140.46 224.23 140.46 226.3 138.62 225.26"/>
                    <path className="cls-11" d="M138.79,223.49l1.49.84V226l-1.49-.84v-1.67m-.35-.6v2.47l2.19,1.24v-2.48l-2.19-1.23Z"/>
                    <polygon className="cls-9" points="148.82 230.96 148.82 228.88 150.65 229.92 150.65 232 148.82 230.96"/>
                    <path className="cls-11" d="M149,229.18l1.49.84v1.68l-1.49-.85v-1.67m-.35-.6v2.48l2.19,1.24v-2.48l-2.19-1.24Z"/>
                    <polygon className="cls-9" points="138.62 220.19 138.62 218.12 140.46 219.16 140.46 221.23 138.62 220.19"/>
                    <path className="cls-11" d="M138.79,218.42l1.49.84v1.67l-1.49-.84v-1.67m-.35-.6v2.47l2.19,1.24v-2.48l-2.19-1.23Z"/>
                    <polygon className="cls-9" points="148.82 225.9 148.82 223.83 150.65 224.87 150.65 226.94 148.82 225.9"/>
                    <path className="cls-11" d="M149,224.13l1.49.84v1.67L149,225.8v-1.67m-.35-.6V226l2.19,1.23v-2.47l-2.19-1.24Z"/>
                    <polygon className="cls-9" points="143.72 223.04 143.72 220.97 145.56 222.01 145.56 224.08 143.72 223.04"/>
                    <path className="cls-11" d="M143.89,221.27l1.49.84v1.67l-1.49-.84v-1.67m-.35-.6v2.47l2.19,1.24v-2.47l-2.19-1.24Z"/>
                    <polygon className="cls-9" points="143.6 229.9 143.6 225.94 145.65 227.11 145.65 231.06 143.6 229.9"/>
                    <path className="cls-11" d="M143.77,226.24l1.7,1v3.55l-1.7-1v-3.56m-.35-.6V230l2.4,1.36V227l-2.4-1.36Z"/>
                    <path className="cls-29" d="M144.64,212.48a4.58,4.58,0,0,1,2.11,3.59c0,1.32-.95,1.86-2.11,1.2a4.58,4.58,0,0,1-2.12-3.6C142.52,212.35,143.47,211.82,144.64,212.48Z"/>
                    <polygon className="cls-9" points="143.34 214.43 144.93 215.33 144.93 213.5 144.51 213.26 144.51 214.63 143.34 213.97 143.34 214.43"/>
                    <polygon className="cls-10" points="135.79 213 140.16 210.52 140.16 215.42 135.79 213"/>
                    <path className="cls-11" d="M140,210.74v4.46l-.94-.52-3-1.68,4-2.26m.26-.44-4.76,2.7,3.44,1.9,1.31.73h0V210.3Z"/>
                    <polygon className="cls-9" points="151.62 282.73 151.62 273.18 160.17 278.02 160.17 287.58 151.62 282.73"/>
                    <path className="cls-11" d="M151.86,273.61l8.06,4.56v9l-8.06-4.56v-9m-.5-.86v10.13l9.06,5.13V277.88l-9.06-5.13Z"/>
                    <polygon className="cls-9" points="157 280.07 157 278.62 158.28 279.35 158.28 280.8 157 280.07"/>
                    <path className="cls-11" d="M157.17,278.92l.93.53v1.05l-.93-.53v-1.05m-.35-.6v1.85l1.63.93v-1.85l-1.63-.93Z"/>
                    <polygon className="cls-9" points="153.51 278.1 153.51 276.65 154.79 277.38 154.79 278.82 153.51 278.1"/>
                    <path className="cls-11" d="M153.68,277l.94.53v1l-.94-.53v-1m-.35-.6v1.85l1.64.92v-1.85l-1.64-.92Z"/>
                    <polygon className="cls-10" points="160.59 277.98 169.31 273.05 169.31 282.78 160.59 287.71 160.59 277.98"/>
                    <path className="cls-11" d="M169.13,273.34v9.34l-8.36,4.73v-9.33l8.36-4.74m.35-.59-9.06,5.13V288l9.06-5.13V272.75Z"/>
                    <polygon className="cls-9" points="155.17 284.82 155.17 282.01 156.61 282.83 156.61 285.64 155.17 284.82"/>
                    <path className="cls-11" d="M155.35,282.31l1.09.62v2.41l-1.09-.62v-2.41m-.35-.6v3.21l1.79,1v-3.21l-1.79-1Z"/>
                    <polygon className="cls-10" points="151.72 272.75 160.41 267.82 169.13 272.75 160.42 277.68 151.72 272.75"/>
                    <path className="cls-11" d="M160.41,268l8.37,4.74-8.36,4.73-8.35-4.73,8.34-4.74m0-.39-9,5.13,9.06,5.13,9.06-5.13-9.07-5.13Z"/>
                    <polygon className="cls-9" points="100.75 283.97 100.77 272.6 104.83 270.33 109.03 277.48 109.02 288.64 100.75 283.97"/>
                    <path className="cls-10" d="M104.73,270.67l4,6.88v10.67l-7.77-4.4,0-11.07,3.71-2.08m.19-.67-4.4,2.45,0,11.66,8.77,5V277.41L104.92,270Z"/>
                    <polygon className="cls-10" points="105.15 270.06 113.34 265.39 117.53 272.52 109.34 277.19 105.15 270.06"/>
                    <path className="cls-11" d="M113.28,265.62l4,6.84L109.4,277l-4-6.84,7.91-4.5m.12-.45L104.92,270l4.36,7.41,8.48-4.83-4.36-7.41Z"/>
                    <polygon className="cls-9" points="103.98 277.73 103.98 275.67 105.81 276.71 105.81 278.77 103.98 277.73"/>
                    <path className="cls-11" d="M104.16,276l1.47.84v1.66l-1.47-.84V276m-.35-.6v2.47l2.17,1.23V276.6l-2.17-1.23Z"/>
                    <polygon className="cls-9" points="103.83 285.67 103.83 281.61 105.96 282.82 105.96 286.87 103.83 285.67"/>
                    <path className="cls-11" d="M104,281.91l1.79,1v3.65l-1.79-1v-3.65m-.35-.6v4.46l2.49,1.4v-4.45l-2.49-1.41Z"/>
                    <polygon className="cls-9" points="109.53 288.94 109.55 277.57 113.61 275.3 117.81 282.45 117.8 293.61 109.53 288.94"/>
                    <path className="cls-10" d="M113.51,275.64l4,6.87v10.67l-7.77-4.39,0-11.08,3.71-2.07m.19-.68-4.4,2.46,0,11.66,8.77,5V282.38L113.7,275Z"/>
                    <polygon className="cls-10" points="113.93 275.02 122.12 270.36 126.31 277.49 118.13 282.15 113.93 275.02"/>
                    <path className="cls-11" d="M122.06,270.58l4,6.85-7.91,4.5-4-6.84,7.91-4.51m.12-.45L113.7,275l4.36,7.42,8.48-4.83-4.36-7.42Z"/>
                    <polygon className="cls-9" points="112.76 282.7 112.76 280.64 114.59 281.67 114.59 283.74 112.76 282.7"/>
                    <path className="cls-11" d="M112.94,280.94l1.47.84v1.65l-1.47-.83v-1.66m-.35-.6v2.46l2.17,1.23v-2.46l-2.17-1.23Z"/>
                    <polygon className="cls-9" points="112.61 290.63 112.61 286.58 114.74 287.79 114.74 291.84 112.61 290.63"/>
                    <path className="cls-11" d="M112.78,286.88l1.79,1v3.65l-1.79-1v-3.65m-.35-.6v4.45l2.49,1.41v-4.45l-2.49-1.41Z"/>
                    <polygon className="cls-10" points="127.01 287.45 135.16 282.81 135.14 294.09 126.99 298.73 127.01 287.45"/>
                    <path className="cls-11" d="M135,283.1l0,10.9-7.81,4.45,0-10.9L135,283.1m.33-.57h0Zm0,0-8.48,4.83V299l8.47-4.83,0-11.66Z"/>
                    <polygon className="cls-9" points="118.31 293.91 118.33 282.54 122.39 280.28 126.59 287.42 126.58 298.59 118.31 293.91"/>
                    <path className="cls-10" d="M122.29,280.62l4,6.87v10.67l-7.77-4.39,0-11.08,3.71-2.07m.19-.68-4.4,2.46,0,11.66,8.77,5V287.36l-4.36-7.42Z"/>
                    <polygon className="cls-10" points="122.71 280 130.9 275.34 135.09 282.46 126.9 287.13 122.71 280"/>
                    <path className="cls-11" d="M130.84,275.56l4,6.84L127,286.91l-4-6.85,7.91-4.5m.12-.45-8.48,4.83,4.36,7.42,8.48-4.83L131,275.11Z"/>
                    <polygon className="cls-9" points="121.54 287.68 121.54 285.62 123.37 286.65 123.37 288.71 121.54 287.68"/>
                    <path className="cls-11" d="M121.72,285.92l1.47.83v1.66l-1.47-.83v-1.66m-.35-.6v2.46l2.17,1.23v-2.46l-2.17-1.23Z"/>
                    <polygon className="cls-9" points="121.39 295.61 121.39 291.56 123.52 292.77 123.52 296.82 121.39 295.61"/>
                    <path className="cls-11" d="M121.56,291.86l1.79,1v3.65l-1.79-1v-3.65m-.35-.6v4.45l2.49,1.41v-4.46l-2.49-1.4Z"/>
                    <polygon className="cls-10" points="252.68 298.51 256.77 296.29 256.77 299.13 252.68 301.45 252.68 298.51"/>
                    <path className="cls-11" d="M256.71,296.4v2.65l-4,2.29v-2.75l4-2.19m.13-.22-4.23,2.33v3.06l4.23-2.44v-2.95Z"/>
                    <polygon className="cls-9" points="248.42 299.07 248.45 296.16 250.61 294.99 252.55 298.54 252.55 301.45 248.42 299.07"/>
                    <path className="cls-11" d="M250.53,295.11l1.95,3.43v2.8l-4-2.29v-2.82l2-1.12m.05-.18-2.19,1.23v3l4.22,2.44v-3.06l-2-3.58Z"/>
                    <polygon className="cls-10" points="250.63 294.9 254.7 292.65 256.78 296.21 252.64 298.42 250.63 294.9"/>
                    <path className="cls-11" d="M254.62,292.77l2,3.36-4,2.2L250.75,295l3.87-2.21m0-.17-4.09,2.33,2,3.58,4.23-2.33-2.17-3.58Z"/>
                    <polygon className="cls-9" points="251.05 299.64 251.07 298.87 251.81 299.29 251.78 300.06 251.05 299.64"/>
                    <path className="cls-11" d="M251.14,299l.57.33v.63l-.57-.32V299m-.13-.22v.94l.83.47v-.94l-.83-.47Z"/>
                    <polygon className="cls-9" points="249.26 299.56 249.29 298.03 250.15 298.51 250.11 300.04 249.26 299.56"/>
                    <path className="cls-11" d="M249.36,298.14l.69.39v1.4l-.69-.39v-1.4m-.13-.22v1.7l1,.53v-1.69l-1-.54Z"/>
                    <polygon className="cls-10" points="220.9 257.85 225.28 255.48 225.28 258.51 220.9 261 220.9 257.85"/>
                    <path className="cls-11" d="M225.21,255.59v2.84L221,260.88v-2.95l4.24-2.34m.14-.23-4.52,2.49v3.27l4.52-2.61v-3.15Z"/>
                    <polygon className="cls-9" points="216.35 258.45 216.39 255.34 218.69 254.09 220.77 257.88 220.77 261 216.35 258.45"/>
                    <path className="cls-11" d="M218.61,254.21l2.09,3.68v3l-4.24-2.45v-3l2.15-1.21m0-.18-2.34,1.31v3.17l4.51,2.61v-3.27L218.66,254Z"/>
                    <polygon className="cls-10" points="218.72 253.99 223.07 251.59 225.29 255.4 220.86 257.76 218.72 253.99"/>
                    <path className="cls-11" d="M223,251.72l2.17,3.59-4.26,2.35-2-3.58,4.13-2.36m.05-.18L218.66,254l2.17,3.82,4.52-2.49L223,251.54Z"/>
                    <polygon className="cls-9" points="219.16 259.06 219.19 258.24 219.98 258.68 219.95 259.5 219.16 259.06"/>
                    <path className="cls-11" d="M219.27,258.36l.6.34v.68l-.6-.34v-.68m-.14-.24v1l.88.5v-1l-.88-.5Z"/>
                    <polygon className="cls-9" points="217.26 258.97 217.29 257.34 218.2 257.85 218.17 259.49 217.26 258.97"/>
                    <path className="cls-11" d="M217.36,257.46l.74.41v1.5l-.74-.42v-1.49m-.14-.24V259l1,.57v-1.81l-1-.57Z"/>
                    <polygon className="cls-10" points="248.45 296.04 252.55 293.82 252.55 296.66 248.45 298.99 248.45 296.04"/>
                    <path className="cls-11" d="M252.48,293.93v2.66l-4,2.29v-2.76l4-2.19m.13-.22L248.39,296v3.06l4.22-2.44v-3Z"/>
                    <polygon className="cls-9" points="244.19 296.61 244.22 293.69 246.38 292.52 248.33 296.08 248.32 298.99 244.19 296.61"/>
                    <path className="cls-11" d="M246.3,292.64l2,3.44v2.8l-4-2.29v-2.82l2-1.13m0-.18-2.19,1.23v3l4.23,2.44V296l-2-3.58Z"/>
                    <polygon className="cls-10" points="246.41 292.43 250.48 290.19 252.56 293.75 248.41 295.96 246.41 292.43"/>
                    <path className="cls-11" d="M250.4,290.31l2,3.35-4,2.21-1.91-3.36,3.87-2.2m0-.18-4.09,2.33,2,3.58,4.22-2.33-2.17-3.58Z"/>
                    <polygon className="cls-9" points="246.82 297.18 246.85 296.4 247.58 296.82 247.56 297.59 246.82 297.18"/>
                    <path className="cls-11" d="M246.92,296.52l.57.32v.64l-.57-.32v-.64m-.13-.22v.93l.83.47v-.94l-.83-.46Z"/>
                    <polygon className="cls-9" points="245.04 297.09 245.07 295.57 245.92 296.05 245.89 297.58 245.04 297.09"/>
                    <path className="cls-11" d="M245.14,295.68l.68.39v1.39l-.68-.39v-1.39m-.13-.22v1.69l.94.54V296l-.94-.53Z"/>
                    <polygon className="cls-10" points="160.29 242.82 165.41 240.04 165.41 243.59 160.29 246.5 160.29 242.82"/>
                    <path className="cls-11" d="M165.33,240.18v3.32l-5,2.86v-3.45l5-2.73m.16-.28-5.29,2.92v3.82l5.29-3V239.9Z"/>
                    <polygon className="cls-9" points="154.96 243.52 155 239.88 157.63 238.45 160.13 242.86 160.12 246.5 154.96 243.52"/>
                    <path className="cls-11" d="M157.6,238.56l2.44,4.3v3.5l-5-2.86V240l2.52-1.41m.06-.22-2.74,1.54v3.71l5.28,3v-3.82l-2.54-4.48Z"/>
                    <polygon className="cls-10" points="157.77 238.37 162.75 235.54 165.38 239.87 160.24 242.71 157.77 238.37"/>
                    <path className="cls-11" d="M162.72,235.65l2.54,4.19-5,2.76-2.39-4.2,4.84-2.75m.06-.22-5.12,2.91,2.54,4.48,5.29-2.92-2.71-4.47Z"/>
                    <polygon className="cls-9" points="158.25 244.23 158.28 243.27 159.2 243.79 159.17 244.75 158.25 244.23"/>
                    <path className="cls-11" d="M158.37,243.41l.71.4v.8l-.71-.4v-.8m-.16-.28v1.17l1,.59v-1.17l-1-.59Z"/>
                    <polygon className="cls-9" points="156.02 244.13 156.06 242.22 157.12 242.82 157.08 244.73 156.02 244.13"/>
                    <path className="cls-11" d="M156.14,242.36l.86.49v1.74l-.86-.48v-1.75m-.16-.28v2.12l1.18.67v-2.12l-1.18-.67Z"/>
                    <polygon className="cls-10" points="165.57 245.84 170.69 243.06 170.69 246.61 165.57 249.52 165.57 245.84"/>
                    <path className="cls-11" d="M170.61,243.19v3.32l-5,2.87v-3.45l5-2.74m.16-.27-5.28,2.92v3.82l5.28-3v-3.69Z"/>
                    <polygon className="cls-9" points="160.25 246.54 160.29 242.89 162.91 241.47 165.42 245.88 165.41 249.52 160.25 246.54"/>
                    <path className="cls-11" d="M162.88,241.58l2.45,4.3v3.5l-5-2.87V243l2.51-1.41m.06-.22-2.74,1.53v3.72l5.29,3v-3.82l-2.55-4.48Z"/>
                    <polygon className="cls-10" points="163.05 241.39 168.03 238.55 170.66 242.89 165.52 245.73 163.05 241.39"/>
                    <path className="cls-11" d="M168,238.66l2.54,4.2-5,2.76-2.39-4.2,4.84-2.76m.06-.22-5.12,2.92,2.55,4.48,5.28-2.92-2.71-4.48Z"/>
                    <polygon className="cls-9" points="163.53 247.25 163.57 246.29 164.49 246.81 164.45 247.77 163.53 247.25"/>
                    <path className="cls-11" d="M163.65,246.43l.71.4v.8l-.71-.4v-.8m-.16-.28v1.17l1,.59v-1.17l-1-.59Z"/>
                    <polygon className="cls-9" points="161.3 247.15 161.34 245.24 162.4 245.84 162.37 247.75 161.3 247.15"/>
                    <path className="cls-11" d="M161.42,245.38l.86.48v1.75l-.86-.49v-1.74m-.16-.28v2.12l1.18.67v-2.12l-1.18-.67Z"/>
                    <polygon className="cls-10" points="170.85 248.92 175.97 246.14 175.97 249.69 170.85 252.6 170.85 248.92"/>
                    <path className="cls-11" d="M175.89,246.28v3.32l-5,2.86V249l5-2.73m.16-.28-5.28,2.92v3.82l5.28-3.05V246Z"/>
                    <polygon className="cls-9" points="165.53 249.62 165.57 245.98 168.19 244.55 170.7 248.96 170.69 252.6 165.53 249.62"/>
                    <path className="cls-11" d="M168.16,244.66l2.45,4.3v3.5l-5-2.86v-3.53l2.51-1.41m.07-.22L165.49,246v3.71l5.28,3.05v-3.82l-2.54-4.48Z"/>
                    <polygon className="cls-10" points="168.34 244.47 173.31 241.64 175.94 245.97 170.8 248.81 168.34 244.47"/>
                    <path className="cls-11" d="M173.29,241.75l2.54,4.19-5,2.76-2.38-4.2,4.84-2.75m.05-.22-5.11,2.91,2.54,4.48,5.28-2.92-2.71-4.47Z"/>
                    <polygon className="cls-9" points="168.81 250.33 168.85 249.37 169.77 249.89 169.73 250.85 168.81 250.33"/>
                    <path className="cls-11" d="M168.94,249.51l.71.4v.8l-.71-.4v-.8m-.17-.28v1.17l1,.59v-1.17l-1-.59Z"/>
                    <polygon className="cls-9" points="166.58 250.23 166.62 248.32 167.69 248.92 167.65 250.83 166.58 250.23"/>
                    <path className="cls-11" d="M166.71,248.46l.85.49v1.74l-.85-.48v-1.75m-.17-.28v2.12l1.19.67v-2.12l-1.19-.67Z"/>
                    <polygon className="cls-10" points="165.57 245.84 170.69 243.06 170.69 246.61 165.57 249.52 165.57 245.84"/>
                    <path className="cls-11" d="M170.61,243.19v3.32l-5,2.87v-3.45l5-2.74m.16-.27-5.28,2.92v3.82l5.28-3v-3.69Z"/>
                    <polygon className="cls-9" points="160.25 246.54 160.29 242.89 162.91 241.47 165.42 245.88 165.41 249.52 160.25 246.54"/>
                    <path className="cls-11" d="M162.88,241.58l2.45,4.3v3.5l-5-2.87V243l2.51-1.41m.06-.22-2.74,1.53v3.72l5.29,3v-3.82l-2.55-4.48Z"/>
                    <polygon className="cls-10" points="163.05 241.39 168.03 238.55 170.66 242.89 165.52 245.73 163.05 241.39"/>
                    <path className="cls-11" d="M168,238.66l2.54,4.2-5,2.76-2.39-4.2,4.84-2.76m.06-.22-5.12,2.92,2.55,4.48,5.28-2.92-2.71-4.48Z"/>
                    <polygon className="cls-9" points="163.53 247.25 163.57 246.29 164.49 246.81 164.45 247.77 163.53 247.25"/>
                    <path className="cls-11" d="M163.65,246.43l.71.4v.8l-.71-.4v-.8m-.16-.28v1.17l1,.59v-1.17l-1-.59Z"/>
                    <polygon className="cls-9" points="161.3 247.15 161.34 245.24 162.4 245.84 162.37 247.75 161.3 247.15"/>
                    <path className="cls-11" d="M161.42,245.38l.86.48v1.75l-.86-.49v-1.74m-.16-.28v2.12l1.18.67v-2.12l-1.18-.67Z"/>
                  </g>
                  <path className="cls-30" d="M168.8,266.48h-2a2.27,2.27,0,0,1-1.48-.4,1.44,1.44,0,0,1-.49-1.21v-2.44a1.45,1.45,0,0,1,.49-1.21,2.33,2.33,0,0,1,1.48-.4h2a.25.25,0,0,1,.29.29v.95a.25.25,0,0,1-.29.28h-1.61a.55.55,0,0,0-.37.1.34.34,0,0,0-.12.29v1.87a.34.34,0,0,0,.12.29.61.61,0,0,0,.37.09h1.61a.24.24,0,0,1,.29.28v.93a.25.25,0,0,1-.29.29Z"/>
                  <path className="cls-30" d="M174.67,265a2,2,0,0,1-.1.66,1.16,1.16,0,0,1-.39.5,2.07,2.07,0,0,1-.76.33,5.88,5.88,0,0,1-1.23.11,6,6,0,0,1-1.23-.11,2.12,2.12,0,0,1-.77-.33,1.14,1.14,0,0,1-.38-.5,2,2,0,0,1-.11-.66v-2.67a2,2,0,0,1,.11-.66,1.16,1.16,0,0,1,.38-.51,2.12,2.12,0,0,1,.77-.33,6,6,0,0,1,1.23-.11,5.88,5.88,0,0,1,1.23.11,2.07,2.07,0,0,1,.76.33,1.19,1.19,0,0,1,.39.51,2,2,0,0,1,.1.66Zm-3.15-.28a.34.34,0,0,0,.12.26,1.43,1.43,0,0,0,1.1,0,.34.34,0,0,0,.12-.26V262.6a.34.34,0,0,0-.12-.26,1.56,1.56,0,0,0-1.1,0,.34.34,0,0,0-.12.26Z"/>
                  <path className="cls-30" d="M177,266.48h-1.19a.32.32,0,0,1-.22-.07.29.29,0,0,1-.07-.22v-5.08a.29.29,0,0,1,.07-.22.32.32,0,0,1,.22-.07h1.29a.37.37,0,0,1,.21.06.45.45,0,0,1,.15.18l.73,1.28a.64.64,0,0,0,.06.1.11.11,0,0,0,.08,0h.07a.11.11,0,0,0,.08,0l.07-.1.71-1.28a.71.71,0,0,1,.15-.18.38.38,0,0,1,.22-.06h1.29a.3.3,0,0,1,.22.07.29.29,0,0,1,.07.22v5.08a.29.29,0,0,1-.07.22.3.3,0,0,1-.22.07h-1.23a.25.25,0,0,1-.29-.29v-2.76l-.47.86a.46.46,0,0,1-.17.19.49.49,0,0,1-.25.06h-.32a.5.5,0,0,1-.26-.06.53.53,0,0,1-.17-.19l-.47-.86v2.76a.29.29,0,0,1-.07.22A.32.32,0,0,1,177,266.48Z"/>
                  <path className="cls-30" d="M183.57,266.48h-1.19a.3.3,0,0,1-.22-.07.29.29,0,0,1-.07-.22v-5.08a.29.29,0,0,1,.07-.22.3.3,0,0,1,.22-.07h1.28a.38.38,0,0,1,.22.06.71.71,0,0,1,.15.18l.72,1.28.07.1a.11.11,0,0,0,.08,0H185a.11.11,0,0,0,.08,0,.64.64,0,0,0,.06-.1l.72-1.28a.55.55,0,0,1,.15-.18.37.37,0,0,1,.21-.06h1.3a.32.32,0,0,1,.22.07.29.29,0,0,1,.07.22v5.08a.29.29,0,0,1-.07.22.32.32,0,0,1-.22.07h-1.23a.32.32,0,0,1-.22-.07.29.29,0,0,1-.07-.22v-2.76l-.47.86a.53.53,0,0,1-.17.19.5.5,0,0,1-.26.06h-.32a.49.49,0,0,1-.25-.06.46.46,0,0,1-.17-.19l-.47-.86v2.76a.25.25,0,0,1-.29.29Z"/>
                  <path className="cls-30" d="M193.59,265a1.76,1.76,0,0,1-.11.66,1.14,1.14,0,0,1-.38.5,2.07,2.07,0,0,1-.76.33,6.88,6.88,0,0,1-2.45,0,2,2,0,0,1-.75-.33,1.09,1.09,0,0,1-.39-.5,2,2,0,0,1-.11-.66v-3.88a.25.25,0,0,1,.29-.29h1.26a.25.25,0,0,1,.29.29v3.58a.34.34,0,0,0,.12.26.88.88,0,0,0,.53.11.92.92,0,0,0,.55-.11.34.34,0,0,0,.12-.26v-3.58a.29.29,0,0,1,.07-.22.32.32,0,0,1,.22-.07h1.21a.3.3,0,0,1,.22.07.29.29,0,0,1,.07.22Z"/>
                  <path className="cls-30" d="M196,266.48h-1.2a.35.35,0,0,1-.23-.07.34.34,0,0,1-.06-.22v-5.08a.34.34,0,0,1,.06-.22.35.35,0,0,1,.23-.07h.83a.67.67,0,0,1,.26.05.63.63,0,0,1,.21.17l1.55,1.74v-1.67a.25.25,0,0,1,.29-.29h1.2a.32.32,0,0,1,.22.07.29.29,0,0,1,.07.22v5.08a.29.29,0,0,1-.07.22.32.32,0,0,1-.22.07h-1.2a.25.25,0,0,1-.29-.29v-1l-1.37-1.63v2.59a.24.24,0,0,1-.28.29Z"/>
                  <path className="cls-30" d="M200.44,266.48a.24.24,0,0,1-.28-.29v-.93a.23.23,0,0,1,.28-.28h.8v-2.64h-.8a.24.24,0,0,1-.28-.28v-.95a.24.24,0,0,1,.28-.29h3.42a.24.24,0,0,1,.28.29v.95a.24.24,0,0,1-.28.28h-.78V265h.78a.23.23,0,0,1,.28.28v.93a.24.24,0,0,1-.28.29Z"/>
                  <path className="cls-30" d="M207.65,266.48h-1.3a.24.24,0,0,1-.28-.29v-3.85h-1.18a.28.28,0,0,1-.22-.07.25.25,0,0,1-.07-.21v-.95a.29.29,0,0,1,.07-.22.32.32,0,0,1,.22-.07h4.23a.24.24,0,0,1,.28.29v.95a.24.24,0,0,1-.28.28h-1.18v3.85a.29.29,0,0,1-.07.22A.32.32,0,0,1,207.65,266.48Z"/>
                  <path className="cls-30" d="M212.9,266.48h-1.29a.3.3,0,0,1-.22-.07.29.29,0,0,1-.07-.22v-1l-1.23-1.61a1.56,1.56,0,0,1-.24-.42,1.85,1.85,0,0,1-.06-.55v-1.5a.29.29,0,0,1,.07-.22.3.3,0,0,1,.22-.07h1.23a.25.25,0,0,1,.29.29v1.28a1,1,0,0,0,0,.17.25.25,0,0,0,.06.16l.37.57a.21.21,0,0,0,.08.1.2.2,0,0,0,.11,0h.08a.2.2,0,0,0,.11,0,.21.21,0,0,0,.08-.1l.37-.57a.25.25,0,0,0,.06-.16,1,1,0,0,0,0-.17v-1.28a.25.25,0,0,1,.29-.29h1.19a.3.3,0,0,1,.22.07.29.29,0,0,1,.07.22v1.5a1.85,1.85,0,0,1-.06.55,1.56,1.56,0,0,1-.24.42l-1.22,1.62v1a.29.29,0,0,1-.07.22A.32.32,0,0,1,212.9,266.48Z"/>
                </g>
                <g id="work"
                   onClick={() => this.props.mapClick('work')}
                   className={workClassNames}>
                  <rect id="work_bg" data-name="work bg" className="cls-13" x="219.33" y="51.48" width="108.79" height="96.88"/>
                  <path className="cls-31" d="M282,130.17,241,154.94l96.44,54.27-53.62,30.6-34-18.56"/>
                  <circle id="work_dot" data-name="work dot" className="cls-32" cx="249.72" cy="221.02" r="1"/>
                  <g id="work_color" data-name="work color">
                    <polygon className="cls-33" points="271.21 123.03 271.21 77.19 292.95 89.49 292.95 135.33 271.21 123.03"/>
                    <path className="cls-17" d="M271.52,77.72l21.12,12V134.8l-21.12-12V77.72m-.62-1.07v46.56l22.36,12.66V89.31L270.9,76.65Z"/>
                    <polygon className="cls-34" points="284.52 116.44 284.52 112.23 288.24 114.34 288.24 118.54 284.52 116.44"/>
                    <path className="cls-35" d="M284.68,112.5l3.41,1.93v3.85l-3.41-1.93V112.5m-.31-.53v4.56l4,2.28v-4.56l-4-2.28Z"/>
                    <polygon className="cls-34" points="275.91 111.56 275.91 107.36 279.63 109.46 279.63 113.67 275.91 111.56"/>
                    <path className="cls-35" d="M276.07,107.63l3.41,1.92v3.85l-3.41-1.93v-3.84m-.31-.54v4.56l4,2.29v-4.57l-4-2.28Z"/>
                    <polygon className="cls-34" points="284.52 105.89 284.52 101.68 288.24 103.79 288.24 107.99 284.52 105.89"/>
                    <path className="cls-35" d="M284.68,102l3.41,1.93v3.84l-3.41-1.93V102m-.31-.54V106l4,2.28V103.7l-4-2.29Z"/>
                    <polygon className="cls-34" points="275.91 101.01 275.91 96.81 279.63 98.91 279.63 103.12 275.91 101.01"/>
                    <path className="cls-35" d="M276.07,97.08,279.48,99v3.85l-3.41-1.93V97.08m-.31-.54v4.56l4,2.28V98.82l-4-2.28Z"/>
                    <polygon className="cls-34" points="284.52 95.33 284.52 91.13 288.24 93.23 288.24 97.44 284.52 95.33"/>
                    <path className="cls-35" d="M284.68,91.4l3.41,1.93v3.84l-3.41-1.93V91.4m-.31-.54v4.56l4,2.29V93.14l-4-2.28Z"/>
                    <polygon className="cls-34" points="275.91 90.46 275.91 86.26 279.63 88.36 279.63 92.57 275.91 90.46"/>
                    <path className="cls-35" d="M276.07,86.52l3.41,1.93V92.3l-3.41-1.93V86.52m-.31-.53v4.56l4,2.28V88.27l-4-2.28Z"/>
                    <polygon className="cls-35" points="315.61 123.21 293.26 135.87 293.26 89.31 315.61 76.65 315.61 123.21"/>
                    <polygon className="cls-35" points="280.02 128.17 280.02 120.6 284.14 122.93 284.14 130.5 280.02 128.17"/>
                    <path className="cls-35" d="M280.18,120.86,284,123v7.21l-3.8-2.15v-7.22m-.31-.53v7.93l4.42,2.51v-7.93l-4.42-2.51Z"/>
                    <polygon className="cls-35" points="293.23 63.98 315.61 76.65 293.26 89.31 270.9 76.65 293.23 63.98"/>
                  </g>
                  <g id="work_gray" data-name="work gray">
                    <polygon className="cls-9" points="271.4 122.92 271.4 77.51 292.76 89.6 292.76 135.01 271.4 122.92"/>
                    <path className="cls-10" d="M271.9,78.37l20.36,11.52v44.26L271.9,122.63V78.37m-1-1.72v46.56l22.36,12.66V89.31L270.9,76.65Z"/>
                    <polygon className="cls-9" points="284.54 116.43 284.54 112.27 288.23 114.35 288.23 118.51 284.54 116.43"/>
                    <path className="cls-11" d="M284.72,112.57l3.33,1.88v3.76l-3.33-1.89v-3.75m-.35-.6v4.56l4,2.28v-4.56l-4-2.28Z"/>
                    <polygon className="cls-9" points="275.93 111.55 275.93 107.39 279.62 109.47 279.62 113.64 275.93 111.55"/>
                    <path className="cls-11" d="M276.11,107.69l3.33,1.89v3.76l-3.33-1.89v-3.76m-.35-.6v4.56l4,2.29v-4.57l-4-2.28Z"/>
                    <polygon className="cls-9" points="284.54 105.87 284.54 101.72 288.23 103.8 288.23 107.96 284.54 105.87"/>
                    <path className="cls-11" d="M284.72,102l3.33,1.89v3.76l-3.33-1.89V102m-.35-.6V106l4,2.28V103.7l-4-2.29Z"/>
                    <polygon className="cls-9" points="275.93 101 275.93 96.84 279.62 98.92 279.62 103.08 275.93 101"/>
                    <path className="cls-11" d="M276.11,97.14,279.44,99v3.75l-3.33-1.88V97.14m-.35-.6v4.56l4,2.28V98.82l-4-2.28Z"/>
                    <polygon className="cls-9" points="284.54 95.32 284.54 91.16 288.23 93.25 288.23 97.41 284.54 95.32"/>
                    <path className="cls-11" d="M284.72,91.46l3.33,1.89v3.76l-3.33-1.89V91.46m-.35-.6v4.56l4,2.29V93.14l-4-2.28Z"/>
                    <polygon className="cls-9" points="275.93 90.45 275.93 86.29 279.62 88.37 279.62 92.53 275.93 90.45"/>
                    <path className="cls-11" d="M276.11,86.59l3.33,1.88v3.76l-3.33-1.88V86.59m-.35-.6v4.56l4,2.28V88.27l-4-2.28Z"/>
                    <polygon className="cls-10" points="293.52 89.46 315.35 77.11 315.35 123.06 293.52 135.41 293.52 89.46"/>
                    <path className="cls-11" d="M315.08,77.56V122.9L293.79,135V89.62l21.29-12.06m.53-.91L293.26,89.31v46.56l22.35-12.66V76.65Z"/>
                    <polygon className="cls-9" points="280.04 128.16 280.04 120.63 284.12 122.94 284.12 130.47 280.04 128.16"/>
                    <path className="cls-11" d="M280.22,120.93l3.72,2.11v7.13l-3.72-2.11v-7.13m-.35-.6v7.93l4.42,2.51v-7.93l-4.42-2.51Z"/>
                    <polygon className="cls-10" points="271.44 76.65 293.23 64.29 315.08 76.65 293.26 89 271.44 76.65"/>
                    <path className="cls-11" d="M293.23,64.59l21.31,12.06-21.28,12L272,76.65l21.25-12.06m0-.61L270.9,76.65l22.36,12.66,22.35-12.66L293.23,64Z"/>
                  </g>
                  <g id="work_title" data-name="work title">
                    <path className="cls-35" d="M236.12,103.4h1.22a.34.34,0,0,1,.22.07.29.29,0,0,1,.07.22v3.1c0,.18,0,.33,0,.48a2.07,2.07,0,0,1-.13.52l-.4,1.08a.72.72,0,0,1-.12.21.29.29,0,0,1-.21.08h-1.09a.59.59,0,0,1-.23,0,.28.28,0,0,1-.14-.19l-.38-1.3a.17.17,0,0,0-.06-.11.11.11,0,0,0-.08,0h-.08a.11.11,0,0,0-.08,0,.17.17,0,0,0-.06.11l-.39,1.3a.29.29,0,0,1-.12.18.38.38,0,0,1-.22.06h-1.08a.3.3,0,0,1-.22-.08.46.46,0,0,1-.14-.21l-.4-1.1a2.07,2.07,0,0,1-.13-.52c0-.14,0-.3,0-.47v-3.09a.29.29,0,0,1,.07-.22.32.32,0,0,1,.22-.07h1.26a.32.32,0,0,1,.22.07.29.29,0,0,1,.07.22v2.94l.48-1a.42.42,0,0,1,.17-.2.67.67,0,0,1,.26-.05h.34a.67.67,0,0,1,.26.05.42.42,0,0,1,.17.2l.48,1v-2.94a.29.29,0,0,1,.07-.22A.32.32,0,0,1,236.12,103.4Z"/>
                    <path className="cls-35" d="M243.46,107.65a2,2,0,0,1-.11.67,1.16,1.16,0,0,1-.4.51,2,2,0,0,1-.77.33,6.62,6.62,0,0,1-2.51,0,2.18,2.18,0,0,1-.78-.33,1.07,1.07,0,0,1-.39-.51,2,2,0,0,1-.11-.67v-2.73a2,2,0,0,1,.11-.67,1.07,1.07,0,0,1,.39-.51,2,2,0,0,1,.78-.34,6.62,6.62,0,0,1,2.51,0,1.9,1.9,0,0,1,.77.34,1.16,1.16,0,0,1,.4.51,2,2,0,0,1,.11.67Zm-3.22-.29a.34.34,0,0,0,.12.27,1,1,0,0,0,.57.11,1,1,0,0,0,.56-.11.34.34,0,0,0,.12-.27v-2.15a.34.34,0,0,0-.12-.26,1,1,0,0,0-.56-.11,1,1,0,0,0-.57.11.34.34,0,0,0-.12.26Z"/>
                    <path className="cls-35" d="M245.84,109.16h-1.26a.28.28,0,0,1-.22-.07.26.26,0,0,1-.07-.22v-5.18a.29.29,0,0,1,.07-.22.32.32,0,0,1,.22-.07h2.72a1.72,1.72,0,0,1,1.26.37,1.37,1.37,0,0,1,.35,1v.37a1.41,1.41,0,0,1-.11.57.59.59,0,0,1-.35.34,1.08,1.08,0,0,1,.71.35,1.13,1.13,0,0,1,.28.81v1.69a.25.25,0,0,1-.3.29h-1.26a.25.25,0,0,1-.3-.29v-1.22a.38.38,0,0,0-.07-.26c0-.06-.12-.08-.24-.08h-1.14v1.56a.26.26,0,0,1-.07.22A.28.28,0,0,1,245.84,109.16Zm.29-4.32v1h.61a.33.33,0,0,0,.29-.11.45.45,0,0,0,.07-.27v-.27A.45.45,0,0,0,247,105a.33.33,0,0,0-.29-.11Z"/>
                    <path className="cls-35" d="M251.7,109.16h-1.26a.28.28,0,0,1-.22-.07.26.26,0,0,1-.07-.22v-5.18a.29.29,0,0,1,.07-.22.32.32,0,0,1,.22-.07h1.26a.35.35,0,0,1,.23.07.29.29,0,0,1,.07.22v1.84h.33l.84-1.86a.38.38,0,0,1,.15-.21.49.49,0,0,1,.25-.06h1.26a.26.26,0,0,1,.19.07.2.2,0,0,1,0,.2l-.83,1.86a1,1,0,0,1,.73.32,1.08,1.08,0,0,1,.27.77v2.25a.26.26,0,0,1-.07.22.28.28,0,0,1-.22.07h-1.26a.28.28,0,0,1-.22-.07.26.26,0,0,1-.07-.22v-1.56a.32.32,0,0,0-.07-.2.27.27,0,0,0-.19-.07H252v1.83a.26.26,0,0,1-.07.22A.3.3,0,0,1,251.7,109.16Z"/>
                  </g>
                </g>
                <g id="school"
                   onClick={() => this.props.mapClick('school')}
                   className={schoolClassNames}>
                  <rect id="school_bg" data-name="school bg" className="cls-13" x="18.95" y="70.02" width="144.11" height="96.88"/>
                  <path className="cls-36" d="M153.13,198.73V165.35l-13.46-7.87L82,190.79l-34.49-20,47.14-27.65"/>
                  <g id="school_color" data-name="school color">
                    <polygon className="cls-37" points="132.61 143.63 113.97 154.4 113.97 127.56 132.69 116.96 132.61 143.63"/>
                    <polygon className="cls-37" points="122.77 111.25 132.69 116.96 113.97 127.56 104.08 121.85 122.77 111.25"/>
                    <polygon className="cls-37" points="94.81 92.08 111.21 82.52 122.77 97.33 122.77 111.26 104.12 121.81 104.12 107.88 94.81 92.08"/>
                    <polygon className="cls-38" points="75.68 132.01 75.68 106.2 85.78 111.78 85.78 97.48 94.7 92.5 103.81 107.97 103.81 121.99 113.66 127.73 113.66 153.87 75.68 132.01"/>
                    <path className="cls-39" d="M94.58,92.91l8.92,15.14v14.12l.31.18,9.54,5.56v25.42L76,131.83v-25.1l9.18,5.07.92.51V97.66l8.49-4.75m.23-.84-9.37,5.24,0,0v13.93l-10.1-5.59v26.52L114,154.4V127.55l-9.85-5.74V107.88L94.81,92.07Z"/>
                    <polygon className="cls-40" points="81.8 131.76 81.8 127.09 85.94 129.43 85.94 134.1 81.8 131.76"/>
                    <path className="cls-37" d="M82.06,127.53l3.62,2.05v4.09l-3.62-2v-4.09m-.51-.87v5.25l4.64,2.63v-5.26l-4.64-2.62Z"/>
                    <polygon className="cls-40" points="103.46 143.86 103.46 139.19 107.59 141.53 107.59 146.2 103.46 143.86"/>
                    <path className="cls-37" d="M103.71,139.63l3.63,2.05v4.08l-3.63-2v-4.08m-.51-.88V144l4.65,2.63v-5.26l-4.65-2.63Z"/>
                    <polygon className="cls-40" points="81.8 121 81.8 116.33 85.94 118.67 85.94 123.34 81.8 121"/>
                    <path className="cls-37" d="M82.06,116.77l3.62,2v4.08l-3.62-2.05v-4.08m-.51-.88v5.26l4.64,2.63v-5.26l-4.64-2.63Z"/>
                    <polygon className="cls-40" points="103.46 133.13 103.46 128.46 107.59 130.8 107.59 135.47 103.46 133.13"/>
                    <path className="cls-37" d="M103.71,128.9l3.63,2V135L103.71,133V128.9m-.51-.88v5.26l4.65,2.63v-5.26L103.2,128Z"/>
                    <polygon className="cls-40" points="92.63 127.06 92.63 122.39 96.76 124.73 96.76 129.4 92.63 127.06"/>
                    <path className="cls-37" d="M92.89,122.82l3.62,2.05V129l-3.62-2.05v-4.09m-.51-.87v5.26L97,129.84v-5.26L92.38,122Z"/>
                    <polygon className="cls-37" points="92.38 141.62 92.38 132.95 96.97 135.55 96.97 144.21 92.38 141.62"/>
                    <path className="cls-37" d="M92.63,133.39l4.08,2.31v8.07l-4.08-2.3v-8.08m-.51-.88v9.25l5.1,2.89V135.4l-5.1-2.89Z"/>
                    <path className="cls-41" d="M96.7,115.07a3.85,3.85,0,0,1-1.88-.57,9.43,9.43,0,0,1-4.36-7.41c0-1.78.86-2.88,2.24-2.88a3.88,3.88,0,0,1,1.87.56,9.45,9.45,0,0,1,4.37,7.41C98.94,114,98.08,115.07,96.7,115.07Z"/>
                    <path className="cls-37" d="M92.7,104.46h0a3.7,3.7,0,0,1,1.75.54,9.15,9.15,0,0,1,4.23,7.18c0,1.65-.74,2.63-2,2.63a3.65,3.65,0,0,1-1.75-.53,9.14,9.14,0,0,1-4.23-7.19c0-1.64.74-2.63,2-2.63m0-.51c-1.48,0-2.49,1.15-2.49,3.14a9.69,9.69,0,0,0,4.49,7.63,4.1,4.1,0,0,0,2,.6c1.48,0,2.49-1.15,2.49-3.14a9.71,9.71,0,0,0-4.49-7.63,4.1,4.1,0,0,0-2-.6Z"/>
                    <polygon className="cls-39" points="91.94 108.7 95.31 110.62 95.31 106.72 94.44 106.23 94.44 109.14 91.94 107.72 91.94 108.7"/>
                    <polygon className="cls-37" points="85.47 99.93 85.47 111.23 85.45 111.24 82.66 109.71 75.37 105.66 85.47 99.93"/>
                  </g>
                  <circle id="school_dot" data-name="school dot" className="cls-41" cx="153.12" cy="199.07" r="1"/>
                  <g id="school_gray" data-name="school gray">
                    <polygon className="cls-10" points="114.24 127.71 132.43 117.42 132.34 143.47 114.24 153.94 114.24 127.71"/>
                    <path className="cls-11" d="M132.16,117.87l-.08,25.45L114.5,153.48V127.86l17.66-10m.53-.91L114,127.56V154.4l18.64-10.77.08-26.67Z"/>
                    <polygon className="cls-10" points="104.61 121.86 122.77 111.55 132.16 116.96 113.97 127.25 104.61 121.86"/>
                    <path className="cls-11" d="M122.77,111.86l8.85,5.1L114,127l-8.83-5.09,17.62-10m0-.61-18.69,10.6,9.89,5.71L132.69,117l-9.92-5.71Z"/>
                    <path className="cls-10" d="M104.38,107.88a.28.28,0,0,0,0-.13L95.17,92.17l16-9.31L122.5,97.42V111.1l-18.12,10.26Z"/>
                    <path className="cls-11" d="M111.08,83.21l11.16,14.3V111l-17.59,10v-13a.64.64,0,0,0-.07-.27l-9-15.35,15.54-9.05m.13-.69-16.4,9.55,9.31,15.81v13.93l18.65-10.55V97.33L111.21,82.52Z"/>
                    <polygon className="cls-9" points="75.62 132.04 75.62 106.1 85.72 111.68 85.72 97.44 94.72 92.41 103.87 107.95 103.87 121.96 113.72 127.7 113.72 153.97 75.62 132.04"/>
                    <path className="cls-10" d="M94.63,92.75l9,15.27V122.1l.25.14,9.6,5.6v25.7L75.87,131.9V106.52l9.36,5.18.74.41V97.59l8.66-4.84m.18-.68-9.37,5.24,0,0v13.93l-10.1-5.59v26.52L114,154.4V127.56l-9.85-5.75V107.88L94.81,92.07Z"/>
                    <polygon className="cls-9" points="81.72 131.81 81.72 126.96 86.02 129.39 86.02 134.24 81.72 131.81"/>
                    <path className="cls-11" d="M81.9,127.26l3.94,2.23v4.45l-3.94-2.23v-4.45m-.35-.6v5.25l4.64,2.63v-5.26l-4.64-2.62Z"/>
                    <polygon className="cls-9" points="103.38 143.91 103.38 139.05 107.67 141.48 107.67 146.34 103.38 143.91"/>
                    <path className="cls-11" d="M103.55,139.35l4,2.23V146l-4-2.23v-4.46m-.35-.6V144l4.65,2.63v-5.26l-4.65-2.63Z"/>
                    <polygon className="cls-9" points="81.72 121.04 81.72 116.19 86.02 118.62 86.02 123.48 81.72 121.04"/>
                    <path className="cls-11" d="M81.9,116.49l3.94,2.23v4.46l-3.94-2.24v-4.45m-.35-.6v5.26l4.64,2.63v-5.26l-4.64-2.63Z"/>
                    <polygon className="cls-9" points="103.38 133.18 103.38 128.32 107.67 130.75 107.67 135.61 103.38 133.18"/>
                    <path className="cls-11" d="M103.55,128.62l4,2.24v4.45l-4-2.23v-4.46m-.35-.6v5.26l4.65,2.63v-5.26L103.2,128Z"/>
                    <polygon className="cls-9" points="92.55 127.1 92.55 122.25 96.84 124.68 96.84 129.53 92.55 127.1"/>
                    <path className="cls-11" d="M92.73,122.55l3.94,2.23v4.45L92.73,127v-4.45m-.35-.6v5.26L97,129.84v-5.26L92.38,122Z"/>
                    <polygon className="cls-9" points="92.3 141.66 92.3 132.81 97.05 135.5 97.05 144.35 92.3 141.66"/>
                    <path className="cls-11" d="M92.47,133.11l4.4,2.49v8.45l-4.4-2.49v-8.45m-.35-.6v9.25l5.1,2.89V135.4l-5.1-2.89Z"/>
                    <path className="cls-29" d="M94.7,104.55a9.71,9.71,0,0,1,4.49,7.63c0,2.81-2,3.95-4.49,2.54a9.69,9.69,0,0,1-4.49-7.62C90.21,104.29,92.22,103.15,94.7,104.55Z"/>
                    <polygon className="cls-9" points="91.94 108.7 95.31 110.62 95.31 106.72 94.44 106.23 94.44 109.14 91.94 107.72 91.94 108.7"/>
                    <polygon className="cls-10" points="82.79 109.47 75.91 105.66 85.21 100.38 85.21 110.81 82.79 109.47"/>
                    <path className="cls-11" d="M84.94,100.84v9.52l-2-1.12-6.47-3.59,8.49-4.81m.53-.91-10.1,5.73,7.29,4,2.79,1.53h0V99.93Z"/>
                  </g>
                  <g id="school_title" data-name="school title">
                    <path className="cls-41" d="M34.89,123.74H32.31a.35.35,0,0,1-.23-.07.34.34,0,0,1-.07-.22v-.94a.34.34,0,0,1,.07-.22.35.35,0,0,1,.23-.07H34.5a.2.2,0,0,0,.16-.06.25.25,0,0,0,.05-.17.3.3,0,0,0-.05-.2.24.24,0,0,0-.16-.07l-1.22-.17a1.71,1.71,0,0,1-.92-.37,1.18,1.18,0,0,1-.35-1v-.84a1.26,1.26,0,0,1,.45-1A1.93,1.93,0,0,1,33.7,118H36a.32.32,0,0,1,.22.06.29.29,0,0,1,.07.22v1a.29.29,0,0,1-.07.22.32.32,0,0,1-.22.06h-1.9a.24.24,0,0,0-.17.07.25.25,0,0,0-.05.17.27.27,0,0,0,.05.18.25.25,0,0,0,.17.08l1.21.16a1.79,1.79,0,0,1,.92.37,1.15,1.15,0,0,1,.35,1v.84a1.26,1.26,0,0,1-.45,1A1.86,1.86,0,0,1,34.89,123.74Z"/>
                    <path className="cls-41" d="M41.17,123.74h-2a2.44,2.44,0,0,1-1.52-.4,1.5,1.5,0,0,1-.49-1.24v-2.48a1.5,1.5,0,0,1,.49-1.24,2.38,2.38,0,0,1,1.52-.4h2a.32.32,0,0,1,.22.06.29.29,0,0,1,.07.22v1a.25.25,0,0,1-.29.29H39.53a.59.59,0,0,0-.38.1.38.38,0,0,0-.12.3v1.9a.35.35,0,0,0,.12.3.67.67,0,0,0,.38.09h1.64a.25.25,0,0,1,.29.29v1a.29.29,0,0,1-.07.22A.32.32,0,0,1,41.17,123.74Z"/>
                    <path className="cls-41" d="M43.74,123.74H42.48a.35.35,0,0,1-.23-.07.29.29,0,0,1-.07-.22v-5.19a.29.29,0,0,1,.07-.22.35.35,0,0,1,.23-.06h1.26A.32.32,0,0,1,44,118a.29.29,0,0,1,.07.22V120H45.4v-1.74a.29.29,0,0,1,.07-.22.32.32,0,0,1,.22-.06H47a.34.34,0,0,1,.22.06.29.29,0,0,1,.07.22v5.19a.29.29,0,0,1-.07.22.34.34,0,0,1-.22.07H45.69a.32.32,0,0,1-.22-.07.29.29,0,0,1-.07-.22v-1.78H44v1.78a.29.29,0,0,1-.07.22A.32.32,0,0,1,43.74,123.74Z"/>
                    <path className="cls-41" d="M53.15,122.22a2,2,0,0,1-.11.67,1.07,1.07,0,0,1-.39.51,2,2,0,0,1-.78.34,5.39,5.39,0,0,1-1.25.12,5.54,5.54,0,0,1-1.26-.12,2,2,0,0,1-.78-.34,1.21,1.21,0,0,1-.39-.51,2,2,0,0,1-.11-.67V119.5a2,2,0,0,1,.11-.67,1.3,1.3,0,0,1,.39-.52,2.18,2.18,0,0,1,.78-.33,5.54,5.54,0,0,1,1.26-.12,5.39,5.39,0,0,1,1.25.12,2.18,2.18,0,0,1,.78.33,1.14,1.14,0,0,1,.39.52,2,2,0,0,1,.11.67Zm-3.22-.28a.35.35,0,0,0,.13.26,1.48,1.48,0,0,0,1.12,0,.35.35,0,0,0,.13-.26v-2.16a.36.36,0,0,0-.13-.26,1.62,1.62,0,0,0-1.12,0,.36.36,0,0,0-.13.26Z"/>
                    <path className="cls-41" d="M59,122.22a2,2,0,0,1-.11.67,1.07,1.07,0,0,1-.39.51,2,2,0,0,1-.78.34,5.39,5.39,0,0,1-1.25.12,5.54,5.54,0,0,1-1.26-.12,2,2,0,0,1-.78-.34,1.21,1.21,0,0,1-.39-.51,2,2,0,0,1-.11-.67V119.5a2,2,0,0,1,.11-.67,1.3,1.3,0,0,1,.39-.52,2.18,2.18,0,0,1,.78-.33,5.54,5.54,0,0,1,1.26-.12,5.39,5.39,0,0,1,1.25.12,2.18,2.18,0,0,1,.78.33,1.14,1.14,0,0,1,.39.52,2,2,0,0,1,.11.67Zm-3.21-.28a.34.34,0,0,0,.12.26,1.48,1.48,0,0,0,1.12,0,.32.32,0,0,0,.13-.26v-2.16a.33.33,0,0,0-.13-.26,1.62,1.62,0,0,0-1.12,0,.35.35,0,0,0-.12.26Z"/>
                    <path className="cls-41" d="M64.24,123.74H60.09a.35.35,0,0,1-.23-.07.34.34,0,0,1-.07-.22v-5.19a.34.34,0,0,1,.07-.22.35.35,0,0,1,.23-.06h1.26a.35.35,0,0,1,.23.06.34.34,0,0,1,.07.22v4h1.11v-1.29a.32.32,0,0,1,.07-.22.3.3,0,0,1,.23-.07h1.18a.32.32,0,0,1,.23.07.32.32,0,0,1,.06.22v2.52a.34.34,0,0,1-.06.22A.37.37,0,0,1,64.24,123.74Z"/>
                  </g>
                </g>
                <g id="retirement"
                   onClick={() => this.props.mapClick('retirement')}
                   className={retirementClassNames}>
                  <rect id="retirement_bg" data-name="retirement bg" className="cls-13" x="-0.36" y="316.48" width="141.58" height="80.9"/>
                  <path className="cls-42" d="M92.48,372.14l-37.2,21.65L29.65,378.7V356.77l45.72-27-36-19.91L89.3,281.36l22.09,12.4"/>
                  <g id="retirement_color" data-name="retirement color">
                    <polygon className="cls-43" points="71.71 360.17 71.74 341.33 78.55 337.52 85.54 349.4 85.51 367.98 71.71 360.17"/>
                    <path className="cls-44" d="M78.44,337.94l6.79,11.54,0,18L72,360l0-18.48,6.39-3.57m.22-.83-7.23,4,0,19.2,14.42,8.16,0-19.2-7.19-12.2Z"/>
                    <polygon className="cls-45" points="85.85 349.31 99.81 341.36 92.62 329.15 78.66 337.11 85.85 349.31"/>
                    <polygon className="cls-46" points="77.02 349.9 77.02 346.28 80.23 348.1 80.23 351.72 77.02 349.9"/>
                    <path className="cls-45" d="M77.21,346.6,80,348.2v3.2l-2.83-1.61V346.6m-.38-.64V350l3.59,2V348l-3.59-2Z"/>
                    <polygon className="cls-45" points="76.77 362.96 76.77 356.06 80.48 358.16 80.48 365.06 76.77 362.96"/>
                    <path className="cls-45" d="M77,356.38l3.35,1.89v6.47L77,362.85v-6.47m-.37-.64v7.33l4.09,2.32v-7.34l-4.09-2.31Z"/>
                    <polygon className="cls-43" points="86.16 368.35 86.19 349.51 93 345.7 100 357.58 99.97 376.16 86.16 368.35"/>
                    <path className="cls-44" d="M92.89,346.12l6.8,11.54,0,18-13.19-7.47,0-18.47,6.39-3.57m.23-.84-7.24,4.05,0,19.2,14.43,8.16,0-19.2-7.19-12.21Z"/>
                    <polygon className="cls-45" points="100.31 357.49 114.27 349.54 107.08 337.33 93.12 345.28 100.31 357.49"/>
                    <polygon className="cls-46" points="91.48 358.08 91.48 354.46 94.68 356.27 94.68 359.9 91.48 358.08"/>
                    <path className="cls-45" d="M91.66,354.78l2.84,1.6v3.2L91.66,358v-3.19m-.37-.64v4.05l3.58,2v-4.06l-3.58-2Z"/>
                    <polygon className="cls-45" points="91.22 371.14 91.22 364.23 94.94 366.34 94.94 373.24 91.22 371.14"/>
                    <path className="cls-45" d="M91.41,364.56l3.34,1.89v6.47L91.41,371v-6.47m-.38-.65v7.34l4.1,2.31v-7.33L91,363.91Z"/>
                    <polygon className="cls-45" points="114.73 384.89 128.69 376.93 128.72 357.73 114.76 365.69 114.73 384.89"/>
                    <polygon className="cls-43" points="100.62 376.54 100.65 357.7 107.46 353.9 114.45 365.77 114.42 384.35 100.62 376.54"/>
                    <path className="cls-44" d="M107.35,354.32l6.79,11.54,0,18-13.18-7.46,0-18.47,6.39-3.57m.22-.84-7.23,4,0,19.2,14.42,8.17,0-19.2-7.19-12.21Z"/>
                    <polygon className="cls-45" points="114.76 365.69 128.72 357.73 121.53 345.53 107.57 353.48 114.76 365.69"/>
                    <polygon className="cls-46" points="105.93 366.28 105.93 362.65 109.14 364.47 109.14 368.09 105.93 366.28"/>
                    <path className="cls-45" d="M106.12,363l2.83,1.6v3.19l-2.83-1.6V363m-.38-.65v4.06l3.59,2v-4.05l-3.59-2Z"/>
                    <polygon className="cls-45" points="105.68 379.33 105.68 372.43 109.39 374.54 109.39 381.44 105.68 379.33"/>
                    <path className="cls-45" d="M105.86,372.75l3.35,1.89v6.48l-3.35-1.89v-6.48m-.37-.64v7.33l4.09,2.32v-7.33l-4.09-2.32Z"/>
                  </g>
                  <circle id="retirement_dot" data-name="retirement dot" className="cls-46" cx="111.11" cy="293.58" r="1"/>
                  <g id="retirement_gray" data-name="retirement gray">
                    <polygon className="cls-9" points="71.65 360.2 71.68 341.3 78.57 337.44 85.6 349.38 85.57 368.08 71.65 360.2"/>
                    <path className="cls-10" d="M78.48,337.78l6.87,11.67,0,18.21-13.42-7.6,0-18.62,6.55-3.66m.18-.67-7.23,4,0,19.2,14.42,8.16,0-19.2-7.19-12.2Z"/>
                    <polygon className="cls-10" points="79.03 337.2 92.53 329.51 99.45 341.26 85.95 348.95 79.03 337.2"/>
                    <path className="cls-11" d="M92.43,329.87l6.65,11.3-13,7.42L79.39,337.3l13-7.43m.19-.72-14,8,7.19,12.2,14-7.95-7.19-12.21Z"/>
                    <polygon className="cls-9" points="77.01 349.91 77.01 346.26 80.24 348.09 80.24 351.74 77.01 349.91"/>
                    <path className="cls-11" d="M77.18,346.56l2.89,1.63v3.25l-2.89-1.63v-3.25m-.35-.6V350l3.59,2V348l-3.59-2Z"/>
                    <polygon className="cls-9" points="76.75 362.97 76.75 356.04 80.5 358.15 80.5 365.09 76.75 362.97"/>
                    <path className="cls-11" d="M76.93,356.34l3.39,1.92v6.53l-3.39-1.92v-6.53m-.35-.6v7.33l4.09,2.32v-7.34l-4.09-2.31Z"/>
                    <polygon className="cls-9" points="86.1 368.38 86.13 349.47 93.03 345.62 100.06 357.56 100.03 376.26 86.1 368.38"/>
                    <path className="cls-10" d="M92.94,346l6.87,11.67,0,18.2-13.43-7.59,0-18.62L92.94,346m.18-.68-7.24,4.05,0,19.2,14.43,8.16,0-19.2-7.19-12.21Z"/>
                    <polygon className="cls-10" points="93.48 345.38 106.98 337.69 113.9 349.44 100.4 357.13 93.48 345.38"/>
                    <path className="cls-11" d="M106.89,338.05l6.65,11.29-13,7.43-6.65-11.29,13-7.43m.19-.72-14,7.95,7.19,12.21,14-7.95-7.19-12.21Z"/>
                    <polygon className="cls-9" points="91.46 358.09 91.46 354.44 94.7 356.27 94.7 359.92 91.46 358.09"/>
                    <path className="cls-11" d="M91.64,354.74l2.88,1.63v3.25L91.64,358v-3.25m-.35-.6v4.05l3.58,2v-4.06l-3.58-2Z"/>
                    <polygon className="cls-9" points="91.21 371.15 91.21 364.21 94.95 366.33 94.95 373.26 91.21 371.15"/>
                    <path className="cls-11" d="M91.38,364.51l3.4,1.92V373L91.38,371v-6.53m-.35-.6v7.34l4.1,2.31v-7.33L91,363.91Z"/>
                    <polygon className="cls-10" points="115.03 365.84 128.46 358.19 128.43 376.78 115 384.43 115.03 365.84"/>
                    <path className="cls-11" d="M128.19,358.65l0,18L115.26,384l0-18,12.9-7.35m.53-.92h0Zm0,0-14,8,0,19.2,14-8,0-19.2Z"/>
                    <polygon className="cls-9" points="100.56 376.58 100.59 357.67 107.48 353.82 114.51 365.75 114.48 384.46 100.56 376.58"/>
                    <path className="cls-10" d="M107.39,354.15l6.87,11.67,0,18.21-13.42-7.6,0-18.61,6.55-3.67m.18-.67-7.23,4,0,19.2,14.42,8.17,0-19.2-7.19-12.21Z"/>
                    <polygon className="cls-10" points="107.94 353.58 121.44 345.89 128.36 357.64 114.86 365.33 107.94 353.58"/>
                    <path className="cls-11" d="M121.34,346.25,128,357.54,115,365l-6.65-11.3,13-7.42m.19-.72-14,8,7.19,12.21,14-8-7.19-12.2Z"/>
                    <polygon className="cls-9" points="105.92 366.29 105.92 362.63 109.15 364.46 109.15 368.11 105.92 366.29"/>
                    <path className="cls-11" d="M106.09,362.93l2.89,1.63v3.25l-2.89-1.63v-3.25m-.35-.6v4.06l3.59,2v-4.05l-3.59-2Z"/>
                    <polygon className="cls-9" points="105.66 379.34 105.66 372.41 109.41 374.53 109.41 381.46 105.66 379.34"/>
                    <path className="cls-11" d="M105.84,372.71l3.39,1.92v6.53l-3.39-1.92v-6.53m-.35-.6v7.33l4.09,2.32v-7.33l-4.09-2.32Z"/>
                  </g>
                  <g id="retirement_title" data-name="retirement title">
                    <path className="cls-46" d="M77.45,398.88H76.19a.29.29,0,0,1-.22-.07.25.25,0,0,1-.07-.21v-5.19a.25.25,0,0,1,.29-.29h2.72a1.72,1.72,0,0,1,1.26.37,1.37,1.37,0,0,1,.35,1v.37a1.61,1.61,0,0,1-.1.57.63.63,0,0,1-.36.34,1.1,1.1,0,0,1,.72.35,1.17,1.17,0,0,1,.27.81v1.7a.25.25,0,0,1-.07.21.29.29,0,0,1-.22.07H79.49a.28.28,0,0,1-.22-.07.25.25,0,0,1-.07-.21v-1.23a.45.45,0,0,0-.07-.26.29.29,0,0,0-.24-.08H77.74v1.57a.25.25,0,0,1-.07.21A.28.28,0,0,1,77.45,398.88Zm.29-4.32v1h.62a.32.32,0,0,0,.28-.11.45.45,0,0,0,.07-.27v-.27a.45.45,0,0,0-.07-.27.32.32,0,0,0-.28-.11Z"/>
                    <path className="cls-46" d="M85.84,398.88H82.05a.28.28,0,0,1-.22-.07.25.25,0,0,1-.07-.21v-5.19a.29.29,0,0,1,.07-.22.28.28,0,0,1,.22-.07h3.79a.28.28,0,0,1,.22.07.29.29,0,0,1,.07.22v.94a.29.29,0,0,1-.07.22.32.32,0,0,1-.22.06H83.59v.62h1.59a.25.25,0,0,1,.29.29v.85a.26.26,0,0,1-.07.22.28.28,0,0,1-.22.07H83.59v.69h2.25a.25.25,0,0,1,.29.29v.94a.25.25,0,0,1-.07.21A.28.28,0,0,1,85.84,398.88Z"/>
                    <path className="cls-46" d="M89.67,398.88H88.34a.29.29,0,0,1-.22-.07.25.25,0,0,1-.07-.21v-3.93h-1.2a.32.32,0,0,1-.22-.07.29.29,0,0,1-.07-.22v-1a.29.29,0,0,1,.07-.22.28.28,0,0,1,.22-.07h4.31a.28.28,0,0,1,.22.07.29.29,0,0,1,.07.22v1a.29.29,0,0,1-.07.22.32.32,0,0,1-.22.07H90v3.93a.25.25,0,0,1-.07.21A.29.29,0,0,1,89.67,398.88Z"/>
                    <path className="cls-46" d="M92.2,398.88a.29.29,0,0,1-.22-.07.25.25,0,0,1-.07-.21v-1a.29.29,0,0,1,.07-.22.34.34,0,0,1,.22-.07H93v-2.68H92.2a.34.34,0,0,1-.22-.07.29.29,0,0,1-.07-.22v-1a.25.25,0,0,1,.29-.29h3.48a.32.32,0,0,1,.23.07.34.34,0,0,1,.07.22v1a.34.34,0,0,1-.07.22.37.37,0,0,1-.23.07h-.79v2.68h.79a.37.37,0,0,1,.23.07.34.34,0,0,1,.07.22v1a.29.29,0,0,1-.07.21.32.32,0,0,1-.23.07Z"/>
                    <path className="cls-46" d="M98.33,398.88H97.07a.32.32,0,0,1-.23-.07.29.29,0,0,1-.06-.21v-5.19a.34.34,0,0,1,.06-.22.32.32,0,0,1,.23-.07h2.72a1.72,1.72,0,0,1,1.26.37,1.37,1.37,0,0,1,.34,1v.37a1.42,1.42,0,0,1-.1.57.62.62,0,0,1-.35.34,1.08,1.08,0,0,1,.71.35,1.17,1.17,0,0,1,.27.81v1.7a.29.29,0,0,1-.06.21.32.32,0,0,1-.23.07h-1.26a.3.3,0,0,1-.23-.07.29.29,0,0,1-.07-.21v-1.23a.38.38,0,0,0-.07-.26c0-.06-.12-.08-.24-.08H98.62v1.57a.25.25,0,0,1-.07.21A.29.29,0,0,1,98.33,398.88Zm.29-4.32v1h.61a.34.34,0,0,0,.29-.11.45.45,0,0,0,.07-.27v-.27a.45.45,0,0,0-.07-.27.34.34,0,0,0-.29-.11Z"/>
                    <path className="cls-46" d="M106.71,398.88h-3.78a.3.3,0,0,1-.23-.07.29.29,0,0,1-.07-.21v-5.19a.34.34,0,0,1,.07-.22.3.3,0,0,1,.23-.07h3.78a.3.3,0,0,1,.23.07.29.29,0,0,1,.07.22v.94a.29.29,0,0,1-.07.22.35.35,0,0,1-.23.06h-2.25v.62h1.59a.3.3,0,0,1,.23.07.32.32,0,0,1,.07.22v.85a.25.25,0,0,1-.3.29h-1.59v.69h2.25a.3.3,0,0,1,.23.07.28.28,0,0,1,.07.22v.94a.25.25,0,0,1-.07.21A.3.3,0,0,1,106.71,398.88Z"/>
                    <path className="cls-46" d="M109.28,398.88h-1.22a.28.28,0,0,1-.22-.07.25.25,0,0,1-.07-.21v-5.19a.29.29,0,0,1,.07-.22.28.28,0,0,1,.22-.07h1.32a.37.37,0,0,1,.21.06.57.57,0,0,1,.16.18l.74,1.31.06.1a.11.11,0,0,0,.08,0h.08a.09.09,0,0,0,.07,0,.43.43,0,0,0,.07-.1l.73-1.31a.57.57,0,0,1,.16-.18.37.37,0,0,1,.21-.06h1.33a.25.25,0,0,1,.29.29v5.19a.25.25,0,0,1-.07.21.29.29,0,0,1-.22.07H112a.28.28,0,0,1-.22-.07.25.25,0,0,1-.07-.21v-2.82l-.49.87a.42.42,0,0,1-.17.2.5.5,0,0,1-.26.06h-.32a.5.5,0,0,1-.26-.06.51.51,0,0,1-.18-.2l-.48-.87v2.82a.25.25,0,0,1-.07.21A.28.28,0,0,1,109.28,398.88Z"/>
                    <path className="cls-46" d="M118.57,398.88h-3.78a.3.3,0,0,1-.23-.07.29.29,0,0,1-.07-.21v-5.19a.34.34,0,0,1,.07-.22.3.3,0,0,1,.23-.07h3.78a.3.3,0,0,1,.23.07.34.34,0,0,1,.07.22v.94a.34.34,0,0,1-.07.22.35.35,0,0,1-.23.06h-2.25v.62h1.59a.3.3,0,0,1,.23.07.32.32,0,0,1,.07.22v.85a.25.25,0,0,1-.3.29h-1.59v.69h2.25a.3.3,0,0,1,.23.07.32.32,0,0,1,.07.22v.94a.29.29,0,0,1-.07.21A.3.3,0,0,1,118.57,398.88Z"/>
                    <path className="cls-46" d="M121.14,398.88h-1.22a.32.32,0,0,1-.23-.07.29.29,0,0,1-.06-.21v-5.19a.34.34,0,0,1,.06-.22.32.32,0,0,1,.23-.07h.85a.67.67,0,0,1,.26.05.57.57,0,0,1,.22.17l1.58,1.77v-1.7a.34.34,0,0,1,.07-.22.3.3,0,0,1,.23-.07h1.22a.32.32,0,0,1,.23.07.34.34,0,0,1,.06.22v5.19a.29.29,0,0,1-.06.21.32.32,0,0,1-.23.07h-1.22a.3.3,0,0,1-.23-.07.29.29,0,0,1-.07-.21v-1L121.44,396v2.64a.29.29,0,0,1-.07.21A.3.3,0,0,1,121.14,398.88Z"/>
                    <path className="cls-46" d="M128.34,398.88H127a.32.32,0,0,1-.23-.07.29.29,0,0,1-.06-.21v-3.93h-1.2a.35.35,0,0,1-.23-.07.34.34,0,0,1-.07-.22v-1a.34.34,0,0,1,.07-.22.3.3,0,0,1,.23-.07h4.31a.25.25,0,0,1,.29.29v1a.29.29,0,0,1-.07.22.34.34,0,0,1-.22.07h-1.2v3.93a.29.29,0,0,1-.07.21A.32.32,0,0,1,128.34,398.88Z"/>
                  </g>
                </g>
                <g id="home"
                   onClick={() => this.props.mapClick('home')}
                   className={homeClassNames}>
                  <rect id="home_bg" data-name="home bg" className="cls-13" x="101.04" y="421.28" width="196.22" height="145.84"/>
                  <path className="cls-47" d="M189.71,533.12l-56.81,33L79.64,535.24V473.47l111.68-64.74V339.52"/>
                  <g id="home_color" data-name="home color">
                    <g id="home_color-2" data-name="home color">
                      <polygon className="cls-48" points="227.85 554.57 284.76 521.71 284.76 481.99 227.85 513.4 227.85 554.57"/>
                      <polygon className="cls-49" points="171.31 521.5 171.31 481.92 200.3 465.7 227.48 513.5 227.48 553.93 171.31 521.5"/>
                      <path className="cls-50" d="M200.16,466.2l26.94,47.4v39.68l-55.41-32V482.14l28.47-15.94m.28-1-29.5,16.51v40l56.91,32.86V513.4l-27.41-48.21Z"/>
                      <polygon className="cls-48" points="227.85 513.4 284.76 481.99 255.57 433.78 200.44 465.19 227.85 513.4"/>
                      <polygon className="cls-51" points="206.53 529.3 206.53 517.11 217.3 523.21 217.3 535.4 206.53 529.3"/>
                      <path className="cls-48" d="M206.71,517.43l10.4,5.89v11.76l-10.4-5.89V517.43m-.37-.64v12.62l11.15,6.31V523.1l-11.15-6.31Z"/>
                      <polygon className="cls-51" points="182.51 528.2 182.51 505.8 194.88 512.79 194.88 535.2 182.51 528.2"/>
                      <path className="cls-48" d="M182.7,506.12l12,6.78v22l-12-6.79v-22m-.38-.64v22.83l12.74,7.21V512.69l-12.74-7.21Z"/>
                    </g>
                  </g>
                  <circle id="home_dot" data-name="home dot" className="cls-52" cx="191.32" cy="339.52" r="1"/>
                  <g id="home_gray" data-name="home gray">
                    <polygon className="cls-10" points="228.12 513.56 284.5 482.44 284.5 521.56 228.12 554.11 228.12 513.56"/>
                    <path className="cls-11" d="M284.23,482.89v38.52l-55.85,32.25V513.72l55.85-30.83m.53-.9L227.85,513.4v41.17l56.91-32.86V482Z"/>
                    <polygon className="cls-9" points="171.44 521.42 171.44 482 200.25 465.87 227.35 513.53 227.35 553.71 171.44 521.42"/>
                    <path className="cls-10" d="M200.06,466.55l26.79,47.12v39.17l-54.91-31.7V482.29l28.12-15.74m.38-1.36-29.5,16.51v40l56.91,32.86V513.4l-27.41-48.21Z"/>
                    <polygon className="cls-10" points="200.8 465.29 255.47 434.14 284.39 481.9 227.95 513.04 200.8 465.29"/>
                    <path className="cls-11" d="M255.38,434.5,284,481.8l-56,30.89-26.89-47.3,54.22-30.89m.19-.72-55.13,31.41,27.41,48.21L284.76,482l-29.19-48.21Z"/>
                    <polygon className="cls-9" points="206.59 529.27 206.59 517.22 217.24 523.25 217.24 535.29 206.59 529.27"/>
                    <path className="cls-11" d="M206.84,517.65,217,523.39v11.47l-10.15-5.74V517.65m-.5-.86v12.62l11.15,6.31V523.1l-11.15-6.31Z"/>
                    <polygon className="cls-9" points="182.57 528.16 182.57 505.91 194.81 512.83 194.81 535.09 182.57 528.16"/>
                    <path className="cls-11" d="M182.82,506.34,194.56,513v21.68L182.82,528V506.34m-.5-.86v22.83l12.74,7.21V512.69l-12.74-7.21Z"/>
                  </g>
                  <g id="home_title" data-name="home title">
                    <path className="cls-48" d="M116.23,508.5H114.1a.57.57,0,0,1-.38-.11.54.54,0,0,1-.12-.37l0-8.72a.54.54,0,0,1,.11-.37.57.57,0,0,1,.38-.11h2.13a.57.57,0,0,1,.38.11.54.54,0,0,1,.11.37v2.92H119V499.3a.48.48,0,0,1,.12-.37.51.51,0,0,1,.38-.12h2.12a.51.51,0,0,1,.38.12.48.48,0,0,1,.12.37l0,8.71a.54.54,0,0,1-.11.37.52.52,0,0,1-.38.12H119.5a.48.48,0,0,1-.37-.11A.5.5,0,0,1,119,508v-3h-2.29v3a.5.5,0,0,1-.12.37A.53.53,0,0,1,116.23,508.5Z"/>
                    <path className="cls-48" d="M132,505.92a3.1,3.1,0,0,1-.18,1.12,1.87,1.87,0,0,1-.65.86,3.49,3.49,0,0,1-1.3.56,8.87,8.87,0,0,1-2.11.21,9.56,9.56,0,0,1-2.11-.19,3.56,3.56,0,0,1-1.31-.56,1.91,1.91,0,0,1-.67-.86,3.29,3.29,0,0,1-.18-1.12l0-4.59a3.33,3.33,0,0,1,.18-1.12,2,2,0,0,1,.66-.87,3.63,3.63,0,0,1,1.31-.57,9.49,9.49,0,0,1,2.11-.2,9,9,0,0,1,2.11.19,3.42,3.42,0,0,1,1.3.56,1.85,1.85,0,0,1,.66.87,3.06,3.06,0,0,1,.19,1.12Zm-5.4-.47a.57.57,0,0,0,.21.45,1.57,1.57,0,0,0,.94.18,1.68,1.68,0,0,0,.95-.19.56.56,0,0,0,.2-.45v-3.62a.57.57,0,0,0-.2-.43,1.56,1.56,0,0,0-1-.18,1.61,1.61,0,0,0-1,.18.59.59,0,0,0-.2.44Z"/>
                    <path className="cls-48" d="M136,508.45h-2a.53.53,0,0,1-.37-.11.5.5,0,0,1-.12-.37l0-8.72a.5.5,0,0,1,.12-.37.56.56,0,0,1,.38-.11h2.21a.61.61,0,0,1,.36.1.89.89,0,0,1,.27.3l1.24,2.19a.7.7,0,0,0,.12.18.17.17,0,0,0,.13.05h.12a.19.19,0,0,0,.13-.05l.12-.18,1.21-2.19a.88.88,0,0,1,.27-.32.64.64,0,0,1,.36-.09h2.22a.51.51,0,0,1,.38.12.48.48,0,0,1,.12.37l0,8.71a.54.54,0,0,1-.11.37.57.57,0,0,1-.38.12h-2.11a.56.56,0,0,1-.38-.11.5.5,0,0,1-.12-.37v-4.73l-.81,1.46a.74.74,0,0,1-.29.33.81.81,0,0,1-.43.11H138a.91.91,0,0,1-.43-.1.81.81,0,0,1-.3-.33l-.81-1.46V508a.54.54,0,0,1-.11.37A.57.57,0,0,1,136,508.45Z"/>
                    <path className="cls-48" d="M151.6,508.4l-6.36,0a.56.56,0,0,1-.38-.11.5.5,0,0,1-.12-.37l0-8.72a.5.5,0,0,1,.11-.37.52.52,0,0,1,.38-.11l6.36,0a.51.51,0,0,1,.38.11.5.5,0,0,1,.12.37v1.57a.48.48,0,0,1-.11.37.51.51,0,0,1-.38.12H147.8v1h2.67a.51.51,0,0,1,.38.11.5.5,0,0,1,.12.37v1.44a.54.54,0,0,1-.11.37.57.57,0,0,1-.38.12h-2.67v1.16h3.79a.42.42,0,0,1,.49.48v1.57a.45.45,0,0,1-.11.37A.51.51,0,0,1,151.6,508.4Z"/>
                  </g>
                </g>
              </g>
            </g>
          </svg>










        </div>
      )
    }
    else {
      return (
        <div className="svg-container landscape-svg">
          <svg id="care_system_landscape" data-name="care system landscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768">
            <defs>
              <clipPath id="clip-path">
                <rect className="cls-1" width="1366" height="768"/>
              </clipPath>
            </defs>
            <g className="cls-2">
              <g id="work" 
              onClick={() => this.props.mapClick('work')}
              onMouseEnter={() => this.onMouseEnterLogic('work')}
              onMouseLeave={() => this.onMouseLeaveLogic('work')}
              ref={this.workRef}
              className={workClassNames}>
                <path id="work_connection" data-name="work connection" className="cls-3" d="M782,186.4l118.84-69.35,163.28,94.75V360.45l-76.46,44.37-90-51.77,75.79-44"/>
                <circle id="work_dot" data-name="work dot" className="cls-4" cx="782.03" cy="186.36" r="1.5"/>
                <g id="work_color" data-name="work color">
                  <polygon className="cls-5" points="956.74 298.87 956.74 225.27 991.64 245.02 991.64 318.63 956.74 298.87"/>
                  <path className="cls-6" d="M957.24,226.12l33.9,19.19v72.46l-33.9-19.19V226.12m-1-1.71v74.76l35.9,20.32V244.73l-35.9-20.32Z"/>
                  <polygon className="cls-7" points="978.11 288.29 978.11 281.54 984.09 284.92 984.09 291.67 978.11 288.29"/>
                  <path className="cls-4" d="M978.36,282l5.48,3.09v6.18l-5.48-3.1V282m-.5-.86v7.32l6.48,3.67v-7.33l-6.48-3.66Z"/>
                  <polygon className="cls-7" points="964.29 280.46 964.29 273.71 970.26 277.09 970.26 283.85 964.29 280.46"/>
                  <path className="cls-4" d="M964.54,274.14l5.47,3.1v6.18l-5.47-3.1v-6.18m-.5-.85v7.32l6.47,3.66V277L964,273.29Z"/>
                  <polygon className="cls-7" points="978.11 271.35 978.11 264.6 984.09 267.98 984.09 274.73 978.11 271.35"/>
                  <path className="cls-4" d="M978.36,265l5.48,3.1v6.18l-5.48-3.1V265m-.5-.85v7.32l6.48,3.67v-7.33l-6.48-3.66Z"/>
                  <polygon className="cls-7" points="964.29 263.52 964.29 256.77 970.26 260.15 970.26 266.9 964.29 263.52"/>
                  <path className="cls-4" d="M964.54,257.2l5.47,3.1v6.17l-5.47-3.09V257.2m-.5-.86v7.33l6.47,3.66V260L964,256.34Z"/>
                  <polygon className="cls-7" points="978.11 254.4 978.11 247.65 984.09 251.03 984.09 257.79 978.11 254.4"/>
                  <path className="cls-4" d="M978.36,248.08l5.48,3.1v6.18l-5.48-3.1v-6.18m-.5-.86v7.33l6.48,3.66v-7.32l-6.48-3.67Z"/>
                  <polygon className="cls-7" points="964.29 246.58 964.29 239.83 970.26 243.21 970.26 249.96 964.29 246.58"/>
                  <path className="cls-4" d="M964.54,240.26l5.47,3.09v6.18l-5.47-3.1v-6.17m-.5-.86v7.32l6.47,3.67v-7.33L964,239.4Z"/>
                  <polygon className="cls-4" points="1028.04 299.17 992.14 319.49 992.14 244.73 1028.04 224.41 1028.04 299.17"/>
                  <polygon className="cls-4" points="970.88 307.13 970.88 294.97 977.49 298.71 977.49 310.87 970.88 307.13"/>
                  <path className="cls-4" d="M971.13,295.4l6.11,3.45v11.59L971.13,307V295.4m-.5-.86v12.74l7.11,4V298.56l-7.11-4Z"/>
                  <polygon className="cls-4" points="992.09 204.06 1028.04 224.41 992.14 244.73 956.24 224.41 992.09 204.06"/>
                </g>
                <g id="work_gray" data-name="work gray">
                  <polygon className="cls-8" points="956.95 298.75 956.95 225.62 991.43 245.14 991.43 318.27 956.95 298.75"/>
                  <path className="cls-9" d="M957.65,226.83l33.07,18.72v71.51l-33.07-18.72V226.83m-1.41-2.42v74.76l35.9,20.32V244.73l-35.9-20.32Z"/>
                  <polygon className="cls-8" points="978.11 288.29 978.11 281.54 984.09 284.92 984.09 291.67 978.11 288.29"/>
                  <path className="cls-10" d="M978.36,282l5.48,3.09v6.18l-5.48-3.1V282m-.5-.86v7.32l6.48,3.67v-7.33l-6.48-3.66Z"/>
                  <polygon className="cls-8" points="964.29 280.46 964.29 273.71 970.26 277.09 970.26 283.85 964.29 280.46"/>
                  <path className="cls-10" d="M964.54,274.14l5.47,3.1v6.18l-5.47-3.1v-6.18m-.5-.85v7.32l6.47,3.66V277L964,273.29Z"/>
                  <polygon className="cls-8" points="978.11 271.35 978.11 264.6 984.09 267.98 984.09 274.73 978.11 271.35"/>
                  <path className="cls-10" d="M978.36,265l5.48,3.1v6.18l-5.48-3.1V265m-.5-.85v7.32l6.48,3.67v-7.33l-6.48-3.66Z"/>
                  <polygon className="cls-8" points="964.29 263.52 964.29 256.77 970.26 260.15 970.26 266.9 964.29 263.52"/>
                  <path className="cls-10" d="M964.54,257.2l5.47,3.1v6.17l-5.47-3.09V257.2m-.5-.86v7.33l6.47,3.66V260L964,256.34Z"/>
                  <polygon className="cls-8" points="978.11 254.4 978.11 247.65 984.09 251.03 984.09 257.79 978.11 254.4"/>
                  <path className="cls-10" d="M978.36,248.08l5.48,3.1v6.18l-5.48-3.1v-6.18m-.5-.86v7.33l6.48,3.66v-7.32l-6.48-3.67Z"/>
                  <polygon className="cls-8" points="964.29 246.58 964.29 239.83 970.26 243.21 970.26 249.96 964.29 246.58"/>
                  <path className="cls-10" d="M964.54,240.26l5.47,3.09v6.18l-5.47-3.1v-6.17m-.5-.86v7.32l6.47,3.67v-7.33L964,239.4Z"/>
                  <polygon className="cls-9" points="992.4 244.88 1027.77 224.86 1027.77 299.01 992.4 319.03 992.4 244.88"/>
                  <path className="cls-10" d="M1027.5,225.32v73.54l-34.83,19.72V245l34.83-19.72m.53-.91-35.89,20.32v74.76L1028,299.17V224.41Z"/>
                  <polygon className="cls-8" points="970.88 307.13 970.88 294.97 977.49 298.71 977.49 310.87 970.88 307.13"/>
                  <path className="cls-10" d="M971.13,295.4l6.11,3.45v11.59L971.13,307V295.4m-.5-.86v12.74l7.11,4V298.56l-7.11-4Z"/>
                  <polygon className="cls-9" points="956.78 224.41 992.09 204.37 1027.5 224.41 992.14 244.42 956.78 224.41"/>
                  <path className="cls-10" d="M992.09,204.67,1027,224.41l-34.82,19.71-34.83-19.71,34.78-19.74m0-.61-35.85,20.35,35.9,20.32L1028,224.41l-35.94-20.35Z"/>
                </g>
                <g id="work_title" data-name="work title">
                  <path className="cls-4" d="M904.39,267.36h2a.54.54,0,0,1,.37.11.49.49,0,0,1,.11.35v5a6.12,6.12,0,0,1,0,.76,3.52,3.52,0,0,1-.2.83l-.65,1.75a1.13,1.13,0,0,1-.19.32.46.46,0,0,1-.34.14h-1.74a.79.79,0,0,1-.38-.09.43.43,0,0,1-.22-.3l-.61-2.09a.27.27,0,0,0-.09-.17.24.24,0,0,0-.14,0h-.12a.24.24,0,0,0-.14,0,.33.33,0,0,0-.1.17l-.61,2.09a.48.48,0,0,1-.2.29.56.56,0,0,1-.35.1H899a.57.57,0,0,1-.36-.13.83.83,0,0,1-.22-.33l-.65-1.78a3.48,3.48,0,0,1-.21-.83c0-.23,0-.49,0-.76v-5a.49.49,0,0,1,.11-.35.53.53,0,0,1,.36-.11h2a.5.5,0,0,1,.36.11.45.45,0,0,1,.11.35v4.73l.76-1.62a.66.66,0,0,1,.28-.31.87.87,0,0,1,.42-.09h.55a.93.93,0,0,1,.42.09.69.69,0,0,1,.27.31l.76,1.62v-4.73a.49.49,0,0,1,.11-.35A.53.53,0,0,1,904.39,267.36Z"/>
                  <path className="cls-4" d="M916.17,274.18a3,3,0,0,1-.18,1.07,1.7,1.7,0,0,1-.62.82,3.46,3.46,0,0,1-1.25.54,8.67,8.67,0,0,1-2,.19,8.76,8.76,0,0,1-2-.19,3.38,3.38,0,0,1-1.25-.54,1.79,1.79,0,0,1-.63-.82,3,3,0,0,1-.17-1.07V269.8a3,3,0,0,1,.17-1.07,1.87,1.87,0,0,1,.63-.83,3.38,3.38,0,0,1,1.25-.54,8.76,8.76,0,0,1,2-.19,8.67,8.67,0,0,1,2,.19,3.46,3.46,0,0,1,1.25.54,1.77,1.77,0,0,1,.62.83,3,3,0,0,1,.18,1.07Zm-5.16-.46a.53.53,0,0,0,.2.43,2.47,2.47,0,0,0,1.8,0,.57.57,0,0,0,.2-.43v-3.46a.53.53,0,0,0-.2-.41,2.34,2.34,0,0,0-1.8,0,.5.5,0,0,0-.2.41Z"/>
                  <path className="cls-4" d="M920,276.61h-2a.5.5,0,0,1-.36-.11.45.45,0,0,1-.11-.35v-8.33a.45.45,0,0,1,.11-.35.5.5,0,0,1,.36-.11h4.36a2.73,2.73,0,0,1,2,.6,2.18,2.18,0,0,1,.55,1.55v.59a2.25,2.25,0,0,1-.16.91,1,1,0,0,1-.57.55,1.7,1.7,0,0,1,1.14.57,1.81,1.81,0,0,1,.44,1.29v2.73a.45.45,0,0,1-.11.35.5.5,0,0,1-.36.11h-2a.5.5,0,0,1-.36-.11.49.49,0,0,1-.11-.35v-2a.68.68,0,0,0-.11-.42.48.48,0,0,0-.39-.13h-1.83v2.52a.49.49,0,0,1-.11.35A.53.53,0,0,1,920,276.61Zm.47-6.94v1.65h1a.51.51,0,0,0,.46-.18.73.73,0,0,0,.12-.43v-.43a.71.71,0,0,0-.12-.43.51.51,0,0,0-.46-.18Z"/>
                  <path className="cls-4" d="M929.42,276.61h-2a.5.5,0,0,1-.36-.11.45.45,0,0,1-.12-.35v-8.33a.45.45,0,0,1,.12-.35.5.5,0,0,1,.36-.11h2a.53.53,0,0,1,.36.11.49.49,0,0,1,.11.35v3h.52l1.35-3a.64.64,0,0,1,.25-.32.73.73,0,0,1,.41-.1h2a.39.39,0,0,1,.31.11.32.32,0,0,1,0,.31l-1.33,3a1.63,1.63,0,0,1,1.17.5,1.77,1.77,0,0,1,.44,1.24v3.63a.49.49,0,0,1-.11.35.53.53,0,0,1-.36.11h-2a.5.5,0,0,1-.36-.11.45.45,0,0,1-.11-.35v-2.51a.46.46,0,0,0-.11-.32.39.39,0,0,0-.31-.12h-1.73v2.95a.49.49,0,0,1-.11.35A.53.53,0,0,1,929.42,276.61Z"/>
                </g>
                <g id="work_bg" data-name="work bg" className="cls-11">
                  <rect className="cls-12" x="868.5" y="182.41" width="184.53" height="159.57"/>
                </g>
              </g>
              <g id="school"
              onMouseEnter={() => this.onMouseEnterLogic('school')}
              onMouseLeave={() => this.onMouseLeaveLogic('school')}
              ref={this.schoolRef}
              onClick={() => this.props.mapClick('school')}
              className={schoolClassNames}>
                <path id="school_connection" data-name="school connection" className="cls-13" d="M359.8,329.9l-77.49,45L226.7,343.42l.44-89,151.49-87.27,76.08,44.39,98.92-57.41,41,23.9"/>
                <circle id="school_dot" data-name="school dot" className="cls-14" cx="594.51" cy="178.09" r="1.5"/>
                <g id="school_color" data-name="school color">
                  <polygon className="cls-15" points="421.23 331.51 391.28 348.83 391.28 305.67 421.37 288.64 421.23 331.51"/>
                  <polygon className="cls-15" points="405.42 279.45 421.37 288.64 391.28 305.67 375.37 296.5 405.42 279.45"/>
                  <polygon className="cls-15" points="360.47 248.63 386.83 233.26 405.41 257.07 405.41 279.47 375.43 296.44 375.43 274.04 360.47 248.63"/>
                  <polygon className="cls-16" points="329.71 312.82 329.71 271.34 345.95 280.31 345.95 257.31 360.29 249.3 374.93 274.18 374.93 296.72 390.78 305.96 390.78 347.97 329.71 312.82"/>
                  <path className="cls-17" d="M360.1,250l14.33,24.33V297l.5.29,15.35,8.94V347.1l-60.07-34.56V272.19L345,280.34l1.48.82V257.6L360.1,250m.37-1.35L345.41,257l0,0v22.4l-16.24-9v42.62l62.07,35.72V305.67l-15.85-9.23V274l-15-25.41Z"/>
                  <polygon className="cls-18" points="339.56 312.44 339.56 304.93 346.2 308.69 346.2 316.2 339.56 312.44"/>
                  <path className="cls-15" d="M340,305.63l5.82,3.3v6.56L340,312.2v-6.57m-.83-1.41v8.46l7.47,4.22v-8.45l-7.47-4.23Z"/>
                  <polygon className="cls-18" points="374.37 331.88 374.37 324.38 381.02 328.13 381.02 335.64 374.37 331.88"/>
                  <path className="cls-15" d="M374.78,325.08l5.83,3.29v6.57l-5.83-3.3v-6.56m-.82-1.41v8.45l7.47,4.23V327.9L374,323.67Z"/>
                  <polygon className="cls-18" points="339.56 295.13 339.56 287.62 346.2 291.38 346.2 298.89 339.56 295.13"/>
                  <path className="cls-15" d="M340,288.32l5.82,3.3v6.57l-5.82-3.3v-6.57m-.83-1.41v8.46l7.47,4.22v-8.45l-7.47-4.23Z"/>
                  <polygon className="cls-18" points="374.37 314.63 374.37 307.13 381.02 310.89 381.02 318.4 374.37 314.63"/>
                  <path className="cls-15" d="M374.78,307.83l5.83,3.3v6.56l-5.83-3.29v-6.57m-.82-1.41v8.45l7.47,4.23v-8.45L374,306.42Z"/>
                  <polygon className="cls-18" points="356.96 304.87 356.96 297.36 363.61 301.12 363.61 308.63 356.96 304.87"/>
                  <path className="cls-15" d="M357.37,298.06l5.83,3.3v6.57l-5.83-3.3v-6.57m-.82-1.4v8.45l7.47,4.22v-8.45l-7.47-4.22Z"/>
                  <polygon className="cls-15" points="356.55 328.27 356.55 314.34 363.93 318.52 363.93 332.45 356.55 328.27"/>
                  <path className="cls-15" d="M357,315.05l6.56,3.71v13L357,328v-13m-.82-1.41v14.87l8.2,4.64V318.28l-8.2-4.64Z"/>
                  <path className="cls-14" d="M363.51,285.59a6.14,6.14,0,0,1-3-.91,15.16,15.16,0,0,1-7-11.9c0-2.87,1.38-4.65,3.6-4.65a6.21,6.21,0,0,1,3,.91,15.16,15.16,0,0,1,7,11.91C367.1,283.81,365.72,285.59,363.51,285.59Z"/>
                  <path className="cls-15" d="M357.07,268.54h0a5.88,5.88,0,0,1,2.81.86A14.71,14.71,0,0,1,366.69,281c0,2.65-1.19,4.23-3.18,4.23a5.81,5.81,0,0,1-2.82-.86,14.7,14.7,0,0,1-6.81-11.54c0-2.66,1.19-4.24,3.19-4.24m0-.82c-2.37,0-4,1.85-4,5.06,0,4.5,3.24,10,7.23,12.26a6.65,6.65,0,0,0,3.22,1c2.37,0,4-1.85,4-5.05a15.6,15.6,0,0,0-7.22-12.26,6.6,6.6,0,0,0-3.22-1Z"/>
                  <polygon className="cls-17" points="355.84 275.36 361.28 278.44 361.28 272.18 359.87 271.38 359.87 276.06 355.84 273.77 355.84 275.36"/>
                  <polygon className="cls-15" points="345.45 261.25 345.45 279.42 345.41 279.44 340.94 276.97 329.21 270.47 345.45 261.25"/>
                </g>
                <g id="school_gray" data-name="school gray">
                  <polygon className="cls-9" points="391.54 305.82 421.1 289.09 420.97 331.36 391.54 348.37 391.54 305.82"/>
                  <path className="cls-10" d="M420.84,289.55l-.13,41.65-28.9,16.71V306l29-16.43m.53-.91-30.09,17v43.16l30-17.32.14-42.87Z"/>
                  <polygon className="cls-9" points="375.9 296.51 405.42 279.75 420.83 288.63 391.28 305.37 375.9 296.51"/>
                  <path className="cls-10" d="M405.42,280.06l14.88,8.57-29,16.43-14.84-8.55,29-16.45m0-.61L375.37,296.5l15.91,9.17,30.09-17-15.95-9.19Z"/>
                  <path className="cls-9" d="M375.7,274a.25.25,0,0,0,0-.14l-14.83-25.18,25.93-15.11,18.39,23.55v22.15L375.7,296Z"/>
                  <path className="cls-10" d="M386.7,234l18.18,23.29v21.91L376,295.53V274a.5.5,0,0,0-.07-.27L361.2,248.82,386.7,234m.13-.7-26.36,15.37,15,25.41v22.4l30-17v-22.4l-18.58-23.81Z"/>
                  <polygon className="cls-8" points="329.79 312.78 329.79 271.47 346.03 280.45 346.03 257.36 360.26 249.41 374.85 274.2 374.85 296.77 390.7 306 390.7 347.83 329.79 312.78"/>
                  <path className="cls-9" d="M360,250.19l14.23,24.17V297.1l.58.34,15.27,8.9v40.49l-59.75-34.39v-40l14.52,8,1.72.95V257.7L360,250.19m.43-1.56L345.41,257l0,0v22.4l-16.24-9v42.62l62.07,35.72V305.67l-15.85-9.23V274l-15-25.41Z"/>
                  <polygon className="cls-8" points="339.56 312.44 339.56 304.93 346.2 308.69 346.2 316.2 339.56 312.44"/>
                  <path className="cls-10" d="M340,305.63l5.82,3.3v6.57L340,312.2v-6.57m-.83-1.41v8.46l7.47,4.22v-8.45l-7.47-4.23Z"/>
                  <polygon className="cls-8" points="374.37 331.88 374.37 324.38 381.02 328.13 381.02 335.64 374.37 331.88"/>
                  <path className="cls-10" d="M374.78,325.08l5.83,3.29v6.57l-5.83-3.3v-6.56m-.82-1.41v8.45l7.47,4.23V327.9L374,323.67Z"/>
                  <polygon className="cls-8" points="339.56 295.13 339.56 287.62 346.2 291.38 346.2 298.89 339.56 295.13"/>
                  <path className="cls-10" d="M340,288.32l5.82,3.3v6.57l-5.82-3.3v-6.57m-.83-1.41v8.46l7.47,4.22v-8.45l-7.47-4.23Z"/>
                  <polygon className="cls-8" points="374.37 314.63 374.37 307.13 381.02 310.89 381.02 318.4 374.37 314.63"/>
                  <path className="cls-10" d="M374.78,307.83l5.83,3.3v6.56l-5.83-3.29v-6.57m-.82-1.41v8.45l7.47,4.23v-8.45L374,306.42Z"/>
                  <polygon className="cls-8" points="356.96 304.87 356.96 297.36 363.61 301.12 363.61 308.63 356.96 304.87"/>
                  <path className="cls-10" d="M357.37,298.06l5.83,3.3v6.57l-5.83-3.3v-6.57m-.82-1.4v8.45l7.47,4.22v-8.45l-7.47-4.22Z"/>
                  <polygon className="cls-8" points="356.55 328.27 356.55 314.34 363.93 318.52 363.93 332.45 356.55 328.27"/>
                  <path className="cls-10" d="M357,315.05l6.56,3.71v13L357,328v-13m-.82-1.41v14.87l8.2,4.64V318.28l-8.2-4.64Z"/>
                  <path className="cls-19" d="M360.29,268.69A15.6,15.6,0,0,1,367.51,281c0,4.51-3.24,6.35-7.22,4.09s-7.23-7.76-7.23-12.26S356.3,266.43,360.29,268.69Z"/>
                  <polygon className="cls-8" points="355.84 275.36 361.28 278.44 361.28 272.18 359.87 271.38 359.87 276.06 355.84 273.77 355.84 275.36"/>
                  <polygon className="cls-9" points="341.06 276.74 329.75 270.46 345.19 261.71 345.19 279.02 341.06 276.74"/>
                  <path className="cls-10" d="M344.92,262.16v16.41l-3.73-2.06-10.9-6.05,14.63-8.3m.53-.91-16.24,9.22,11.73,6.5,4.47,2.48,0,0V261.25Z"/>
                </g>
                <g id="school_title" data-name="school title">
                  <path className="cls-14" d="M260.13,299.53H256a.5.5,0,0,1-.36-.11.49.49,0,0,1-.11-.35v-1.51a.49.49,0,0,1,.11-.35.5.5,0,0,1,.36-.11h3.53a.33.33,0,0,0,.26-.11.45.45,0,0,0,.08-.26.57.57,0,0,0-.08-.33.42.42,0,0,0-.26-.11l-2-.27a2.89,2.89,0,0,1-1.47-.59,1.89,1.89,0,0,1-.56-1.55v-1.35a2,2,0,0,1,.72-1.68,3.08,3.08,0,0,1,2-.58h3.68a.53.53,0,0,1,.36.11.49.49,0,0,1,.11.35v1.53a.52.52,0,0,1-.11.36.53.53,0,0,1-.36.11h-3.06a.33.33,0,0,0-.26.1.45.45,0,0,0-.09.28.43.43,0,0,0,.09.28.35.35,0,0,0,.26.13l2,.26a3,3,0,0,1,1.48.59,1.93,1.93,0,0,1,.56,1.55v1.35a2,2,0,0,1-.73,1.68A3.11,3.11,0,0,1,260.13,299.53Z"/>
                  <path className="cls-14" d="M270.23,299.53H267a3.83,3.83,0,0,1-2.44-.64,2.4,2.4,0,0,1-.79-2v-4a2.4,2.4,0,0,1,.79-2,3.82,3.82,0,0,1,2.44-.65h3.2a.5.5,0,0,1,.36.11.49.49,0,0,1,.11.35v1.56a.49.49,0,0,1-.11.35.5.5,0,0,1-.36.11h-2.64a.92.92,0,0,0-.6.16.6.6,0,0,0-.2.48v3.07a.56.56,0,0,0,.2.47,1.06,1.06,0,0,0,.6.14h2.64a.5.5,0,0,1,.36.11.52.52,0,0,1,.11.36v1.53a.49.49,0,0,1-.11.35A.5.5,0,0,1,270.23,299.53Z"/>
                  <path className="cls-14" d="M274.36,299.53h-2a.5.5,0,0,1-.36-.11.45.45,0,0,1-.11-.35v-8.34a.45.45,0,0,1,.11-.35.5.5,0,0,1,.36-.11h2a.5.5,0,0,1,.36.11.45.45,0,0,1,.11.35v2.79H277v-2.79a.45.45,0,0,1,.11-.35.5.5,0,0,1,.36-.11h2a.5.5,0,0,1,.36.11.45.45,0,0,1,.11.35v8.34a.45.45,0,0,1-.11.35.5.5,0,0,1-.36.11h-2a.5.5,0,0,1-.36-.11.45.45,0,0,1-.11-.35v-2.86h-2.2v2.86a.45.45,0,0,1-.11.35A.5.5,0,0,1,274.36,299.53Z"/>
                  <path className="cls-14" d="M289.49,297.1a3,3,0,0,1-.18,1.07,1.73,1.73,0,0,1-.63.83,3.5,3.5,0,0,1-1.24.53,8.7,8.7,0,0,1-2,.19,8.82,8.82,0,0,1-2-.19,3.56,3.56,0,0,1-1.25-.53,1.87,1.87,0,0,1-.63-.83,3,3,0,0,1-.18-1.07v-4.39a3,3,0,0,1,.18-1.07,1.87,1.87,0,0,1,.63-.83,3.38,3.38,0,0,1,1.25-.54,8.82,8.82,0,0,1,2-.19,8.7,8.7,0,0,1,2,.19,3.33,3.33,0,0,1,1.24.54,1.73,1.73,0,0,1,.63.83,3,3,0,0,1,.18,1.07Zm-5.17-.46a.57.57,0,0,0,.2.43,1.56,1.56,0,0,0,.9.17,1.58,1.58,0,0,0,.91-.17.57.57,0,0,0,.2-.43v-3.46a.53.53,0,0,0-.2-.42,1.48,1.48,0,0,0-.91-.18,1.46,1.46,0,0,0-.9.18.53.53,0,0,0-.2.42Z"/>
                  <path className="cls-14" d="M298.83,297.1a3.29,3.29,0,0,1-.17,1.07,1.87,1.87,0,0,1-.63.83,3.64,3.64,0,0,1-1.25.53,8.7,8.7,0,0,1-2,.19,8.82,8.82,0,0,1-2-.19,3.56,3.56,0,0,1-1.25-.53,1.87,1.87,0,0,1-.63-.83,3,3,0,0,1-.18-1.07v-4.39a3,3,0,0,1,.18-1.07,1.87,1.87,0,0,1,.63-.83,3.38,3.38,0,0,1,1.25-.54,8.82,8.82,0,0,1,2-.19,8.7,8.7,0,0,1,2,.19,3.46,3.46,0,0,1,1.25.54,1.87,1.87,0,0,1,.63.83,3.29,3.29,0,0,1,.17,1.07Zm-5.17-.46a.57.57,0,0,0,.2.43,1.56,1.56,0,0,0,.9.17,1.58,1.58,0,0,0,.91-.17.57.57,0,0,0,.2-.43v-3.46a.53.53,0,0,0-.2-.42,1.48,1.48,0,0,0-.91-.18,1.46,1.46,0,0,0-.9.18.53.53,0,0,0-.2.42Z"/>
                  <path className="cls-14" d="M307.32,299.53h-6.68a.53.53,0,0,1-.36-.11.49.49,0,0,1-.11-.35v-8.34a.49.49,0,0,1,.11-.35.53.53,0,0,1,.36-.11h2a.53.53,0,0,1,.36.11.49.49,0,0,1,.11.35v6.37H305V295a.46.46,0,0,1,.11-.36.5.5,0,0,1,.36-.11h1.9a.5.5,0,0,1,.36.11.46.46,0,0,1,.11.36v4.05a.45.45,0,0,1-.11.35A.5.5,0,0,1,307.32,299.53Z"/>
                </g>
              </g>
              <g id="retirement"
                onMouseEnter={() => this.onMouseEnterLogic('retirement')}
                onMouseLeave={() => this.onMouseLeaveLogic('retirement')}
                ref={this.retirementRef}
                onClick={() => this.props.mapClick('retirement')}
                className={retirementClassNames}>
                <path id="retirement_connection" data-name="retirement connection" className="cls-20" d="M553.29,317.87l-97.77,56.32V561.71l-152.3,87.86L172.48,575l64.93-37.79"/>
                <circle id="retirement_dot" data-name="retirement dot" className="cls-21" cx="553.38" cy="317.68" r="1.5"/>
                <g id="retirement_color" data-name="retirement color">
                  <polygon className="cls-22" points="203.74 517.79 203.79 487.52 214.73 481.4 225.97 500.49 225.92 530.35 203.74 517.79"/>
                  <path className="cls-23" d="M214.55,482.08l10.92,18.54,0,28.88-21.19-12,0-29.69,10.26-5.73m.37-1.35-11.63,6.5,0,30.86,23.18,13.12.05-30.86-11.55-19.62Z"/>
                  <polygon className="cls-24" points="226.47 500.35 248.91 487.57 237.35 467.95 214.92 480.73 226.47 500.35"/>
                  <polygon className="cls-21" points="212.28 501.3 212.28 495.48 217.43 498.39 217.43 504.22 212.28 501.3"/>
                  <path className="cls-24" d="M212.58,496l4.55,2.58v5.13l-4.55-2.58V496m-.6-1v6.52l5.75,3.25v-6.51L212,495Z"/>
                  <polygon className="cls-24" points="211.87 522.28 211.87 511.19 217.84 514.57 217.84 525.67 211.87 522.28"/>
                  <path className="cls-24" d="M212.17,511.7l5.37,3.05v10.4l-5.37-3V511.7m-.6-1v11.79l6.57,3.72V514.4l-6.57-3.73Z"/>
                  <polygon className="cls-22" points="226.97 530.94 227.02 500.66 237.97 494.55 249.2 513.63 249.16 543.5 226.97 530.94"/>
                  <path className="cls-23" d="M237.78,495.22l10.92,18.55,0,28.87-21.19-12,.05-29.69,10.26-5.74m.37-1.35-11.63,6.5-.05,30.86,23.19,13.12,0-30.86-11.55-19.62Z"/>
                  <polygon className="cls-24" points="249.7 513.49 272.14 500.71 260.58 481.09 238.15 493.87 249.7 513.49"/>
                  <polygon className="cls-21" points="235.51 514.44 235.51 508.62 240.66 511.54 240.66 517.36 235.51 514.44"/>
                  <path className="cls-24" d="M235.81,509.14l4.55,2.57v5.14l-4.55-2.58v-5.13m-.6-1v6.52l5.76,3.26v-6.52l-5.76-3.26Z"/>
                  <polygon className="cls-24" points="235.1 535.43 235.1 524.33 241.07 527.72 241.07 538.81 235.1 535.43"/>
                  <path className="cls-24" d="M235.4,524.85l5.37,3v10.4l-5.37-3v-10.4m-.6-1V535.6l6.58,3.73V527.54l-6.58-3.72Z"/>
                  <polygon className="cls-24" points="272.89 557.53 295.32 544.74 295.37 513.88 272.94 526.67 272.89 557.53"/>
                  <polygon className="cls-22" points="250.2 544.11 250.25 513.84 261.2 507.72 272.44 526.8 272.39 556.67 250.2 544.11"/>
                  <path className="cls-23" d="M261,508.4l10.92,18.54-.05,28.87-21.19-12,.05-29.69L261,508.4m.36-1.35-11.63,6.49-.05,30.86,23.19,13.13.05-30.86-11.56-19.62Z"/>
                  <polygon className="cls-24" points="272.94 526.67 295.37 513.88 283.82 494.26 261.38 507.05 272.94 526.67"/>
                  <polygon className="cls-21" points="258.74 527.62 258.74 521.79 263.9 524.71 263.9 530.53 258.74 527.62"/>
                  <path className="cls-24" d="M259,522.31l4.56,2.57V530L259,527.44v-5.13m-.6-1v6.51l5.76,3.26v-6.52l-5.76-3.25Z"/>
                  <polygon className="cls-24" points="258.33 548.6 258.33 537.5 264.31 540.89 264.31 551.98 258.33 548.6"/>
                  <path className="cls-24" d="M258.63,538l5.38,3v10.41l-5.38-3V538m-.6-1v11.79l6.58,3.72V540.71L258,537Z"/>
                </g>
                <g id="retirement_gray" data-name="retirement gray">
                  <polygon className="cls-8" points="203.54 518.15 203.59 487.67 214.77 481.42 226.12 500.69 226.07 530.9 203.54 518.15"/>
                  <path className="cls-9" d="M214.65,481.86l11.14,18.92,0,29.56L203.87,518l0-30.1,10.73-6m.24-.89-11.63,6.5,0,30.87,23.19,13.13,0-30.87L214.89,481Z"/>
                  <polygon className="cls-9" points="215.26 481.07 237.24 468.55 248.53 487.72 226.55 500.24 215.26 481.07"/>
                  <path className="cls-10" d="M237.14,468.91l11,18.71-21.52,12.26-11-18.71,21.52-12.26m.2-.72L214.89,481l11.56,19.63,22.44-12.78-11.55-19.63Z"/>
                  <polygon className="cls-8" points="212.25 501.55 212.25 495.73 217.41 498.64 217.41 504.47 212.25 501.55"/>
                  <path className="cls-10" d="M212.56,496.24l4.55,2.58V504l-4.55-2.57v-5.14m-.61-1v6.52l5.76,3.26v-6.52L212,495.21Z"/>
                  <polygon className="cls-8" points="211.84 522.54 211.84 511.44 217.82 514.83 217.82 525.92 211.84 522.54"/>
                  <path className="cls-10" d="M212.14,512l5.38,3v10.41l-5.38-3V512m-.6-1v11.79l6.58,3.72V514.65l-6.58-3.72Z"/>
                  <polygon className="cls-8" points="226.78 531.3 226.83 500.81 238.01 494.57 249.36 513.84 249.31 544.05 226.78 531.3"/>
                  <path className="cls-9" d="M237.89,495,249,513.93,249,543.49l-21.87-12.38,0-30.1,10.73-6m.24-.89-11.63,6.5-.05,30.87,23.19,13.13.05-30.87-11.56-19.63Z"/>
                  <polygon className="cls-9" points="238.5 494.22 260.48 481.7 271.77 500.87 249.79 513.39 238.5 494.22"/>
                  <path className="cls-10" d="M260.38,482.06l11,18.71L249.88,513l-11-18.71,21.52-12.26m.19-.72-22.44,12.78,11.56,19.63L272.13,501l-11.56-19.62Z"/>
                  <polygon className="cls-8" points="235.49 514.7 235.49 508.87 240.65 511.79 240.65 517.62 235.49 514.7"/>
                  <path className="cls-10" d="M235.79,509.39l4.56,2.58v5.13l-4.56-2.58v-5.13m-.6-1v6.51l5.76,3.26v-6.51l-5.76-3.26Z"/>
                  <polygon className="cls-8" points="235.08 535.69 235.08 524.59 241.06 527.97 241.06 539.07 235.08 535.69"/>
                  <path className="cls-10" d="M235.38,525.11l5.38,3v10.41l-5.38-3v-10.4m-.6-1v11.79l6.58,3.73V527.8l-6.58-3.73Z"/>
                  <polygon className="cls-9" points="273.19 527.08 295.11 514.6 295.06 544.85 273.15 557.34 273.19 527.08"/>
                  <path className="cls-10" d="M294.84,515.05l0,29.65-21.38,12.18,0-29.65,21.38-12.18m.53-.91h0Zm0,0-22.44,12.78-.05,30.87L295.32,545l.05-30.87Z"/>
                  <polygon className="cls-8" points="250.02 544.48 250.07 513.99 261.25 507.75 272.6 527.01 272.55 557.23 250.02 544.48"/>
                  <path className="cls-9" d="M261.13,508.19l11.14,18.91,0,29.56-21.87-12.38.05-30.09,10.73-6m.24-.89-11.63,6.5-.05,30.87,23.19,13.12.05-30.87L261.37,507.3Z"/>
                  <polygon className="cls-9" points="261.74 507.4 283.72 494.87 295.01 514.04 273.02 526.57 261.74 507.4"/>
                  <path className="cls-10" d="M283.62,495.23l11,18.71-21.52,12.27-11-18.72,21.52-12.26m.19-.72L261.37,507.3l11.56,19.62,22.44-12.78-11.56-19.63Z"/>
                  <polygon className="cls-8" points="258.73 527.88 258.73 522.05 263.89 524.97 263.89 530.79 258.73 527.88"/>
                  <path className="cls-10" d="M259,522.57l4.56,2.57v5.14L259,527.7v-5.13m-.6-1v6.52l5.76,3.26v-6.52l-5.76-3.26Z"/>
                  <polygon className="cls-8" points="258.32 548.87 258.32 537.77 264.3 541.15 264.3 552.25 258.32 548.87"/>
                  <path className="cls-10" d="M258.62,538.28l5.38,3.05v10.4l-5.38-3V538.28m-.6-1V549l6.58,3.72V541L258,537.25Z"/>
                </g>
                <g id="retirement_title" data-name="retirement title">
                  <path className="cls-21" d="M97.49,507.34h-2a.4.4,0,0,1-.47-.46v-8.33a.52.52,0,0,1,.11-.36.53.53,0,0,1,.36-.11h4.37a2.72,2.72,0,0,1,2,.6,2.2,2.2,0,0,1,.56,1.55v.59a2.29,2.29,0,0,1-.17.92,1,1,0,0,1-.57.55,1.76,1.76,0,0,1,1.15.56,1.89,1.89,0,0,1,.44,1.3v2.73a.4.4,0,0,1-.47.46h-2a.4.4,0,0,1-.47-.46v-2a.68.68,0,0,0-.11-.42.47.47,0,0,0-.39-.13H98v2.52a.45.45,0,0,1-.12.35A.46.46,0,0,1,97.49,507.34ZM98,500.4V502h1a.51.51,0,0,0,.46-.17.73.73,0,0,0,.12-.43V501a.75.75,0,0,0-.12-.43.53.53,0,0,0-.46-.17Z"/>
                  <path className="cls-21" d="M111,507.34h-6.09a.4.4,0,0,1-.47-.46v-8.33a.52.52,0,0,1,.11-.36.53.53,0,0,1,.36-.11H111a.53.53,0,0,1,.36.11.52.52,0,0,1,.11.36v1.5a.4.4,0,0,1-.47.46h-3.62v1h2.56a.53.53,0,0,1,.36.11.52.52,0,0,1,.11.36v1.37a.52.52,0,0,1-.11.36.53.53,0,0,1-.36.11h-2.56v1.1H111a.53.53,0,0,1,.36.11.49.49,0,0,1,.11.35v1.51a.4.4,0,0,1-.47.46Z"/>
                  <path className="cls-21" d="M117.13,507.34H115a.4.4,0,0,1-.47-.46v-6.32H112.6a.53.53,0,0,1-.36-.1.52.52,0,0,1-.11-.36v-1.55a.52.52,0,0,1,.11-.36.53.53,0,0,1,.36-.11h6.93a.5.5,0,0,1,.36.11.47.47,0,0,1,.11.36v1.55a.47.47,0,0,1-.11.36.5.5,0,0,1-.36.1H117.6v6.32a.49.49,0,0,1-.11.35A.46.46,0,0,1,117.13,507.34Z"/>
                  <path className="cls-21" d="M121.21,507.34a.46.46,0,0,1-.36-.11.45.45,0,0,1-.11-.35v-1.53a.45.45,0,0,1,.11-.35.46.46,0,0,1,.36-.11h1.3v-4.33h-1.3a.5.5,0,0,1-.36-.1.47.47,0,0,1-.11-.36v-1.55a.47.47,0,0,1,.11-.36.5.5,0,0,1,.36-.11h5.59a.53.53,0,0,1,.36.11.52.52,0,0,1,.11.36v1.55a.52.52,0,0,1-.11.36.53.53,0,0,1-.36.1h-1.28v4.33h1.28a.4.4,0,0,1,.47.46v1.53a.4.4,0,0,1-.47.46Z"/>
                  <path className="cls-21" d="M131.05,507.34h-2a.46.46,0,0,1-.36-.11.45.45,0,0,1-.12-.35v-8.33a.47.47,0,0,1,.12-.36.5.5,0,0,1,.36-.11h4.36a2.72,2.72,0,0,1,2,.6,2.2,2.2,0,0,1,.56,1.55v.59a2.29,2.29,0,0,1-.17.92,1,1,0,0,1-.57.55,1.73,1.73,0,0,1,1.15.56,1.89,1.89,0,0,1,.44,1.3v2.73a.49.49,0,0,1-.11.35.47.47,0,0,1-.36.11h-2a.46.46,0,0,1-.36-.11.45.45,0,0,1-.12-.35v-2a.62.62,0,0,0-.11-.42.47.47,0,0,0-.39-.13h-1.83v2.52a.4.4,0,0,1-.47.46Zm.47-6.94V502h1a.51.51,0,0,0,.46-.17.73.73,0,0,0,.12-.43V501a.75.75,0,0,0-.12-.43.53.53,0,0,0-.46-.17Z"/>
                  <path className="cls-21" d="M144.53,507.34h-6.09a.4.4,0,0,1-.47-.46v-8.33a.52.52,0,0,1,.11-.36.53.53,0,0,1,.36-.11h6.09a.5.5,0,0,1,.36.11.47.47,0,0,1,.11.36v1.5a.45.45,0,0,1-.11.35.46.46,0,0,1-.36.11h-3.62v1h2.56a.53.53,0,0,1,.36.11.52.52,0,0,1,.11.36v1.37a.52.52,0,0,1-.11.36.53.53,0,0,1-.36.11h-2.56v1.1h3.62a.5.5,0,0,1,.36.11.45.45,0,0,1,.11.35v1.51a.45.45,0,0,1-.11.35A.46.46,0,0,1,144.53,507.34Z"/>
                  <path className="cls-21" d="M148.65,507.34h-2a.4.4,0,0,1-.47-.46v-8.33a.52.52,0,0,1,.11-.36.53.53,0,0,1,.36-.11h2.12a.59.59,0,0,1,.34.09.78.78,0,0,1,.26.3l1.18,2.09a.71.71,0,0,0,.11.17.14.14,0,0,0,.13,0h.11a.14.14,0,0,0,.13,0,.71.71,0,0,0,.11-.17l1.17-2.09a.86.86,0,0,1,.25-.3.65.65,0,0,1,.35-.09h2.13a.53.53,0,0,1,.36.11.52.52,0,0,1,.11.36v8.33a.4.4,0,0,1-.47.46h-2a.46.46,0,0,1-.36-.11.45.45,0,0,1-.12-.35v-4.53l-.77,1.4a.75.75,0,0,1-.28.32.86.86,0,0,1-.41.1h-.53a.83.83,0,0,1-.41-.1.69.69,0,0,1-.28-.32l-.78-1.4v4.53a.49.49,0,0,1-.11.35A.47.47,0,0,1,148.65,507.34Z"/>
                  <path className="cls-21" d="M163.59,507.34H157.5a.4.4,0,0,1-.47-.46v-8.33a.52.52,0,0,1,.11-.36.53.53,0,0,1,.36-.11h6.09a.4.4,0,0,1,.47.47v1.5a.49.49,0,0,1-.11.35.47.47,0,0,1-.36.11H160v1h2.56a.53.53,0,0,1,.36.11.52.52,0,0,1,.11.36v1.37a.52.52,0,0,1-.11.36.53.53,0,0,1-.36.11H160v1.1h3.62a.52.52,0,0,1,.36.11.49.49,0,0,1,.11.35v1.51a.49.49,0,0,1-.11.35A.47.47,0,0,1,163.59,507.34Z"/>
                  <path className="cls-21" d="M167.72,507.34h-2a.4.4,0,0,1-.47-.46v-8.33a.52.52,0,0,1,.11-.36.53.53,0,0,1,.36-.11h1.37a1,1,0,0,1,.42.08,1,1,0,0,1,.35.27l2.55,2.86v-2.74a.47.47,0,0,1,.11-.36.5.5,0,0,1,.36-.11h2a.53.53,0,0,1,.36.11.52.52,0,0,1,.11.36v8.33a.4.4,0,0,1-.47.46h-2a.46.46,0,0,1-.36-.11.45.45,0,0,1-.11-.35V505.3l-2.25-2.66v4.24a.45.45,0,0,1-.11.35A.46.46,0,0,1,167.72,507.34Z"/>
                  <path className="cls-21" d="M179.29,507.34h-2.13a.4.4,0,0,1-.47-.46v-6.32h-1.93a.53.53,0,0,1-.36-.1.52.52,0,0,1-.11-.36v-1.55a.52.52,0,0,1,.11-.36.53.53,0,0,1,.36-.11h6.93a.5.5,0,0,1,.36.11.52.52,0,0,1,.11.36v1.55a.52.52,0,0,1-.11.36.5.5,0,0,1-.36.1h-1.93v6.32a.49.49,0,0,1-.11.35A.46.46,0,0,1,179.29,507.34Z"/>
                </g>
                <g id="retirement_bg" data-name="retirement bg" className="cls-11">
                  <rect className="cls-12" x="83" y="455.93" width="236.68" height="113.59"/>
                </g>
              </g>
              <g id="care"
                onMouseEnter={() => this.onMouseEnterLogic('care')}
                onMouseLeave={() => this.onMouseLeaveLogic('care')}
                ref={this.careRef}                
                onClick={() => this.props.mapClick('care')}
                className={careClassNames}>
                <path id="care_connection" data-name="care connection" className="cls-25" d="M1216.58,449.2l-73.81,42.57-76.08-43.94L987.6,493.55,822.26,397.2V314.3"/>
                <circle id="care_dot" data-name="care dot" className="cls-26" cx="822.26" cy="314.3" r="1.5"/>
                <g id="care_color" data-name="care color">
                  <path className="cls-27" d="M1170.26,421.55V383.09l31.8,17.89a.53.53,0,0,0,.24.06.58.58,0,0,0,.26-.06.52.52,0,0,0,.25-.44V378.19l28.87,16.35v61.69Z"/>
                  <path className="cls-28" d="M1203.31,379.05l27.87,15.78v60.54l-60.42-34.11V383.94l31,17.48a1.12,1.12,0,0,0,.49.13,1,1,0,0,0,.51-.14,1,1,0,0,0,.5-.87V379.05m-1-1.72v23.21l-32.54-18.31v39.61l62.42,35.25V394.24l-29.88-16.91Z"/>
                  <polygon className="cls-26" points="1232.18 394.24 1202.3 377.33 1202.3 400.54 1169.76 382.23 1199.6 365.29 1262.06 400.56 1232.18 417.48 1232.18 394.24"/>
                  <polygon className="cls-26" points="1232.14 360.4 1262.06 377.33 1232.18 394.24 1202.3 377.33 1232.14 360.4"/>
                  <polygon className="cls-26" points="1262.06 377.33 1262.06 400.56 1262.06 440.18 1232.18 457.09 1232.18 417.48 1232.18 394.24 1262.06 377.33"/>
                  <polygon className="cls-26" points="1213.05 445.74 1213.05 432.53 1220.24 436.6 1220.24 449.81 1213.05 445.74"/>
                  <path className="cls-26" d="M1213.3,433l6.69,3.79v12.63l-6.69-3.78V433m-.5-.86v13.79l7.69,4.35V436.45l-7.69-4.35Z"/>
                  <polygon className="cls-29" points="1195.69 429.63 1195.69 422.27 1202.19 425.95 1202.19 433.31 1195.69 429.63"/>
                  <path className="cls-26" d="M1195.94,422.7l6,3.4v6.78l-6-3.4V422.7m-.5-.86v7.93l7,4v-7.93l-7-4Z"/>
                  <polygon className="cls-29" points="1179.49 420.28 1179.49 412.92 1186 416.61 1186 423.96 1179.49 420.28"/>
                  <path className="cls-26" d="M1179.74,413.35l6,3.4v6.78l-6-3.4v-6.78m-.5-.86v7.94l7,4v-7.93l-7-4Z"/>
                  <polygon className="cls-29" points="1197.14 414.63 1197.14 407.27 1203.65 410.96 1203.65 418.31 1197.14 414.63"/>
                  <path className="cls-26" d="M1197.39,407.7l6,3.4v6.78l-6-3.4V407.7m-.5-.85v7.93l7,4v-7.93l-7-4Z"/>
                  <polygon className="cls-29" points="1214.06 424.32 1214.06 416.96 1220.57 420.64 1220.57 428 1214.06 424.32"/>
                  <path className="cls-26" d="M1214.31,417.39l6,3.4v6.78l-6-3.4v-6.78m-.5-.86v7.93l7,4V420.5l-7-4Z"/>
                  <polygon className="cls-29" points="1180.22 404.95 1180.22 397.59 1186.73 401.27 1186.73 408.63 1180.22 404.95"/>
                  <path className="cls-26" d="M1180.47,398l6,3.4v6.78l-6-3.4V398m-.5-.86v7.93l7,4v-7.93l-7-4Z"/>
                  <path className="cls-30" d="M1211.64,390.86c2.09.25,4.46,2.75,5.28,5.59.83-1.9,3.19-1.73,5.29.39s3.12,5.38,2.3,7.29a4.7,4.7,0,0,1-.44.67,1.78,1.78,0,0,1-.45.37l-6.7,3.89h0l-6.7-11.47c-.16-.29-.45-.88-.45-.88a8.2,8.2,0,0,1-.43-1.17c-.83-2.84.2-4.94,2.3-4.68"/>
                </g>
                <g id="care_gray" data-name="care gray">
                  <path className="cls-8" d="M1170.61,421.48V383.55l31.44,17.7a.73.73,0,0,0,.35.09.64.64,0,0,0,.35-.1.71.71,0,0,0,.35-.61v-22l28.42,16.08v61.13Z"/>
                  <path className="cls-9" d="M1203.81,379.88l27,15.28v59.51l-59.5-33.6V384.76l30.38,17.1a1.46,1.46,0,0,0,.7.18,1.4,1.4,0,0,0,.71-.19,1.42,1.42,0,0,0,.7-1.22V379.88m-1.41-2.43v23.18l-32.5-18.29v39.55l62.33,35.2V394.34l-29.83-16.89Z"/>
                  <path className="cls-9" d="M1232.49,394.34a.26.26,0,0,0-.13-.23l-29.83-16.89a.31.31,0,0,0-.13,0,.23.23,0,0,0-.14,0,.25.25,0,0,0-.13.22v22.73l-31.69-17.84,29.26-16.6,61.82,34.91-29,16.43Z"/>
                  <path className="cls-10" d="M1199.7,366,1261,400.65l-28.22,16V394.34a.52.52,0,0,0-.27-.46L1202.66,377a.59.59,0,0,0-.26-.07.5.5,0,0,0-.27.08.51.51,0,0,0-.26.45v22.27L1171,382.34,1199.7,366m0-.61-29.8,16.91,32.5,18.29V377.45l29.83,16.89v23.2l29.83-16.89-62.36-35.22Z"/>
                  <polygon className="cls-9" points="1202.93 377.45 1232.19 360.85 1261.52 377.45 1232.23 394.04 1202.93 377.45"/>
                  <path className="cls-10" d="M1232.19,361.15l28.8,16.3-28.76,16.28-28.76-16.28,28.72-16.3m0-.6-29.79,16.9,29.83,16.89,29.83-16.89-29.87-16.9Z"/>
                  <polygon className="cls-9" points="1232.49 394.49 1261.8 377.91 1261.8 440.05 1232.49 456.63 1232.49 394.49"/>
                  <path className="cls-10" d="M1261.53,378.36v61.53l-28.77,16.29V394.65l28.77-16.29m.53-.91-29.83,16.89v62.75l29.83-16.89V377.45Z"/>
                  <polygon className="cls-8" points="1213.12 445.76 1213.12 432.57 1220.3 436.63 1220.3 449.83 1213.12 445.76"/>
                  <path className="cls-10" d="M1213.37,433l6.68,3.78V449.4l-6.68-3.78V433m-.5-.86v13.77l7.68,4.34V436.49l-7.68-4.35Z"/>
                  <polygon className="cls-8" points="1195.79 429.67 1195.79 422.32 1202.28 426 1202.28 433.35 1195.79 429.67"/>
                  <path className="cls-10" d="M1196,422.75l6,3.4v6.77l-6-3.4v-6.77m-.5-.85v7.92l7,4v-7.93l-7-4Z"/>
                  <polygon className="cls-8" points="1179.62 420.34 1179.62 412.99 1186.12 416.67 1186.12 424.01 1179.62 420.34"/>
                  <path className="cls-10" d="M1179.87,413.42l6,3.39v6.77l-6-3.39v-6.77m-.5-.86v7.92l7,4v-7.92l-7-4Z"/>
                  <polygon className="cls-8" points="1197.24 414.7 1197.24 407.35 1203.73 411.03 1203.73 418.37 1197.24 414.7"/>
                  <path className="cls-10" d="M1197.49,407.78l6,3.39v6.77l-6-3.39v-6.77m-.5-.86v7.92l7,4v-7.92l-7-4Z"/>
                  <polygon className="cls-8" points="1214.13 424.37 1214.13 417.02 1220.63 420.7 1220.63 428.04 1214.13 424.37"/>
                  <path className="cls-10" d="M1214.38,417.45l6,3.39v6.77l-6-3.39v-6.77m-.5-.86v7.92l7,4v-7.92l-7-4Z"/>
                  <polygon className="cls-8" points="1180.35 405.03 1180.35 397.68 1186.84 401.36 1186.84 408.7 1180.35 405.03"/>
                  <path className="cls-10" d="M1180.6,398.11l6,3.39v6.77l-6-3.39v-6.77m-.5-.86v7.92l7,4v-7.92l-7-4Z"/>
                  <path className="cls-31" d="M1211.72,391c2.09.25,4.45,2.75,5.27,5.58.83-1.9,3.19-1.72,5.28.39s3.12,5.37,2.3,7.28a4.7,4.7,0,0,1-.44.67,1.78,1.78,0,0,1-.45.37l-6.69,3.88h0l-6.69-11.45c-.16-.29-.45-.88-.45-.88a8.2,8.2,0,0,1-.43-1.17c-.82-2.83.2-4.93,2.3-4.67"/>
                </g>
                <g id="care_title" data-name="care title">
                  <path className="cls-26" d="M1114.67,400.49h-3.24a3.91,3.91,0,0,1-2.46-.65,2.44,2.44,0,0,1-.8-2v-4a2.47,2.47,0,0,1,.8-2,3.91,3.91,0,0,1,2.46-.65h3.24a.4.4,0,0,1,.47.47v1.57a.49.49,0,0,1-.11.36.5.5,0,0,1-.36.11H1112a1,1,0,0,0-.61.16.6.6,0,0,0-.2.49v3.09a.57.57,0,0,0,.2.48,1,1,0,0,0,.61.15h2.67a.46.46,0,0,1,.36.11.45.45,0,0,1,.11.35V400a.49.49,0,0,1-.11.36A.5.5,0,0,1,1114.67,400.49Z"/>
                  <path className="cls-26" d="M1118.84,400.49h-2a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36V391.6a.4.4,0,0,1,.48-.47h2a.5.5,0,0,1,.37.11.46.46,0,0,1,.11.36v2.82h2.22V391.6a.46.46,0,0,1,.11-.36.47.47,0,0,1,.36-.11h2.06a.41.41,0,0,1,.48.47V400a.49.49,0,0,1-.12.36.5.5,0,0,1-.36.11H1122a.52.52,0,0,1-.36-.11.49.49,0,0,1-.11-.36v-2.89h-2.22V400a.49.49,0,0,1-.11.36A.54.54,0,0,1,1118.84,400.49Z"/>
                  <path className="cls-26" d="M1126.31,400.49a.5.5,0,0,1-.36-.11.49.49,0,0,1-.12-.36v-1.55a.45.45,0,0,1,.12-.35.46.46,0,0,1,.36-.11h1.31v-4.37h-1.31a.5.5,0,0,1-.36-.11.49.49,0,0,1-.12-.36V391.6a.41.41,0,0,1,.48-.47H1132a.41.41,0,0,1,.48.47v1.57a.49.49,0,0,1-.12.36.5.5,0,0,1-.36.11h-1.29V398H1132a.46.46,0,0,1,.36.11.45.45,0,0,1,.12.35V400a.49.49,0,0,1-.12.36.5.5,0,0,1-.36.11Z"/>
                  <path className="cls-26" d="M1141,400.49h-6.75a.5.5,0,0,1-.36-.11.49.49,0,0,1-.11-.36V391.6a.4.4,0,0,1,.47-.47h2.06a.41.41,0,0,1,.48.47V398h1.81v-2.1a.41.41,0,0,1,.48-.47H1141a.4.4,0,0,1,.48.47V400a.53.53,0,0,1-.11.36A.54.54,0,0,1,1141,400.49Z"/>
                  <path className="cls-26" d="M1147.57,400.49h-4.47a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36V391.6a.4.4,0,0,1,.48-.47h4.47a3.91,3.91,0,0,1,2.46.65,2.43,2.43,0,0,1,.8,2v4.06a2.44,2.44,0,0,1-.8,2A3.91,3.91,0,0,1,1147.57,400.49Zm-1.94-6.88V398h1.58a.65.65,0,0,0,.47-.15.7.7,0,0,0,.15-.49v-3.16a.54.54,0,0,0-.62-.62Z"/>
                  <path className="cls-26" d="M1123.33,414.49h-3.24a3.89,3.89,0,0,1-2.46-.65,2.44,2.44,0,0,1-.8-2v-4a2.47,2.47,0,0,1,.8-2,3.89,3.89,0,0,1,2.46-.65h3.24a.47.47,0,0,1,.36.11.46.46,0,0,1,.11.36v1.57a.49.49,0,0,1-.11.36.52.52,0,0,1-.36.11h-2.67a1,1,0,0,0-.61.16.6.6,0,0,0-.2.49v3.09a.57.57,0,0,0,.2.48,1,1,0,0,0,.61.15h2.67a.47.47,0,0,1,.36.11.45.45,0,0,1,.11.35V414a.49.49,0,0,1-.11.36A.52.52,0,0,1,1123.33,414.49Z"/>
                  <path className="cls-26" d="M1127.68,414a.49.49,0,0,1-.12.36.5.5,0,0,1-.36.11h-2a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36v-3.43a3.54,3.54,0,0,1,.13-.9,8.47,8.47,0,0,1,.39-1.18l1.15-2.95a.61.61,0,0,1,.25-.33.78.78,0,0,1,.43-.1h3.57a.76.76,0,0,1,.41.1.66.66,0,0,1,.25.33l1.15,2.95a8.47,8.47,0,0,1,.39,1.18,3.54,3.54,0,0,1,.13.9V414a.53.53,0,0,1-.11.36.54.54,0,0,1-.37.11h-2a.59.59,0,0,1-.39-.11.45.45,0,0,1-.13-.36V412.2h-2.34Zm.75-6.16-.62,2h2.08l-.62-2a.5.5,0,0,0-.12-.17.17.17,0,0,0-.14-.05h-.33a.19.19,0,0,0-.14.05A.5.5,0,0,0,1128.43,407.86Z"/>
                  <path className="cls-26" d="M1136.84,414.49h-2a.4.4,0,0,1-.47-.47V405.6a.5.5,0,0,1,.11-.36.49.49,0,0,1,.36-.11h4.42a2.77,2.77,0,0,1,2,.6,2.25,2.25,0,0,1,.56,1.57v.6a2.38,2.38,0,0,1-.16.93,1,1,0,0,1-.58.55,1.72,1.72,0,0,1,1.16.57,1.88,1.88,0,0,1,.45,1.31V414a.49.49,0,0,1-.12.36.5.5,0,0,1-.36.11h-2.06a.5.5,0,0,1-.36-.11.49.49,0,0,1-.11-.36v-2a.62.62,0,0,0-.12-.42.47.47,0,0,0-.39-.14h-1.86V414a.49.49,0,0,1-.11.36A.5.5,0,0,1,1136.84,414.49Zm.47-7v1.66h1a.53.53,0,0,0,.46-.17.78.78,0,0,0,.12-.44v-.44a.78.78,0,0,0-.12-.44.53.53,0,0,0-.46-.17Z"/>
                  <path className="cls-26" d="M1150.47,414.49h-6.15a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36V405.6a.4.4,0,0,1,.48-.47h6.15a.41.41,0,0,1,.48.47v1.52a.45.45,0,0,1-.12.35.46.46,0,0,1-.36.12h-3.66v1h2.58a.48.48,0,0,1,.37.11.45.45,0,0,1,.11.35v1.39a.46.46,0,0,1-.11.36.48.48,0,0,1-.37.11h-2.58V412h3.66a.5.5,0,0,1,.36.11.47.47,0,0,1,.12.36V414a.49.49,0,0,1-.12.36A.5.5,0,0,1,1150.47,414.49Z"/>
                </g>
                <g id="care_bg" data-name="care bg" className="cls-11">
                  <rect className="cls-12" x="1080.06" y="344.99" width="206.97" height="127.53"/>
                </g>
              </g>
              <g id="home"
                onMouseEnter={() => this.onMouseEnterLogic('home')}
                onMouseLeave={() => this.onMouseLeaveLogic('home')}
                ref={this.homeRef}
                onClick={() => this.props.mapClick('home')}
                className={homeClassNames}>
                <path id="home_connection" data-name="home connection" className="cls-32" d="M759.15,606.1l-75.91,43.81-70-41v-80.9l70.41-40.2V394"/>
                <circle id="home_dot" data-name="home dot" className="cls-33" cx="683.7" cy="393.24" r="1.5"/>
                <g id="home_color" data-name="home color">
                  <polygon className="cls-34" points="810.98 636.61 887.12 592.65 887.12 539.51 810.98 581.53 810.98 636.61"/>
                  <polygon className="cls-35" points="735.34 592.37 735.34 539.42 774.12 517.71 810.48 581.66 810.48 635.75 735.34 592.37"/>
                  <path className="cls-36" d="M773.93,518.39,810,581.8v53.08l-74.14-42.8V539.71l38.09-21.32m.38-1.36-39.47,22.09v53.53l76.14,44V581.53L774.31,517Z"/>
                  <polygon className="cls-34" points="810.98 581.53 887.12 539.51 848.06 475.01 774.31 517.03 810.98 581.53"/>
                  <polygon className="cls-37" points="782.45 602.81 782.45 586.5 796.86 594.66 796.86 610.96 782.45 602.81"/>
                  <path className="cls-34" d="M782.7,586.93l13.92,7.87v15.74l-13.92-7.88V586.93m-.5-.86V603l14.91,8.44V594.51l-14.91-8.44Z"/>
                  <g id="home_door" data-name="home door">
                    <polygon className="cls-37" points="750.32 601.33 750.32 571.36 766.86 580.72 766.86 610.69 750.32 601.33"/>
                    <path className="cls-34" d="M750.57,571.79l16,9.08v29.4l-16-9.09V571.79m-.5-.86v30.55l17,9.64V580.58l-17-9.65Z"/>
                  </g>
                </g>
                <g id="home_gray" data-name="home gray">
                  <polygon className="cls-9" points="811.25 581.75 886.86 540.02 886.86 592.56 811.25 636.21 811.25 581.75"/>
                  <path className="cls-10" d="M886.59,540.47v51.94l-75.08,43.35V581.91l75.08-41.44m.53-.9L811,581.6v55.07l76.14-44V539.57Z"/>
                  <polygon className="cls-8" points="735.55 592.31 735.55 539.6 774.04 518.06 810.27 581.78 810.27 635.45 735.55 592.31"/>
                  <path className="cls-9" d="M773.77,519l35.8,63v52.26L736.25,591.9V540l37.52-21m.54-1.91-39.47,22.09v53.52l76.14,44V581.59L774.31,517.1Z"/>
                  <polygon className="cls-9" points="774.67 517.2 847.97 475.43 886.75 539.48 811.08 581.24 774.67 517.2"/>
                  <path className="cls-10" d="M847.88,475.79l38.5,63.59-75.2,41.5L775,517.29l72.85-41.5m.18-.71-73.75,42L811,581.6l76.14-42-39.06-64.49Z"/>
                  <polygon className="cls-8" points="782.45 602.87 782.45 586.56 796.86 594.72 796.86 611.02 782.45 602.87"/>
                  <path className="cls-10" d="M782.7,587l13.91,7.87V610.6l-13.91-7.88V587m-.5-.86V603l14.91,8.44V594.57l-14.91-8.44Z"/>
                  <polygon className="cls-8" points="750.32 601.39 750.32 571.42 766.86 580.78 766.86 610.75 750.32 601.39"/>
                  <path className="cls-10" d="M750.57,571.85l16,9.08v29.4l-16-9.08v-29.4m-.5-.86v30.55l17,9.64V580.64l-17-9.65Z"/>
                </g>
                <g id="visit_title" data-name="visit title">
                  <path className="cls-38" d="M715.93,625.85l-3.58-3.35.53-.3,1.76,1.69,1.33,1.28,0,0-.44-1.79L715,621l.51-.3,1.11,4.77Z"/>
                  <path className="cls-38" d="M718.44,623.87l1.11-.63L718.27,621l-1.1.63-.23-.39,1.58-.91L720,623l1-.59.23.39-2.61,1.51Zm-1.09-4.12-.05-.09c-.1-.17-.07-.36.2-.52s.45-.08.55.09l.05.09c.1.17.07.35-.2.51S717.45,619.92,717.35,619.75Z"/>
                  <path className="cls-38" d="M721.7,621.83l.16-.46a1.63,1.63,0,0,0,1.38-.17c.48-.27.72-.63.52-1s-.49-.25-.79-.13l-.52.21c-.48.19-1.16.43-1.54-.23s0-1.19.72-1.6a1.88,1.88,0,0,1,1.53-.24l-.15.46a1.31,1.31,0,0,0-1.17.18c-.48.27-.65.6-.47.9s.49.25.79.13l.52-.21c.48-.19,1.15-.42,1.53.23s0,1.23-.75,1.68A2.1,2.1,0,0,1,721.7,621.83Z"/>
                  <path className="cls-38" d="M725.56,619.76l1.1-.63-1.27-2.21-1.11.63-.22-.39,1.57-.91,1.51,2.6,1-.59.23.39-2.61,1.51Zm-1.09-4.12-.05-.09c-.1-.17-.08-.36.2-.51s.45-.09.54.08l.06.09c.09.17.07.35-.21.51S724.57,615.81,724.47,615.64Z"/>
                  <path className="cls-38" d="M730.65,617.36a.73.73,0,0,1-1.08-.3l-1.1-1.92-1,.59-.22-.4.76-.43a.28.28,0,0,0,.12-.44l-.46-.78.46-.27.61,1.06,1.4-.8.23.39-1.4.81,1.28,2.21,1.39-.81.23.4Z"/>
                  <path className="cls-38" d="M737.76,613.25a.72.72,0,0,1-1.07-.3L735.58,611l-1,.59-.23-.4.76-.43c.21-.12.24-.23.12-.44l-.45-.78.46-.27.61,1.06,1.39-.8.23.39-1.39.81,1.27,2.21,1.4-.81.23.4Z"/>
                  <path className="cls-38" d="M737.49,607.67l.47-.28,1,1.79h0a1,1,0,0,1,.55-1.06,1.19,1.19,0,0,1,1.73.53l1.11,1.92-.48.28L740.87,609c-.32-.55-.73-.67-1.2-.4s-.65.62-.42,1l1.2,2.09L740,612Z"/>
                  <path className="cls-38" d="M742.49,608.53a1.63,1.63,0,0,1,.49-2.39,1.56,1.56,0,0,1,2.21.68l.13.22-2.27,1.31.08.14a1,1,0,0,0,1.46.37,1.07,1.07,0,0,0,.58-1l.49,0a1.51,1.51,0,0,1-.83,1.4A1.63,1.63,0,0,1,742.49,608.53Zm.33-.57,0,0,1.75-1,0-.07a1,1,0,0,0-1.37-.4A1,1,0,0,0,742.82,608Z"/>
                  <path className="cls-38" d="M708,644.2l-1-1-1.45.84.32,1.36-.52.3-1.07-4.8.65-.38,3.62,3.33Zm-3.09-2.94-.06,0,.55,2.3,1.22-.71Z"/>
                  <path className="cls-38" d="M709.05,643.6l-1.73-3,.44-.25.2.35,0,0a.66.66,0,0,1,.34-.75.57.57,0,0,1,.83.15h0a.78.78,0,0,1,.39-.86c.5-.29.84,0,1.2.63l1.2,2.08-.43.25-1.16-2c-.3-.52-.48-.63-.77-.47a.49.49,0,0,0-.15.73l1.27,2.21-.43.25-1.16-2c-.3-.51-.48-.63-.76-.47a.5.5,0,0,0-.16.74l1.27,2.2Z"/>
                  <path className="cls-38" d="M711.83,640a1.63,1.63,0,0,1,.49-2.39,1.56,1.56,0,0,1,2.21.68l.13.23-2.27,1.3.08.14a1,1,0,0,0,1.47.37,1.06,1.06,0,0,0,.57-1l.49,0a1.52,1.52,0,0,1-.83,1.4A1.63,1.63,0,0,1,711.83,640Zm.33-.57,0,0,1.76-1,0-.06a1,1,0,0,0-1.36-.4A1,1,0,0,0,712.16,639.44Z"/>
                  <path className="cls-38" d="M716.08,639l.9-.52-1.28-2.21-.9.52-.22-.39,1.37-.8.43.76,0,0A1.13,1.13,0,0,1,717,635l.43-.25.26.47-.57.33a1,1,0,0,0-.43,1.37l.75,1.3,1.19-.69.23.4-2.56,1.48Z"/>
                  <path className="cls-38" d="M719.81,636.87l1.1-.64L719.64,634l-1.11.64-.22-.4,1.57-.91,1.51,2.61,1-.6.23.4-2.61,1.5Zm-1.09-4.13-.05-.09c-.1-.17-.08-.35.2-.51s.45-.09.54.08l.06.09c.09.17.07.36-.21.51S718.82,632.91,718.72,632.74Z"/>
                  <path className="cls-38" d="M722.6,633.78a1.59,1.59,0,0,1,.48-2.37,1.31,1.31,0,0,1,1.53,0l-.27.42a.85.85,0,0,0-1,0,1,1,0,0,0-.35,1.41l.3.51a1,1,0,0,0,1.41.41,1,1,0,0,0,.53-1l.47,0a1.38,1.38,0,0,1-.77,1.37A1.59,1.59,0,0,1,722.6,633.78Z"/>
                  <path className="cls-38" d="M729.46,631.82a.56.56,0,0,1-.82-.17l0,0a1,1,0,0,1-.6,1.08,1.06,1.06,0,0,1-1.58-.3c-.32-.55-.1-1.1.81-1.64l.66-.37-.17-.3a.73.73,0,0,0-1.12-.24,1,1,0,0,0-.56.91l-.45,0a1.4,1.4,0,0,1,.82-1.28,1.17,1.17,0,0,1,1.75.33l1,1.65.43-.24.23.4Zm-1.07-.54-.29-.49-.65.37c-.57.33-.73.64-.56.93l.07.12c.18.31.52.33.91.11S728.56,631.59,728.39,631.28Z"/>
                  <path className="cls-38" d="M730.67,631.13l-1.74-3,.48-.28.28.49h0a1,1,0,0,1,.55-1.07,1.19,1.19,0,0,1,1.73.54l1.11,1.92-.47.28-1.07-1.85c-.32-.54-.72-.66-1.19-.39s-.66.61-.43,1l1.2,2.08Z"/>
                  <path className="cls-38" d="M737.47,626.67l1-.59-1.28-2.21-1.09.63-.23-.4,1.09-.63-.35-.61a.72.72,0,0,1,.28-1.08l1-.59.22.39-1.22.71.52.91,1.23-.71.23.39-1.23.71L739,625.8l1.1-.63.22.4-2.59,1.5Z"/>
                  <path className="cls-38" d="M743.7,623.6a.57.57,0,0,1-.83-.17l0,0a1,1,0,0,1-.6,1.09,1.06,1.06,0,0,1-1.57-.31c-.32-.55-.11-1.1.81-1.63l.65-.38-.17-.3a.73.73,0,0,0-1.11-.24,1,1,0,0,0-.57.92l-.45-.05a1.43,1.43,0,0,1,.82-1.28,1.18,1.18,0,0,1,1.76.33l1,1.65.43-.24.23.4Zm-1.08-.54-.29-.49-.65.38c-.56.32-.72.63-.56.92l.07.12c.18.31.52.33.91.11S742.8,623.37,742.62,623.06Z"/>
                  <path className="cls-38" d="M744.64,623.06l-1.73-3,.44-.26.2.35h0a.68.68,0,0,1,.35-.76.58.58,0,0,1,.83.15h0a.76.76,0,0,1,.39-.85c.5-.29.83,0,1.2.62l1.2,2.08-.44.26-1.16-2c-.29-.51-.48-.63-.77-.46a.49.49,0,0,0-.14.73l1.27,2.2-.44.25-1.16-2c-.29-.52-.48-.63-.76-.47a.48.48,0,0,0-.15.73l1.27,2.21Z"/>
                  <path className="cls-38" d="M748.28,620.43l1.1-.64-1.27-2.21-1.11.64-.23-.39,1.58-.92,1.51,2.61,1-.6.23.4-2.61,1.51Zm-1.09-4.12-.06-.09c-.09-.17-.07-.36.21-.52s.44-.09.54.08l0,.1c.1.16.08.35-.2.51S747.28,616.48,747.19,616.31Z"/>
                  <path className="cls-38" d="M751.68,618.47l1.07-.62-2-3.51-1.07.61-.22-.39,1.54-.89,2.26,3.9,1.06-.61.23.39-2.61,1.51Z"/>
                  <path className="cls-38" d="M756,612.48l.48-.27.59,4.53a.82.82,0,0,1-.49,1l-.45.26-.23-.4.71-.41-.13-1-2.93-2.23.48-.28,1.24,1,1.12.87h0l-.2-1.41Z"/>
                </g>
                <g id="home_title" data-name="home title">
                  <path className="cls-34" d="M656.64,575H653.8a.74.74,0,0,1-.51-.15.68.68,0,0,1-.15-.49l0-11.66a.67.67,0,0,1,.16-.5.7.7,0,0,1,.5-.15h2.85a.56.56,0,0,1,.66.65v3.9h3.06v-3.9a.65.65,0,0,1,.16-.5.7.7,0,0,1,.5-.15h2.85a.7.7,0,0,1,.5.15.69.69,0,0,1,.16.49l0,11.66a.7.7,0,0,1-.15.5.72.72,0,0,1-.51.15H661a.74.74,0,0,1-.51-.15.68.68,0,0,1-.15-.49l0-4h-3.06v4a.68.68,0,0,1-.15.49A.77.77,0,0,1,656.64,575Z"/>
                  <path className="cls-34" d="M677.79,571.51a4.51,4.51,0,0,1-.24,1.51,2.57,2.57,0,0,1-.88,1.15,4.7,4.7,0,0,1-1.74.75,12.16,12.16,0,0,1-2.82.28,12.35,12.35,0,0,1-2.82-.26,5,5,0,0,1-1.76-.74,2.5,2.5,0,0,1-.88-1.15,4.22,4.22,0,0,1-.25-1.5l0-6.14a4.45,4.45,0,0,1,.24-1.5,2.68,2.68,0,0,1,.88-1.17,4.84,4.84,0,0,1,1.75-.76,12.35,12.35,0,0,1,2.82-.27,11.76,11.76,0,0,1,2.82.26,4.51,4.51,0,0,1,1.75.75,2.53,2.53,0,0,1,.88,1.16,4.22,4.22,0,0,1,.25,1.5Zm-7.23-.62a.75.75,0,0,0,.28.6,3.39,3.39,0,0,0,2.53,0,.77.77,0,0,0,.27-.6V566a.74.74,0,0,0-.28-.59,2.14,2.14,0,0,0-1.27-.24,2.19,2.19,0,0,0-1.26.25.73.73,0,0,0-.27.59Z"/>
                  <path className="cls-34" d="M683.07,574.9h-2.74a.65.65,0,0,1-.5-.16.67.67,0,0,1-.16-.49l0-11.66a.67.67,0,0,1,.15-.49.68.68,0,0,1,.51-.16h3a.83.83,0,0,1,.49.13,1.14,1.14,0,0,1,.36.41l1.66,2.93a1.13,1.13,0,0,0,.15.23.24.24,0,0,0,.18.07h.16a.21.21,0,0,0,.18-.07,2.33,2.33,0,0,0,.15-.23l1.63-2.94a1.28,1.28,0,0,1,.36-.42.88.88,0,0,1,.48-.12h3a.72.72,0,0,1,.51.15.69.69,0,0,1,.16.49l0,11.66a.71.71,0,0,1-.15.5.74.74,0,0,1-.51.15h-2.82a.71.71,0,0,1-.51-.15.7.7,0,0,1-.16-.5l0-6.33-1.07,2a1.08,1.08,0,0,1-.4.44,1.09,1.09,0,0,1-.57.13h-.74a1.16,1.16,0,0,1-.58-.14,1.07,1.07,0,0,1-.39-.44l-1.09-2,0,6.33a.63.63,0,0,1-.16.49A.65.65,0,0,1,683.07,574.9Z"/>
                  <path className="cls-34" d="M704,574.84l-8.51,0a.72.72,0,0,1-.51-.15.69.69,0,0,1-.16-.49l0-11.66a.65.65,0,0,1,.16-.5.7.7,0,0,1,.5-.15l8.51,0a.72.72,0,0,1,.51.15.71.71,0,0,1,.16.5v2.1a.7.7,0,0,1-.15.5.72.72,0,0,1-.51.15l-5.06,0v1.38h3.58a.74.74,0,0,1,.51.15.71.71,0,0,1,.15.5v1.92a.7.7,0,0,1-.15.5.74.74,0,0,1-.51.15h-3.58v1.55H704a.74.74,0,0,1,.51.15.67.67,0,0,1,.15.49v2.11a.67.67,0,0,1-.15.49A.74.74,0,0,1,704,574.84Z"/>
                </g>
                <g id="home_bg" data-name="home bg" className="cls-11">
                  <rect className="cls-12" x="632.91" y="456.55" width="279.21" height="197.78"/>
                </g>
              </g>
              <g id="community"
                onMouseEnter={() => this.onMouseEnterLogic('community')}
                onMouseLeave={() => this.onMouseLeaveLogic('community')}
                ref={this.communityRef}
                onClick={() => this.props.mapClick('community')}
                className={communityClassNames}>
                <g id="community_color" data-name="community color">
                  <polygon className="cls-39" points="689.13 325.54 707.7 314.82 707.7 301.86 689.13 312.11 689.13 325.54"/>
                  <polygon className="cls-40" points="670.8 314.68 670.8 301.91 680.08 296.71 688.88 312.17 688.88 325.11 670.8 314.68"/>
                  <path className="cls-41" d="M680,297.05l8.63,15.19v12.44l-17.57-10.15V302.06l8.94-5m.19-.67-9.63,5.38v13.06l18.57,10.72V312.11l-8.94-15.73Z"/>
                  <polygon className="cls-39" points="689.13 312.11 707.7 301.86 698.17 286.13 680.18 296.38 689.13 312.11"/>
                  <polygon className="cls-6" points="682.36 317.18 682.36 313.66 685.48 315.42 685.48 318.95 682.36 317.18"/>
                  <path className="cls-41" d="M682.62,314.1l2.61,1.47v2.93L682.62,317V314.1m-.52-.89v4.12l3.64,2.06v-4.12l-3.64-2.06Z"/>
                  <polygon className="cls-6" points="674.53 316.82 674.53 309.96 678.17 312.02 678.17 318.88 674.53 316.82"/>
                  <path className="cls-41" d="M674.78,310.41l3.13,1.77v6.26l-3.13-1.77v-6.26m-.51-.89V317l4.15,2.36v-7.46l-4.15-2.35Z"/>
                  <polygon className="cls-39" points="553.77 268.71 571.53 258.46 571.53 246.06 553.77 255.86 553.77 268.71"/>
                  <polygon className="cls-40" points="536.27 258.31 536.27 246.12 545.13 241.16 553.52 255.93 553.52 268.27 536.27 258.31"/>
                  <path className="cls-41" d="M545,241.5l8.24,14.5v11.84l-16.75-9.67v-11.9L545,241.5m.19-.68L536,246v12.49l17.75,10.25V255.86l-8.55-15Z"/>
                  <polygon className="cls-39" points="553.77 255.86 571.53 246.06 562.42 231.03 545.22 240.82 553.77 255.86"/>
                  <polygon className="cls-6" points="547.31 260.71 547.31 257.35 550.29 259.03 550.29 262.4 547.31 260.71"/>
                  <path className="cls-41" d="M547.56,257.77l2.49,1.41V262l-2.49-1.41v-2.8m-.5-.85v3.94l3.48,2v-3.94l-3.48-2Z"/>
                  <polygon className="cls-6" points="539.82 260.37 539.82 253.82 543.3 255.78 543.3 262.34 539.82 260.37"/>
                  <path className="cls-41" d="M540.06,254.24l3,1.69v6l-3-1.69v-6m-.49-.85v7.12l4,2.25v-7.12l-4-2.25Z"/>
                  <polygon className="cls-40" points="603.42 340.35 603.42 326.64 626.87 339.91 626.87 353.62 603.42 340.35"/>
                  <path className="cls-41" d="M603.85,327.37l22.59,12.79v12.73L603.85,340.1V327.37m-.85-1.45v14.67l24.29,13.76V339.67L603,325.92Z"/>
                  <polygon className="cls-39" points="651.59 340.59 627.29 354.35 627.29 339.67 651.59 325.92 651.59 340.59"/>
                  <polygon className="cls-6" points="618.46 348.99 618.46 340.89 622.82 343.36 622.82 351.46 618.46 348.99"/>
                  <path className="cls-41" d="M618.68,341.28l3.91,2.21v7.58l-3.91-2.21v-7.58m-.45-.78v8.62l4.81,2.72v-8.61l-4.81-2.73Z"/>
                  <polygon className="cls-39" points="627.26 312.14 651.59 325.91 627.29 339.67 603 325.91 627.26 312.14"/>
                  <polygon className="cls-6" points="606.51 338.61 606.51 334.65 614.69 339.25 614.69 343.21 606.51 338.61"/>
                  <path className="cls-41" d="M606.73,335l7.73,4.34v3.44l-7.73-4.34V335m-.45-.77v4.47l8.63,4.85v-4.48l-8.63-4.84Z"/>
                  <polygon className="cls-40" points="768.02 260.86 768.02 255.59 777.03 260.69 777.03 265.96 768.02 260.86"/>
                  <path className="cls-41" d="M768.18,255.87l8.69,4.92v4.89l-8.69-4.92v-4.89m-.33-.56V261l9.35,5.29V260.6l-9.35-5.29Z"/>
                  <polygon className="cls-39" points="786.54 260.95 777.2 266.24 777.2 260.6 786.54 255.31 786.54 260.95"/>
                  <polygon className="cls-6" points="773.75 264.16 773.8 261.06 775.52 262.04 775.48 265.13 773.75 264.16"/>
                  <path className="cls-41" d="M773.89,261.21l1.5.86V265l-1.5-.85v-2.92m-.18-.29v3.31l1.85,1V262l-1.85-1Z"/>
                  <polygon className="cls-39" points="777.18 250.01 786.54 255.31 777.2 260.6 767.85 255.31 777.18 250.01"/>
                  <polygon className="cls-6" points="769.16 260.17 769.2 258.66 772.39 260.46 772.35 261.96 769.16 260.17"/>
                  <path className="cls-41" d="M769.29,258.82l3,1.66v1.33l-3-1.67v-1.32m-.17-.3v1.72l3.32,1.86v-1.72l-3.32-1.86Z"/>
                  <polygon className="cls-40" points="620.5 299.6 620.5 283.46 634.95 291.64 634.95 307.77 620.5 299.6"/>
                  <path className="cls-41" d="M620.75,283.89l13.94,7.89v15.56l-13.94-7.89V283.89m-.5-.86v16.71l14.94,8.46V291.49L620.25,283Z"/>
                  <polygon className="cls-6" points="629.47 295.15 629.47 292.61 631.73 293.88 631.73 296.43 629.47 295.15"/>
                  <path className="cls-41" d="M629.69,293l1.82,1v2l-1.82-1v-2m-.44-.75v3l2.7,1.52v-3.05l-2.7-1.52Z"/>
                  <polygon className="cls-6" points="623.72 291.89 623.72 289.35 625.97 290.62 625.97 293.17 623.72 291.89"/>
                  <path className="cls-41" d="M623.94,289.72l1.82,1v2l-1.82-1v-2m-.44-.75v3l2.69,1.53V290.5L623.5,289Z"/>
                  <polygon className="cls-39" points="650.14 299.75 635.2 308.2 635.2 291.49 650.14 283.03 650.14 299.75"/>
                  <polygon className="cls-6" points="626.46 302.99 626.46 298.19 628.98 299.62 628.98 304.42 626.46 302.99"/>
                  <path className="cls-41" d="M626.68,298.57l2.08,1.18V304l-2.08-1.18v-4.29m-.43-.75v5.3l3,1.67v-5.3l-3-1.67Z"/>
                  <polygon className="cls-39" points="635.17 274.57 650.14 283.04 635.2 291.49 620.25 283.04 635.17 274.57"/>
                  <polygon className="cls-40" points="774.61 241.12 774.66 231.87 783.02 236.59 782.97 245.85 774.61 241.12"/>
                  <path className="cls-41" d="M774.76,232l8.11,4.59v9.06l-8.11-4.59V232m-.2-.33v9.5l8.5,4.81v-9.5l-8.5-4.81Z"/>
                  <polygon className="cls-6" points="779.81 238.59 779.81 237.14 781.09 237.87 781.09 239.32 779.81 238.59"/>
                  <path className="cls-41" d="M779.93,237.35l1,.59v1.16l-1-.58v-1.17m-.25-.42v1.73l1.54.87v-1.74l-1.54-.86Z"/>
                  <polygon className="cls-6" points="776.54 236.74 776.54 235.29 777.82 236.01 777.82 237.46 776.54 236.74"/>
                  <path className="cls-41" d="M776.66,235.5l1,.59v1.16l-1-.59V235.5m-.25-.42v1.73l1.53.87v-1.74l-1.53-.86Z"/>
                  <polygon className="cls-39" points="791.56 241.2 783.06 246.01 783.06 236.51 791.56 231.7 791.56 241.2"/>
                  <polygon className="cls-6" points="778.1 243.05 778.1 240.32 779.53 241.13 779.53 243.86 778.1 243.05"/>
                  <path className="cls-41" d="M778.22,240.54l1.19.67v2.44l-1.19-.67v-2.44m-.25-.43v3l1.68,1v-3l-1.68-.95Z"/>
                  <polygon className="cls-39" points="783.05 226.88 791.56 231.7 783.06 236.51 774.56 231.7 783.05 226.88"/>
                  <polygon className="cls-39" points="686.38 388.7 714.85 372.27 714.85 352.4 686.38 368.11 686.38 388.7"/>
                  <polygon className="cls-40" points="658.41 371.98 658.41 352.54 672.48 344.67 685.88 368.24 685.88 387.84 658.41 371.98"/>
                  <path className="cls-41" d="M672.29,345.35l13.09,23V387l-26.47-15.28V352.84l13.38-7.49m.38-1.36-14.76,8.26v20l28.47,16.44v-20.6L672.67,344Z"/>
                  <polygon className="cls-39" points="686.38 368.11 714.85 352.4 700.25 328.28 672.67 343.99 686.38 368.11"/>
                  <polygon className="cls-6" points="675.88 375.96 675.88 370.26 680.93 373.12 680.93 378.82 675.88 375.96"/>
                  <path className="cls-41" d="M676.15,370.71l4.52,2.56v5.1l-4.52-2.56v-5.1m-.53-.9v6.31l5.57,3.15V373l-5.57-3.15Z"/>
                  <polygon className="cls-6" points="663.87 375.41 663.87 364.6 669.71 367.91 669.71 378.72 663.87 375.41"/>
                  <path className="cls-41" d="M664.13,365.05l5.32,3v10.21l-5.32-3V365.05m-.53-.91v11.43l6.38,3.6V367.75l-6.38-3.61Z"/>
                  <path className="cls-40" d="M775.32,296.35v-22.5l18.56,10.45a.18.18,0,0,0,.1,0l.11,0a.22.22,0,0,0,.1-.18V271l16.91,9.57v36Z"/>
                  <path className="cls-41" d="M794.4,271.37l16.5,9.34V316.2l-35.37-20v-22l18.25,10.27a.46.46,0,0,0,.2,0,.37.37,0,0,0,.21-.06.4.4,0,0,0,.21-.35V271.37m-.42-.71v13.46L775.11,273.5v23l36.2,20.44V280.46L794,270.66Z"/>
                  <polygon className="cls-39" points="811.31 280.46 793.98 270.66 793.98 284.12 775.11 273.5 792.42 263.68 828.63 284.13 811.31 293.94 811.31 280.46"/>
                  <polygon className="cls-39" points="811.29 260.84 828.63 270.66 811.31 280.46 793.98 270.66 811.29 260.84"/>
                  <polygon className="cls-39" points="828.63 270.66 828.63 284.13 828.63 307.1 811.31 316.91 811.31 293.94 811.31 280.46 828.63 270.66"/>
                  <polygon className="cls-42" points="800.24 310.31 800.24 302.71 804.36 305.04 804.36 312.64 800.24 310.31"/>
                  <path className="cls-41" d="M800.41,303l3.77,2.13v7.21l-3.77-2.14V303m-.34-.59v8l4.46,2.53v-8l-4.46-2.52Z"/>
                  <polygon className="cls-42" points="790.17 300.97 790.17 296.76 793.89 298.87 793.89 303.07 790.17 300.97"/>
                  <path className="cls-41" d="M790.34,297.06l3.38,1.91v3.81l-3.38-1.91v-3.81m-.34-.59v4.6l4.06,2.3v-4.6l-4.06-2.3Z"/>
                  <polygon className="cls-42" points="780.78 295.55 780.78 291.34 784.5 293.45 784.5 297.65 780.78 295.55"/>
                  <path className="cls-41" d="M781,291.64l3.38,1.91v3.81L781,295.45v-3.81m-.34-.59v4.6l4.06,2.3v-4.6l-4.06-2.3Z"/>
                  <polygon className="cls-42" points="791.02 292.27 791.02 288.07 794.73 290.17 794.73 294.38 791.02 292.27"/>
                  <path className="cls-41" d="M791.19,288.36l3.37,1.91v3.81l-3.37-1.91v-3.81m-.35-.59v4.6l4.07,2.3v-4.6l-4.07-2.3Z"/>
                  <polygon className="cls-42" points="800.83 297.89 800.83 293.68 804.54 295.79 804.54 299.99 800.83 297.89"/>
                  <path className="cls-41" d="M801,294l3.37,1.91v3.81L801,297.79V294m-.35-.59V298l4.07,2.3v-4.6l-4.07-2.3Z"/>
                  <polygon className="cls-42" points="781.2 286.65 781.2 282.45 784.92 284.56 784.92 288.76 781.2 286.65"/>
                  <path className="cls-41" d="M781.38,282.75l3.37,1.91v3.8l-3.37-1.91v-3.8m-.35-.59v4.6l4.06,2.3v-4.6l-4.06-2.3Z"/>
                  <path className="cls-39" d="M799.4,278.5c1.21.15,2.58,1.6,3.06,3.24.48-1.1,1.85-1,3.06.23s1.82,3.12,1.34,4.22a2.7,2.7,0,0,1-.25.4,1.71,1.71,0,0,1-.26.21l-3.89,2.25h0c-.05-.07-3.79-6.48-3.88-6.65s-.27-.51-.27-.51a5.4,5.4,0,0,1-.25-.67c-.48-1.65.12-2.87,1.34-2.72"/>
                  <polygon className="cls-40" points="742.53 223.96 742.53 188.11 759.55 197.75 759.55 233.59 742.53 223.96"/>
                  <path className="cls-41" d="M742.73,188.47l16.62,9.4v35.37l-16.62-9.41V188.47m-.41-.71v36.31l17.44,9.87V197.63l-17.44-9.87Z"/>
                  <polygon className="cls-42" points="753 218.76 753 215.6 755.8 217.18 755.8 220.34 753 218.76"/>
                  <path className="cls-41" d="M753.17,215.89l2.46,1.39v2.77l-2.46-1.39v-2.77m-.34-.59v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-42" points="746.28 214.96 746.28 211.8 749.08 213.38 749.08 216.54 746.28 214.96"/>
                  <path className="cls-41" d="M746.45,212.09l2.46,1.39v2.77l-2.46-1.39v-2.77m-.34-.59v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-42" points="753 210.53 753 207.37 755.8 208.95 755.8 212.12 753 210.53"/>
                  <path className="cls-41" d="M753.17,207.66l2.46,1.39v2.77l-2.46-1.39v-2.77m-.34-.59v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-42" points="746.28 206.73 746.28 203.57 749.08 205.15 749.08 208.31 746.28 206.73"/>
                  <path className="cls-41" d="M746.45,203.86l2.46,1.39V208l-2.46-1.39v-2.77m-.34-.59v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-42" points="753 202.3 753 199.14 755.8 200.72 755.8 203.88 753 202.3"/>
                  <path className="cls-41" d="M753.17,199.43l2.46,1.39v2.77l-2.46-1.39v-2.77m-.34-.59v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-42" points="746.28 198.5 746.28 195.34 749.08 196.92 749.08 200.08 746.28 198.5"/>
                  <path className="cls-41" d="M746.45,195.63l2.46,1.39v2.77l-2.46-1.39v-2.77m-.34-.59v3.56l3.14,1.78v-3.56L746.11,195Z"/>
                  <polygon className="cls-39" points="777.2 224.07 759.76 233.94 759.76 197.63 777.2 187.76 777.2 224.07"/>
                  <polygon className="cls-42" points="749.49 227.91 749.49 222.12 752.59 223.88 752.59 229.67 749.49 227.91"/>
                  <path className="cls-41" d="M749.66,222.42l2.76,1.56v5.4l-2.76-1.57v-5.39m-.35-.59V228l3.46,2v-6.19l-3.46-1.95Z"/>
                  <polygon className="cls-39" points="759.74 177.88 777.2 187.76 759.76 197.63 742.32 187.76 759.74 177.88"/>
                  <polygon className="cls-39" points="638.68 213.72 624.14 222.12 624.14 201.18 638.74 192.92 638.68 213.72"/>
                  <polygon className="cls-39" points="631.01 188.46 638.74 192.92 624.14 201.18 616.43 196.74 631.01 188.46"/>
                  <polygon className="cls-39" points="609.2 173.51 621.99 166.06 631 177.61 631 188.47 616.46 196.71 616.46 185.84 609.2 173.51"/>
                  <path className="cls-40" d="M594.24,204.68V184.47l7.57,4.18a.19.19,0,0,0,.1,0,.23.23,0,0,0,.11,0,.22.22,0,0,0,.1-.18V177.7l7-3.91,7.13,12.1V196.7a.2.2,0,0,0,.1.18l7.59,4.42v20.47Z"/>
                  <path className="cls-41" d="M609.05,174.07,616,186V196.7a.41.41,0,0,0,.21.36l7.48,4.36v20l-29.28-16.85V184.82l7.26,4a.35.35,0,0,0,.2,0,.38.38,0,0,0,.21,0,.42.42,0,0,0,.21-.36V177.82l6.72-3.75m.15-.56-7.31,4.08,0,0v10.86L594,184.12V204.8l30.11,17.32V201.18l-7.68-4.48V185.84l-7.26-12.33Z"/>
                  <polygon className="cls-42" points="599.02 204.48 599.02 200.78 602.3 202.63 602.3 206.34 599.02 204.48"/>
                  <path className="cls-41" d="M599.2,201.07l2.93,1.66V206l-2.93-1.66v-3.31m-.35-.59v4.1l3.62,2v-4.1l-3.62-2.05Z"/>
                  <polygon className="cls-42" points="615.92 213.92 615.92 210.21 619.19 212.07 619.19 215.77 615.92 213.92"/>
                  <path className="cls-41" d="M616.09,210.51l2.93,1.66v3.31l-2.93-1.66v-3.31m-.35-.59V214l3.63,2V212l-3.63-2.05Z"/>
                  <polygon className="cls-42" points="599.02 196.08 599.02 192.38 602.3 194.24 602.3 197.94 599.02 196.08"/>
                  <path className="cls-41" d="M599.2,192.68l2.93,1.66v3.3L599.2,196v-3.3m-.35-.6v4.1l3.62,2.06v-4.11l-3.62-2Z"/>
                  <polygon className="cls-42" points="615.92 205.55 615.92 201.84 619.19 203.7 619.19 207.4 615.92 205.55"/>
                  <path className="cls-41" d="M616.09,202.14,619,203.8v3.31l-2.93-1.66v-3.31m-.35-.59v4.1l3.63,2v-4.1l-3.63-2Z"/>
                  <polygon className="cls-42" points="607.47 200.81 607.47 197.11 610.75 198.96 610.75 202.66 607.47 200.81"/>
                  <path className="cls-41" d="M607.64,197.4l2.94,1.66v3.31l-2.94-1.66V197.4m-.34-.59v4.1l3.62,2.05v-4.1l-3.62-2.05Z"/>
                  <polygon className="cls-42" points="607.27 212.16 607.27 205.35 610.9 207.4 610.9 214.22 607.27 212.16"/>
                  <path className="cls-41" d="M607.44,205.64l3.29,1.86v6.43l-3.29-1.86v-6.43m-.34-.59v7.22l4,2.25V207.3l-4-2.25Z"/>
                  <path className="cls-39" d="M609.11,183.24a7.57,7.57,0,0,1,3.5,5.95c0,2.19-1.57,3.08-3.5,2a7.58,7.58,0,0,1-3.51-5.95C605.6,183,607.18,182.15,609.11,183.24Z"/>
                  <path className="cls-42" d="M607.13,186.38V186l1.69,1,.09,0a.16.16,0,0,0,.09,0,.16.16,0,0,0,.08-.14v-2l.34.19v2.65Z"/>
                  <path className="cls-41" d="M609.25,186.89v.49l-.4-.23h.06a.34.34,0,0,0,.17,0,.37.37,0,0,0,.17-.22m-.34-2.34v2.27l-2-1.11v.77l2.64,1.49v-3l-.68-.38Z"/>
                  <polygon className="cls-39" points="601.91 179.63 601.91 188.45 601.89 188.46 599.72 187.26 594.03 184.1 601.91 179.63"/>
                  <polygon className="cls-40" points="536.23 301.77 536.25 282.69 543.25 278.79 550.35 290.84 550.32 309.75 536.23 301.77"/>
                  <path className="cls-41" d="M543.18,279.06l7,11.84,0,18.49-13.69-7.74,0-18.83,6.72-3.76m.15-.55-7.28,4.06,0,19.32,14.51,8.21,0-19.31-7.23-12.28Z"/>
                  <polygon className="cls-39" points="550.56 290.79 564.6 282.79 557.37 270.5 543.33 278.51 550.56 290.79"/>
                  <polygon className="cls-42" points="541.66 291.39 541.66 287.71 544.92 289.55 544.92 293.24 541.66 291.39"/>
                  <path className="cls-41" d="M541.83,288l2.92,1.65v3.29l-2.92-1.65V288m-.34-.59v4.08l3.6,2v-4.08l-3.6-2Z"/>
                  <polygon className="cls-42" points="541.4 304.52 541.4 297.54 545.17 299.68 545.17 306.66 541.4 304.52"/>
                  <path className="cls-41" d="M541.58,297.84l3.42,1.94v6.58l-3.42-1.94v-6.58m-.35-.59v7.38l4.12,2.32v-7.37l-4.12-2.33Z"/>
                  <polygon className="cls-40" points="550.77 310 550.8 290.92 557.79 287.01 564.89 299.07 564.87 317.97 550.77 310"/>
                  <path className="cls-41" d="M557.72,287.29l7,11.84,0,18.49L551,309.87,551,291l6.72-3.75m.15-.56-7.28,4.07,0,19.31,14.51,8.22,0-19.32-7.23-12.28Z"/>
                  <polygon className="cls-39" points="565.1 299.01 579.14 291.01 571.91 278.73 557.87 286.73 565.1 299.01"/>
                  <polygon className="cls-42" points="556.2 299.62 556.2 295.93 559.46 297.78 559.46 301.46 556.2 299.62"/>
                  <path className="cls-41" d="M556.37,296.23l2.92,1.65v3.29l-2.92-1.65v-3.29m-.34-.59v4.08l3.6,2v-4.08l-3.6-2Z"/>
                  <polygon className="cls-42" points="555.95 312.75 555.95 305.77 559.72 307.9 559.72 314.89 555.95 312.75"/>
                  <path className="cls-41" d="M556.12,306.06l3.42,1.95v6.58l-3.42-1.94v-6.59m-.35-.59v7.38l4.12,2.33V307.8l-4.12-2.33Z"/>
                  <polygon className="cls-39" points="579.61 326.57 593.65 318.57 593.68 299.26 579.64 307.26 579.61 326.57"/>
                  <polygon className="cls-40" points="565.31 318.24 565.34 299.17 572.34 295.26 579.44 307.31 579.41 326.22 565.31 318.24"/>
                  <path className="cls-41" d="M572.26,295.54l7,11.83,0,18.49-13.69-7.74,0-18.83,6.72-3.75m.15-.56L565.13,299l0,19.32,14.51,8.21,0-19.31L572.41,295Z"/>
                  <polygon className="cls-39" points="579.64 307.26 593.68 299.26 586.45 286.98 572.41 294.98 579.64 307.26"/>
                  <polygon className="cls-42" points="570.74 307.86 570.74 304.18 574 306.02 574 309.71 570.74 307.86"/>
                  <path className="cls-41" d="M570.91,304.47l2.92,1.65v3.29l-2.92-1.65v-3.29m-.34-.59V308l3.6,2v-4.08l-3.6-2Z"/>
                  <polygon className="cls-42" points="570.47 321.01 570.47 313.98 574.28 316.14 574.28 323.16 570.47 321.01"/>
                  <path className="cls-41" d="M570.62,314.24l3.5,2v6.67l-3.5-2v-6.68m-.31-.52v7.38l4.12,2.33v-7.38l-4.12-2.33Z"/>
                  <polygon className="cls-39" points="694.74 246.84 704.49 241.21 704.49 234.41 694.74 239.78 694.74 246.84"/>
                  <polygon className="cls-40" points="685.04 241.13 685.08 234.35 690.01 231.65 694.66 239.83 694.65 246.68 685.04 241.13"/>
                  <path className="cls-41" d="M690,231.77l4.58,8.06v6.69l-9.39-5.42v-6.64l4.81-2.69m.07-.24L685,234.35v6.86l9.75,5.62v-7.05l-4.69-8.25Z"/>
                  <polygon className="cls-39" points="694.74 239.78 704.49 234.41 699.49 226.15 690.05 231.53 694.74 239.78"/>
                  <polygon className="cls-42" points="691.1 242.45 691.14 240.52 692.92 241.52 692.88 243.45 691.1 242.45"/>
                  <path className="cls-41" d="M691.24,240.67l1.54.88v1.75l-1.54-.88v-1.75m-.18-.31v2.17l1.91,1.08v-2.17l-1.91-1.08Z"/>
                  <polygon className="cls-42" points="686.99 242.26 687.03 238.58 689.08 239.74 689.03 243.42 686.99 242.26"/>
                  <path className="cls-41" d="M687.12,238.74l1.82,1v3.49l-1.82-1v-3.49m-.18-.31v3.91l2.18,1.23v-3.91l-2.18-1.23Z"/>
                  <polygon className="cls-39" points="675.63 233.61 684.04 228.76 684.04 222.89 675.63 227.53 675.63 233.61"/>
                  <polygon className="cls-40" points="667.26 228.69 667.3 222.84 671.55 220.51 675.56 227.57 675.55 233.48 667.26 228.69"/>
                  <path className="cls-41" d="M671.52,220.62l4,6.95v5.77l-8.1-4.67v-5.73l4.15-2.32m.06-.22-4.36,2.44v5.92l8.41,4.85v-6.08l-4-7.13Z"/>
                  <polygon className="cls-39" points="675.63 227.53 684.04 222.89 679.73 215.76 671.58 220.41 675.63 227.53"/>
                  <polygon className="cls-42" points="672.49 229.83 672.52 228.16 674.06 229.03 674.02 230.7 672.49 229.83"/>
                  <path className="cls-41" d="M672.61,228.3l1.33.75v1.51l-1.33-.76v-1.5m-.16-.27v1.87l1.65.93V229l-1.65-.93Z"/>
                  <polygon className="cls-42" points="668.94 229.66 668.98 226.49 670.75 227.49 670.71 230.66 668.94 229.66"/>
                  <path className="cls-41" d="M669.06,226.63l1.57.88v3l-1.57-.89v-3m-.16-.27v3.37l1.88,1.07v-3.38l-1.88-1.06Z"/>
                  <polygon className="cls-39" points="609.59 275.16 628.16 264.44 628.16 251.47 609.59 261.72 609.59 275.16"/>
                  <polygon className="cls-40" points="591.27 264.29 591.27 251.53 600.55 246.33 609.34 261.79 609.34 274.73 591.27 264.29"/>
                  <path className="cls-41" d="M600.46,246.67l8.63,15.19v12.43l-17.57-10.14V251.67l8.94-5m.18-.68L591,251.38v13.06l18.57,10.72V261.72l-9-15.73Z"/>
                  <polygon className="cls-39" points="609.59 261.72 628.16 251.47 618.63 235.74 600.64 245.99 609.59 261.72"/>
                  <polygon className="cls-42" points="602.74 266.85 602.74 263.13 606.03 264.99 606.03 268.71 602.74 266.85"/>
                  <path className="cls-41" d="M602.91,263.42l3,1.67v3.33l-3-1.67v-3.33m-.34-.59V267l3.64,2.06v-4.12l-3.64-2.06Z"/>
                  <polygon className="cls-42" points="594.9 266.49 594.9 259.43 598.72 261.59 598.72 268.65 594.9 266.49"/>
                  <path className="cls-41" d="M595.08,259.73l3.46,2v6.66l-3.46-2v-6.66m-.35-.59v7.45l4.16,2.35v-7.45l-4.16-2.35Z"/>
                  <polygon className="cls-39" points="751.04 280.85 760.79 275.22 760.79 268.42 751.04 273.8 751.04 280.85"/>
                  <polygon className="cls-40" points="741.4 275.16 741.4 268.43 746.3 265.69 750.95 273.85 750.93 280.66 741.4 275.16"/>
                  <path className="cls-41" d="M746.26,265.84l4.56,8v6.62l-9.31-5.37v-6.6l4.75-2.66m.09-.3-5.06,2.83v6.85l9.75,5.63V273.8l-4.69-8.26Z"/>
                  <polygon className="cls-39" points="751.04 273.8 760.79 268.42 755.79 260.16 746.35 265.54 751.04 273.8"/>
                  <polygon className="cls-42" points="747.4 276.46 747.44 274.53 749.22 275.54 749.18 277.47 747.4 276.46"/>
                  <path className="cls-41" d="M747.54,274.69l1.54.87v1.75l-1.54-.88v-1.74m-.18-.31v2.16l1.9,1.08v-2.16l-1.9-1.08Z"/>
                  <polygon className="cls-42" points="743.29 276.27 743.33 272.59 745.38 273.75 745.34 277.43 743.29 276.27"/>
                  <path className="cls-41" d="M743.42,272.75l1.82,1v3.49l-1.82-1v-3.49m-.18-.31v3.91l2.18,1.23v-3.91l-2.18-1.23Z"/>
                  <polygon className="cls-39" points="714.55 255.09 722.03 250.77 722.03 245.55 714.55 249.68 714.55 255.09"/>
                  <polygon className="cls-40" points="707.12 250.7 707.16 245.51 710.92 243.46 714.48 249.72 714.47 254.94 707.12 250.7"/>
                  <path className="cls-41" d="M710.89,243.57l3.49,6.15v5.08l-7.14-4.13v-5.06l3.65-2m.06-.22-3.88,2.16v5.26l7.48,4.32v-5.41l-3.6-6.33Z"/>
                  <polygon className="cls-39" points="714.55 249.68 722.03 245.55 718.19 239.22 710.95 243.34 714.55 249.68"/>
                  <polygon className="cls-42" points="711.76 251.72 711.79 250.24 713.15 251.01 713.12 252.49 711.76 251.72"/>
                  <path className="cls-41" d="M711.86,250.36l1.19.67v1.34l-1.19-.67v-1.34m-.14-.24v1.66l1.47.83V251l-1.47-.83Z"/>
                  <polygon className="cls-42" points="708.6 251.58 708.64 248.75 710.21 249.65 710.17 252.47 708.6 251.58"/>
                  <path className="cls-41" d="M708.71,248.87l1.39.79v2.69l-1.39-.79v-2.69m-.14-.23v3l1.67.94v-3l-1.67-.94Z"/>
                  <polygon className="cls-39" points="735.31 263.8 742.78 259.48 742.78 254.26 735.31 258.39 735.31 263.8"/>
                  <polygon className="cls-40" points="727.88 259.41 727.92 254.22 731.68 252.17 735.24 258.43 735.23 263.65 727.88 259.41"/>
                  <path className="cls-41" d="M731.64,252.28l3.5,6.15v5.08L728,259.39v-5.07l3.64-2m.07-.22-3.88,2.17v5.25l7.48,4.32v-5.41l-3.6-6.33Z"/>
                  <polygon className="cls-39" points="735.31 258.39 742.78 254.26 738.95 247.93 731.71 252.06 735.31 258.39"/>
                  <polygon className="cls-42" points="732.52 260.43 732.55 258.95 733.91 259.72 733.88 261.2 732.52 260.43"/>
                  <path className="cls-41" d="M732.62,259.07l1.19.67v1.34l-1.19-.67v-1.34m-.14-.24v1.66l1.47.83v-1.66l-1.47-.83Z"/>
                  <polygon className="cls-42" points="729.36 260.29 729.4 257.47 730.97 258.36 730.93 261.18 729.36 260.29"/>
                  <path className="cls-41" d="M729.47,257.59l1.39.79v2.68l-1.39-.79v-2.68m-.14-.24v3l1.67.94v-3l-1.67-.95Z"/>
                  <polygon className="cls-39" points="743.42 247.99 750.42 243.94 750.42 239.06 743.42 242.92 743.42 247.99"/>
                  <polygon className="cls-40" points="736.46 243.88 736.49 239.02 740.01 237.1 743.35 242.96 743.34 247.85 736.46 243.88"/>
                  <path className="cls-41" d="M740,237.2l3.27,5.76v4.76l-6.69-3.87v-4.74L740,237.2m0-.21-3.62,2v4.92l7,4.05v-5.07L740,237Z"/>
                  <polygon className="cls-39" points="743.42 242.92 750.42 239.06 746.83 233.13 740.04 236.99 743.42 242.92"/>
                  <polygon className="cls-42" points="740.8 244.83 740.83 243.45 742.11 244.17 742.08 245.56 740.8 244.83"/>
                  <path className="cls-41" d="M740.9,243.56l1.11.63v1.25l-1.11-.63v-1.25m-.13-.22v1.55l1.37.78v-1.56l-1.37-.77Z"/>
                  <polygon className="cls-42" points="737.85 244.7 737.88 242.06 739.35 242.89 739.32 245.53 737.85 244.7"/>
                  <path className="cls-41" d="M738,242.17l1.3.74v2.51l-1.3-.74v-2.51m-.13-.22v2.8l1.56.89v-2.81l-1.56-.88Z"/>
                  <polygon className="cls-39" points="773.94 322.71 780.94 318.67 780.94 313.79 773.94 317.65 773.94 322.71"/>
                  <polygon className="cls-40" points="766.98 318.6 767.02 313.75 770.54 311.83 773.87 317.69 773.86 322.58 766.98 318.6"/>
                  <path className="cls-41" d="M770.51,311.93l3.28,5.76v4.75l-6.69-3.86v-4.74l3.41-1.91m.06-.21-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-39" points="773.94 317.65 780.94 313.79 777.35 307.86 770.57 311.72 773.94 317.65"/>
                  <polygon className="cls-42" points="771.33 319.56 771.36 318.18 772.63 318.9 772.6 320.28 771.33 319.56"/>
                  <path className="cls-41" d="M771.43,318.29l1.11.63v1.25l-1.11-.63v-1.25m-.13-.22v1.55l1.37.77v-1.55l-1.37-.77Z"/>
                  <polygon className="cls-42" points="768.38 319.43 768.41 316.79 769.88 317.62 769.85 320.26 768.38 319.43"/>
                  <path className="cls-41" d="M768.47,316.9l1.31.74v2.51l-1.31-.74V316.9m-.13-.23v2.81l1.57.89v-2.81l-1.57-.89Z"/>
                  <polygon className="cls-39" points="780.94 326.71 787.94 322.67 787.94 317.78 780.94 321.65 780.94 326.71"/>
                  <polygon className="cls-40" points="773.98 322.6 774.02 317.75 777.54 315.82 780.87 321.68 780.86 326.57 773.98 322.6"/>
                  <path className="cls-41" d="M777.51,315.93l3.27,5.76v4.75l-6.68-3.86v-4.74l3.41-1.91m.06-.21-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-39" points="780.94 321.65 787.94 317.78 784.35 311.86 777.57 315.72 780.94 321.65"/>
                  <polygon className="cls-42" points="778.33 323.56 778.36 322.17 779.63 322.89 779.6 324.28 778.33 323.56"/>
                  <path className="cls-41" d="M778.42,322.29l1.11.62v1.26l-1.11-.63v-1.25m-.13-.23v1.55l1.37.78v-1.55l-1.37-.78Z"/>
                  <polygon className="cls-42" points="775.37 323.42 775.4 320.78 776.87 321.62 776.84 324.25 775.37 323.42"/>
                  <path className="cls-41" d="M775.47,320.89l1.31.74v2.51l-1.31-.74v-2.51m-.13-.22v2.81l1.57.89v-2.81l-1.57-.89Z"/>
                  <polygon className="cls-39" points="787.94 330.79 794.93 326.75 794.93 321.87 787.94 325.73 787.94 330.79"/>
                  <polygon className="cls-40" points="780.98 326.68 781.02 321.83 784.53 319.91 787.87 325.77 787.86 330.66 780.98 326.68"/>
                  <path className="cls-41" d="M784.51,320l3.27,5.76v4.75l-6.69-3.86v-4.74l3.42-1.91m.06-.21-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-39" points="787.94 325.73 794.93 321.87 791.34 315.94 784.57 319.8 787.94 325.73"/>
                  <polygon className="cls-42" points="785.32 327.64 785.35 326.26 786.63 326.98 786.6 328.36 785.32 327.64"/>
                  <path className="cls-41" d="M785.42,326.37l1.11.63v1.25l-1.11-.63v-1.25m-.13-.22v1.55l1.37.77v-1.55l-1.37-.77Z"/>
                  <polygon className="cls-42" points="782.37 327.5 782.4 324.87 783.87 325.7 783.84 328.34 782.37 327.5"/>
                  <path className="cls-41" d="M782.47,325l1.3.74v2.51l-1.3-.74V325m-.13-.22v2.8l1.56.89v-2.81l-1.56-.88Z"/>
                  <polygon className="cls-39" points="634.89 239.82 643.64 234.77 643.64 228.66 634.89 233.49 634.89 239.82"/>
                  <polygon className="cls-40" points="626.19 234.69 626.24 228.62 630.64 226.21 634.81 233.54 634.8 239.66 626.19 234.69"/>
                  <path className="cls-41" d="M630.61,226.35l4.09,7.19v6l-8.36-4.83v-5.93l4.27-2.38m.07-.27-4.54,2.54v6.15l8.75,5v-6.33l-4.21-7.41Z"/>
                  <polygon className="cls-39" points="634.89 233.49 643.64 228.66 639.15 221.25 630.68 226.08 634.89 233.49"/>
                  <polygon className="cls-42" points="631.63 235.88 631.66 234.15 633.26 235.06 633.22 236.79 631.63 235.88"/>
                  <path className="cls-41" d="M631.75,234.29l1.39.79v1.57l-1.39-.79v-1.57m-.16-.28V236l1.71,1V235l-1.71-1Z"/>
                  <polygon className="cls-42" points="627.93 235.72 627.97 232.41 629.81 233.45 629.77 236.75 627.93 235.72"/>
                  <path className="cls-41" d="M628.06,232.55l1.63.93v3.14l-1.63-.93v-3.14m-.17-.27v3.51l2,1.1v-3.51l-2-1.1Z"/>
                  <polygon className="cls-39" points="643.64 244.82 652.39 239.77 652.39 233.66 643.64 238.49 643.64 244.82"/>
                  <polygon className="cls-40" points="634.94 239.69 634.99 233.62 639.39 231.21 643.56 238.54 643.55 244.65 634.94 239.69"/>
                  <path className="cls-41" d="M639.36,231.34l4.09,7.2v5.94l-8.36-4.82v-5.93l4.27-2.39m.07-.26-4.54,2.54v6.15l8.75,5v-6.33l-4.21-7.41Z"/>
                  <polygon className="cls-39" points="643.64 238.49 652.39 233.66 647.9 226.25 639.43 231.08 643.64 238.49"/>
                  <polygon className="cls-42" points="640.38 240.88 640.41 239.15 642.01 240.05 641.97 241.78 640.38 240.88"/>
                  <path className="cls-41" d="M640.5,239.29l1.39.79v1.56l-1.39-.78v-1.57m-.16-.28V241l1.71,1V240l-1.71-1Z"/>
                  <polygon className="cls-42" points="636.68 240.71 636.72 237.41 638.56 238.45 638.52 241.75 636.68 240.71"/>
                  <path className="cls-41" d="M636.81,237.55l1.63.93v3.13l-1.63-.92v-3.14m-.17-.28v3.51l2,1.11v-3.51l-2-1.11Z"/>
                  <polygon className="cls-39" points="652.39 249.93 661.14 244.88 661.14 238.77 652.39 243.6 652.39 249.93"/>
                  <polygon className="cls-40" points="643.69 244.79 643.74 238.72 648.14 236.32 652.31 243.65 652.3 249.76 643.69 244.79"/>
                  <path className="cls-41" d="M648.11,236.45l4.09,7.2v5.94l-8.36-4.83v-5.92l4.27-2.39m.07-.26-4.54,2.53v6.16l8.75,5.05V243.6l-4.21-7.41Z"/>
                  <polygon className="cls-39" points="652.39 243.6 661.14 238.77 656.65 231.36 648.18 236.19 652.39 243.6"/>
                  <polygon className="cls-42" points="649.13 245.99 649.16 244.26 650.76 245.16 650.72 246.89 649.13 245.99"/>
                  <path className="cls-41" d="M649.25,244.4l1.39.78v1.57l-1.39-.79V244.4m-.16-.28v1.94l1.71,1v-1.94l-1.71-1Z"/>
                  <polygon className="cls-42" points="645.43 245.82 645.47 242.52 647.31 243.56 647.27 246.86 645.43 245.82"/>
                  <path className="cls-41" d="M645.56,242.66l1.63.92v3.14l-1.63-.93v-3.13m-.17-.28v3.51l2,1.11v-3.51l-2-1.11Z"/>
                  <polygon className="cls-12" points="735.67 187.82 738.41 186.31 738.41 187.78 735.67 189.36 735.67 187.82"/>
                  <path className="cls-12" d="M737.91,187.15v.34l-1.74,1v-.38l1.74-1m1-1.69-3.74,2.06v2.71l3.74-2.16v-2.61Z"/>
                  <polygon className="cls-12" points="731.48 187.99 731.52 185.44 733.34 184.48 735.09 187.57 735.08 190.07 731.48 187.99"/>
                  <path className="cls-12" d="M733.3,184.6l1.69,3v2.34L731.61,188v-2.41l1.69-1m.07-.24-1.94,1.08v2.63l3.74,2.16v-2.71l-1.8-3.16Z"/>
                  <polygon className="cls-12" points="734.05 184.54 736.82 182.97 738.21 185.27 735.36 186.85 734.05 184.54"/>
                  <path className="cls-12" d="M736.64,183.64l.87,1.45-2,1.08-.82-1.44,1.91-1.09m.35-1.35-3.62,2.07,1.8,3.16,3.74-2.06L737,182.29Z"/>
                  <polygon className="cls-12" points="713.15 202.8 717.76 200.25 717.76 203.03 713.15 205.69 713.15 202.8"/>
                  <path className="cls-12" d="M717.26,201.1v1.64l-3.61,2.09v-1.74l3.61-2m1-1.69-5.61,3.09v4.06l5.61-3.24v-3.91Z"/>
                  <polygon className="cls-12" points="707.09 203.24 707.13 199.38 709.92 197.87 712.57 202.55 712.56 206.4 707.09 203.24"/>
                  <path className="cls-12" d="M709.88,198l2.59,4.55v3.7l-5.25-3v-3.73l2.66-1.49m.07-.25L707,199.38v3.94l5.61,3.24V202.5l-2.7-4.75Z"/>
                  <polygon className="cls-12" points="710.63 197.94 715.21 195.33 717.56 199.22 712.84 201.83 710.63 197.94"/>
                  <path className="cls-12" d="M715,196l1.83,3L713,201.15l-1.72-3L715,196m.35-1.35L710,197.75l2.7,4.75,5.61-3.09-2.88-4.75Z"/>
                  <polygon className="cls-12" points="695.56 214.84 701.55 211.53 701.55 215.28 695.56 218.74 695.56 214.84"/>
                  <path className="cls-12" d="M701.06,212.38V215l-5,2.88v-2.74l5-2.75m1-1.7-7,3.86v5.07l7-4v-4.89Z"/>
                  <polygon className="cls-12" points="688.1 215.49 688.15 210.65 691.65 208.74 694.98 214.59 694.97 219.45 688.1 215.49"/>
                  <path className="cls-12" d="M691.62,208.86l3.26,5.73v4.7l-6.64-3.83v-4.71l3.38-1.89m.07-.24-3.63,2v4.92l7,4v-5.07l-3.37-5.92Z"/>
                  <polygon className="cls-12" points="692.37 208.8 698.29 205.43 701.36 210.5 695.25 213.87 692.37 208.8"/>
                  <path className="cls-12" d="M698.11,206.11l2.55,4.2-5.22,2.88-2.39-4.2,5.06-2.88m.36-1.36-6.78,3.87,3.37,5.92,7-3.86-3.59-5.93Z"/>
                  <polygon className="cls-12" points="724.25 213.99 724.29 210.6 730.16 213.92 730.11 217.31 724.25 213.99"/>
                  <path className="cls-12" d="M724.38,210.75l5.64,3.19v3.22l-5.64-3.2v-3.21m-.18-.31v3.63l6,3.39v-3.62l-6-3.4Z"/>
                  <polygon className="cls-12" points="730.7 214.13 735.7 211.3 735.7 213.78 730.7 216.61 730.7 214.13"/>
                  <path className="cls-12" d="M735.2,212.16v1.33l-4,2.26v-1.33l4-2.26m1-1.72-6,3.4v3.62l6-3.39v-3.63Z"/>
                  <polygon className="cls-12" points="725.22 210.44 730.19 207.62 735.19 210.44 730.2 213.26 725.22 210.44"/>
                  <path className="cls-12" d="M730.19,208.19l4,2.25-4,2.25-4-2.25,4-2.25m0-1.15-6,3.4,6,3.4,6-3.4-6-3.4Z"/>
                  <polygon className="cls-12" points="659.04 194.51 665.03 191.2 665.03 194.95 659.04 198.41 659.04 194.51"/>
                  <path className="cls-12" d="M664.53,192.05v2.61l-5,2.89v-2.74l5-2.76m1-1.7-7,3.87v5.06l7-4v-4.89Z"/>
                  <polygon className="cls-12" points="651.58 195.16 651.63 190.32 655.13 188.41 658.46 194.26 658.45 199.12 651.58 195.16"/>
                  <path className="cls-12" d="M655.1,188.53l3.26,5.73V199l-6.64-3.84v-4.71l3.38-1.89m.07-.24-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-12" points="655.85 188.48 661.77 185.1 664.84 190.17 658.73 193.54 655.85 188.48"/>
                  <path className="cls-12" d="M661.59,185.78l2.55,4.2-5.22,2.88-2.39-4.2,5.06-2.88m.35-1.35-6.77,3.86,3.37,5.93,7-3.87-3.59-5.92Z"/>
                  <polygon className="cls-12" points="696.03 192.97 700.54 190.48 700.54 193.19 696.03 195.79 696.03 192.97"/>
                  <path className="cls-12" d="M700,191.33v1.57l-3.51,2v-1.66l3.51-1.94m1-1.69-5.51,3v4l5.51-3.18v-3.84Z"/>
                  <polygon className="cls-12" points="690.07 193.4 690.11 189.61 692.85 188.13 695.46 192.72 695.44 196.51 690.07 193.4"/>
                  <path className="cls-12" d="M692.81,188.25l2.54,4.47v3.63l-5.15-3v-3.67l2.61-1.46m.07-.24-2.86,1.6v3.87l5.51,3.18v-4L692.88,188Z"/>
                  <polygon className="cls-12" points="693.56 188.2 698.04 185.65 700.35 189.45 695.72 192 693.56 188.2"/>
                  <path className="cls-12" d="M697.86,186.32l1.79,2.94-3.74,2.06-1.67-2.94,3.62-2.06m.36-1.35-5.34,3,2.65,4.67,5.51-3L698.22,185Z"/>
                  <polygon className="cls-12" points="670.99 206.26 671.03 198.13 678.38 202.29 678.33 210.42 670.99 206.26"/>
                  <path className="cls-12" d="M671.12,198.29l7.12,4v7.95l-7.12-4v-7.94m-.18-.31v8.36l7.48,4.23v-8.36L670.94,198Z"/>
                  <polygon className="cls-12" points="678.92 202.5 685.39 198.84 685.39 206.05 678.92 209.71 678.92 202.5"/>
                  <path className="cls-12" d="M684.89,199.69v6.07l-5.47,3.1v-6.07l5.47-3.1m1-1.71-7.47,4.23v8.36l7.47-4.23V198Z"/>
                  <polygon className="cls-12" points="671.96 197.98 678.41 194.32 684.88 197.98 678.42 201.64 671.96 197.98"/>
                  <path className="cls-12" d="M678.41,194.89l5.45,3.09-5.44,3.08L673,198l5.44-3.09m0-1.15L670.94,198l7.48,4.23,7.47-4.23-7.48-4.24Z"/>
                  <polygon className="cls-12" points="643.62 185.77 649.16 182.71 649.16 186.14 643.62 189.34 643.62 185.77"/>
                  <path className="cls-12" d="M648.66,183.56v2.3l-4.54,2.62v-2.41l4.54-2.51m1-1.69-6.54,3.61v4.73l6.54-3.78v-4.56Z"/>
                  <polygon className="cls-12" points="636.62 186.35 636.67 181.83 639.93 180.06 643.04 185.52 643.03 190.05 636.62 186.35"/>
                  <path className="cls-12" d="M639.9,180.18l3,5.35v4.37l-6.18-3.57v-4.39l3.14-1.76m.07-.24-3.39,1.89v4.6l6.54,3.78v-4.73L640,179.94Z"/>
                  <polygon className="cls-12" points="640.65 180.12 646.13 177 648.96 181.68 643.31 184.8 640.65 180.12"/>
                  <path className="cls-12" d="M646,177.68l2.31,3.82-4.76,2.63-2.17-3.82,4.62-2.63m.35-1.35L640,179.94l3.15,5.54,6.54-3.61-3.36-5.54Z"/>
                  <polygon className="cls-12" points="726.03 194.55 729.7 192.52 729.7 194.65 726.03 196.77 726.03 194.55"/>
                  <path className="cls-12" d="M729.2,193.37v1l-2.67,1.54v-1.06l2.67-1.47m1-1.69-4.67,2.57v3.38l4.67-2.69v-3.26Z"/>
                  <polygon className="cls-12" points="720.9 194.86 720.95 191.65 723.24 190.42 725.45 194.3 725.44 197.48 720.9 194.86"/>
                  <path className="cls-12" d="M723.21,190.54l2.14,3.76v3L721,194.83v-3.07l2.17-1.22m.07-.24-2.42,1.35v3.29l4.67,2.69v-3.38l-2.25-3.95Z"/>
                  <polygon className="cls-12" points="723.96 190.48 727.63 188.39 729.5 191.49 725.72 193.58 723.96 190.48"/>
                  <path className="cls-12" d="M727.45,189.07l1.36,2.23-2.9,1.6-1.27-2.23,2.81-1.6m.35-1.35-4.52,2.58,2.25,3.95,4.67-2.57-2.4-4Z"/>
                  <polygon className="cls-12" points="673.77 175.12 676.68 173.52 676.68 175.11 673.77 176.79 673.77 175.12"/>
                  <path className="cls-12" d="M676.18,174.37v.45l-1.91,1.1v-.5l1.91-1m1-1.7-3.91,2.16v2.83l3.91-2.26v-2.73Z"/>
                  <polygon className="cls-12" points="669.41 175.32 669.46 172.65 671.36 171.64 673.2 174.87 673.18 177.5 669.41 175.32"/>
                  <path className="cls-12" d="M671.32,171.76l1.77,3.12v2.46l-3.54-2v-2.54l1.77-1m.07-.24-2,1.13v2.75l3.9,2.26v-2.83l-1.88-3.31Z"/>
                  <polygon className="cls-12" points="672.07 171.71 675 170.04 676.48 172.49 673.46 174.15 672.07 171.71"/>
                  <path className="cls-12" d="M674.83,170.71l1,1.59-2.14,1.18-.9-1.59,2.08-1.18m.35-1.35-3.79,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                  <polygon className="cls-12" points="720.89 178.25 723.8 176.64 723.8 178.23 720.89 179.91 720.89 178.25"/>
                  <path className="cls-12" d="M723.3,177.49v.45l-1.91,1.1v-.5l1.91-1m1-1.7L720.39,178v2.83l3.91-2.26v-2.73Z"/>
                  <polygon className="cls-12" points="716.53 178.44 716.58 175.77 718.48 174.76 720.31 178 720.3 180.62 716.53 178.44"/>
                  <path className="cls-12" d="M718.44,174.88l1.77,3.12v2.47l-3.54-2.05v-2.54l1.77-1m.07-.24-2,1.13v2.75l3.9,2.26V178l-1.88-3.31Z"/>
                  <polygon className="cls-12" points="719.19 174.83 722.12 173.16 723.6 175.61 720.58 177.27 719.19 174.83"/>
                  <path className="cls-12" d="M721.94,173.83l1,1.59-2.14,1.18-.9-1.59,2.07-1.18m.36-1.35-3.79,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                  <polygon className="cls-12" points="711.83 183.85 715.5 181.83 715.5 183.95 711.83 186.07 711.83 183.85"/>
                  <path className="cls-12" d="M715,182.67v1l-2.67,1.55v-1.06l2.67-1.48m1-1.69-4.67,2.58v3.38l4.67-2.7V181Z"/>
                  <polygon className="cls-12" points="706.7 184.16 706.74 180.96 709.04 179.72 711.25 183.6 711.24 186.78 706.7 184.16"/>
                  <path className="cls-12" d="M709,179.84l2.14,3.76v3l-4.32-2.49v-3.08l2.18-1.22m.06-.24L706.65,181v3.29l4.68,2.7v-3.38l-2.26-4Z"/>
                  <polygon className="cls-12" points="709.76 179.79 713.42 177.7 715.3 180.79 711.52 182.88 709.76 179.79"/>
                  <path className="cls-12" d="M713.25,178.37l1.35,2.24-2.89,1.59L710.44,180l2.81-1.6m.35-1.35-4.53,2.58,2.26,4L716,181l-2.4-4Z"/>
                  <polygon className="cls-12" points="676.7 187.59 680.58 185.45 680.58 187.72 676.7 189.97 676.7 187.59"/>
                  <path className="cls-12" d="M680.08,186.3v1.14l-2.88,1.66v-1.21l2.88-1.59m1-1.7-4.88,2.7v3.53l4.88-2.82V184.6Z"/>
                  <polygon className="cls-12" points="671.36 187.93 671.4 184.58 673.81 183.28 676.12 187.34 676.11 190.68 671.36 187.93"/>
                  <path className="cls-12" d="M673.78,183.41l2.24,3.94v3.17l-4.53-2.61v-3.23l2.29-1.27m.06-.25-2.53,1.42V188l4.89,2.82V187.3l-2.36-4.14Z"/>
                  <polygon className="cls-12" points="674.52 183.35 678.4 181.14 680.38 184.42 676.39 186.62 674.52 183.35"/>
                  <path className="cls-12" d="M678.22,181.82l1.47,2.41L676.58,186l-1.37-2.41,3-1.72m.35-1.35-4.73,2.69,2.36,4.14,4.88-2.7-2.51-4.13Z"/>
                  <polygon className="cls-12" points="658.41 175.57 662.29 173.43 662.29 175.7 658.41 177.94 658.41 175.57"/>
                  <path className="cls-12" d="M661.79,174.27v1.14l-2.88,1.67v-1.22l2.88-1.59m1-1.69-4.88,2.69v3.54l4.88-2.82v-3.41Z"/>
                  <polygon className="cls-12" points="653.07 175.91 653.11 172.55 655.52 171.26 657.83 175.32 657.82 178.65 653.07 175.91"/>
                  <path className="cls-12" d="M655.49,171.38l2.24,3.94v3.18l-4.53-2.62v-3.22l2.29-1.28m.07-.24L653,172.55V176l4.89,2.82v-3.54l-2.35-4.13Z"/>
                  <polygon className="cls-12" points="656.24 171.32 660.11 169.12 662.1 172.39 658.1 174.6 656.24 171.32"/>
                  <path className="cls-12" d="M659.94,169.79l1.46,2.42-3.11,1.71-1.37-2.41,3-1.72m.35-1.35-4.73,2.7,2.35,4.13,4.88-2.69-2.5-4.14Z"/>
                  <polygon className="cls-12" points="683.27 180.86 683.32 178.48 687.51 180.85 687.47 183.24 683.27 180.86"/>
                  <path className="cls-12" d="M683.41,178.64l4,2.24v2.2l-4-2.24v-2.2m-.18-.31v2.61l4.33,2.45v-2.61l-4.33-2.45Z"/>
                  <polygon className="cls-12" points="688.06 181.07 691.38 179.19 691.38 180.65 688.06 182.53 688.06 181.07"/>
                  <path className="cls-12" d="M690.88,180v.32l-2.32,1.31v-.31l2.32-1.32m1-1.71-4.32,2.45v2.61l4.32-2.45v-2.61Z"/>
                  <polygon className="cls-12" points="684.25 178.33 687.55 176.45 690.87 178.33 687.56 180.2 684.25 178.33"/>
                  <path className="cls-12" d="M687.55,177l2.3,1.3-2.29,1.3-2.3-1.3,2.29-1.3m0-1.15-4.32,2.45,4.33,2.45,4.32-2.45-4.33-2.45Z"/>
                  <polygon className="cls-12" points="697.26 175.12 700.17 173.52 700.17 175.11 697.26 176.79 697.26 175.12"/>
                  <path className="cls-12" d="M699.67,174.37v.45l-1.91,1.1v-.5l1.91-1m1-1.7-3.91,2.16v2.83l3.91-2.26v-2.73Z"/>
                  <polygon className="cls-12" points="692.9 175.32 692.94 172.65 694.84 171.64 696.68 174.87 696.67 177.5 692.9 175.32"/>
                  <path className="cls-12" d="M694.81,171.76l1.77,3.12v2.46l-3.55-2v-2.54l1.78-1m.07-.24-2,1.13v2.75l3.91,2.26v-2.83l-1.88-3.31Z"/>
                  <polygon className="cls-12" points="695.56 171.71 698.49 170.04 699.97 172.49 696.95 174.15 695.56 171.71"/>
                  <path className="cls-12" d="M698.31,170.71l1,1.59-2.13,1.18-.9-1.59,2.07-1.18m.35-1.35-3.78,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                  <polygon className="cls-12" points="669.28 166.8 671.38 165.64 671.38 166.66 669.28 167.88 669.28 166.8"/>
                  <polygon className="cls-12" points="671.88 164.79 668.78 166.5 668.78 168.75 671.88 166.95 671.88 164.79 671.88 164.79"/>
                  <polygon className="cls-12" points="665.72 166.88 665.76 164.77 667.25 163.99 668.7 166.55 668.69 168.59 665.72 166.88"/>
                  <path className="cls-12" d="M667.21,164.12l1.39,2.43v1.88l-2.75-1.58v-2l1.36-.76m.07-.25-1.61.9V167l3.11,1.8V166.5l-1.5-2.63Z"/>
                  <polygon className="cls-12" points="667.96 164.06 670.11 162.84 671.18 164.6 668.97 165.83 667.96 164.06"/>
                  <path className="cls-12" d="M669.94,163.51l.54.91-1.32.73-.52-.9,1.3-.74m.35-1.35-3,1.71,1.5,2.63,3.1-1.71-1.59-2.63Z"/>
                  <polygon className="cls-12" points="683.73 155.9 684.97 155.21 684.97 155.64 683.73 156.36 683.73 155.9"/>
                  <polygon className="cls-12" points="685.47 154.37 683.23 155.6 683.23 157.23 685.47 155.93 685.47 154.37 685.47 154.37"/>
                  <polygon className="cls-12" points="681.03 155.85 681.08 154.35 682.12 153.82 683.15 155.65 683.14 157.07 681.03 155.85"/>
                  <path className="cls-12" d="M682.08,154l1,1.7v1.26l-1.88-1.08v-1.37l.91-.51m.07-.25-1.16.65v1.58l2.24,1.3V155.6l-1.08-1.9Z"/>
                  <polygon className="cls-12" points="682.83 153.89 684.15 153.14 684.78 154.18 683.42 154.93 682.83 153.89"/>
                  <path className="cls-12" d="M684,153.82l.11.17-.47.26-.1-.17.46-.26m.35-1.35-2.17,1.23,1.08,1.9,2.24-1.23-1.15-1.9Z"/>
                  <polygon className="cls-12" points="697.26 164.77 699.36 163.61 699.36 164.64 697.26 165.85 697.26 164.77"/>
                  <polygon className="cls-12" points="699.86 162.76 696.76 164.48 696.76 166.72 699.86 164.93 699.86 162.76 699.86 162.76"/>
                  <polygon className="cls-12" points="693.7 164.85 693.75 162.75 695.23 161.97 696.68 164.52 696.67 166.57 693.7 164.85"/>
                  <path className="cls-12" d="M695.2,162.09l1.38,2.43v1.89l-2.74-1.58v-2l1.36-.76m.06-.24-1.6.9v2.18l3.1,1.79v-2.24l-1.5-2.63Z"/>
                  <polygon className="cls-12" points="695.95 162.04 698.09 160.81 699.16 162.58 696.95 163.8 695.95 162.04"/>
                  <path className="cls-12" d="M697.92,161.49l.55.9-1.33.73-.51-.9,1.29-.73m.35-1.35-3,1.71,1.5,2.63,3.1-1.71-1.59-2.63Z"/>
                  <polygon className="cls-12" points="690.22 159.57 691.83 158.68 691.83 159.37 690.22 160.3 690.22 159.57"/>
                  <polygon className="cls-12" points="692.33 157.83 689.72 159.28 689.72 161.17 692.33 159.66 692.33 157.83 692.33 157.83"/>
                  <polygon className="cls-12" points="687.15 159.58 687.19 157.82 688.42 157.18 689.64 159.32 689.63 161.01 687.15 159.58"/>
                  <path className="cls-12" d="M688.39,157.3l1.15,2v1.54l-2.26-1.31v-1.63l1.11-.62m.07-.24-1.36.76v1.84l2.62,1.51v-1.9l-1.26-2.21Z"/>
                  <polygon className="cls-12" points="689.14 157.25 690.82 156.29 691.64 157.64 689.91 158.6 689.14 157.25"/>
                  <path className="cls-12" d="M690.64,157l.3.49-.84.46-.28-.49.82-.46m.35-1.36-2.53,1.45,1.26,2.21,2.61-1.44L691,155.61Z"/>
                  <polygon className="cls-12" points="682.68 169.05 682.73 165.64 685.85 167.41 685.81 170.82 682.68 169.05"/>
                  <path className="cls-12" d="M682.82,165.8l2.89,1.64v3.22L682.82,169V165.8m-.18-.31v3.64l3.25,1.84v-3.64l-3.25-1.84Z"/>
                  <polygon className="cls-12" points="686.39 167.62 688.65 166.35 688.65 168.84 686.39 170.12 686.39 167.62"/>
                  <path className="cls-12" d="M688.15,167.2v1.35l-1.26.71v-1.35l1.26-.71m1-1.71-3.26,1.84V171l3.26-1.84v-3.64Z"/>
                  <polygon className="cls-12" points="683.65 165.49 685.89 164.22 688.14 165.49 685.89 166.76 683.65 165.49"/>
                  <path className="cls-12" d="M685.89,164.79l1.23.7-1.23.69-1.22-.69,1.22-.7m0-1.15-3.25,1.85,3.25,1.84,3.26-1.84-3.26-1.85Z"/>
                  <polygon className="cls-12" points="674.25 160.99 674.29 159.25 677.42 161.02 677.38 162.76 674.25 160.99"/>
                  <path className="cls-12" d="M674.39,159.41l2.89,1.64v1.55L674.39,161v-1.55m-.18-.31v2l3.25,1.84v-2l-3.25-1.84Z"/>
                  <polygon className="cls-12" points="677.96 161.24 680.22 159.96 680.22 160.78 677.96 162.06 677.96 161.24"/>
                  <polygon className="cls-12" points="680.72 159.1 677.46 160.94 677.46 162.91 680.72 161.07 680.72 159.1 680.72 159.1"/>
                  <polygon className="cls-12" points="675.22 159.1 677.46 157.83 679.7 159.1 677.46 160.37 675.22 159.1"/>
                  <path className="cls-12" d="M677.46,158.41l1.23.69-1.23.7-1.23-.7,1.23-.69m0-1.15-3.25,1.84,3.25,1.85,3.26-1.85-3.26-1.84Z"/>
                  <polygon className="cls-12" points="702.51 170.26 702.56 168.19 704.33 168.02 706.05 170.35 706.03 172.32 702.51 170.26"/>
                  <path className="cls-12" d="M704.24,168.12l1.7,2.23v1.81l-3.29-1.92v-2l1.59-.16m.08-.19-1.85.18v2.23l3.65,2.13v-2.18l-1.8-2.36Z"/>
                  <path className="cls-12" d="M705.58,168.83l.78-.45v-1.94l1.45-.83,1.71,2.22-3.25,1.89Zm-.84-3.46h.15l.36.46-.1.06Z"/>
                  <path className="cls-12" d="M707.68,166.26l1.1,1.42-2.38,1.38-.07-.08,0,0,.5-.29v-1.94l0,0,.77-.44m-2.56-1.42-.85.07-.82.47.12.3.73-.07.73.91.83-.48v2.06l-.84.48-.73-.91-.73.07v-.22l-1.09.63h0l1.84-.18,1.85,2.4,4.13-2.4-2.33-3-1.52.86-.36.21-.94-1.2Z"/>
                  <polygon className="cls-12" points="706.62 170.63 709.57 168.94 709.57 169.89 706.62 171.61 706.62 170.63"/>
                  <polygon className="cls-12" points="710.07 168.07 706.12 170.34 706.12 172.47 710.07 170.18 710.07 168.07 710.07 168.07"/>
                  <polyline className="cls-43" points="704.3 165.61 705.03 166.52 705.86 166.04 705.86 168.09 705.02 168.58 704.29 167.67"/>
                  <polygon className="cls-43" points="705.03 166.5 705.88 166.02 705.88 168.12 705.02 168.6 705.03 166.5"/>
                  <polyline className="cls-43" points="704.29 167.66 703.57 167.74 703.57 165.68 704.29 165.62"/>
                  <polygon className="cls-39" points="804.53 250.04 811.52 246 811.52 241.12 804.53 244.98 804.53 250.04"/>
                  <polygon className="cls-40" points="797.57 245.94 797.61 241.08 801.13 239.16 804.46 245.02 804.45 249.91 797.57 245.94"/>
                  <path className="cls-41" d="M801.1,239.26l3.27,5.76v4.75l-6.69-3.86v-4.74l3.42-1.91m.06-.21-3.63,2V246l7,4V245l-3.37-5.93Z"/>
                  <polygon className="cls-39" points="804.53 244.98 811.52 241.12 807.93 235.19 801.15 239.05 804.53 244.98"/>
                  <polygon className="cls-42" points="801.91 246.89 801.94 245.51 803.22 246.23 803.19 247.62 801.91 246.89"/>
                  <path className="cls-41" d="M802,245.62l1.11.63v1.25l-1.11-.63v-1.25m-.13-.22V247l1.37.77v-1.55l-1.37-.77Z"/>
                  <polygon className="cls-42" points="798.96 246.76 798.99 244.12 800.46 244.95 800.43 247.59 798.96 246.76"/>
                  <path className="cls-41" d="M799.06,244.23l1.3.74v2.51l-1.3-.74v-2.51m-.13-.22v2.8l1.56.89v-2.81l-1.56-.88Z"/>
                  <polygon className="cls-39" points="801.42 228.91 807.42 225.45 807.42 221.26 801.42 224.57 801.42 228.91"/>
                  <polygon className="cls-40" points="795.45 225.39 795.49 221.23 798.56 219.55 801.36 224.6 801.35 228.8 795.45 225.39"/>
                  <path className="cls-41" d="M798.48,219.67l2.81,4.94v4.07l-5.74-3.31v-4.06l2.93-1.64m0-.18-3.11,1.74v4.22l6,3.46v-4.34l-2.89-5.08Z"/>
                  <polygon className="cls-39" points="801.42 224.57 807.42 221.26 804.34 216.18 798.53 219.49 801.42 224.57"/>
                  <polygon className="cls-42" points="799.18 226.21 799.21 224.93 800.3 225.64 800.27 226.83 799.18 226.21"/>
                  <path className="cls-41" d="M799.26,225.12l1,.54v1.07l-1-.54v-1.07m-.11-.19v1.33l1.18.66v-1.33l-1.18-.66Z"/>
                  <polygon className="cls-42" points="796.65 226.09 796.68 223.74 797.94 224.54 797.91 226.81 796.65 226.09"/>
                  <path className="cls-41" d="M796.73,223.93l1.12.63v2.15l-1.12-.63v-2.15m-.11-.19v2.4l1.34.76v-2.4l-1.34-.76Z"/>
                  <polygon className="cls-39" points="821.38 240.3 827.38 236.84 827.38 232.65 821.38 235.96 821.38 240.3"/>
                  <polygon className="cls-40" points="815.41 236.78 815.45 232.62 818.52 230.94 821.32 236 821.32 240.19 815.41 236.78"/>
                  <path className="cls-41" d="M818.44,231.06l2.81,4.94v4.07l-5.73-3.31V232.7l2.92-1.64m0-.18-3.11,1.74v4.22l6,3.46V236l-2.89-5.08Z"/>
                  <polygon className="cls-39" points="821.38 235.96 827.38 232.65 824.3 227.57 818.49 230.88 821.38 235.96"/>
                  <polygon className="cls-42" points="819.14 237.6 819.17 236.32 820.26 237.03 820.24 238.22 819.14 237.6"/>
                  <path className="cls-41" d="M819.23,236.51l.95.54v1.07l-.95-.54v-1.07m-.12-.19v1.33l1.18.66V237l-1.18-.66Z"/>
                  <polygon className="cls-42" points="816.61 237.48 816.64 235.13 817.9 235.93 817.87 238.2 816.61 237.48"/>
                  <path className="cls-41" d="M816.69,235.32l1.12.63v2.15l-1.12-.63v-2.15m-.11-.19v2.4l1.35.76v-2.4l-1.35-.76Z"/>
                  <polygon className="cls-39" points="821.38 255.9 827.38 252.44 827.38 248.25 821.38 251.56 821.38 255.9"/>
                  <polygon className="cls-40" points="815.41 252.38 815.45 248.22 818.52 246.54 821.32 251.6 821.32 255.79 815.41 252.38"/>
                  <path className="cls-41" d="M818.44,246.66l2.81,4.94v4.07l-5.73-3.31V248.3l2.92-1.64m0-.18-3.11,1.74v4.22l6,3.46v-4.34l-2.89-5.08Z"/>
                  <polygon className="cls-39" points="821.38 251.56 827.38 248.25 824.3 243.17 818.49 246.48 821.38 251.56"/>
                  <polygon className="cls-42" points="819.14 253.2 819.17 251.92 820.26 252.63 820.24 253.82 819.14 253.2"/>
                  <path className="cls-41" d="M819.23,252.11l.95.54v1.08l-.95-.54v-1.08m-.12-.19v1.33l1.18.67v-1.33l-1.18-.67Z"/>
                  <polygon className="cls-42" points="816.61 253.09 816.64 250.73 817.9 251.54 817.87 253.8 816.61 253.09"/>
                  <path className="cls-41" d="M816.69,250.92l1.12.63v2.15l-1.12-.63v-2.15m-.11-.19v2.41l1.35.76v-2.41l-1.35-.76Z"/>
                  <polygon className="cls-39" points="759.96 256.04 766.96 252 766.96 247.11 759.96 250.97 759.96 256.04"/>
                  <polygon className="cls-40" points="753 251.93 753.03 247.08 756.56 245.15 759.89 251.01 759.88 255.9 753 251.93"/>
                  <path className="cls-41" d="M756.53,245.26,759.8,251v4.75l-6.69-3.86v-4.74l3.42-1.91m.06-.22-3.63,2V252l7,4V251L756.59,245Z"/>
                  <polygon className="cls-39" points="759.96 250.97 766.96 247.11 763.37 241.18 756.59 245.04 759.96 250.97"/>
                  <polygon className="cls-42" points="757.34 252.89 757.37 251.5 758.65 252.22 758.62 253.61 757.34 252.89"/>
                  <path className="cls-41" d="M757.44,251.61l1.11.63v1.26l-1.11-.63v-1.26m-.13-.22v1.55l1.37.78v-1.55l-1.37-.78Z"/>
                  <polygon className="cls-42" points="754.39 252.75 754.42 250.11 755.89 250.94 755.86 253.58 754.39 252.75"/>
                  <path className="cls-41" d="M754.49,250.22l1.3.74v2.51l-1.3-.74v-2.51m-.13-.22v2.81l1.56.88v-2.8l-1.56-.89Z"/>
                  <polygon className="cls-39" points="727.8 238.45 734.8 234.41 734.8 229.53 727.8 233.39 727.8 238.45"/>
                  <polygon className="cls-40" points="720.84 234.34 720.88 229.49 724.39 227.56 727.73 233.43 727.72 238.32 720.84 234.34"/>
                  <path className="cls-41" d="M724.37,227.67l3.27,5.76v4.75L721,234.32v-4.74l3.42-1.91m0-.21-3.62,2v4.92l7,4v-5.06l-3.38-5.93Z"/>
                  <polygon className="cls-39" points="727.8 233.39 734.8 229.53 731.21 223.6 724.42 227.46 727.8 233.39"/>
                  <polygon className="cls-42" points="725.18 235.3 725.21 233.92 726.49 234.64 726.46 236.02 725.18 235.3"/>
                  <path className="cls-41" d="M725.28,234l1.11.63v1.25l-1.11-.63V234m-.13-.22v1.55l1.37.77v-1.55l-1.37-.77Z"/>
                  <polygon className="cls-42" points="722.23 235.17 722.26 232.53 723.73 233.36 723.7 236 722.23 235.17"/>
                  <path className="cls-41" d="M722.33,232.64l1.3.74v2.51l-1.3-.74v-2.51m-.13-.23v2.81l1.56.89V233.3l-1.56-.89Z"/>
                  <polygon className="cls-39" points="713.28 230.19 720.28 226.15 720.28 221.26 713.28 225.12 713.28 230.19"/>
                  <polygon className="cls-40" points="706.32 226.08 706.36 221.22 709.88 219.3 713.21 225.16 713.2 230.05 706.32 226.08"/>
                  <path className="cls-41" d="M709.85,219.41l3.27,5.76v4.75l-6.68-3.86v-4.74l3.41-1.91m.06-.22-3.63,2v4.92l7,4v-5.07l-3.37-5.93Z"/>
                  <polygon className="cls-39" points="713.28 225.12 720.28 221.26 716.69 215.33 709.91 219.19 713.28 225.12"/>
                  <polygon className="cls-40" points="721.14 343.19 721.14 330.22 731.84 329.17 742.47 343.12 742.47 355.64 721.14 343.19"/>
                  <path className="cls-41" d="M731.72,329.43l10.5,13.77v12l-20.83-12.15V330.44l10.33-1m.23-.52L720.89,330v13.34l21.83,12.74V343L732,328.91Z"/>
                  <path className="cls-6" d="M723.78,340.28v-6.16a.79.79,0,0,1,.17-.6.55.55,0,0,1,.41-.17,1,1,0,0,1,.53.16,2.87,2.87,0,0,1,1.31,2.21v6Z"/>
                  <path className="cls-41" d="M724.36,333.5h0a.94.94,0,0,1,.45.14,2.76,2.76,0,0,1,1.24,2.08v5.71l-2.12-1.24V334.1a.69.69,0,0,1,.13-.48.41.41,0,0,1,.3-.12m0-.3c-.45,0-.77.35-.73.92v6.24l2.72,1.61v-6.25a3,3,0,0,0-1.39-2.34,1.14,1.14,0,0,0-.6-.18Z"/>
                  <polygon className="cls-6" points="729.25 347.89 729.25 341.61 734.28 344.5 734.23 350.82 729.25 347.89"/>
                  <path className="cls-41" d="M729.47,342l4.58,2.62,0,5.8-4.54-2.67V342m-.45-.79V348l5.44,3.2,0-6.85L729,341.22Z"/>
                  <path className="cls-6" d="M737.39,348.17V342c0-.5.21-.78.58-.78a1.18,1.18,0,0,1,.58.17,2.82,2.82,0,0,1,1.25,2.21v6Z"/>
                  <path className="cls-41" d="M738,341.39h0a1.05,1.05,0,0,1,.5.15,2.67,2.67,0,0,1,1.18,2.08v5.71l-2.11-1.24V342c0-.29.07-.63.43-.63m0-.31c-.45,0-.74.34-.74.94v6.24l2.72,1.6v-6.24a3,3,0,0,0-1.33-2.35,1.39,1.39,0,0,0-.65-.19Z"/>
                  <polygon className="cls-39" points="753.58 311.18 744.49 316.38 742.35 317.6 736.72 310.42 731.65 310.84 726.75 313.67 726.75 313.67 727.44 315.43 727.44 315.43 731.82 315.06 736.19 320.5 741.15 317.62 741.15 329.89 736.14 332.77 731.76 327.33 727.39 327.76 727.32 326.45 720.81 330.23 720.8 330.25 731.76 329.14 742.86 343.48 767.53 329.15 753.58 311.18"/>
                  <path className="cls-39" d="M742.72,343.33v12.74l23.6-13.7V329.75Zm6.3,5.78-2.72,1.55v-6.24a3,3,0,0,1,1.34-2.35c.74-.42,1.38-.1,1.38.8Zm6.83-3.94-2.72,1.6v-6.25a3,3,0,0,1,1.34-2.4c.74-.42,1.38-.1,1.38.8Zm6.83-3.95L760,342.77v-6.25a2.85,2.85,0,0,1,1.33-2.34c.75-.43,1.39-.11,1.39.8Z"/>
                  <path className="cls-6" d="M746.46,344.42a2.86,2.86,0,0,1,1.25-2.22,1.41,1.41,0,0,1,.58-.16c.37,0,.58.28.58.78V349l-2.41,1.42Z"/>
                  <path className="cls-41" d="M748.29,342.19c.35,0,.43.34.43.63v6.07l-2.11,1.24v-5.71a2.69,2.69,0,0,1,1.17-2.08,1.12,1.12,0,0,1,.51-.15m0-.31a1.52,1.52,0,0,0-.65.19,3,3,0,0,0-1.34,2.35v6.24l2.72-1.6v-6.24c0-.6-.29-.94-.73-.94Z"/>
                  <path className="cls-6" d="M753.31,340.5a2.87,2.87,0,0,1,1.26-2.22,1.3,1.3,0,0,1,.57-.17c.38,0,.59.29.59.79v6.15l-2.42,1.42Z"/>
                  <path className="cls-41" d="M755.14,338.27c.36,0,.44.34.44.63V345l-2.11,1.24V340.5a2.69,2.69,0,0,1,1.17-2.08,1.08,1.08,0,0,1,.5-.15m0-.31a1.39,1.39,0,0,0-.65.19,3,3,0,0,0-1.33,2.35v6.24l2.72-1.6V338.9c0-.6-.29-.94-.74-.94Z"/>
                  <path className="cls-6" d="M760.11,336.52a2.85,2.85,0,0,1,1.26-2.21,1.23,1.23,0,0,1,.57-.17c.37,0,.59.29.59.78v6.16l-2.42,1.42Z"/>
                  <path className="cls-41" d="M761.94,334.29c.36,0,.43.35.43.63V341l-2.11,1.24v-5.71a2.65,2.65,0,0,1,1.18-2.07,1.11,1.11,0,0,1,.5-.16m0-.3a1.39,1.39,0,0,0-.65.19,3,3,0,0,0-1.33,2.34v6.25l2.72-1.6v-6.25c0-.59-.29-.93-.74-.93Z"/>
                  <polygon className="cls-39" points="736.19 320.34 741.31 317.46 741.31 330.03 736.14 332.91 736.19 320.34"/>
                  <polygon className="cls-40" points="727.47 327.77 727.47 315.45 731.8 315.09 731.82 315.06 736.19 320.5 741.15 317.62 741.15 329.89 736.14 332.77 731.76 327.33 727.47 327.77"/>
                  <polygon className="cls-40" points="646.06 217.13 646.06 211.83 650.41 211.4 654.8 217.16 654.8 222.23 646.06 217.13"/>
                  <path className="cls-41" d="M650.33,211.59l4.29,5.64v4.69l-8.38-4.9v-5l4.09-.4m.16-.38-4.61.45v5.57l9.1,5.31V217.1l-4.49-5.89Z"/>
                  <path className="cls-6" d="M647.05,215.94l0-2.55a.35.35,0,0,1,.07-.26.24.24,0,0,1,.17-.07.47.47,0,0,1,.22.07,1.19,1.19,0,0,1,.55.93v2.49Z"/>
                  <path className="cls-41" d="M647.32,213v.13a.35.35,0,0,1,.19.06,1.12,1.12,0,0,1,.52.86v2.39l-.88-.52v-2.54a.28.28,0,0,1,.06-.2.13.13,0,0,1,.12-.05V213m0,0c-.19,0-.32.15-.3.39V216l1.13.67v-2.61a1.23,1.23,0,0,0-.58-1,.41.41,0,0,0-.25-.08Z"/>
                  <polygon className="cls-6" points="649.31 219.1 649.36 216.51 651.51 217.74 651.45 220.36 649.31 219.1"/>
                  <path className="cls-41" d="M649.46,216.68l1.91,1.09,0,2.42-1.89-1.11v-2.4m-.19-.33v2.84l2.27,1.33,0-2.86-2.29-1.31Z"/>
                  <path className="cls-6" d="M652.73,219.23l0-2.55c0-.21.09-.33.24-.33a.49.49,0,0,1,.24.08,1.17,1.17,0,0,1,.53.92v2.49Z"/>
                  <path className="cls-41" d="M653,216.42h0a.48.48,0,0,1,.21.06,1.1,1.1,0,0,1,.49.87v2.38l-.88-.52v-2.53c0-.12,0-.26.18-.26m0-.13c-.19,0-.31.14-.31.39v2.61l1.14.66v-2.6a1.25,1.25,0,0,0-.56-1,.63.63,0,0,0-.27-.08Z"/>
                  <polygon className="cls-39" points="659.52 203.81 655.72 205.98 654.83 206.49 652.48 203.5 650.37 203.68 648.32 204.85 648.32 204.85 648.61 205.59 648.61 205.59 650.43 205.43 652.26 207.7 654.33 206.5 654.33 211.62 652.24 212.82 650.41 210.55 648.59 210.73 648.56 210.18 645.84 211.76 645.84 211.77 650.41 211.31 655.04 217.29 665.33 211.31 659.52 203.81"/>
                  <path className="cls-39" d="M655,217.23v5.31l9.85-5.71v-5.27Z"/>
                  <polygon className="cls-39" points="652.26 207.64 654.39 206.44 654.39 211.68 652.24 212.88 652.26 207.64"/>
                  <polygon className="cls-40" points="648.62 210.74 648.62 205.6 650.43 205.44 650.43 205.43 652.26 207.7 654.33 206.5 654.33 211.62 652.24 212.82 650.41 210.55 648.62 210.74"/>
                  <polygon className="cls-39" points="582.09 242.14 598.54 232.65 598.54 221.17 582.09 230.24 582.09 242.14"/>
                  <polygon className="cls-40" points="565.89 232.5 565.89 221.23 574.07 216.65 581.84 230.31 581.84 241.71 565.89 232.5"/>
                  <path className="cls-41" d="M574,217l7.61,13.39v10.89l-15.45-8.91v-11L574,217m.19-.68-8.53,4.77v11.57l16.45,9.49v-11.9l-7.92-13.93Z"/>
                  <polygon className="cls-39" points="582.09 230.24 598.54 221.17 590.1 207.24 574.17 216.31 582.09 230.24"/>
                  <polygon className="cls-42" points="576.03 234.78 576.03 231.49 578.94 233.13 578.94 236.43 576.03 234.78"/>
                  <path className="cls-41" d="M576.18,231.75l2.61,1.47v2.95l-2.61-1.48v-2.94m-.31-.53v3.65l3.23,1.82v-3.64l-3.23-1.83Z"/>
                  <polygon className="cls-42" points="569.09 234.46 569.09 228.22 572.46 230.13 572.46 236.37 569.09 234.46"/>
                  <path className="cls-41" d="M569.24,228.48l3.07,1.73v5.9l-3.07-1.74v-5.89m-.31-.53v6.6l3.68,2.08V230L568.93,228Z"/>
                  <polygon className="cls-39" points="723.71 300.5 736.04 293.38 736.04 284.77 723.71 291.58 723.71 300.5"/>
                  <polygon className="cls-40" points="711.62 293.24 711.62 284.86 717.67 281.47 723.46 291.65 723.46 300.07 711.62 293.24"/>
                  <path className="cls-41" d="M717.57,281.81l5.63,9.9v7.93l-11.33-6.55V285l5.7-3.19m.19-.68-6.39,3.58v8.67l12.33,7.12v-8.92l-5.94-10.45Z"/>
                  <polygon className="cls-39" points="723.71 291.58 736.04 284.77 729.71 274.32 717.76 281.13 723.71 291.58"/>
                  <polygon className="cls-42" points="719.16 294.98 719.16 292.51 721.34 293.75 721.34 296.22 719.16 294.98"/>
                  <path className="cls-41" d="M719.27,292.71l2,1.1V296l-2-1.1v-2.21m-.23-.4v2.74l2.42,1.37v-2.74L719,292.31Z"/>
                  <polygon className="cls-42" points="713.95 294.74 713.95 290.06 716.48 291.49 716.48 296.18 713.95 294.74"/>
                  <path className="cls-41" d="M714.07,290.25l2.3,1.31V296l-2.3-1.3v-4.43m-.23-.39v4.95l2.76,1.56v-4.95l-2.76-1.56Z"/>
                  <g className="cls-44">
                    <polygon className="cls-39" points="735.17 190.23 738.91 188.07 738.91 185.46 735.17 187.52 735.17 190.23"/>
                    <polygon className="cls-40" points="731.48 187.99 731.52 185.44 733.34 184.48 735.09 187.57 735.08 190.07 731.48 187.99"/>
                    <path className="cls-41" d="M733.3,184.6l1.69,3v2.34L731.61,188v-2.41l1.69-1m.07-.24-1.94,1.08v2.63l3.74,2.16v-2.71l-1.8-3.16Z"/>
                    <polygon className="cls-39" points="735.17 187.52 738.91 185.46 736.99 182.29 733.37 184.36 735.17 187.52"/>
                    <polygon className="cls-39" points="712.65 206.56 718.26 203.32 718.26 199.41 712.65 202.5 712.65 206.56"/>
                    <polygon className="cls-40" points="707.09 203.24 707.13 199.38 709.92 197.87 712.57 202.55 712.56 206.4 707.09 203.24"/>
                    <path className="cls-41" d="M709.88,198l2.59,4.55v3.7l-5.25-3v-3.73l2.66-1.49m.07-.25L707,199.38v3.94l5.61,3.24V202.5l-2.7-4.75Z"/>
                    <polygon className="cls-39" points="712.65 202.5 718.26 199.41 715.38 194.66 709.95 197.75 712.65 202.5"/>
                    <polygon className="cls-39" points="695.06 219.61 702.05 215.57 702.05 210.68 695.06 214.54 695.06 219.61"/>
                    <polygon className="cls-40" points="688.1 215.49 688.15 210.65 691.65 208.74 694.98 214.59 694.97 219.45 688.1 215.49"/>
                    <path className="cls-41" d="M691.62,208.86l3.26,5.73v4.7l-6.64-3.83v-4.71l3.38-1.89m.07-.24-3.63,2v4.92l7,4v-5.07l-3.37-5.92Z"/>
                    <polygon className="cls-39" points="695.06 214.54 702.05 210.68 698.47 204.75 691.69 208.62 695.06 214.54"/>
                    <polygon className="cls-40" points="724.25 213.99 724.29 210.6 730.16 213.92 730.11 217.31 724.25 213.99"/>
                    <path className="cls-41" d="M724.38,210.75l5.64,3.19v3.22l-5.64-3.2v-3.21m-.18-.31v3.63l6,3.39v-3.62l-6-3.4Z"/>
                    <polygon className="cls-39" points="736.2 214.07 730.2 217.46 730.2 213.84 736.2 210.44 736.2 214.07"/>
                    <polygon className="cls-39" points="730.19 207.04 736.2 210.44 730.2 213.84 724.2 210.44 730.19 207.04"/>
                    <polygon className="cls-39" points="658.54 199.28 665.53 195.24 665.53 190.35 658.54 194.22 658.54 199.28"/>
                    <polygon className="cls-40" points="651.58 195.16 651.63 190.32 655.13 188.41 658.46 194.26 658.45 199.12 651.58 195.16"/>
                    <path className="cls-41" d="M655.1,188.53l3.26,5.73V199l-6.64-3.84v-4.71l3.38-1.89m.07-.24-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                    <polygon className="cls-39" points="658.54 194.22 665.53 190.35 661.94 184.43 655.17 188.29 658.54 194.22"/>
                    <polygon className="cls-39" points="695.53 196.66 701.04 193.48 701.04 189.64 695.53 192.68 695.53 196.66"/>
                    <polygon className="cls-40" points="690.07 193.4 690.11 189.61 692.85 188.13 695.46 192.72 695.44 196.51 690.07 193.4"/>
                    <path className="cls-41" d="M692.81,188.25l2.54,4.47v3.63l-5.15-3v-3.67l2.61-1.46m.07-.24-2.86,1.6v3.87l5.51,3.18v-4L692.88,188Z"/>
                    <polygon className="cls-39" points="695.53 192.68 701.04 189.64 698.22 184.97 692.88 188.01 695.53 192.68"/>
                    <polygon className="cls-40" points="670.99 206.26 671.03 198.13 678.38 202.29 678.33 210.42 670.99 206.26"/>
                    <path className="cls-41" d="M671.12,198.29l7.12,4v7.95l-7.12-4v-7.94m-.18-.31v8.36l7.48,4.23v-8.36L670.94,198Z"/>
                    <polygon className="cls-39" points="685.89 206.34 678.42 210.57 678.42 202.21 685.89 197.98 685.89 206.34"/>
                    <polygon className="cls-39" points="678.41 193.74 685.89 197.98 678.42 202.21 670.94 197.98 678.41 193.74"/>
                    <polygon className="cls-39" points="643.12 190.21 649.66 186.43 649.66 181.87 643.12 185.48 643.12 190.21"/>
                    <polygon className="cls-40" points="636.62 186.35 636.67 181.83 639.93 180.06 643.04 185.52 643.03 190.05 636.62 186.35"/>
                    <path className="cls-41" d="M639.9,180.18l3,5.35v4.37l-6.18-3.57v-4.39l3.14-1.76m.07-.24-3.39,1.89v4.6l6.54,3.78v-4.73L640,179.94Z"/>
                    <polygon className="cls-39" points="643.12 185.48 649.66 181.87 646.3 176.33 639.97 179.94 643.12 185.48"/>
                    <polygon className="cls-39" points="725.53 197.63 730.2 194.94 730.2 191.68 725.53 194.25 725.53 197.63"/>
                    <polygon className="cls-40" points="720.9 194.86 720.95 191.65 723.24 190.42 725.45 194.3 725.44 197.48 720.9 194.86"/>
                    <path className="cls-41" d="M723.21,190.54l2.14,3.76v3L721,194.83v-3.07l2.17-1.22m.07-.24-2.42,1.35v3.29l4.67,2.69v-3.38l-2.25-3.95Z"/>
                    <polygon className="cls-39" points="725.53 194.25 730.2 191.68 727.8 187.72 723.28 190.3 725.53 194.25"/>
                  </g>
                  <g className="cls-45">
                    <polygon className="cls-39" points="673.27 177.66 677.18 175.4 677.18 172.67 673.27 174.83 673.27 177.66"/>
                    <polygon className="cls-40" points="669.41 175.32 669.46 172.65 671.36 171.64 673.2 174.87 673.18 177.5 669.41 175.32"/>
                    <path className="cls-41" d="M671.32,171.76l1.77,3.12v2.46l-3.54-2v-2.54l1.77-1m.07-.24-2,1.13v2.75l3.9,2.26v-2.83l-1.88-3.31Z"/>
                    <polygon className="cls-39" points="673.27 174.83 677.18 172.67 675.18 169.36 671.39 171.52 673.27 174.83"/>
                    <polygon className="cls-39" points="720.39 180.78 724.3 178.52 724.3 175.79 720.39 177.95 720.39 180.78"/>
                    <polygon className="cls-40" points="716.53 178.44 716.58 175.77 718.48 174.76 720.31 178 720.3 180.62 716.53 178.44"/>
                    <path className="cls-41" d="M718.44,174.88l1.77,3.12v2.47l-3.54-2.05v-2.54l1.77-1m.07-.24-2,1.13v2.75l3.9,2.26V178l-1.88-3.31Z"/>
                    <polygon className="cls-39" points="720.39 177.95 724.3 175.79 722.3 172.48 718.51 174.64 720.39 177.95"/>
                    <polygon className="cls-39" points="711.33 186.94 716 184.24 716 180.98 711.33 183.56 711.33 186.94"/>
                    <polygon className="cls-40" points="706.7 184.16 706.74 180.96 709.04 179.72 711.25 183.6 711.24 186.78 706.7 184.16"/>
                    <path className="cls-41" d="M709,179.84l2.14,3.76v3l-4.32-2.49v-3.08l2.18-1.22m.06-.24L706.65,181v3.29l4.68,2.7v-3.38l-2.26-4Z"/>
                    <polygon className="cls-39" points="711.33 183.56 716 180.98 713.6 177.02 709.08 179.6 711.33 183.56"/>
                    <polygon className="cls-39" points="676.2 190.83 681.08 188.01 681.08 184.6 676.2 187.3 676.2 190.83"/>
                    <polygon className="cls-40" points="671.36 187.93 671.4 184.58 673.81 183.28 676.12 187.34 676.11 190.68 671.36 187.93"/>
                    <path className="cls-41" d="M673.78,183.41l2.24,3.94v3.17l-4.53-2.61v-3.23l2.29-1.27m.06-.25-2.53,1.42V188l4.89,2.82V187.3l-2.36-4.14Z"/>
                    <polygon className="cls-39" points="676.2 187.3 681.08 184.6 678.58 180.47 673.84 183.16 676.2 187.3"/>
                    <polygon className="cls-39" points="657.91 178.81 662.79 175.99 662.79 172.58 657.91 175.27 657.91 178.81"/>
                    <polygon className="cls-40" points="653.07 175.91 653.11 172.55 655.52 171.26 657.83 175.32 657.82 178.65 653.07 175.91"/>
                    <path className="cls-41" d="M655.49,171.38l2.24,3.94v3.18l-4.53-2.62v-3.22l2.29-1.28m.07-.24L653,172.55V176l4.89,2.82v-3.54l-2.35-4.13Z"/>
                    <polygon className="cls-39" points="657.91 175.27 662.79 172.58 660.29 168.44 655.56 171.14 657.91 175.27"/>
                    <polygon className="cls-40" points="683.27 180.86 683.32 178.48 687.51 180.85 687.47 183.24 683.27 180.86"/>
                    <path className="cls-41" d="M683.41,178.64l4,2.24v2.2l-4-2.24v-2.2m-.18-.31v2.61l4.33,2.45v-2.61l-4.33-2.45Z"/>
                    <polygon className="cls-39" points="691.88 180.94 687.56 183.39 687.56 180.78 691.88 178.33 691.88 180.94"/>
                    <polygon className="cls-39" points="687.55 175.88 691.88 178.33 687.56 180.78 683.23 178.33 687.55 175.88"/>
                    <polygon className="cls-39" points="696.76 177.66 700.67 175.4 700.67 172.67 696.76 174.83 696.76 177.66"/>
                    <polygon className="cls-40" points="692.9 175.32 692.94 172.65 694.84 171.64 696.68 174.87 696.67 177.5 692.9 175.32"/>
                    <path className="cls-41" d="M694.81,171.76l1.77,3.12v2.46l-3.55-2v-2.54l1.78-1m.07-.24-2,1.13v2.75l3.91,2.26v-2.83l-1.88-3.31Z"/>
                    <polygon className="cls-39" points="696.76 174.83 700.67 172.67 698.66 169.36 694.88 171.52 696.76 174.83"/>
                  </g>
                  <g className="cls-46">
                    <polygon className="cls-39" points="668.78 168.75 671.88 166.95 671.88 164.79 668.78 166.5 668.78 168.75"/>
                    <polygon className="cls-40" points="665.72 166.88 665.76 164.77 667.25 163.99 668.7 166.55 668.69 168.59 665.72 166.88"/>
                    <path className="cls-41" d="M667.21,164.12l1.39,2.43v1.88l-2.75-1.58v-2l1.36-.76m.07-.25-1.61.9V167l3.11,1.8V166.5l-1.5-2.63Z"/>
                    <polygon className="cls-39" points="668.78 166.5 671.88 164.79 670.29 162.16 667.28 163.87 668.78 166.5"/>
                    <polygon className="cls-39" points="683.23 157.23 685.47 155.93 685.47 154.37 683.23 155.6 683.23 157.23"/>
                    <polygon className="cls-40" points="681.03 155.85 681.08 154.35 682.12 153.82 683.15 155.65 683.14 157.07 681.03 155.85"/>
                    <path className="cls-41" d="M682.08,154l1,1.7v1.26l-1.88-1.08v-1.37l.91-.51m.07-.25-1.16.65v1.58l2.24,1.3V155.6l-1.08-1.9Z"/>
                    <polygon className="cls-39" points="683.23 155.6 685.47 154.37 684.32 152.47 682.15 153.7 683.23 155.6"/>
                    <polygon className="cls-39" points="696.76 166.72 699.86 164.93 699.86 162.76 696.76 164.48 696.76 166.72"/>
                    <polygon className="cls-40" points="693.7 164.85 693.75 162.75 695.23 161.97 696.68 164.52 696.67 166.57 693.7 164.85"/>
                    <path className="cls-41" d="M695.2,162.09l1.38,2.43v1.89l-2.74-1.58v-2l1.36-.76m.06-.24-1.6.9v2.18l3.1,1.79v-2.24l-1.5-2.63Z"/>
                    <polygon className="cls-39" points="696.76 164.48 699.86 162.76 698.27 160.14 695.26 161.85 696.76 164.48"/>
                    <polygon className="cls-39" points="689.72 161.17 692.33 159.66 692.33 157.83 689.72 159.28 689.72 161.17"/>
                    <polygon className="cls-40" points="687.15 159.58 687.19 157.82 688.42 157.18 689.64 159.32 689.63 161.01 687.15 159.58"/>
                    <path className="cls-41" d="M688.39,157.3l1.15,2v1.54l-2.26-1.31v-1.63l1.11-.62m.07-.24-1.36.76v1.84l2.62,1.51v-1.9l-1.26-2.21Z"/>
                    <polygon className="cls-39" points="689.72 159.28 692.33 157.83 690.99 155.61 688.46 157.06 689.72 159.28"/>
                    <polygon className="cls-40" points="682.68 169.05 682.73 165.64 685.85 167.41 685.81 170.82 682.68 169.05"/>
                    <path className="cls-41" d="M682.82,165.8l2.89,1.64v3.22L682.82,169V165.8m-.18-.31v3.64l3.25,1.84v-3.64l-3.25-1.84Z"/>
                    <polygon className="cls-39" points="689.15 169.13 685.89 170.97 685.89 167.33 689.15 165.49 689.15 169.13"/>
                    <polygon className="cls-39" points="685.89 163.64 689.15 165.49 685.89 167.33 682.64 165.49 685.89 163.64"/>
                    <polygon className="cls-40" points="674.25 160.99 674.29 159.25 677.42 161.02 677.38 162.76 674.25 160.99"/>
                    <path className="cls-41" d="M674.39,159.41l2.89,1.64v1.55L674.39,161v-1.55m-.18-.31v2l3.25,1.84v-2l-3.25-1.84Z"/>
                    <polygon className="cls-39" points="680.72 161.07 677.46 162.91 677.46 160.94 680.72 159.1 680.72 161.07"/>
                    <polygon className="cls-39" points="677.46 157.26 680.72 159.1 677.46 160.94 674.21 159.1 677.46 157.26"/>
                    <polygon className="cls-40" points="702.51 170.26 702.56 168.19 704.33 168.02 706.05 170.35 706.03 172.32 702.51 170.26"/>
                    <path className="cls-41" d="M704.24,168.12l1.7,2.23v1.81l-3.29-1.92v-2l1.59-.16m.08-.19-1.85.18v2.23l3.65,2.13v-2.18l-1.8-2.36Z"/>
                    <polygon className="cls-39" points="707.94 164.97 706.42 165.83 706.06 166.04 705.12 164.84 704.27 164.91 703.45 165.38 703.45 165.38 703.57 165.68 703.57 165.68 704.3 165.61 705.03 166.52 705.86 166.04 705.86 168.09 705.02 168.58 704.29 167.67 703.56 167.74 703.54 167.52 702.46 168.15 702.46 168.16 704.29 167.97 706.14 170.37 710.27 167.97 707.94 164.97"/>
                    <path className="cls-39" d="M706.12,170.34v2.13l4-2.29v-2.11Z"/>
                    <polyline className="cls-40" points="704.3 165.61 705.03 166.52 705.86 166.04 705.86 168.09 705.02 168.58 704.29 167.67"/>
                    <polygon className="cls-40" points="705.03 166.5 705.88 166.02 705.88 168.12 705.02 168.6 705.03 166.5"/>
                    <polyline className="cls-40" points="704.29 167.66 703.57 167.74 703.57 165.68 704.29 165.62"/>
                  </g>
                </g>
                <g id="community_title" data-name="community title">
                  <path className="cls-47" d="M649.12,272.68h-3.23a3.87,3.87,0,0,1-2.46-.65,2.42,2.42,0,0,1-.8-2v-4a2.44,2.44,0,0,1,.8-2,3.81,3.81,0,0,1,2.46-.65h3.23a.5.5,0,0,1,.37.11.49.49,0,0,1,.11.35v1.58a.49.49,0,0,1-.11.35.5.5,0,0,1-.37.11h-2.66a.93.93,0,0,0-.61.17.6.6,0,0,0-.2.48v3.1a.55.55,0,0,0,.2.47,1,1,0,0,0,.61.15h2.66a.54.54,0,0,1,.37.11.53.53,0,0,1,.11.36v1.55a.53.53,0,0,1-.11.36A.54.54,0,0,1,649.12,272.68Z"/>
                  <path className="cls-47" d="M658.86,270.22a3.06,3.06,0,0,1-.18,1.08,1.79,1.79,0,0,1-.64.84,3.36,3.36,0,0,1-1.26.54,8.79,8.79,0,0,1-2,.19,9,9,0,0,1-2-.19,3.44,3.44,0,0,1-1.26-.54,1.79,1.79,0,0,1-.64-.84,3.06,3.06,0,0,1-.18-1.08v-4.43a3.12,3.12,0,0,1,.18-1.09,1.9,1.9,0,0,1,.64-.84,3.44,3.44,0,0,1,1.26-.54,8.39,8.39,0,0,1,2-.2,8.22,8.22,0,0,1,2,.2,3.36,3.36,0,0,1,1.26.54,1.9,1.9,0,0,1,.64.84,3.12,3.12,0,0,1,.18,1.09Zm-5.23-.47a.59.59,0,0,0,.2.44,1.62,1.62,0,0,0,.92.17,1.6,1.6,0,0,0,.91-.17.59.59,0,0,0,.2-.44v-3.5a.56.56,0,0,0-.2-.42,1.52,1.52,0,0,0-.91-.17,1.54,1.54,0,0,0-.92.17.56.56,0,0,0-.2.42Z"/>
                  <path className="cls-47" d="M662.66,272.68h-2a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36v-8.43a.49.49,0,0,1,.11-.35.5.5,0,0,1,.37-.11h2.13a.63.63,0,0,1,.35.09.78.78,0,0,1,.26.3l1.19,2.11a1.12,1.12,0,0,0,.12.17.14.14,0,0,0,.12.06H665a.15.15,0,0,0,.13-.06.71.71,0,0,0,.11-.17l1.18-2.11a.88.88,0,0,1,.26-.3.63.63,0,0,1,.35-.09h2.15a.46.46,0,0,1,.36.11.45.45,0,0,1,.12.35v8.43a.49.49,0,0,1-.12.36.5.5,0,0,1-.36.11h-2a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36v-4.58l-.78,1.42a.83.83,0,0,1-.29.32.86.86,0,0,1-.41.09h-.54a.86.86,0,0,1-.41-.09.83.83,0,0,1-.29-.32l-.78-1.42v4.58a.53.53,0,0,1-.11.36A.54.54,0,0,1,662.66,272.68Z"/>
                  <path className="cls-47" d="M673.59,272.68h-2a.52.52,0,0,1-.36-.11.49.49,0,0,1-.11-.36v-8.43a.45.45,0,0,1,.11-.35.47.47,0,0,1,.36-.11h2.14a.63.63,0,0,1,.35.09.88.88,0,0,1,.26.3l1.19,2.11a1.27,1.27,0,0,0,.11.17.18.18,0,0,0,.13.06h.12A.18.18,0,0,0,676,266a1.27,1.27,0,0,0,.11-.17l1.18-2.11a.78.78,0,0,1,.26-.3.63.63,0,0,1,.35-.09h2.15a.47.47,0,0,1,.36.11.45.45,0,0,1,.11.35v8.43a.49.49,0,0,1-.11.36.52.52,0,0,1-.36.11H678a.52.52,0,0,1-.36-.11.49.49,0,0,1-.11-.36v-4.58l-.79,1.42a.74.74,0,0,1-.28.32.87.87,0,0,1-.42.09h-.53a.87.87,0,0,1-.42-.09.81.81,0,0,1-.28-.32l-.78-1.42v4.58a.49.49,0,0,1-.12.36A.5.5,0,0,1,673.59,272.68Z"/>
                  <path className="cls-47" d="M690.19,270.22a3.06,3.06,0,0,1-.18,1.08,1.79,1.79,0,0,1-.64.84,3.38,3.38,0,0,1-1.25.54,10.94,10.94,0,0,1-4.06,0,3.46,3.46,0,0,1-1.25-.54,1.86,1.86,0,0,1-.64-.84,3.06,3.06,0,0,1-.17-1.08v-6.44a.45.45,0,0,1,.11-.35.46.46,0,0,1,.36-.11h2.08a.5.5,0,0,1,.37.11.49.49,0,0,1,.11.35v5.93a.57.57,0,0,0,.2.44,1.54,1.54,0,0,0,.89.17,1.56,1.56,0,0,0,.9-.17.57.57,0,0,0,.2-.44v-5.93a.49.49,0,0,1,.11-.35.5.5,0,0,1,.37-.11h2a.5.5,0,0,1,.37.11.49.49,0,0,1,.11.35Z"/>
                  <path className="cls-47" d="M694.09,272.68h-2a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36v-8.43a.49.49,0,0,1,.11-.35.5.5,0,0,1,.37-.11h1.38a.87.87,0,0,1,.78.35l2.57,2.88v-2.77a.45.45,0,0,1,.12-.35.46.46,0,0,1,.36-.11h2a.46.46,0,0,1,.36.11.45.45,0,0,1,.12.35v8.43a.49.49,0,0,1-.12.36.5.5,0,0,1-.36.11h-2a.5.5,0,0,1-.36-.11.49.49,0,0,1-.12-.36v-1.6l-2.26-2.69v4.29a.49.49,0,0,1-.12.36A.5.5,0,0,1,694.09,272.68Z"/>
                  <path className="cls-47" d="M701.54,272.68a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36v-1.55a.53.53,0,0,1,.11-.36.54.54,0,0,1,.37-.11h1.31v-4.37h-1.31a.5.5,0,0,1-.37-.11.49.49,0,0,1-.11-.35v-1.58a.49.49,0,0,1,.11-.35.5.5,0,0,1,.37-.11h5.65a.46.46,0,0,1,.36.11.45.45,0,0,1,.12.35v1.58a.45.45,0,0,1-.12.35.46.46,0,0,1-.36.11H705.9v4.37h1.29a.5.5,0,0,1,.36.11.49.49,0,0,1,.12.36v1.55a.49.49,0,0,1-.12.36.5.5,0,0,1-.36.11Z"/>
                  <path className="cls-47" d="M713.47,272.68h-2.14a.54.54,0,0,1-.37-.11.53.53,0,0,1-.11-.36v-6.39h-2a.46.46,0,0,1-.36-.11.45.45,0,0,1-.12-.35v-1.58a.45.45,0,0,1,.12-.35.46.46,0,0,1,.36-.11h7a.5.5,0,0,1,.37.11.49.49,0,0,1,.11.35v1.58a.49.49,0,0,1-.11.35.5.5,0,0,1-.37.11H714v6.39a.53.53,0,0,1-.11.36A.54.54,0,0,1,713.47,272.68Z"/>
                  <path className="cls-47" d="M722.17,272.68H720a.52.52,0,0,1-.36-.11.49.49,0,0,1-.11-.36v-1.67l-2-2.66a2.55,2.55,0,0,1-.41-.7,3.28,3.28,0,0,1-.1-.9v-2.5a.45.45,0,0,1,.12-.35.46.46,0,0,1,.36-.11h2a.5.5,0,0,1,.37.11.49.49,0,0,1,.11.35v2.14a2.62,2.62,0,0,0,0,.27.57.57,0,0,0,.11.27l.61.94a.49.49,0,0,0,.14.17.35.35,0,0,0,.18.05h.13a.35.35,0,0,0,.18-.05.63.63,0,0,0,.14-.17l.61-.94a.47.47,0,0,0,.1-.27c0-.1,0-.19,0-.27v-2.14a.49.49,0,0,1,.11-.35.5.5,0,0,1,.37-.11h2a.5.5,0,0,1,.37.11.49.49,0,0,1,.11.35v2.5a2.87,2.87,0,0,1-.11.9,3,3,0,0,1-.39.7l-2,2.68v1.65a.53.53,0,0,1-.11.36A.54.54,0,0,1,722.17,272.68Z"/>
                </g>
                <g id="community_gray" data-name="community gray">
                  <g className="cls-44">
                    <polygon className="cls-12" points="711.51 183.67 715.81 181.29 715.81 184.13 711.51 186.61 711.51 183.67"/>
                    <path className="cls-12" d="M715.62,181.61V184l-3.92,2.27v-2.51l3.92-2.17M716,181l-4.67,2.58v3.38l4.67-2.7V181Z"/>
                    <polygon className="cls-12" points="706.69 184.18 706.72 180.96 709.11 179.66 711.27 183.59 711.26 186.82 706.69 184.18"/>
                    <path className="cls-12" d="M709,179.78l2.16,3.81v3.12l-4.41-2.55V181l2.25-1.25m0-.18L706.65,181v3.29l4.68,2.7v-3.38l-2.26-4Z"/>
                    <polygon className="cls-12" points="709.33 179.67 713.53 177.27 715.74 180.91 711.4 183.3 709.33 179.67"/>
                    <path className="cls-12" d="M713.47,177.53l2,3.31-4,2.21-1.89-3.31,3.89-2.21m.13-.51-4.53,2.58,2.26,4L716,181l-2.4-4Z"/>
                    <polygon className="cls-12" points="683.25 180.91 683.27 178.33 687.53 180.81 687.52 183.32 683.25 180.91"/>
                    <path className="cls-12" d="M683.31,178.47l4.16,2.35v2.43l-4.16-2.36v-2.42m-.08-.14v2.61l4.33,2.45v-2.61l-4.33-2.45Z"/>
                    <polygon className="cls-12" points="687.74 180.88 691.69 178.65 691.69 180.83 687.74 183.07 687.74 180.88"/>
                    <path className="cls-12" d="M691.51,179v1.75l-3.58,2V181l3.58-2m.37-.64-4.32,2.45v2.61l4.32-2.45v-2.61Z"/>
                    <polygon className="cls-12" points="683.61 178.33 687.55 176.09 691.5 178.33 687.56 180.56 683.61 178.33"/>
                    <path className="cls-12" d="M687.55,176.31l3.57,2-3.56,2-3.57-2,3.56-2m0-.43-4.32,2.45,4.33,2.45,4.32-2.45-4.33-2.45Z"/>
                    <polygon className="cls-12" points="676.38 187.41 680.89 184.92 680.89 187.91 676.38 190.51 676.38 187.41"/>
                    <path className="cls-12" d="M680.71,185.24v2.56l-4.14,2.38v-2.66l4.14-2.28m.37-.64-4.88,2.7v3.53l4.88-2.82V184.6Z"/>
                    <polygon className="cls-12" points="671.33 187.97 671.36 184.58 673.87 183.2 676.16 187.32 676.15 190.75 671.33 187.97"/>
                    <path className="cls-12" d="M673.81,183.29l2.29,4v3.35L671.4,188v-3.33l2.41-1.34m0-.13-2.53,1.42V188l4.89,2.82V187.3l-2.36-4.14Z"/>
                    <polygon className="cls-12" points="674.1 183.23 678.51 180.72 680.82 184.53 676.27 187.05 674.1 183.23"/>
                    <path className="cls-12" d="M678.44,181l2.12,3.49-4.22,2.33-2-3.49,4.09-2.33m.13-.5-4.73,2.69,2.36,4.14,4.88-2.7-2.51-4.13Z"/>
                    <polygon className="cls-12" points="673.46 174.94 676.99 172.99 676.99 175.29 673.46 177.33 673.46 174.94"/>
                    <path className="cls-12" d="M676.81,173.31v1.87L673.65,177v-2l3.16-1.74m.37-.64-3.91,2.16v2.83l3.91-2.26v-2.73Z"/>
                    <polygon className="cls-12" points="669.38 175.37 669.4 172.65 671.41 171.55 673.24 174.85 673.24 177.59 669.38 175.37"/>
                    <path className="cls-12" d="M671.36,171.62l1.84,3.23v2.68l-3.76-2.17V172.7l1.92-1.08m0-.1-2,1.13v2.75l3.9,2.26v-2.83l-1.88-3.31Z"/>
                    <polygon className="cls-12" points="671.65 171.59 675.11 169.62 676.92 172.6 673.35 174.58 671.65 171.59"/>
                    <path className="cls-12" d="M675.05,169.87l1.61,2.66-3.24,1.79-1.52-2.66,3.15-1.79m.13-.51-3.79,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                    <polygon className="cls-12" points="696.95 174.94 700.48 172.99 700.48 175.29 696.95 177.33 696.95 174.94"/>
                    <path className="cls-12" d="M700.29,173.31v1.87L697.13,177v-2l3.16-1.74m.38-.64-3.91,2.16v2.83l3.91-2.26v-2.73Z"/>
                    <polygon className="cls-12" points="692.87 175.37 692.89 172.65 694.89 171.55 696.73 174.85 696.72 177.59 692.87 175.37"/>
                    <path className="cls-12" d="M694.85,171.62l1.84,3.23v2.68l-3.77-2.17V172.7l1.93-1.08m0-.1-2,1.13v2.75l3.91,2.26v-2.83l-1.88-3.31Z"/>
                    <polygon className="cls-12" points="695.13 171.59 698.6 169.62 700.4 172.6 696.83 174.58 695.13 171.59"/>
                    <path className="cls-12" d="M698.53,169.87l1.62,2.66-3.25,1.79-1.51-2.66,3.14-1.79m.13-.51-3.78,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                    <polygon className="cls-12" points="720.58 178.06 724.11 176.11 724.11 178.41 720.58 180.45 720.58 178.06"/>
                    <path className="cls-12" d="M723.93,176.43v1.88l-3.16,1.82v-2l3.16-1.74m.37-.64L720.39,178v2.83l3.91-2.26v-2.73Z"/>
                    <polygon className="cls-12" points="716.5 178.49 716.52 175.77 718.53 174.67 720.36 177.97 720.36 180.71 716.5 178.49"/>
                    <path className="cls-12" d="M718.48,174.74l1.84,3.23v2.68l-3.76-2.17v-2.66l1.92-1.08m0-.1-2,1.13v2.75l3.9,2.26V178l-1.88-3.31Z"/>
                    <polygon className="cls-12" points="718.77 174.71 722.23 172.74 724.04 175.72 720.46 177.7 718.77 174.71"/>
                    <path className="cls-12" d="M722.16,173l1.62,2.66-3.24,1.79L719,174.78l3.14-1.79m.14-.51-3.79,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                    <polygon className="cls-12" points="658.1 175.38 662.61 172.9 662.61 175.88 658.1 178.48 658.1 175.38"/>
                    <path className="cls-12" d="M662.42,173.21v2.56l-4.14,2.39v-2.67l4.14-2.28m.37-.63-4.88,2.69v3.54l4.88-2.82v-3.41Z"/>
                    <polygon className="cls-12" points="653.05 175.95 653.07 172.55 655.58 171.18 657.87 175.3 657.86 178.73 653.05 175.95"/>
                    <path className="cls-12" d="M655.52,171.26l2.3,4v3.35l-4.7-2.72v-3.32l2.4-1.35m0-.12L653,172.55V176l4.89,2.82v-3.54l-2.35-4.13Z"/>
                    <polygon className="cls-12" points="655.81 171.21 660.22 168.69 662.53 172.51 657.98 175.02 655.81 171.21"/>
                    <path className="cls-12" d="M660.16,169l2.11,3.49-4.22,2.33-2-3.49,4.09-2.33m.13-.51-4.73,2.7,2.35,4.13,4.88-2.69-2.5-4.14Z"/>
                  </g>
                  <g className="cls-45">
                    <polygon className="cls-12" points="674.22 161.04 674.24 159.1 677.45 160.97 677.43 162.86 674.22 161.04"/>
                    <path className="cls-12" d="M674.27,159.21,677.4,161v1.83L674.27,161v-1.82m-.06-.11v2l3.25,1.84v-2l-3.25-1.84Z"/>
                    <polygon className="cls-12" points="677.65 161.05 680.53 159.42 680.53 160.96 677.65 162.59 677.65 161.05"/>
                    <path className="cls-12" d="M680.34,159.74v1.11l-2.5,1.42v-1.11l2.5-1.42m.38-.64-3.26,1.84v2l3.26-1.84v-2Z"/>
                    <polygon className="cls-12" points="674.58 159.1 677.46 157.47 680.34 159.1 677.46 160.73 674.58 159.1"/>
                    <path className="cls-12" d="M677.46,157.69l2.5,1.41-2.5,1.42L675,159.1l2.5-1.41m0-.43-3.25,1.84,3.25,1.85,3.26-1.85-3.26-1.84Z"/>
                    <polygon className="cls-12" points="696.95 164.59 699.67 163.08 699.67 164.82 696.95 166.4 696.95 164.59"/>
                    <path className="cls-12" d="M699.49,163.4v1.31l-2.36,1.36V164.7l2.36-1.3m.37-.63-3.1,1.71v2.24l3.1-1.79v-2.16Z"/>
                    <polygon className="cls-12" points="693.67 164.9 693.69 162.75 695.28 161.88 696.73 164.5 696.72 166.66 693.67 164.9"/>
                    <path className="cls-12" d="M695.24,162l1.44,2.55v2.09l-2.95-1.7v-2.1l1.51-.84m0-.1-1.6.9v2.18l3.1,1.79v-2.24l-1.5-2.63Z"/>
                    <polygon className="cls-12" points="695.52 161.92 698.2 160.39 699.6 162.7 696.83 164.22 695.52 161.92"/>
                    <path className="cls-12" d="M698.14,160.64l1.2,2L696.9,164l-1.13-2,2.37-1.35m.13-.5-3,1.71,1.5,2.63,3.1-1.71-1.59-2.63Z"/>
                    <polygon className="cls-12" points="683.42 155.71 685.29 154.68 685.29 155.82 683.42 156.9 683.42 155.71"/>
                    <path className="cls-12" d="M685.1,155v.72l-1.5.86v-.76l1.5-.82m.37-.63-2.24,1.23v1.63l2.24-1.3v-1.56Z"/>
                    <polygon className="cls-12" points="681 155.91 681.01 154.35 682.16 153.73 683.21 155.62 683.2 157.18 681 155.91"/>
                    <path className="cls-12" d="M682.13,153.78l1,1.84v1.51L681,155.9v-1.51l1.09-.61m0-.08-1.16.65v1.58l2.24,1.3V155.6l-1.08-1.9Z"/>
                    <polygon className="cls-12" points="682.41 153.78 684.26 152.72 685.21 154.3 683.3 155.35 682.41 153.78"/>
                    <path className="cls-12" d="M684.19,153l.76,1.26-1.58.87-.71-1.26,1.53-.87m.13-.5-2.17,1.23,1.08,1.9,2.24-1.23-1.15-1.9Z"/>
                    <polygon className="cls-12" points="689.9 159.38 692.15 158.15 692.15 159.55 689.9 160.84 689.9 159.38"/>
                    <path className="cls-12" d="M692,158.46v1l-1.87,1.08v-1l1.87-1m.37-.63-2.61,1.44v1.9l2.61-1.51v-1.83Z"/>
                    <polygon className="cls-12" points="687.12 159.63 687.13 157.82 688.47 157.09 689.69 159.29 689.69 161.11 687.12 159.63"/>
                    <path className="cls-12" d="M688.43,157.14l1.23,2.15v1.77l-2.5-1.44v-1.77l1.27-.71m0-.08-1.36.76v1.84l2.62,1.51v-1.9l-1.26-2.21Z"/>
                    <polygon className="cls-12" points="688.71 157.13 690.93 155.87 692.07 157.76 689.79 159.02 688.71 157.13"/>
                    <path className="cls-12" d="M690.86,156.12l.95,1.57-1.95,1.08L689,157.2l1.89-1.08m.13-.51-2.53,1.45,1.26,2.21,2.61-1.44L691,155.61Z"/>
                    <polygon className="cls-12" points="668.96 166.61 671.69 165.11 671.69 166.85 668.96 168.42 668.96 166.61"/>
                    <path className="cls-12" d="M671.5,165.42v1.32l-2.35,1.36v-1.38l2.35-1.3m.38-.63-3.1,1.71v2.25l3.1-1.8v-2.16Z"/>
                    <polygon className="cls-12" points="665.69 166.92 665.71 164.77 667.3 163.91 668.74 166.52 668.74 168.68 665.69 166.92"/>
                    <path className="cls-12" d="M667.25,164l1.45,2.55v2.1l-3-1.71v-2.09l1.5-.85m0-.1-1.61.9V167l3.11,1.8V166.5l-1.5-2.63Z"/>
                    <polygon className="cls-12" points="667.54 163.94 670.22 162.41 671.62 164.72 668.85 166.25 667.54 163.94"/>
                    <path className="cls-12" d="M670.16,162.67l1.2,2L668.92,166l-1.13-2,2.37-1.34m.13-.51-3,1.71,1.5,2.63,3.1-1.71-1.59-2.63Z"/>
                    <polygon className="cls-12" points="682.66 169.09 682.68 165.49 685.87 167.37 685.85 170.9 682.66 169.09"/>
                    <path className="cls-12" d="M682.72,165.63l3.09,1.75v3.45l-3.09-1.75v-3.45m-.08-.14v3.64l3.25,1.84v-3.64l-3.25-1.84Z"/>
                    <polygon className="cls-12" points="686.08 167.44 688.97 165.81 688.97 169.02 686.08 170.65 686.08 167.44"/>
                    <path className="cls-12" d="M688.78,166.13v2.78l-2.51,1.42v-2.78l2.51-1.42m.37-.64-3.26,1.84V171l3.26-1.84v-3.64Z"/>
                    <polygon className="cls-12" points="683.02 165.49 685.89 163.86 688.77 165.49 685.89 167.12 683.02 165.49"/>
                    <path className="cls-12" d="M685.89,164.07l2.5,1.42-2.5,1.41-2.49-1.41,2.49-1.42m0-.43-3.25,1.85,3.25,1.84,3.26-1.84-3.26-1.85Z"/>
                    <polygon className="cls-12" points="702.51 170.28 702.54 168.11 704.33 168 706.06 170.34 706.05 172.35 702.51 170.28"/>
                    <path className="cls-12" d="M704.25,168.08l1.73,2.26v1.88l-3.37-2v-2l1.64-.16m.07-.15-1.85.18v2.23l3.65,2.13v-2.18l-1.8-2.36Z"/>
                    <path className="cls-12" d="M705.12,168.74l.92-.54v-1.94l1.85-1,2.1,2.71-3.8,2.2Zm-1.75-.91v0h-.09Zm1-2.41-.69.06v0l.68-.39.67,0,.69.88-.65.37Z"/>
                    <path className="cls-12" d="M705,165.23l.49.62-.32.19-.53-.66-.09-.11.45,0m2.89.22,1.87,2.41-3.47,2-.84-1.09.64-.37.19-.11v-1.94h0l.35-.2,1.24-.71m-2.72-.61-.85.07-.82.47.12.3.73-.07.73.91.83-.48v2.06l-.84.48-.73-.91-.73.07v-.22l-1.09.63h0l1.84-.18,1.85,2.4,4.13-2.4-2.33-3-1.52.86-.36.21-.94-1.2Z"/>
                    <polygon className="cls-12" points="706.31 170.45 709.88 168.4 709.88 170.07 706.31 172.15 706.31 170.45"/>
                    <path className="cls-12" d="M709.7,168.72V170l-3.21,1.86v-1.27l3.21-1.84m.37-.65-4,2.27v2.13l4-2.29v-2.11Z"/>
                    <polygon className="cls-12" points="705.22 166.61 705.7 166.34 705.7 168.01 705.21 168.28 705.22 166.61"/>
                    <path className="cls-12" d="M705.51,166.65v1.25l-.11.06v-1.24l.11-.07m.38-.63-.86.48v2.1l.87-.48V166Z"/>
                    <polygon className="cls-12" points="704.37 167.47 703.76 167.53 703.76 165.85 704.22 165.81 704.98 166.77 705.67 166.37 705.67 167.99 705.07 168.34 704.37 167.47"/>
                    <path className="cls-12" d="M704.13,166l.61.75.2.25.28-.16.26-.16v1.19l-.37.21-.53-.66-.13-.16-.2,0-.31,0V166h.19m.17-.4h0l-.72.06v2.06l.72-.07.73.91.84-.48V166l-.83.48-.73-.91Z"/>
                  </g>
                  <g className="cls-48">
                    <polygon className="cls-12" points="658.72 194.33 665.35 190.67 665.35 195.13 658.72 198.96 658.72 194.33"/>
                    <path className="cls-12" d="M665.16,191v4l-6.25,3.61v-4.19l6.25-3.45m.37-.64-7,3.87v5.06l7-4v-4.89Z"/>
                    <polygon className="cls-12" points="651.57 195.18 651.6 190.32 655.2 188.35 658.48 194.25 658.47 199.16 651.57 195.18"/>
                    <path className="cls-12" d="M655.12,188.47l3.28,5.78v4.8l-6.73-3.89V190.4l3.45-1.93m0-.18-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                    <polygon className="cls-12" points="655.42 188.36 661.88 184.68 665.27 190.29 658.61 193.96 655.42 188.36"/>
                    <path className="cls-12" d="M661.81,184.93l3.2,5.29-6.33,3.49-3-5.28,6.13-3.5m.13-.5-6.77,3.86,3.37,5.93,7-3.87-3.59-5.92Z"/>
                    <polygon className="cls-12" points="735.36 187.63 738.72 185.78 738.72 187.96 735.36 189.9 735.36 187.63"/>
                    <path className="cls-12" d="M738.54,186.09v1.76l-3,1.73v-1.84l3-1.65m.37-.63-3.74,2.06v2.71l3.74-2.16v-2.61Z"/>
                    <polygon className="cls-12" points="731.47 188.01 731.5 185.44 733.4 184.41 735.11 187.56 735.11 190.11 731.47 188.01"/>
                    <path className="cls-12" d="M733.32,184.54l1.72,3V190l-3.47-2v-2.47l1.75-1m0-.18-1.94,1.08v2.63l3.74,2.16v-2.71l-1.8-3.16Z"/>
                    <polygon className="cls-12" points="733.63 184.43 736.93 182.55 738.65 185.39 735.24 187.27 733.63 184.43"/>
                    <path className="cls-12" d="M736.86,182.8l1.53,2.52-3.08,1.7-1.43-2.52,3-1.7m.13-.51-3.62,2.07,1.8,3.16,3.74-2.06L737,182.29Z"/>
                    <polygon className="cls-12" points="712.84 202.61 718.07 199.72 718.07 203.21 712.84 206.24 712.84 202.61"/>
                    <path className="cls-12" d="M717.88,200v3.07l-4.86,2.8v-3.19l4.86-2.68m.38-.63-5.61,3.09v4.06l5.61-3.24v-3.91Z"/>
                    <polygon className="cls-12" points="707.08 203.26 707.11 199.38 709.98 197.81 712.59 202.54 712.59 206.44 707.08 203.26"/>
                    <path className="cls-12" d="M709.9,197.93l2.62,4.61v3.79l-5.34-3.08v-3.79l2.72-1.53m.05-.18L707,199.38v3.94l5.61,3.24V202.5l-2.7-4.75Z"/>
                    <polygon className="cls-12" points="710.21 197.82 715.32 194.91 718 199.34 712.72 202.25 710.21 197.82"/>
                    <path className="cls-12" d="M715.25,195.16l2.49,4.11-5,2.73-2.33-4.11,4.79-2.73m.13-.5L710,197.75l2.7,4.75,5.61-3.09-2.88-4.75Z"/>
                    <polygon className="cls-12" points="725.72 194.37 730.01 191.99 730.01 194.83 725.72 197.31 725.72 194.37"/>
                    <path className="cls-12" d="M729.83,192.31v2.41L725.9,197v-2.51l3.93-2.17m.37-.63-4.67,2.57v3.38l4.67-2.69v-3.26Z"/>
                    <polygon className="cls-12" points="720.89 194.88 720.92 191.65 723.31 190.35 725.47 194.29 725.46 197.52 720.89 194.88"/>
                    <path className="cls-12" d="M723.23,190.48l2.17,3.81v3.12L721,194.86v-3.13l2.24-1.25m0-.18-2.42,1.35v3.29l4.67,2.69v-3.38l-2.25-3.95Z"/>
                    <polygon className="cls-12" points="723.53 190.37 727.74 187.97 729.94 191.61 725.6 194 723.53 190.37"/>
                    <path className="cls-12" d="M727.67,188.22l2,3.32-4,2.21-1.88-3.31,3.88-2.22m.13-.5-4.52,2.58,2.25,3.95,4.67-2.57-2.4-4Z"/>
                    <polygon className="cls-12" points="643.3 185.59 649.47 182.18 649.47 186.32 643.3 189.89 643.3 185.59"/>
                    <path className="cls-12" d="M649.29,182.5v3.72l-5.8,3.34V185.7l5.8-3.2m.37-.63-6.54,3.61v4.73l6.54-3.78v-4.56Z"/>
                    <polygon className="cls-12" points="636.61 186.38 636.64 181.83 640 179.99 643.06 185.51 643.06 190.1 636.61 186.38"/>
                    <path className="cls-12" d="M639.92,180.1l3.07,5.41V190l-6.29-3.64v-4.45l3.22-1.81m.05-.16-3.39,1.89v4.6l6.54,3.78v-4.73L640,179.94Z"/>
                    <polygon className="cls-12" points="640.22 180.01 646.24 176.58 649.4 181.8 643.19 185.22 640.22 180.01"/>
                    <path className="cls-12" d="M646.17,176.83l3,4.9L643.26,185l-2.78-4.89,5.69-3.25m.13-.5L640,179.94l3.15,5.54,6.54-3.61-3.36-5.54Z"/>
                    <polygon className="cls-12" points="695.72 192.79 700.86 189.95 700.86 193.37 695.72 196.34 695.72 192.79"/>
                    <path className="cls-12" d="M700.67,190.27v3L695.91,196V192.9l4.76-2.63m.37-.63-5.51,3v4l5.51-3.18v-3.84Z"/>
                    <polygon className="cls-12" points="690.06 193.42 690.09 189.61 692.91 188.07 695.48 192.71 695.47 196.55 690.06 193.42"/>
                    <path className="cls-12" d="M692.83,188.19l2.57,4.52v3.72l-5.24-3v-3.72l2.67-1.49m0-.18-2.86,1.6v3.87l5.51,3.18v-4L692.88,188Z"/>
                    <polygon className="cls-12" points="693.13 188.08 698.15 185.22 700.78 189.57 695.6 192.42 693.13 188.08"/>
                    <path className="cls-12" d="M698.08,185.47l2.44,4-4.84,2.67-2.29-4,4.69-2.68m.14-.5-5.34,3,2.65,4.67,5.51-3L698.22,185Z"/>
                    <polygon className="cls-12" points="670.99 206.31 671.04 198.2 678.37 202.35 678.33 210.46 670.99 206.31"/>
                    <path className="cls-12" d="M671.13,198.36l7.1,4v7.93l-7.1-4v-7.92m-.19-.33v8.36l7.48,4.23v-8.36L670.94,198Z"/>
                    <polygon className="cls-12" points="678.61 202.37 685.71 198.35 685.71 206.28 678.61 210.3 678.61 202.37"/>
                    <path className="cls-12" d="M685.52,198.67v7.5L678.79,210v-7.5l6.73-3.81m.37-.64-7.47,4.23v8.36l7.47-4.23V198Z"/>
                    <polygon className="cls-12" points="671.32 198.03 678.41 194.01 685.52 198.03 678.42 202.05 671.32 198.03"/>
                    <path className="cls-12" d="M678.41,194.23l6.73,3.8-6.72,3.8L671.7,198l6.71-3.8m0-.43L670.94,198l7.48,4.23,7.47-4.23-7.48-4.23Z"/>
                    <polygon className="cls-12" points="695.24 214.66 701.87 211 701.87 215.46 695.24 219.28 695.24 214.66"/>
                    <path className="cls-12" d="M701.68,211.32v4L695.43,219v-4.19l6.25-3.45m.38-.64-7,3.86v5.07l7-4v-4.89Z"/>
                    <polygon className="cls-12" points="688.09 215.51 688.13 210.65 691.72 208.67 695 214.58 694.99 219.49 688.09 215.51"/>
                    <path className="cls-12" d="M691.64,208.8l3.29,5.78v4.8l-6.74-3.89v-4.77l3.45-1.92m.05-.18-3.63,2v4.92l7,4v-5.07l-3.37-5.92Z"/>
                    <polygon className="cls-12" points="691.94 208.69 698.4 205.01 701.79 210.61 695.13 214.29 691.94 208.69"/>
                    <path className="cls-12" d="M698.33,205.26l3.2,5.28L695.2,214l-3-5.28,6.13-3.5m.14-.51-6.78,3.87,3.37,5.92,7-3.86-3.59-5.93Z"/>
                  </g>
                  <polygon className="cls-12" points="724.39 213.96 724.39 210.76 730.01 213.95 730.01 217.14 724.39 213.96"/>
                  <path className="cls-12" d="M724.57,211.08l5.26,3v2.76l-5.26-3v-2.77m-.37-.64v3.63l6,3.39v-3.62l-6-3.4Z"/>
                  <polygon className="cls-12" points="730.39 213.95 736.01 210.76 736.01 213.96 730.39 217.14 730.39 213.95"/>
                  <path className="cls-12" d="M735.83,211.08v2.77l-5.26,3v-2.76l5.26-3m.37-.64-6,3.4v3.62l6-3.39v-3.63Z"/>
                  <polygon className="cls-12" points="724.58 210.44 730.19 207.26 735.82 210.44 730.2 213.62 724.58 210.44"/>
                  <path className="cls-12" d="M730.19,207.47l5.25,3-5.24,3-5.24-3,5.23-3m0-.43-6,3.4,6,3.4,6-3.4-6-3.4Z"/>
                  <g className="cls-44">
                    <polygon className="cls-9" points="735.21 187.52 738.93 185.49 738.87 188.07 735.21 190.17 735.21 187.52"/>
                    <path className="cls-10" d="M738.84,185.58V188l-3.6,2.08v-2.55l3.6-2m.07-.12-3.74,2.06v2.71l3.74-2.16v-2.61Z"/>
                    <polygon className="cls-8" points="731.45 188.04 731.47 185.44 733.39 184.39 735.14 187.54 735.14 190.17 731.45 188.04"/>
                    <path className="cls-10" d="M733.34,184.45l1.76,3.09v2.57L731.5,188v-2.55l1.84-1m0-.09-1.94,1.08v2.63l3.74,2.16v-2.71l-1.8-3.16Z"/>
                    <polygon className="cls-9" points="733.4 184.34 737.01 182.32 738.88 185.48 735.19 187.48 733.4 184.34"/>
                    <path className="cls-10" d="M737,182.39l1.84,3-3.61,2-1.73-3.05,3.5-2m0-.1-3.62,2.07,1.8,3.16,3.74-2.06L737,182.29Z"/>
                    <polygon className="cls-9" points="712.7 202.5 718.28 199.46 718.21 203.32 712.7 206.47 712.7 202.5"/>
                    <path className="cls-10" d="M718.15,199.58v3.68l-5.4,3.12v-3.82l5.4-3m.11-.17-5.61,3.09v4.06l5.61-3.24v-3.91Z"/>
                    <polygon className="cls-8" points="707.07 203.28 707.1 199.38 709.98 197.8 712.61 202.53 712.6 206.47 707.07 203.28"/>
                    <path className="cls-10" d="M709.91,197.89l2.64,4.64v3.85l-5.4-3.12v-3.82l2.76-1.55m0-.14L707,199.38v3.94l5.61,3.24V202.5l-2.7-4.75Z"/>
                    <polygon className="cls-9" points="710 197.73 715.41 194.7 718.21 199.44 712.67 202.43 710 197.73"/>
                    <path className="cls-10" d="M715.34,194.8l2.77,4.57-5.42,3-2.6-4.57,5.25-3m0-.14L710,197.75l2.7,4.75,5.61-3.09-2.88-4.75Z"/>
                    <polygon className="cls-9" points="695.12 214.54 701.99 210.79 701.99 215.57 695.12 219.5 695.12 214.54"/>
                    <path className="cls-10" d="M701.93,210.9v4.59l-6.74,3.89v-4.76l6.74-3.72m.13-.22-7,3.86v5.07l7-4v-4.89Z"/>
                    <polygon className="cls-8" points="688.09 215.51 688.12 210.65 691.72 208.67 695 214.58 694.99 219.5 688.09 215.51"/>
                    <path className="cls-10" d="M691.64,208.79l3.29,5.79v4.8l-6.74-3.89v-4.77l3.45-1.93m.05-.17-3.63,2v4.92l7,4v-5.07l-3.37-5.92Z"/>
                    <polygon className="cls-9" points="691.74 208.58 698.5 204.81 702 210.72 695.08 214.46 691.74 208.58"/>
                    <path className="cls-10" d="M698.42,204.93l3.45,5.7-6.76,3.74-3.25-5.7,6.56-3.74m.05-.18-6.78,3.87,3.37,5.92,7-3.86-3.59-5.93Z"/>
                    <polygon className="cls-8" points="724.23 214.02 724.25 210.44 730.18 213.88 730.15 217.38 724.23 214.02"/>
                    <path className="cls-10" d="M724.3,210.62l5.8,3.28v3.39L724.3,214v-3.39m-.1-.18v3.63l6,3.39v-3.62l-6-3.4Z"/>
                    <polygon className="cls-9" points="730.25 213.84 736.23 210.49 736.15 214.07 730.25 217.38 730.25 213.84"/>
                    <path className="cls-10" d="M736.1,210.62V214l-5.8,3.28V213.9l5.8-3.28m.1-.18-6,3.4v3.62l6-3.39v-3.63Z"/>
                    <polygon className="cls-9" points="724.23 210.4 730.22 207.09 736.18 210.49 730.2 213.78 724.23 210.4"/>
                    <path className="cls-10" d="M730.19,207.16l5.8,3.28-5.79,3.28-5.79-3.28,5.78-3.28m0-.12-6,3.4,6,3.4,6-3.4-6-3.4Z"/>
                    <polygon className="cls-9" points="658.6 194.22 665.47 190.46 665.47 195.24 658.6 199.17 658.6 194.22"/>
                    <path className="cls-10" d="M665.4,190.57v4.59l-6.73,3.89v-4.76l6.73-3.72m.13-.22-7,3.87v5.06l7-4v-4.89Z"/>
                    <polygon className="cls-8" points="651.57 195.18 651.6 190.32 655.2 188.35 658.48 194.25 658.47 199.17 651.57 195.18"/>
                    <path className="cls-10" d="M655.12,188.47l3.29,5.78v4.8l-6.74-3.89V190.4l3.45-1.93m0-.18-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                    <polygon className="cls-9" points="655.22 188.26 661.98 184.48 665.48 190.39 658.56 194.13 655.22 188.26"/>
                    <path className="cls-10" d="M661.9,184.6l3.45,5.71L658.59,194l-3.25-5.7,6.56-3.74m0-.17-6.77,3.86,3.37,5.93,7-3.87-3.59-5.92Z"/>
                    <polygon className="cls-9" points="695.58 192.68 701.07 189.68 700.99 193.48 695.58 196.57 695.58 192.68"/>
                    <path className="cls-10" d="M700.94,189.81v3.61l-5.3,3.06v-3.74l5.3-2.93m.1-.17-5.51,3v4l5.51-3.18v-3.84Z"/>
                    <polygon className="cls-8" points="690.05 193.44 690.08 189.61 692.9 188.05 695.49 192.7 695.48 196.57 690.05 193.44"/>
                    <path className="cls-10" d="M692.84,188.15l2.59,4.55v3.78l-5.3-3.06v-3.75l2.71-1.52m0-.14-2.86,1.6v3.87l5.51,3.18v-4L692.88,188Z"/>
                    <polygon className="cls-9" points="692.92 187.98 698.24 185.01 701 189.66 695.55 192.61 692.92 187.98"/>
                    <path className="cls-10" d="M698.18,185.11l2.72,4.49-5.33,2.94L693,188.05l5.16-2.94m0-.14-5.34,3,2.65,4.67,5.51-3L698.22,185Z"/>
                    <polygon className="cls-8" points="670.99 206.26 671.03 198.13 678.38 202.28 678.33 210.42 670.99 206.26"/>
                    <path className="cls-10" d="M671.12,198.27l7.13,4v8l-7.13-4v-8m-.18-.29v8.36l7.48,4.23v-8.36L670.94,198Z"/>
                    <polygon className="cls-9" points="678.5 202.21 685.81 198.13 685.81 206.34 678.5 210.42 678.5 202.21"/>
                    <path className="cls-10" d="M685.72,198.27v8l-7.13,4v-8l7.13-4m.17-.29-7.47,4.23v8.36l7.47-4.23V198Z"/>
                    <polygon className="cls-9" points="671.12 197.98 678.45 193.82 685.72 197.98 678.42 202.11 671.12 197.98"/>
                    <path className="cls-10" d="M678.41,193.94l7.13,4-7.12,4-7.13-4,7.12-4m0-.2L670.94,198l7.48,4.23,7.47-4.23-7.48-4.24Z"/>
                    <polygon className="cls-9" points="643.18 185.48 649.6 181.97 649.6 186.43 643.18 190.1 643.18 185.48"/>
                    <path className="cls-10" d="M649.54,182.07v4.29l-6.3,3.64v-4.45l6.3-3.48m.12-.2-6.54,3.61v4.73l6.54-3.78v-4.56Z"/>
                    <polygon className="cls-8" points="636.61 186.38 636.64 181.83 640 179.99 643.07 185.51 643.06 190.1 636.61 186.38"/>
                    <path className="cls-10" d="M639.92,180.1l3.08,5.41V190l-6.3-3.64v-4.45l3.22-1.81m.05-.16-3.39,1.89v4.6l6.54,3.78v-4.73L640,179.94Z"/>
                    <polygon className="cls-9" points="640.02 179.91 646.33 176.38 649.61 181.9 643.14 185.4 640.02 179.91"/>
                    <path className="cls-10" d="M646.26,176.49l3.23,5.33-6.33,3.49-3-5.33,6.13-3.49m0-.16L640,179.94l3.15,5.54,6.54-3.61-3.36-5.54Z"/>
                    <polygon className="cls-9" points="725.57 194.25 730.22 191.71 730.16 194.94 725.57 197.56 725.57 194.25"/>
                    <path className="cls-10" d="M730.11,191.82v3.07l-4.49,2.59v-3.17l4.49-2.49m.09-.14-4.67,2.57v3.38l4.67-2.69v-3.26Z"/>
                    <polygon className="cls-8" points="720.88 194.9 720.9 191.65 723.3 190.33 725.49 194.28 725.49 197.56 720.88 194.9"/>
                    <path className="cls-10" d="M723.25,190.41l2.19,3.87v3.2l-4.5-2.59V191.7l2.31-1.29m0-.11-2.42,1.35v3.29l4.67,2.69v-3.38l-2.25-3.95Z"/>
                    <polygon className="cls-9" points="723.32 190.28 727.83 187.76 730.16 191.7 725.54 194.2 723.32 190.28"/>
                    <path className="cls-10" d="M727.77,187.83l2.31,3.81-4.52,2.5-2.16-3.81,4.37-2.5m0-.11-4.52,2.58,2.25,3.95,4.67-2.57-2.4-4Z"/>
                  </g>
                  <g className="cls-45">
                    <polygon className="cls-9" points="673.31 174.83 677.2 172.7 677.14 175.4 673.31 177.59 673.31 174.83"/>
                    <path className="cls-10" d="M677.11,172.8v2.56l-3.76,2.17v-2.66l3.76-2.07m.07-.13-3.91,2.16v2.83l3.91-2.26v-2.73Z"/>
                    <polygon className="cls-8" points="669.38 175.37 669.4 172.65 671.41 171.55 673.24 174.85 673.24 177.59 669.38 175.37"/>
                    <path className="cls-10" d="M671.36,171.62l1.84,3.23v2.68l-3.76-2.17v-2.67l1.92-1.07m0-.1-2,1.13v2.75l3.9,2.26v-2.83l-1.88-3.31Z"/>
                    <polygon className="cls-9" points="671.42 171.5 675.2 169.39 677.15 172.69 673.29 174.78 671.42 171.5"/>
                    <path className="cls-10" d="M675.15,169.46l1.93,3.19-3.78,2.08-1.81-3.18,3.66-2.09m0-.1-3.79,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                    <polygon className="cls-9" points="720.43 177.95 724.32 175.82 724.26 178.52 720.43 180.71 720.43 177.95"/>
                    <path className="cls-10" d="M724.23,175.92v2.56l-3.76,2.17V178l3.76-2.07m.07-.13L720.39,178v2.83l3.91-2.26v-2.73Z"/>
                    <polygon className="cls-8" points="716.5 178.49 716.52 175.77 718.53 174.67 720.36 177.97 720.36 180.71 716.5 178.49"/>
                    <path className="cls-10" d="M718.48,174.74l1.84,3.23v2.68l-3.76-2.17v-2.66l1.92-1.08m0-.1-2,1.13v2.75l3.9,2.26V178l-1.88-3.31Z"/>
                    <polygon className="cls-9" points="718.54 174.62 722.31 172.51 724.27 175.81 720.41 177.9 718.54 174.62"/>
                    <path className="cls-10" d="M722.27,172.58l1.93,3.19-3.78,2.08-1.81-3.18,3.66-2.09m0-.1-3.79,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                    <polygon className="cls-9" points="711.37 183.56 716.02 181.02 715.96 184.24 711.37 186.86 711.37 183.56"/>
                    <path className="cls-10" d="M715.91,181.13v3.06l-4.5,2.6v-3.18l4.5-2.48M716,181l-4.67,2.58v3.38l4.67-2.7V181Z"/>
                    <polygon className="cls-8" points="706.67 184.2 706.7 180.96 709.1 179.64 711.29 183.58 711.28 186.86 706.67 184.2"/>
                    <path className="cls-10" d="M709,179.72l2.2,3.86v3.21l-4.5-2.6V181l2.3-1.29m0-.12L706.65,181v3.29l4.68,2.7v-3.38l-2.26-4Z"/>
                    <polygon className="cls-9" points="709.11 179.58 713.62 177.06 715.96 181 711.34 183.5 709.11 179.58"/>
                    <path className="cls-10" d="M713.57,177.14l2.31,3.81-4.52,2.49-2.17-3.81,4.38-2.49m0-.12-4.53,2.58,2.26,4L716,181l-2.4-4Z"/>
                    <polygon className="cls-9" points="676.24 187.3 681.1 184.64 681.03 188.01 676.24 190.75 676.24 187.3"/>
                    <path className="cls-10" d="M681,184.76V188l-4.7,2.72v-3.33l4.7-2.59m.09-.16-4.88,2.7v3.53l4.88-2.82V184.6Z"/>
                    <polygon className="cls-8" points="671.33 187.97 671.36 184.58 673.87 183.2 676.16 187.32 676.15 190.75 671.33 187.97"/>
                    <path className="cls-10" d="M673.81,183.28l2.3,4v3.36L671.4,188v-3.33l2.41-1.35m0-.12-2.53,1.42V188l4.89,2.82V187.3l-2.36-4.14Z"/>
                    <polygon className="cls-9" points="673.88 183.14 678.6 180.5 681.04 184.63 676.21 187.24 673.88 183.14"/>
                    <path className="cls-10" d="M678.54,180.59l2.41,4-4.72,2.61-2.26-4,4.57-2.61m0-.12-4.73,2.69,2.36,4.14,4.88-2.7-2.51-4.13Z"/>
                    <polygon className="cls-9" points="657.95 175.27 662.82 172.62 662.75 175.99 657.95 178.73 657.95 175.27"/>
                    <path className="cls-10" d="M662.7,172.73v3.2l-4.7,2.72v-3.32l4.7-2.6m.09-.15-4.88,2.69v3.54l4.88-2.82v-3.41Z"/>
                    <polygon className="cls-8" points="653.05 175.95 653.07 172.55 655.58 171.18 657.87 175.3 657.86 178.73 653.05 175.95"/>
                    <path className="cls-10" d="M655.52,171.26l2.3,4v3.35l-4.71-2.72v-3.32l2.41-1.35m0-.12L653,172.55V176l4.89,2.82v-3.54l-2.35-4.13Z"/>
                    <polygon className="cls-9" points="655.6 171.11 660.31 168.48 662.75 172.6 657.93 175.21 655.6 171.11"/>
                    <path className="cls-10" d="M660.26,168.56l2.41,4-4.73,2.61-2.26-4,4.58-2.61m0-.12-4.73,2.7,2.35,4.13,4.88-2.69-2.5-4.14Z"/>
                    <polygon className="cls-8" points="683.25 180.91 683.27 178.33 687.54 180.81 687.52 183.33 683.25 180.91"/>
                    <path className="cls-10" d="M683.3,178.45l4.18,2.37v2.44l-4.18-2.36v-2.45m-.07-.12v2.61l4.33,2.45v-2.61l-4.33-2.45Z"/>
                    <polygon className="cls-9" points="687.59 180.78 691.9 178.36 691.84 180.94 687.59 183.33 687.59 180.78"/>
                    <path className="cls-10" d="M691.81,178.45v2.45l-4.18,2.36v-2.44l4.18-2.37m.07-.12-4.32,2.45v2.61l4.32-2.45v-2.61Z"/>
                    <polygon className="cls-9" points="683.25 178.3 687.57 175.91 691.86 178.36 687.56 180.74 683.25 178.3"/>
                    <path className="cls-10" d="M687.55,176l4.18,2.37-4.17,2.36-4.18-2.36,4.17-2.37m0-.08-4.32,2.45,4.33,2.45,4.32-2.45-4.33-2.45Z"/>
                    <polygon className="cls-9" points="696.79 174.83 700.68 172.7 700.63 175.4 696.79 177.59 696.79 174.83"/>
                    <path className="cls-10" d="M700.59,172.8v2.56l-3.76,2.17v-2.66l3.76-2.07m.08-.13-3.91,2.16v2.83l3.91-2.26v-2.73Z"/>
                    <polygon className="cls-8" points="692.87 175.37 692.89 172.65 694.89 171.55 696.73 174.85 696.72 177.59 692.87 175.37"/>
                    <path className="cls-10" d="M694.85,171.62l1.84,3.23v2.68l-3.77-2.17v-2.67l1.93-1.07m0-.1-2,1.13v2.75l3.91,2.26v-2.83l-1.88-3.31Z"/>
                    <polygon className="cls-9" points="694.91 171.5 698.68 169.39 700.63 172.69 696.77 174.78 694.91 171.5"/>
                    <path className="cls-10" d="M698.64,169.46l1.93,3.19-3.78,2.08L695,171.55l3.66-2.09m0-.1-3.78,2.16,1.88,3.31,3.91-2.16-2-3.31Z"/>
                  </g>
                  <g className="cls-46">
                    <polygon className="cls-9" points="668.8 166.5 671.89 164.81 671.85 166.96 668.8 168.7 668.8 166.5"/>
                    <path className="cls-10" d="M671.82,164.89v2l-3,1.73v-2.11l3-1.65m.06-.1-3.1,1.71v2.25l3.1-1.8v-2.16Z"/>
                    <polygon className="cls-8" points="665.69 166.93 665.7 164.77 667.29 163.9 668.75 166.52 668.75 168.7 665.69 166.93"/>
                    <path className="cls-10" d="M667.26,164l1.46,2.57v2.13l-3-1.73v-2.11l1.53-.86m0-.08-1.61.9V167l3.11,1.8V166.5l-1.5-2.63Z"/>
                    <polygon className="cls-9" points="667.31 163.86 670.3 162.19 671.85 164.8 668.79 166.46 667.31 163.86"/>
                    <path className="cls-10" d="M670.27,162.24l1.53,2.53-3,1.65-1.44-2.53,2.91-1.65m0-.08-3,1.71,1.5,2.63,3.1-1.71-1.59-2.63Z"/>
                    <polygon className="cls-9" points="683.25 155.6 685.48 154.38 685.45 155.93 683.25 157.19 683.25 155.6"/>
                    <path className="cls-10" d="M685.43,154.44v1.47l-2.16,1.24v-1.52l2.16-1.19m0-.07-2.24,1.23v1.63l2.24-1.3v-1.56Z"/>
                    <polygon className="cls-8" points="681 155.91 681.01 154.35 682.16 153.72 683.21 155.62 683.21 157.19 681 155.91"/>
                    <path className="cls-10" d="M682.14,153.76l1.05,1.85v1.54L681,155.91v-1.53l1.11-.62m0-.06-1.16.65v1.58l2.24,1.3V155.6l-1.08-1.9Z"/>
                    <polygon className="cls-9" points="682.17 153.69 684.33 152.49 685.46 154.38 683.24 155.58 682.17 153.69"/>
                    <path className="cls-10" d="M684.31,152.52l1.11,1.83-2.17,1.2-1-1.83,2.1-1.2m0-.05-2.17,1.23,1.08,1.9,2.24-1.23-1.15-1.9Z"/>
                    <polygon className="cls-9" points="696.79 164.48 699.88 162.79 699.83 164.93 696.79 166.67 696.79 164.48"/>
                    <path className="cls-10" d="M699.8,162.86v2l-3,1.72v-2.11l3-1.65m.06-.09-3.1,1.71v2.24l3.1-1.79v-2.16Z"/>
                    <polygon className="cls-8" points="693.67 164.91 693.68 162.75 695.28 161.87 696.73 164.49 696.73 166.67 693.67 164.91"/>
                    <path className="cls-10" d="M695.24,161.93l1.46,2.56v2.13l-3-1.72v-2.12l1.53-.85m0-.08-1.6.9v2.18l3.1,1.79v-2.24l-1.5-2.63Z"/>
                    <polygon className="cls-9" points="695.29 161.83 698.28 160.16 699.84 162.78 696.77 164.44 695.29 161.83"/>
                    <path className="cls-10" d="M698.25,160.22l1.53,2.52-3,1.66-1.44-2.53,2.91-1.65m0-.08-3,1.71,1.5,2.63,3.1-1.71-1.59-2.63Z"/>
                    <polygon className="cls-9" points="689.74 159.28 692.35 157.85 692.31 159.66 689.74 161.13 689.74 159.28"/>
                    <path className="cls-10" d="M692.29,157.91v1.72l-2.52,1.45V159.3l2.52-1.39m0-.08-2.61,1.44v1.9l2.61-1.51v-1.83Z"/>
                    <polygon className="cls-8" points="687.11 159.63 687.13 157.82 688.47 157.08 689.7 159.29 689.69 161.13 687.11 159.63"/>
                    <path className="cls-10" d="M688.44,157.12l1.23,2.17v1.79l-2.52-1.45v-1.78l1.29-.73m0-.06-1.36.76v1.84l2.62,1.51v-1.9l-1.26-2.21Z"/>
                    <polygon className="cls-9" points="688.48 157.05 691 155.64 692.31 157.84 689.73 159.24 688.48 157.05"/>
                    <path className="cls-10" d="M691,155.68l1.29,2.13-2.53,1.4-1.22-2.13,2.46-1.4m0-.07-2.53,1.45,1.26,2.21,2.61-1.44L691,155.61Z"/>
                    <polygon className="cls-8" points="682.66 169.1 682.68 165.49 685.88 167.36 685.86 170.91 682.66 169.1"/>
                    <path className="cls-10" d="M682.71,165.62l3.11,1.76v3.46l-3.11-1.75v-3.47m-.07-.13v3.64l3.25,1.84v-3.64l-3.25-1.84Z"/>
                    <polygon className="cls-9" points="685.93 167.33 689.17 165.52 689.11 169.13 685.93 170.91 685.93 167.33"/>
                    <path className="cls-10" d="M689.08,165.62v3.47L686,170.84v-3.46l3.11-1.76m.07-.13-3.26,1.84V171l3.26-1.84v-3.64Z"/>
                    <polygon className="cls-9" points="682.66 165.46 685.91 163.68 689.13 165.52 685.89 167.29 682.66 165.46"/>
                    <path className="cls-10" d="M685.89,163.73l3.11,1.76-3.11,1.76-3.1-1.76,3.1-1.76m0-.09-3.25,1.85,3.25,1.84,3.26-1.84-3.26-1.85Z"/>
                    <polygon className="cls-8" points="674.22 161.04 674.23 159.1 677.45 160.97 677.43 162.87 674.22 161.04"/>
                    <path className="cls-10" d="M674.26,159.2l3.15,1.78v1.84L674.26,161V159.2m0-.1v2l3.25,1.84v-2l-3.25-1.84Z"/>
                    <polygon className="cls-9" points="677.49 160.94 680.73 159.13 680.69 161.07 677.49 162.87 677.49 160.94"/>
                    <path className="cls-10" d="M680.66,159.2V161l-3.14,1.78V161l3.14-1.78m.06-.1-3.26,1.84v2l3.26-1.84v-2Z"/>
                    <polygon className="cls-9" points="674.22 159.08 677.47 157.28 680.71 159.13 677.46 160.91 674.22 159.08"/>
                    <path className="cls-10" d="M677.46,157.32l3.15,1.78-3.15,1.78-3.14-1.78,3.14-1.78m0-.06-3.25,1.84,3.25,1.85,3.26-1.85-3.26-1.84Z"/>
                    <polygon className="cls-8" points="702.49 170.3 702.52 168.11 704.32 167.98 706.08 170.32 706.07 172.39 702.49 170.3"/>
                    <path className="cls-10" d="M704.28,168l1.75,2.3v2l-3.47-2V168.2l1.72-.17m0-.1-1.85.18v2.23l3.65,2.13v-2.18l-1.8-2.36Z"/>
                    <polygon className="cls-9" points="704.31 167.96 702.45 168.13 703.56 167.54 703.53 167.74 704.29 167.69 705 168.59 705.87 168.12 705.88 166.04 705.02 166.5 704.32 165.6 703.56 165.65 703.47 165.37 704.28 164.93 705.12 164.86 706.04 166.06 706.43 165.86 707.95 164.99 710.25 167.99 706.15 170.34 704.31 167.96"/>
                    <path className="cls-10" d="M705.09,164.89l.93,1.18a.05.05,0,0,0,0,0h0l.36-.2,1.48-.85,2.27,2.93-4,2.34-1.83-2.36,0,0h0l-1.6.16.82-.48v.14a.06.06,0,0,0,0,0h0l.71-.07.71.89,0,0h0l.84-.48a.05.05,0,0,0,0,0V166s0,0,0,0h0l-.79.46-.7-.88,0,0h0l-.69.06-.09-.22.78-.44.8-.07m0,0-.85.07-.82.47.12.3.73-.07.73.91.83-.48v2.06l-.84.48-.73-.91-.73.07v-.22l-1.09.63h0l1.84-.18,1.85,2.4,4.13-2.4-2.33-3-1.52.86-.36.21-.94-1.2Z"/>
                    <polygon className="cls-9" points="706.15 170.34 710.08 168.09 710.04 170.18 706.15 172.43 706.15 170.34"/>
                    <path className="cls-10" d="M710,168.16v2l-3.85,2.24v-2l3.85-2.21m.05-.09-4,2.27v2.13l4-2.29v-2.11Z"/>
                    <line className="cls-49" x1="705.03" y1="166.33" x2="705.03" y2="168.68"/>
                    <polyline className="cls-8" points="704.3 165.61 705.03 166.52 705.86 166.04 705.86 168.09 705.02 168.58 704.29 167.67"/>
                    <polygon className="cls-9" points="705.05 166.5 705.9 166.04 705.86 168.12 705.04 168.56 705.05 166.5"/>
                    <path className="cls-10" d="M705.83,166.1v2l-.76.42v-2l.75-.43m.06-.08-.86.48v2.1l.87-.48V166Z"/>
                    <polyline className="cls-8" points="704.29 167.66 703.57 167.74 703.57 165.68 704.29 165.62"/>
                    <line className="cls-49" x1="703.57" y1="165.43" x2="703.57" y2="167.79"/>
                  </g>
                  <polygon className="cls-9" points="723.82 291.65 735.93 284.96 735.93 293.31 723.82 300.3 723.82 291.65"/>
                  <path className="cls-10" d="M735.81,285.16v8.09l-11.88,6.86v-8.4l11.88-6.55m.23-.39-12.34,6.81v8.92L736,293.38v-8.61Z"/>
                  <polygon className="cls-8" points="711.48 293.31 711.48 284.78 717.72 281.29 723.61 291.64 723.59 300.3 711.48 293.31"/>
                  <path className="cls-10" d="M717.68,281.44l5.8,10.2v8.47l-11.88-6.86v-8.41l6.08-3.4m.08-.31-6.39,3.58v8.67l12.33,7.12v-8.92l-5.94-10.45Z"/>
                  <polygon className="cls-9" points="717.92 281.17 729.67 274.48 735.88 284.73 723.75 291.43 717.92 281.17"/>
                  <path className="cls-10" d="M729.63,274.63l6.09,10.06-11.93,6.58-5.71-10,11.55-6.59m.08-.31-12,6.81,5.94,10.45L736,284.77l-6.33-10.45Z"/>
                  <polygon className="cls-8" points="719.16 294.98 719.16 292.51 721.34 293.75 721.34 296.22 719.16 294.98"/>
                  <path className="cls-10" d="M719.27,292.71l2,1.1V296l-2-1.1v-2.21m-.23-.4v2.74l2.42,1.37v-2.74L719,292.31Z"/>
                  <polygon className="cls-8" points="713.95 294.74 713.95 290.06 716.48 291.49 716.48 296.18 713.95 294.74"/>
                  <path className="cls-10" d="M714.07,290.25l2.3,1.31V296l-2.3-1.3v-4.43m-.23-.39v4.95l2.76,1.56v-4.95l-2.76-1.56Z"/>
                  <polygon className="cls-9" points="694.83 239.78 704.4 234.56 704.4 241.21 694.83 246.68 694.83 239.78"/>
                  <path className="cls-10" d="M704.31,234.71v6.39l-9.39,5.42v-6.63l9.39-5.18m.18-.31-9.75,5.38v7.05l9.75-5.62V234.4Z"/>
                  <polygon className="cls-8" points="685.04 241.13 685.08 234.35 690.01 231.65 694.66 239.83 694.65 246.68 685.04 241.13"/>
                  <path className="cls-10" d="M690,231.77l4.58,8.06v6.69l-9.39-5.42v-6.64l4.81-2.69m.07-.24L685,234.35v6.86l9.75,5.62v-7.05l-4.69-8.25Z"/>
                  <polygon className="cls-9" points="690.17 231.56 699.46 226.27 704.36 234.37 694.77 239.66 690.17 231.56"/>
                  <path className="cls-10" d="M699.42,226.39l4.81,8-9.42,5.2-4.52-7.95,9.13-5.2m.07-.24-9.44,5.38,4.69,8.25,9.75-5.38-5-8.25Z"/>
                  <polygon className="cls-8" points="691.1 242.45 691.14 240.52 692.92 241.52 692.88 243.45 691.1 242.45"/>
                  <path className="cls-10" d="M691.24,240.67l1.54.88v1.75l-1.54-.88v-1.75m-.18-.31v2.17l1.91,1.08v-2.17l-1.91-1.08Z"/>
                  <polygon className="cls-8" points="686.99 242.26 687.03 238.58 689.08 239.74 689.03 243.42 686.99 242.26"/>
                  <path className="cls-10" d="M687.12,238.74l1.82,1v3.49l-1.82-1v-3.49m-.18-.31v3.91l2.18,1.23v-3.91l-2.18-1.23Z"/>
                  <polygon className="cls-9" points="675.71 227.53 683.96 223.02 683.96 228.76 675.71 233.48 675.71 227.53"/>
                  <path className="cls-10" d="M683.88,223.15v5.52l-8.1,4.67v-5.72l8.1-4.47m.16-.26-8.41,4.64v6.08l8.41-4.85v-5.87Z"/>
                  <polygon className="cls-8" points="667.26 228.69 667.3 222.84 671.55 220.51 675.56 227.57 675.55 233.48 667.26 228.69"/>
                  <path className="cls-10" d="M671.52,220.62l4,6.95v5.77l-8.1-4.67v-5.73l4.15-2.32m.06-.22-4.36,2.44v5.92l8.41,4.85v-6.08l-4-7.13Z"/>
                  <polygon className="cls-9" points="671.68 220.43 679.7 215.87 683.93 222.86 675.66 227.42 671.68 220.43"/>
                  <path className="cls-10" d="M679.67,216l4.15,6.86-8.13,4.49-3.9-6.86,7.88-4.49m.05-.21-8.14,4.64,4,7.13,8.41-4.64-4.32-7.13Z"/>
                  <polygon className="cls-8" points="672.49 229.83 672.52 228.16 674.06 229.03 674.02 230.7 672.49 229.83"/>
                  <path className="cls-10" d="M672.61,228.3l1.33.75v1.51l-1.33-.76v-1.5m-.16-.27v1.86l1.65.94V229l-1.65-.93Z"/>
                  <polygon className="cls-8" points="668.94 229.66 668.98 226.49 670.75 227.49 670.71 230.66 668.94 229.66"/>
                  <path className="cls-10" d="M669.06,226.63l1.57.88v3l-1.57-.89v-3m-.16-.27v3.37l1.88,1.07v-3.38l-1.88-1.06Z"/>
                  <polygon className="cls-9" points="751.13 273.8 760.7 268.57 760.7 275.22 751.13 280.69 751.13 273.8"/>
                  <path className="cls-10" d="M760.61,268.72v6.4l-9.39,5.41V273.9l9.39-5.18m.18-.3L751,273.8v7.05l9.75-5.63v-6.8Z"/>
                  <polygon className="cls-8" points="741.34 275.14 741.38 268.37 746.31 265.66 750.96 273.84 750.95 280.69 741.34 275.14"/>
                  <path className="cls-10" d="M746.28,265.79l4.58,8v6.69l-9.39-5.41v-6.65l4.81-2.68m.07-.25-5.06,2.83v6.85l9.75,5.63V273.8l-4.69-8.26Z"/>
                  <polygon className="cls-9" points="746.47 265.57 755.76 260.28 760.66 268.38 751.07 273.68 746.47 265.57"/>
                  <path className="cls-10" d="M755.72,260.41l4.81,7.94-9.42,5.2-4.52-7.94,9.13-5.2m.07-.25-9.44,5.38L751,273.8l9.75-5.38-5-8.26Z"/>
                  <polygon className="cls-8" points="747.4 276.46 747.44 274.53 749.22 275.54 749.18 277.47 747.4 276.46"/>
                  <path className="cls-10" d="M747.54,274.69l1.54.87v1.75l-1.54-.88v-1.74m-.18-.31v2.16l1.9,1.08v-2.16l-1.9-1.08Z"/>
                  <polygon className="cls-8" points="743.29 276.27 743.33 272.59 745.38 273.75 745.34 277.43 743.29 276.27"/>
                  <path className="cls-10" d="M743.42,272.75l1.82,1v3.49l-1.82-1v-3.49m-.18-.31v3.91l2.18,1.23v-3.91l-2.18-1.23Z"/>
                  <polygon className="cls-9" points="760.02 250.97 766.89 247.22 766.89 252 760.02 255.93 760.02 250.97"/>
                  <path className="cls-10" d="M766.83,247.33v4.59l-6.74,3.89v-4.76l6.74-3.72m.13-.22L760,251V256l7-4v-4.89Z"/>
                  <polygon className="cls-8" points="752.99 251.94 753.02 247.08 756.62 245.1 759.9 251.01 759.89 255.93 752.99 251.94"/>
                  <path className="cls-10" d="M756.54,245.22l3.29,5.79v4.8l-6.74-3.89v-4.77l3.45-1.93m.05-.18-3.63,2V252l7,4V251L756.59,245Z"/>
                  <polygon className="cls-9" points="756.64 245.01 763.4 241.24 766.9 247.14 759.98 250.89 756.64 245.01"/>
                  <path className="cls-10" d="M763.32,241.36l3.46,5.7L760,250.8l-3.25-5.71,6.56-3.73m0-.18L756.59,245,760,251l7-3.86-3.59-5.93Z"/>
                  <polygon className="cls-8" points="757.34 252.89 757.37 251.5 758.65 252.22 758.62 253.61 757.34 252.89"/>
                  <path className="cls-10" d="M757.44,251.61l1.11.63v1.26l-1.11-.63v-1.26m-.13-.22v1.55l1.37.78v-1.55l-1.37-.78Z"/>
                  <polygon className="cls-8" points="754.39 252.75 754.42 250.11 755.89 250.94 755.86 253.58 754.39 252.75"/>
                  <path className="cls-10" d="M754.49,250.22l1.3.74v2.51l-1.3-.74v-2.51m-.13-.22v2.81l1.56.88v-2.8l-1.56-.89Z"/>
                  <polygon className="cls-9" points="743.48 242.92 750.35 239.17 750.35 243.94 743.48 247.87 743.48 242.92"/>
                  <path className="cls-10" d="M750.29,239.28v4.59l-6.74,3.89V243l6.74-3.72m.13-.22-7,3.86V248l7-4v-4.88Z"/>
                  <polygon className="cls-8" points="736.45 243.89 736.48 239.02 740.08 237.05 743.36 242.95 743.35 247.87 736.45 243.89"/>
                  <path className="cls-10" d="M740,237.17l3.29,5.79v4.8l-6.74-3.89V239.1l3.45-1.93m0-.18-3.62,2v4.92l7,4v-5.06L740,237Z"/>
                  <polygon className="cls-9" points="740.1 236.96 746.86 233.19 750.36 239.09 743.44 242.83 740.1 236.96"/>
                  <path className="cls-10" d="M746.78,233.3l3.45,5.71-6.76,3.74L740.22,237l6.56-3.74m0-.17L740,237l3.38,5.93,7-3.86-3.6-5.93Z"/>
                  <polygon className="cls-8" points="740.8 244.83 740.83 243.45 742.11 244.17 742.08 245.56 740.8 244.83"/>
                  <path className="cls-10" d="M740.9,243.56l1.11.63v1.25l-1.11-.63v-1.25m-.13-.22v1.55l1.37.78v-1.56l-1.37-.77Z"/>
                  <polygon className="cls-8" points="737.85 244.7 737.88 242.06 739.35 242.89 739.32 245.53 737.85 244.7"/>
                  <path className="cls-10" d="M738,242.17l1.3.74v2.51l-1.3-.74v-2.51m-.13-.22v2.8l1.56.89v-2.81l-1.56-.88Z"/>
                  <polygon className="cls-9" points="714.62 249.68 721.96 245.67 721.96 250.77 714.62 254.97 714.62 249.68"/>
                  <path className="cls-10" d="M721.89,245.79v4.9l-7.2,4.16v-5.09l7.2-4m.14-.24-7.48,4.13v5.41l7.48-4.32v-5.22Z"/>
                  <polygon className="cls-8" points="707.11 250.71 707.14 245.51 710.98 243.41 714.49 249.71 714.48 254.97 707.11 250.71"/>
                  <path className="cls-10" d="M710.9,243.53l3.51,6.18v5.14l-7.2-4.16V245.6l3.69-2.07m.05-.18-3.88,2.16v5.26l7.48,4.32v-5.41l-3.6-6.33Z"/>
                  <polygon className="cls-9" points="711.01 243.31 718.23 239.28 721.97 245.59 714.58 249.58 711.01 243.31"/>
                  <path className="cls-10" d="M718.14,239.41l3.69,6.09-7.23,4-3.46-6.09,7-4m.05-.19L711,243.35l3.6,6.33,7.48-4.13-3.84-6.33Z"/>
                  <polygon className="cls-8" points="711.76 251.72 711.79 250.24 713.15 251.01 713.12 252.49 711.76 251.72"/>
                  <path className="cls-10" d="M711.86,250.36l1.19.67v1.34l-1.19-.67v-1.34m-.14-.24v1.66l1.47.83V251l-1.47-.83Z"/>
                  <polygon className="cls-8" points="708.6 251.58 708.64 248.75 710.21 249.64 710.17 252.46 708.6 251.58"/>
                  <path className="cls-10" d="M708.71,248.87l1.39.79v2.69l-1.39-.8v-2.68m-.14-.23v3l1.67.94v-3l-1.67-.94Z"/>
                  <polygon className="cls-9" points="801.48 224.57 807.45 221.31 807.36 225.45 801.48 228.82 801.48 224.57"/>
                  <path className="cls-10" d="M807.31,221.45v3.93l-5.78,3.34v-4.08l5.78-3.19m.11-.19-6,3.31v4.34l6-3.46v-4.19Z"/>
                  <polygon className="cls-8" points="795.45 225.4 795.48 221.23 798.56 219.54 801.37 224.6 801.36 228.81 795.45 225.4"/>
                  <path className="cls-10" d="M798.49,219.64l2.82,5v4.12l-5.78-3.34v-4.09l3-1.65m0-.15-3.11,1.74v4.22l6,3.46v-4.34l-2.89-5.08Z"/>
                  <polygon className="cls-9" points="798.58 219.46 804.37 216.23 807.37 221.29 801.44 224.5 798.58 219.46"/>
                  <path className="cls-10" d="M804.3,216.33l3,4.89-5.8,3.2-2.78-4.89,5.62-3.2m0-.15-5.81,3.31,2.89,5.08,6-3.31-3.08-5.08Z"/>
                  <polygon className="cls-8" points="799.18 226.21 799.21 224.93 800.3 225.64 800.27 226.83 799.18 226.21"/>
                  <path className="cls-10" d="M799.26,225.12l1,.54v1.07l-1-.54v-1.07m-.11-.19v1.33l1.18.66v-1.33l-1.18-.66Z"/>
                  <polygon className="cls-8" points="796.65 226.09 796.68 223.74 797.94 224.54 797.91 226.81 796.65 226.09"/>
                  <path className="cls-10" d="M796.73,223.93l1.12.63v2.15l-1.12-.63v-2.15m-.11-.19v2.4l1.34.76v-2.4l-1.34-.76Z"/>
                  <polygon className="cls-9" points="727.86 233.39 734.73 229.64 734.73 234.41 727.86 238.34 727.86 233.39"/>
                  <path className="cls-10" d="M734.67,229.75v4.59l-6.74,3.89v-4.76l6.74-3.72m.13-.22-7,3.86v5.06l7-4v-4.88Z"/>
                  <polygon className="cls-8" points="720.83 234.35 720.86 229.49 724.46 227.52 727.74 233.42 727.73 238.34 720.83 234.35"/>
                  <path className="cls-10" d="M724.38,227.64l3.29,5.78v4.81l-6.74-3.89v-4.77l3.45-1.93m0-.18-3.62,2v4.92l7,4v-5.06l-3.38-5.93Z"/>
                  <polygon className="cls-9" points="724.48 227.43 731.24 223.65 734.74 229.56 727.82 233.3 724.48 227.43"/>
                  <path className="cls-10" d="M731.16,223.77l3.46,5.71-6.77,3.73-3.25-5.7,6.56-3.74m.05-.17-6.79,3.86,3.38,5.93,7-3.86-3.59-5.93Z"/>
                  <polygon className="cls-8" points="725.18 235.3 725.21 233.92 726.49 234.64 726.46 236.02 725.18 235.3"/>
                  <path className="cls-10" d="M725.28,234l1.11.63v1.25l-1.11-.63V234m-.13-.22v1.55l1.37.77v-1.55l-1.37-.77Z"/>
                  <polygon className="cls-8" points="722.23 235.17 722.26 232.53 723.73 233.36 723.7 236 722.23 235.17"/>
                  <path className="cls-10" d="M722.33,232.64l1.3.74v2.51l-1.3-.74v-2.51m-.13-.23v2.81l1.56.89V233.3l-1.56-.89Z"/>
                  <polygon className="cls-9" points="713.34 225.12 720.21 221.37 720.21 226.14 713.34 230.07 713.34 225.12"/>
                  <path className="cls-10" d="M720.15,221.48v4.59L713.41,230V225.2l6.74-3.72m.13-.22-7,3.86v5.06l7-4v-4.88Z"/>
                  <polygon className="cls-8" points="706.31 226.09 706.35 221.22 709.94 219.25 713.22 225.15 713.21 230.07 706.31 226.09"/>
                  <path className="cls-10" d="M709.86,219.37l3.29,5.79V230l-6.74-3.89V221.3l3.45-1.93m0-.18-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-9" points="709.97 219.16 716.72 215.39 720.22 221.29 713.3 225.03 709.97 219.16"/>
                  <path className="cls-10" d="M716.64,215.51l3.46,5.7L713.33,225l-3.25-5.71,6.56-3.73m.05-.18-6.78,3.86,3.37,5.93,7-3.86-3.59-5.93Z"/>
                  <polygon className="cls-8" points="646 217.16 646 211.77 650.43 211.34 654.89 217.18 654.87 222.34 646 217.16"/>
                  <path className="cls-10" d="M650.38,211.46l4.37,5.72v4.95l-8.64-5v-5.21l4.27-.42m.11-.25-4.61.45v5.57l9.1,5.31V217.1l-4.49-5.89Z"/>
                  <path className="cls-8" d="M647.05,215.94l0-2.55a.35.35,0,0,1,.07-.26.24.24,0,0,1,.17-.07.47.47,0,0,1,.22.07,1.19,1.19,0,0,1,.55.93v2.49Z"/>
                  <path className="cls-10" d="M647.32,213.13h0a.35.35,0,0,1,.19.06,1.12,1.12,0,0,1,.52.86v2.39l-.88-.52v-2.54a.28.28,0,0,1,.06-.2.13.13,0,0,1,.12-.05m0-.13c-.19,0-.32.15-.3.39V216l1.13.67v-2.61a1.23,1.23,0,0,0-.58-1,.41.41,0,0,0-.25-.08Z"/>
                  <polygon className="cls-8" points="649.31 219.1 649.36 216.51 651.51 217.74 651.45 220.36 649.31 219.1"/>
                  <path className="cls-10" d="M649.46,216.68l1.91,1.09,0,2.42-1.89-1.11v-2.4m-.19-.33v2.84l2.27,1.33,0-2.86-2.29-1.31Z"/>
                  <path className="cls-8" d="M652.73,219.23l0-2.55c0-.21.09-.33.24-.33a.49.49,0,0,1,.24.08,1.17,1.17,0,0,1,.53.92v2.49Z"/>
                  <path className="cls-10" d="M653,216.42a.48.48,0,0,1,.21.06,1.1,1.1,0,0,1,.49.87v2.38l-.88-.52v-2.53c0-.12,0-.26.18-.26m0-.13c-.19,0-.31.14-.31.39v2.61l1.14.66v-2.6a1.25,1.25,0,0,0-.56-1,.63.63,0,0,0-.27-.08Z"/>
                  <path className="cls-9" d="M650.46,211.27l-4.38.41,2.42-1.39,0,.44,1.9-.11,1.77,2.24,2.17-1.18,0-5.18a.05.05,0,0,0,0-.05h-.06l-2.07,1.2-1.75-2.26-1.88.14-.22-.7,2-1.1,2.09-.17,2.29,3,1-.49,3.8-2.17,5.73,7.48-10.22,5.86Z"/>
                  <path className="cls-10" d="M652.42,203.63l2.31,2.94a.12.12,0,0,0,.1.05.07.07,0,0,0,.06,0l.89-.51,3.7-2.11,5.66,7.3-10.07,5.85-4.56-5.9a.12.12,0,0,0-.1,0h0l-4,.41,2-1.19,0,.34a.18.18,0,0,0,0,.09.16.16,0,0,0,.09,0h0l1.76-.17,1.78,2.21a.12.12,0,0,0,.1,0l.06,0,2.09-1.2a.12.12,0,0,0,.07-.11V206.5a.14.14,0,0,0-.07-.11l-.06,0-.07,0-2,1.15-1.76-2.19a.12.12,0,0,0-.1,0h0l-1.73.14-.21-.54,1.93-1.11,2-.17m.06-.13-2.11.17-2,1.18.29.74,1.82-.16,1.83,2.27,2.07-1.2v5.12l-2.09,1.2-1.83-2.27-1.82.18,0-.55-2.72,1.58h0l4.57-.46,4.63,6,10.29-6-5.81-7.49-3.8,2.16-.89.51-2.35-3Z"/>
                  <polygon className="cls-9" points="655.05 217.23 664.77 211.67 664.77 216.83 655.05 222.43 655.05 217.23"/>
                  <path className="cls-10" d="M664.7,211.78v5l-9.59,5.57v-5l9.59-5.52m.13-.22L655,217.23v5.31l9.85-5.71v-5.27Z"/>
                  <line className="cls-50" x1="652.26" y1="207.21" x2="652.26" y2="213.09"/>
                  <polyline className="cls-8" points="650.43 205.43 652.26 207.7 654.33 206.5 654.33 211.62 652.24 212.82 650.41 210.55"/>
                  <polygon className="cls-9" points="652.32 207.64 654.33 206.54 654.33 211.68 652.3 212.78 652.32 207.64"/>
                  <path className="cls-10" d="M654.27,206.65v5l-1.91,1.06,0-5,1.88-1.06m.13-.22-2.14,1.21,0,5.24,2.16-1.2v-5.25Z"/>
                  <polyline className="cls-8" points="650.43 210.53 648.62 210.74 648.62 205.6 650.43 205.44"/>
                  <line className="cls-50" x1="648.62" y1="204.97" x2="648.62" y2="210.85"/>
                  <polygon className="cls-9" points="804.59 244.98 811.46 241.23 811.46 246.01 804.59 249.94 804.59 244.98"/>
                  <path className="cls-10" d="M811.4,241.34v4.59l-6.74,3.89v-4.76l6.74-3.72m.13-.22-7,3.86v5.07l7-4v-4.89Z"/>
                  <polygon className="cls-8" points="797.56 245.95 797.59 241.09 801.19 239.11 804.47 245.02 804.46 249.94 797.56 245.95"/>
                  <path className="cls-10" d="M801.11,239.23,804.4,245v4.8l-6.74-3.89v-4.77l3.45-1.93m0-.18-3.63,2V246l7,4V245l-3.37-5.93Z"/>
                  <polygon className="cls-9" points="801.21 239.02 807.97 235.25 811.47 241.15 804.55 244.9 801.21 239.02"/>
                  <path className="cls-10" d="M807.89,235.37l3.46,5.7-6.77,3.74-3.25-5.71,6.56-3.73m.05-.18-6.78,3.86,3.37,5.93,7-3.86-3.59-5.93Z"/>
                  <polygon className="cls-8" points="801.91 246.9 801.94 245.51 803.22 246.23 803.19 247.62 801.91 246.9"/>
                  <path className="cls-10" d="M802,245.62l1.11.63v1.26l-1.11-.63v-1.26m-.13-.22V247l1.37.78v-1.55l-1.37-.78Z"/>
                  <polygon className="cls-8" points="798.96 246.76 798.99 244.12 800.46 244.95 800.43 247.59 798.96 246.76"/>
                  <path className="cls-10" d="M799.06,244.23l1.31.74v2.51l-1.31-.74v-2.51m-.13-.22v2.81l1.56.88v-2.8l-1.56-.89Z"/>
                  <polygon className="cls-9" points="821.44 235.96 827.41 232.7 827.33 236.84 821.44 240.21 821.44 235.96"/>
                  <path className="cls-10" d="M827.27,232.84v3.93l-5.78,3.34V236l5.78-3.19m.11-.19-6,3.31v4.34l6-3.46v-4.19Z"/>
                  <polygon className="cls-8" points="815.41 236.79 815.44 232.62 818.52 230.93 821.33 235.99 821.33 240.21 815.41 236.79"/>
                  <path className="cls-10" d="M818.45,231l2.82,5v4.12l-5.78-3.34v-4.09l3-1.65m0-.15-3.11,1.74v4.22l6,3.46V236l-2.89-5.08Z"/>
                  <polygon className="cls-9" points="818.54 230.85 824.33 227.62 827.33 232.68 821.4 235.89 818.54 230.85"/>
                  <path className="cls-10" d="M824.27,227.72l3,4.89-5.81,3.2-2.78-4.89,5.63-3.2m0-.15-5.81,3.31,2.89,5.08,6-3.31-3.08-5.08Z"/>
                  <polygon className="cls-8" points="819.14 237.6 819.17 236.32 820.26 237.03 820.24 238.22 819.14 237.6"/>
                  <path className="cls-10" d="M819.23,236.51l.95.54v1.07l-.95-.54v-1.07m-.12-.19v1.33l1.18.66V237l-1.18-.66Z"/>
                  <polygon className="cls-8" points="816.61 237.48 816.64 235.13 817.9 235.93 817.87 238.2 816.61 237.48"/>
                  <path className="cls-10" d="M816.69,235.32l1.12.63v2.15l-1.12-.63v-2.15m-.11-.19v2.4l1.35.76v-2.4l-1.35-.76Z"/>
                  <polygon className="cls-9" points="774 317.65 780.87 313.9 780.87 318.67 774 322.6 774 317.65"/>
                  <path className="cls-10" d="M780.81,314v4.59l-6.74,3.89v-4.76l6.74-3.72m.13-.22-7,3.86v5.06l7-4v-4.88Z"/>
                  <polygon className="cls-8" points="766.98 318.62 767.01 313.75 770.6 311.78 773.88 317.68 773.88 322.6 766.98 318.62"/>
                  <path className="cls-10" d="M770.52,311.9l3.29,5.78v4.81l-6.74-3.89v-4.77l3.45-1.93m.05-.18-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-9" points="770.63 311.69 777.38 307.92 780.88 313.82 773.97 317.56 770.63 311.69"/>
                  <path className="cls-10" d="M777.3,308l3.46,5.7L774,317.47l-3.24-5.7L777.3,308m.05-.18-6.78,3.86,3.37,5.93,7-3.86-3.59-5.93Z"/>
                  <polygon className="cls-8" points="771.33 319.56 771.36 318.18 772.63 318.9 772.6 320.28 771.33 319.56"/>
                  <path className="cls-10" d="M771.43,318.29l1.11.63v1.25l-1.11-.63v-1.25m-.13-.22v1.55l1.37.77v-1.55l-1.37-.77Z"/>
                  <polygon className="cls-8" points="768.38 319.43 768.41 316.79 769.88 317.62 769.85 320.26 768.38 319.43"/>
                  <path className="cls-10" d="M768.47,316.9l1.31.74v2.51l-1.31-.74V316.9m-.13-.23v2.81l1.57.89v-2.81l-1.57-.89Z"/>
                  <polygon className="cls-9" points="781 321.65 787.87 317.89 787.87 322.67 781 326.6 781 321.65"/>
                  <path className="cls-10" d="M787.81,318v4.59l-6.74,3.89v-4.76l6.74-3.72m.13-.22-7,3.87v5.06l7-4v-4.89Z"/>
                  <polygon className="cls-8" points="773.97 322.61 774 317.75 777.6 315.77 780.88 321.68 780.87 326.6 773.97 322.61"/>
                  <path className="cls-10" d="M777.52,315.89l3.29,5.79v4.8l-6.74-3.89v-4.77l3.45-1.93m.05-.17-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-9" points="777.63 315.69 784.38 311.91 787.88 317.82 780.96 321.56 777.63 315.69"/>
                  <path className="cls-10" d="M784.3,312l3.45,5.71L781,321.47l-3.25-5.7L784.3,312m.05-.17-6.78,3.86,3.37,5.93,7-3.87-3.59-5.92Z"/>
                  <polygon className="cls-8" points="778.33 323.56 778.36 322.17 779.63 322.89 779.6 324.28 778.33 323.56"/>
                  <path className="cls-10" d="M778.42,322.29l1.11.62v1.26l-1.11-.63v-1.25m-.13-.23v1.55l1.37.78v-1.55l-1.37-.78Z"/>
                  <polygon className="cls-8" points="775.37 323.42 775.4 320.78 776.87 321.62 776.84 324.25 775.37 323.42"/>
                  <path className="cls-10" d="M775.47,320.89l1.31.74v2.51l-1.31-.74v-2.51m-.13-.22v2.81l1.57.89v-2.81l-1.57-.89Z"/>
                  <polygon className="cls-9" points="821.44 251.56 827.41 248.3 827.33 252.44 821.44 255.81 821.44 251.56"/>
                  <path className="cls-10" d="M827.27,248.44v3.94l-5.78,3.33v-4.08l5.78-3.19m.11-.19-6,3.31v4.34l6-3.46v-4.19Z"/>
                  <polygon className="cls-8" points="815.41 252.39 815.44 248.22 818.52 246.53 821.33 251.59 821.33 255.81 815.41 252.39"/>
                  <path className="cls-10" d="M818.45,246.63l2.82,5v4.12l-5.78-3.33v-4.09l3-1.66m0-.15-3.11,1.74v4.22l6,3.46v-4.34l-2.89-5.08Z"/>
                  <polygon className="cls-9" points="818.54 246.45 824.33 243.22 827.33 248.28 821.4 251.49 818.54 246.45"/>
                  <path className="cls-10" d="M824.27,243.32l3,4.89-5.81,3.2-2.78-4.89,5.63-3.2m0-.15-5.81,3.31,2.89,5.08,6-3.31-3.08-5.08Z"/>
                  <polygon className="cls-8" points="819.14 253.2 819.17 251.92 820.26 252.63 820.24 253.82 819.14 253.2"/>
                  <path className="cls-10" d="M819.23,252.11l.95.54v1.08l-.95-.54v-1.08m-.12-.19v1.33l1.18.67v-1.33l-1.18-.67Z"/>
                  <polygon className="cls-8" points="816.61 253.09 816.64 250.73 817.9 251.54 817.87 253.8 816.61 253.09"/>
                  <path className="cls-10" d="M816.69,250.92l1.12.63v2.15l-1.12-.63v-2.15m-.11-.19v2.41l1.35.76v-2.41l-1.35-.76Z"/>
                  <polygon className="cls-9" points="689.3 312.21 707.52 302.15 707.52 314.72 689.3 325.25 689.3 312.21"/>
                  <path className="cls-10" d="M707.35,302.44v12.18L689.47,325V312.31l17.88-9.87m.35-.58-18.58,10.25v13.43l18.58-10.72v-13Z"/>
                  <polygon className="cls-8" points="670.73 314.72 670.73 301.86 680.11 296.61 688.98 312.19 688.95 325.25 670.73 314.72"/>
                  <path className="cls-10" d="M680.05,296.84l8.73,15.36V325L670.9,314.62V302l9.15-5.13m.13-.46-9.63,5.38v13.06l18.57,10.72V312.11l-8.94-15.73Z"/>
                  <polygon className="cls-9" points="680.41 296.44 698.11 286.36 707.46 301.79 689.19 311.87 680.41 296.44"/>
                  <path className="cls-10" d="M698.05,286.59l9.17,15.14-18,9.91-8.61-15.14,17.4-9.91m.12-.46-18,10.25,8.94,15.73,18.58-10.25-9.53-15.73Z"/>
                  <polygon className="cls-8" points="682.28 317.23 682.28 313.51 685.57 315.37 685.57 319.1 682.28 317.23"/>
                  <path className="cls-10" d="M682.45,313.8l2.95,1.67v3.33l-2.95-1.67V313.8m-.35-.59v4.12l3.64,2.06v-4.12l-3.64-2.06Z"/>
                  <polygon className="cls-8" points="674.44 316.87 674.44 309.82 678.25 311.98 678.25 319.03 674.44 316.87"/>
                  <path className="cls-10" d="M674.61,310.11l3.47,2v6.65l-3.47-2v-6.66m-.34-.59V317l4.15,2.35v-7.45l-4.15-2.35Z"/>
                  <polygon className="cls-9" points="609.76 261.83 627.99 251.77 627.99 264.34 609.76 274.86 609.76 261.83"/>
                  <path className="cls-10" d="M627.82,252.06v12.18l-17.89,10.32V261.93l17.89-9.87m.34-.59-18.57,10.25v13.44l18.57-10.72v-13Z"/>
                  <polygon className="cls-8" points="591.19 264.34 591.19 251.48 600.58 246.22 609.44 261.81 609.42 274.86 591.19 264.34"/>
                  <path className="cls-10" d="M600.51,246.46l8.74,15.36v12.74l-17.89-10.32V251.58l9.15-5.12m.13-.47L591,251.38v13.06l18.57,10.72V261.72l-9-15.73Z"/>
                  <polygon className="cls-9" points="600.88 246.06 618.57 235.97 627.92 251.41 609.65 261.49 600.88 246.06"/>
                  <path className="cls-10" d="M618.51,236.21l9.17,15.14-18,9.91-8.61-15.14,17.4-9.91m.12-.47-18,10.25,9,15.73,18.57-10.25-9.53-15.73Z"/>
                  <polygon className="cls-8" points="602.74 266.85 602.74 263.13 606.03 264.99 606.03 268.71 602.74 266.85"/>
                  <path className="cls-10" d="M602.91,263.42l3,1.67v3.33l-3-1.67v-3.33m-.34-.59V267l3.64,2.06v-4.12l-3.64-2.06Z"/>
                  <polygon className="cls-8" points="594.9 266.49 594.9 259.43 598.72 261.59 598.72 268.65 594.9 266.49"/>
                  <path className="cls-10" d="M595.08,259.73l3.47,2v6.66l-3.47-2v-6.66m-.35-.59v7.45l4.16,2.35v-7.45l-4.16-2.35Z"/>
                  <polygon className="cls-9" points="582.24 230.33 598.38 221.43 598.38 232.56 582.24 241.88 582.24 230.33"/>
                  <path className="cls-10" d="M598.23,221.68v10.79l-15.83,9.14V230.42l15.83-8.74m.31-.51-16.45,9.07v11.9l16.45-9.49V221.17Z"/>
                  <polygon className="cls-8" points="565.8 232.56 565.8 221.17 574.11 216.52 581.96 230.32 581.94 241.88 565.8 232.56"/>
                  <path className="cls-10" d="M574.05,216.73l7.74,13.59v11.29L566,232.47V221.26l8.1-4.53m.12-.42-8.53,4.77v11.57l16.45,9.49v-11.9l-7.92-13.93Z"/>
                  <polygon className="cls-9" points="574.38 216.37 590.05 207.44 598.33 221.11 582.15 230.04 574.38 216.37"/>
                  <path className="cls-10" d="M590,207.65l8.12,13.4-15.9,8.78-7.62-13.4,15.4-8.78m.11-.41-15.93,9.07,7.92,13.93,16.45-9.07-8.44-13.93Z"/>
                  <polygon className="cls-8" points="576.03 234.78 576.03 231.49 578.94 233.13 578.94 236.43 576.03 234.78"/>
                  <path className="cls-10" d="M576.18,231.75l2.61,1.47v2.95l-2.61-1.48v-2.94m-.31-.53v3.65l3.23,1.82v-3.64l-3.23-1.83Z"/>
                  <polygon className="cls-8" points="569.09 234.46 569.09 228.22 572.46 230.13 572.46 236.37 569.09 234.46"/>
                  <path className="cls-10" d="M569.24,228.48l3.07,1.74v5.89l-3.07-1.74v-5.89m-.31-.53v6.6l3.69,2.09V230L568.93,228Z"/>
                  <polygon className="cls-9" points="553.94 255.96 571.36 246.34 571.36 258.36 553.94 268.42 553.94 255.96"/>
                  <path className="cls-10" d="M571.2,246.62v11.65l-17.1,9.87V256.06l17.1-9.44m.33-.55-17.76,9.79v12.85l17.76-10.25V246.07Z"/>
                  <polygon className="cls-8" points="536.18 258.36 536.18 246.07 545.16 241.05 553.63 255.94 553.61 268.42 536.18 258.36"/>
                  <path className="cls-10" d="M545.1,241.27,553.44,256v12.19l-17.09-9.87v-12.1l8.75-4.9m.12-.45L536,246v12.49l17.75,10.25V255.86l-8.55-15Z"/>
                  <polygon className="cls-9" points="545.45 240.88 562.36 231.25 571.3 246 553.84 255.64 545.45 240.88"/>
                  <path className="cls-10" d="M562.3,231.47l8.77,14.47-17.17,9.48L545.67,241l16.63-9.48m.12-.45-17.2,9.8,8.55,15,17.76-9.79-9.11-15Z"/>
                  <polygon className="cls-8" points="547.23 260.76 547.23 257.2 550.38 258.99 550.38 262.54 547.23 260.76"/>
                  <path className="cls-10" d="M547.39,257.49l2.82,1.59v3.18l-2.82-1.59v-3.18m-.33-.57v3.94l3.48,2v-3.94l-3.48-2Z"/>
                  <polygon className="cls-8" points="539.74 260.42 539.74 253.67 543.38 255.74 543.38 262.48 539.74 260.42"/>
                  <path className="cls-10" d="M539.9,254l3.31,1.87v6.37l-3.31-1.88V254m-.33-.57v7.12l4,2.25v-7.12l-4-2.25Z"/>
                  <polygon className="cls-8" points="603.2 340.47 603.2 326.27 627.09 339.79 627.09 353.99 603.2 340.47"/>
                  <path className="cls-10" d="M603.41,326.62l23.47,13.29v13.73l-23.47-13.29V326.62m-.41-.71v14.68l24.29,13.76V339.67L603,325.91Z"/>
                  <polygon className="cls-9" points="627.5 339.79 651.38 326.27 651.38 340.47 627.5 353.99 627.5 339.79"/>
                  <path className="cls-10" d="M651.17,326.62v13.73l-23.46,13.29V339.91l23.46-13.29m.42-.71-24.3,13.76v14.68l24.3-13.76V325.91Z"/>
                  <polygon className="cls-8" points="618.44 349 618.44 340.86 622.83 343.35 622.83 351.49 618.44 349"/>
                  <path className="cls-10" d="M618.65,341.21l4,2.26v7.66l-4-2.25v-7.67m-.42-.71v8.62l4.81,2.72v-8.62l-4.81-2.72Z"/>
                  <polygon className="cls-9" points="603.42 325.91 627.26 312.38 651.17 325.91 627.29 339.43 603.42 325.91"/>
                  <path className="cls-10" d="M627.26,312.62l23.49,13.29-23.46,13.28-23.45-13.28,23.42-13.29m0-.48L603,325.91l24.29,13.76,24.3-13.76-24.33-13.77Z"/>
                  <polygon className="cls-8" points="606.49 338.62 606.49 334.62 614.71 339.24 614.71 343.24 606.49 338.62"/>
                  <path className="cls-10" d="M606.69,335l7.81,4.38v3.52l-7.81-4.38V335m-.41-.71v4.47l8.63,4.85v-4.48l-8.63-4.84Z"/>
                  <polygon className="cls-8" points="721.18 343.17 721.18 330.25 731.82 329.21 742.43 343.13 742.43 355.58 721.18 343.17"/>
                  <path className="cls-10" d="M731.69,329.51l10.46,13.71v11.86L721.46,343v-12.5l10.23-1m.26-.6L720.89,330v13.34l21.83,12.74V343L732,328.91Z"/>
                  <path className="cls-8" d="M723.78,340.28v-6.16a.79.79,0,0,1,.17-.6.55.55,0,0,1,.41-.17,1,1,0,0,1,.53.16,2.87,2.87,0,0,1,1.31,2.21v6Z"/>
                  <path className="cls-10" d="M724.36,333.5a.94.94,0,0,1,.45.14,2.76,2.76,0,0,1,1.24,2.08v5.71l-2.12-1.24V334.1a.69.69,0,0,1,.13-.48.41.41,0,0,1,.3-.12m0-.3c-.45,0-.77.35-.73.92v6.24l2.72,1.61v-6.25a3,3,0,0,0-1.39-2.34,1.14,1.14,0,0,0-.6-.18Z"/>
                  <polygon className="cls-8" points="729.25 347.89 729.25 341.61 734.28 344.5 734.23 350.82 729.25 347.89"/>
                  <path className="cls-10" d="M729.47,342l4.58,2.62,0,5.8-4.54-2.67V342m-.45-.79V348l5.44,3.2,0-6.85L729,341.22Z"/>
                  <path className="cls-8" d="M737.39,348.17V342c0-.5.21-.78.58-.78a1.18,1.18,0,0,1,.58.17,2.82,2.82,0,0,1,1.25,2.21v6Z"/>
                  <path className="cls-10" d="M738,341.39h0a1.05,1.05,0,0,1,.5.15,2.67,2.67,0,0,1,1.18,2.08v5.71l-2.11-1.24V342c0-.29.07-.63.43-.63m0-.31c-.45,0-.74.34-.74.94v6.24l2.72,1.6v-6.24a3,3,0,0,0-1.33-2.35,1.39,1.39,0,0,0-.65-.19Z"/>
                  <path className="cls-9" d="M731.88,329.05a.14.14,0,0,0-.12-.06l-10.36,1,5.78-3.33.05,1.06a.2.2,0,0,0,.06.11.13.13,0,0,0,.1,0l4.3-.42,4.33,5.38a.17.17,0,0,0,.12.05.1.1,0,0,0,.07,0l5-2.88a.17.17,0,0,0,.07-.13V317.62a.16.16,0,0,0-.07-.13.12.12,0,0,0-.08,0,.08.08,0,0,0-.07,0l-4.85,2.81-4.3-5.34a.17.17,0,0,0-.12,0l-4.27.36-.6-1.53,4.79-2.76,4.92-.4,5.58,7.12a.18.18,0,0,0,.12.05.12.12,0,0,0,.08,0l2.13-1.22,9-5.13,13.76,17.73-24.4,14.17Z"/>
                  <path className="cls-10" d="M736.58,310.73l5.53,7.06a.3.3,0,0,0,.24.12.37.37,0,0,0,.15,0l2.14-1.23,8.87-5.06,13.56,17.48-24.14,14L732,329a.29.29,0,0,0-.24-.12h0l-9.59,1L727,327l0,.81a.33.33,0,0,0,.11.22.32.32,0,0,0,.2.07h0l4.21-.41L735.9,333a.28.28,0,0,0,.39.07l5-2.88a.29.29,0,0,0,.16-.26V317.62a.29.29,0,0,0-.16-.26.26.26,0,0,0-.15,0,.28.28,0,0,0-.15,0l-4.74,2.75-4.21-5.24a.31.31,0,0,0-.24-.12h0l-4.15.35-.51-1.31,4.62-2.66,4.83-.41m.14-.31-5.07.42-4.9,2.83.69,1.76,4.37-.37,4.38,5.44,5-2.88v12.27l-5,2.88-4.38-5.44-4.37.43-.07-1.31-6.51,3.78v0l11-1.11,11.1,14.34,24.67-14.33-13.95-18-9.09,5.19-2.14,1.23-5.63-7.18Z"/>
                  <path className="cls-9" d="M743,343.5l23-13.26v12l-23,13.37Zm5.26-1.9a1.52,1.52,0,0,0-.76.23,3.25,3.25,0,0,0-1.48,2.59v6.24a.3.3,0,0,0,.14.25.34.34,0,0,0,.28,0l2.72-1.55a.28.28,0,0,0,.15-.25v-6.24C749.31,342.1,748.89,341.6,748.26,341.6Zm6.82-4a1.55,1.55,0,0,0-.76.23,3.29,3.29,0,0,0-1.47,2.64v6.25a.27.27,0,0,0,.14.24.23.23,0,0,0,.14,0,.28.28,0,0,0,.15,0l2.72-1.6a.29.29,0,0,0,.14-.24v-6.25C756.14,338.15,755.72,337.65,755.08,337.65Zm6.83-3.94a1.53,1.53,0,0,0-.76.22,3.11,3.11,0,0,0-1.47,2.61v6.23a.27.27,0,0,0,.14.24.27.27,0,0,0,.28,0l2.72-1.54a.3.3,0,0,0,.14-.25V335C763,334.2,762.55,333.71,761.91,333.71Z"/>
                  <path className="cls-10" d="M765.76,330.73V342l-22.47,13V343.66l22.47-12.93m-5.8,12.6a.55.55,0,0,0,.28-.07l2.72-1.55a.56.56,0,0,0,.29-.49V335a1.76,1.76,0,0,0-.34-1.1,1.3,1.3,0,0,0-1-.46,1.88,1.88,0,0,0-.9.26,3.42,3.42,0,0,0-1.62,2.84v6.25a.55.55,0,0,0,.57.56m-6.83,4a.59.59,0,0,0,.29-.07l2.72-1.6a.59.59,0,0,0,.28-.49v-6.25a1.37,1.37,0,0,0-1.34-1.55,1.88,1.88,0,0,0-.9.26,3.56,3.56,0,0,0-1.62,2.89v6.25a.56.56,0,0,0,.29.49.55.55,0,0,0,.28.07m-6.83,3.9a.54.54,0,0,0,.28-.08l2.72-1.54a.57.57,0,0,0,.29-.5v-6.24a1.37,1.37,0,0,0-1.33-1.55,1.88,1.88,0,0,0-.91.26,3.58,3.58,0,0,0-1.62,2.84v6.24a.59.59,0,0,0,.57.57m20-21.48-23.6,13.58v12.74l23.6-13.7V329.75Zm-6.36,13v-6.25a2.85,2.85,0,0,1,1.33-2.34,1.26,1.26,0,0,1,.62-.19c.45,0,.77.35.77,1v6.24L760,342.77Zm-6.83,4v-6.25a3,3,0,0,1,1.34-2.4,1.31,1.31,0,0,1,.61-.18c.45,0,.77.34.77,1v6.25l-2.72,1.6Zm-6.83,3.89v-6.24a3,3,0,0,1,1.34-2.35,1.34,1.34,0,0,1,.62-.19c.45,0,.76.35.76,1v6.24l-2.72,1.55Z"/>
                  <path className="cls-8" d="M746.47,344.32a2.89,2.89,0,0,1,1.31-2.22,1.15,1.15,0,0,1,.53-.16.55.55,0,0,1,.41.17.76.76,0,0,1,.17.6v6.16l-2.42,1.42Z"/>
                  <path className="cls-10" d="M748.31,342.09a.41.41,0,0,1,.3.12.69.69,0,0,1,.13.51v6.06L746.62,350v-5.71a2.76,2.76,0,0,1,1.24-2.09.94.94,0,0,1,.45-.14m0-.3a1.24,1.24,0,0,0-.6.18,3,3,0,0,0-1.39,2.35v6.24L749,349v-6.24c0-.58-.28-.93-.73-.93Z"/>
                  <path className="cls-8" d="M753.31,340.49a2.87,2.87,0,0,1,1.31-2.21,1.15,1.15,0,0,1,.53-.16.55.55,0,0,1,.41.17.77.77,0,0,1,.17.59v6.17l-2.42,1.42Z"/>
                  <path className="cls-10" d="M755.15,338.27a.41.41,0,0,1,.3.12.71.71,0,0,1,.13.5V345l-2.12,1.24v-5.71a2.76,2.76,0,0,1,1.24-2.08.94.94,0,0,1,.45-.14m0-.3a1.12,1.12,0,0,0-.6.18,3,3,0,0,0-1.39,2.34v6.25l2.72-1.6v-6.25c0-.57-.28-.92-.73-.92Z"/>
                  <path className="cls-8" d="M760.11,336.52a2.89,2.89,0,0,1,1.31-2.22,1.09,1.09,0,0,1,.52-.15.57.57,0,0,1,.42.16.86.86,0,0,1,.17.6v6.16l-2.42,1.42Z"/>
                  <path className="cls-10" d="M761.94,334.3a.38.38,0,0,1,.3.12.62.62,0,0,1,.13.5V341l-2.11,1.24v-5.71a2.74,2.74,0,0,1,1.24-2.08.9.9,0,0,1,.44-.14m0-.31a1.27,1.27,0,0,0-.6.18,3.07,3.07,0,0,0-1.38,2.35v6.24l2.72-1.6v-6.24c0-.57-.28-.93-.74-.93Z"/>
                  <line className="cls-51" x1="736.2" y1="319.33" x2="736.2" y2="333.41"/>
                  <polyline className="cls-8" points="731.82 315.06 736.19 320.5 741.15 317.62 741.15 329.89 736.14 332.77 731.76 327.33"/>
                  <polygon className="cls-9" points="736.34 320.43 741.16 317.72 741.16 329.94 736.29 332.65 736.34 320.43"/>
                  <path className="cls-10" d="M741,318v11.87l-4.57,2.54,0-11.87L741,318m.3-.52-5.12,2.88-.05,12.57,5.17-2.88V317.46Z"/>
                  <polyline className="cls-8" points="731.8 327.27 727.47 327.77 727.47 315.45 731.8 315.09"/>
                  <line className="cls-51" x1="727.47" y1="313.96" x2="727.47" y2="328.04"/>
                  <polygon className="cls-8" points="774.61 241.12 774.66 231.87 783.02 236.59 782.97 245.85 774.61 241.12"/>
                  <path className="cls-10" d="M774.76,232l8.11,4.59v9.06l-8.11-4.59V232m-.2-.33v9.5l8.5,4.81v-9.5l-8.5-4.81Z"/>
                  <polygon className="cls-8" points="779.73 238.58 779.78 237.09 781.17 237.88 781.12 239.37 779.73 238.58"/>
                  <path className="cls-10" d="M779.88,237.26l1.14.65v1.28l-1.14-.64v-1.29m-.2-.33v1.73l1.54.87v-1.74l-1.54-.86Z"/>
                  <polygon className="cls-8" points="776.46 236.72 776.5 235.24 777.9 236.03 777.85 237.51 776.46 236.72"/>
                  <path className="cls-10" d="M776.61,235.41l1.14.65v1.28l-1.14-.64v-1.29m-.2-.33v1.73l1.53.87v-1.74l-1.53-.86Z"/>
                  <polygon className="cls-9" points="783.16 236.51 791.47 231.86 791.47 241.2 783.16 245.85 783.16 236.51"/>
                  <path className="cls-10" d="M791.37,232v9.06l-8.11,4.59v-9.06l8.11-4.59m.19-.33-8.5,4.81V246l8.5-4.81v-9.5Z"/>
                  <polygon className="cls-8" points="778.02 243.04 778.07 240.28 779.61 241.15 779.56 243.91 778.02 243.04"/>
                  <path className="cls-10" d="M778.17,240.44l1.29.73v2.57l-1.29-.73v-2.57m-.2-.33v3l1.68,1v-3l-1.68-.95Z"/>
                  <polygon className="cls-9" points="774.76 231.7 783.1 226.96 791.37 231.7 783.06 236.4 774.76 231.7"/>
                  <path className="cls-10" d="M783.05,227.11l8.12,4.59-8.11,4.58L775,231.7l8.09-4.59m0-.23-8.49,4.82,8.5,4.81,8.5-4.81-8.51-4.82Z"/>
                  <polygon className="cls-8" points="767.89 260.88 767.93 255.44 777.16 260.67 777.12 266.11 767.89 260.88"/>
                  <path className="cls-10" d="M768,255.58l9,5.11V266l-9-5.11v-5.28m-.16-.27V261l9.35,5.29V260.6l-9.35-5.29Z"/>
                  <polygon className="cls-9" points="777.28 260.6 786.46 255.44 786.46 260.95 777.28 266.11 777.28 260.6"/>
                  <path className="cls-10" d="M786.38,255.58v5.28l-9,5.11v-5.28l9-5.11m.16-.27-9.34,5.29v5.64l9.34-5.29v-5.64Z"/>
                  <polygon className="cls-8" points="773.75 264.16 773.79 261.05 775.52 262.03 775.48 265.14 773.75 264.16"/>
                  <path className="cls-10" d="M773.87,261.19l1.53.87V265l-1.53-.87v-2.95m-.16-.27v3.31l1.85,1V262l-1.85-1Z"/>
                  <polygon className="cls-9" points="768.01 255.31 777.22 250.08 786.38 255.31 777.2 260.5 768.01 255.31"/>
                  <path className="cls-10" d="M777.18,250.19l9,5.12-9,5.1-9-5.1,9-5.12m0-.18-9.33,5.3,9.35,5.29,9.34-5.29-9.36-5.3Z"/>
                  <polygon className="cls-8" points="769.15 260.17 769.19 258.65 772.4 260.45 772.36 261.97 769.15 260.17"/>
                  <path className="cls-10" d="M769.27,258.79l3,1.69v1.35l-3-1.68v-1.36m-.15-.27v1.72l3.32,1.86v-1.72l-3.32-1.86Z"/>
                  <polygon className="cls-9" points="686.5 368.19 714.79 352.58 714.79 372.29 686.5 388.57 686.5 368.19"/>
                  <path className="cls-10" d="M714.69,352.75v19.43L686.6,388.4V368.25l28.09-15.5m.2-.34L686.4,368.13v20.61l28.49-16.45V352.41Z"/>
                  <polygon className="cls-8" points="658.17 372.14 658.17 352.42 672.58 344.36 686.13 368.2 686.13 388.29 658.17 372.14"/>
                  <path className="cls-9" d="M672.48,344.72l13.39,23.55v19.56L658.44,372V352.58l14-7.86m.2-.72-14.77,8.27v20l28.49,16.45V368.13L672.68,344Z"/>
                  <polygon className="cls-9" points="672.81 344.04 700.24 328.41 714.75 352.37 686.44 368 672.81 344.04"/>
                  <path className="cls-10" d="M700.2,328.55l14.41,23.79-28.14,15.53L673,344.08l27.25-15.53m.07-.27L672.68,344l13.72,24.13,28.49-15.72-14.62-24.13Z"/>
                  <polygon className="cls-8" points="675.67 376.07 675.72 369.99 681.16 373.07 681.12 379.15 675.67 376.07"/>
                  <path className="cls-10" d="M675.82,370.15l5.2,3V379l-5.2-2.95v-5.89m-.19-.32v6.32l5.58,3.16V373l-5.58-3.16Z"/>
                  <polygon className="cls-8" points="663.65 375.51 663.7 364.33 669.94 367.86 669.89 379.04 663.65 375.51"/>
                  <path className="cls-10" d="M663.79,364.49l6,3.4v11l-6-3.39v-11m-.18-.32V375.6l6.37,3.6V367.78l-6.37-3.61Z"/>
                  <path className="cls-8" d="M775.52,296.23v-22l18.26,10.28a.46.46,0,0,0,.2,0,.42.42,0,0,0,.41-.41V271.36l16.51,9.34v35.5Z"/>
                  <path className="cls-9" d="M794.8,272.07l15.69,8.87V315.5L775.93,296V274.9l17.65,9.93a.83.83,0,0,0,.4.11.88.88,0,0,0,.42-.11.83.83,0,0,0,.4-.71V272.07m-.82-1.41v13.46L775.11,273.5v23l36.2,20.44V280.46L794,270.66Z"/>
                  <path className="cls-9" d="M811.46,280.46a.16.16,0,0,0-.08-.13l-17.32-9.81-.08,0-.07,0a.16.16,0,0,0-.08.14v13.19l-18.4-10.35,17-9.65,35.9,20.28-16.86,9.54Z"/>
                  <path className="cls-10" d="M792.42,264,828,284.13l-16.39,9.28v-13a.29.29,0,0,0-.16-.26l-17.33-9.81a.24.24,0,0,0-.15,0,.28.28,0,0,0-.15,0,.31.31,0,0,0-.15.27v12.93L775.74,273.5,792.42,264m0-.35-17.31,9.82L794,284.12V270.66l17.33,9.8v13.48l17.32-9.81-36.21-20.45Z"/>
                  <polygon className="cls-9" points="794.3 270.66 811.29 261.01 828.32 270.66 811.31 280.29 794.3 270.66"/>
                  <path className="cls-10" d="M811.29,261.19,828,270.66l-16.7,9.45-16.7-9.45,16.68-9.47m0-.35L794,270.66l17.33,9.8,17.32-9.8-17.34-9.82Z"/>
                  <polygon className="cls-9" points="811.46 280.55 828.48 270.92 828.48 307.01 811.46 316.64 811.46 280.55"/>
                  <path className="cls-10" d="M828.33,271.19v35.73l-16.71,9.46V280.64l16.71-9.45m.3-.53-17.32,9.8v36.45l17.32-9.81V270.66Z"/>
                  <polygon className="cls-8" points="800.21 310.33 800.21 302.67 804.38 305.03 804.38 312.69 800.21 310.33"/>
                  <path className="cls-10" d="M800.36,302.91l3.88,2.2v7.33l-3.88-2.2v-7.33m-.29-.49v8l4.46,2.53v-8l-4.46-2.52Z"/>
                  <polygon className="cls-8" points="790.15 300.98 790.15 296.72 793.92 298.85 793.92 303.12 790.15 300.98"/>
                  <path className="cls-10" d="M790.29,297l3.48,2v3.93l-3.48-2V297m-.29-.5v4.6l4.06,2.3v-4.6l-4.06-2.3Z"/>
                  <polygon className="cls-8" points="780.76 295.56 780.76 291.3 784.53 293.43 784.53 297.7 780.76 295.56"/>
                  <path className="cls-10" d="M780.9,291.55l3.48,2v3.93l-3.48-2v-3.93m-.29-.5v4.6l4.06,2.3v-4.6l-4.06-2.3Z"/>
                  <polygon className="cls-8" points="790.99 292.29 790.99 288.02 794.76 290.16 794.76 294.42 790.99 292.29"/>
                  <path className="cls-10" d="M791.13,288.27l3.49,2v3.93l-3.49-2v-3.93m-.29-.5v4.6l4.07,2.3v-4.6l-4.07-2.3Z"/>
                  <polygon className="cls-8" points="800.8 297.9 800.8 293.64 804.57 295.77 804.57 300.04 800.8 297.9"/>
                  <path className="cls-10" d="M800.94,293.89l3.49,2v3.93l-3.49-2v-3.93m-.29-.5V298l4.07,2.3v-4.6l-4.07-2.3Z"/>
                  <polygon className="cls-8" points="781.18 286.67 781.18 282.41 784.95 284.54 784.95 288.81 781.18 286.67"/>
                  <path className="cls-10" d="M781.32,282.65l3.48,2v3.94l-3.48-2v-3.94m-.29-.49v4.6l4.06,2.3v-4.6l-4.06-2.3Z"/>
                  <path className="cls-52" d="M799.4,278.5c1.21.15,2.58,1.6,3.06,3.24.48-1.1,1.85-1,3.06.23s1.82,3.12,1.34,4.22a2.7,2.7,0,0,1-.25.4,1.71,1.71,0,0,1-.26.21l-3.89,2.25h0c-.05-.07-3.79-6.48-3.88-6.65s-.27-.51-.27-.51a5.4,5.4,0,0,1-.25-.67c-.48-1.65.12-2.87,1.34-2.72"/>
                  <polygon className="cls-8" points="742.66 223.87 742.66 188.35 759.41 197.83 759.41 233.36 742.66 223.87"/>
                  <path className="cls-9" d="M743,188.94,759.07,198v34.74L743,223.67V188.94m-.69-1.18v36.31l17.44,9.87V197.63l-17.44-9.87Z"/>
                  <polygon className="cls-8" points="752.95 218.79 752.95 215.51 755.85 217.15 755.85 220.43 752.95 218.79"/>
                  <path className="cls-10" d="M753.07,215.72l2.66,1.5v3l-2.66-1.5v-3m-.24-.42v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-8" points="746.23 214.99 746.23 211.71 749.13 213.35 749.13 216.63 746.23 214.99"/>
                  <path className="cls-10" d="M746.35,211.92l2.66,1.5v3l-2.66-1.5v-3m-.24-.42v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-8" points="752.95 210.56 752.95 207.28 755.85 208.92 755.85 212.2 752.95 210.56"/>
                  <path className="cls-10" d="M753.07,207.49l2.66,1.5v3l-2.66-1.5v-3m-.24-.42v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-8" points="746.23 206.76 746.23 203.48 749.13 205.12 749.13 208.4 746.23 206.76"/>
                  <path className="cls-10" d="M746.35,203.69l2.66,1.5v3l-2.66-1.5v-3m-.24-.42v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-8" points="752.95 202.33 752.95 199.05 755.85 200.69 755.85 203.97 752.95 202.33"/>
                  <path className="cls-10" d="M753.07,199.26l2.66,1.5v3l-2.66-1.5v-3m-.24-.42v3.56l3.14,1.78v-3.56l-3.14-1.78Z"/>
                  <polygon className="cls-8" points="746.23 198.53 746.23 195.25 749.13 196.89 749.13 200.17 746.23 198.53"/>
                  <path className="cls-10" d="M746.35,195.46,749,197v3l-2.66-1.5v-3m-.24-.42v3.56l3.14,1.78v-3.56L746.11,195Z"/>
                  <polygon className="cls-9" points="759.89 197.71 777.07 187.98 777.07 224 759.89 233.72 759.89 197.71"/>
                  <path className="cls-10" d="M776.94,188.2v35.72L760,233.5V197.78l16.92-9.58m.26-.44-17.44,9.87v36.31l17.44-9.87V187.76Z"/>
                  <polygon className="cls-8" points="749.43 227.94 749.43 222.03 752.64 223.85 752.64 229.76 749.43 227.94"/>
                  <path className="cls-10" d="M749.56,222.24l3,1.68v5.63l-3-1.68v-5.63m-.25-.41V228l3.46,2v-6.19l-3.46-1.95Z"/>
                  <polygon className="cls-9" points="742.58 187.76 759.74 178.03 776.94 187.76 759.76 197.48 742.58 187.76"/>
                  <path className="cls-10" d="M759.74,178.17l16.93,9.59-16.91,9.57-16.92-9.57,16.9-9.59m0-.29-17.42,9.88,17.44,9.87,17.44-9.87-17.46-9.88Z"/>
                  <polygon className="cls-9" points="624.27 201.26 638.61 193.14 638.55 213.65 624.27 221.9 624.27 201.26"/>
                  <path className="cls-10" d="M638.48,193.36l-.06,20.21-14,8.11V201.33l14.08-8m.26-.44-14.6,8.26v20.94l14.54-8.4.06-20.8Z"/>
                  <polygon className="cls-9" points="616.69 196.74 631.01 188.61 638.48 192.92 624.14 201.04 616.69 196.74"/>
                  <path className="cls-10" d="M631,188.76l7.22,4.16-14.08,8-7.2-4.15,14.06-8m0-.3-14.58,8.28,7.71,4.44,14.6-8.26L631,188.46Z"/>
                  <polygon className="cls-9" points="616.59 185.84 609.37 173.56 621.96 166.22 630.9 177.69 630.88 188.4 616.59 196.48 616.59 185.84"/>
                  <path className="cls-10" d="M621.92,166.39l8.83,11.3v10.63l-14,7.94V185.84a.28.28,0,0,0,0-.13l-7.13-12.11,12.37-7.21m.07-.33-12.79,7.45,7.26,12.33V196.7L631,188.47V177.61l-9-11.55Z"/>
                  <polygon className="cls-8" points="594.31 204.63 594.31 184.59 602.19 188.95 602.19 177.75 609.09 173.89 616.18 185.91 616.18 196.87 623.86 201.34 623.86 221.64 594.31 204.63"/>
                  <path className="cls-9" d="M609,174.27l6.9,11.72v11l.28.16,7.41,4.32v19.64l-29-16.68v-19.4l7,3.89.83.47V177.91l6.52-3.64m.21-.76-7.31,4.08,0,0v10.86L594,184.12V204.8l30.11,17.32V201.18l-7.68-4.48V185.84l-7.26-12.33Z"/>
                  <polygon className="cls-8" points="599.05 204.47 599.05 200.82 602.27 202.65 602.27 206.29 599.05 204.47"/>
                  <path className="cls-10" d="M599.25,201.17l2.83,1.59V206l-2.83-1.6v-3.18m-.4-.69v4.1l3.62,2v-4.1l-3.62-2.05Z"/>
                  <polygon className="cls-8" points="615.94 213.9 615.94 210.26 619.17 212.08 619.17 215.73 615.94 213.9"/>
                  <path className="cls-10" d="M616.14,210.6l2.83,1.6v3.19l-2.83-1.6V210.6m-.4-.68V214l3.63,2V212l-3.63-2.05Z"/>
                  <polygon className="cls-8" points="599.05 196.07 599.05 192.43 602.27 194.25 602.27 197.89 599.05 196.07"/>
                  <path className="cls-10" d="M599.25,192.77l2.83,1.6v3.18l-2.83-1.6v-3.18m-.4-.69v4.1l3.62,2.06v-4.11l-3.62-2Z"/>
                  <polygon className="cls-8" points="615.94 205.53 615.94 201.89 619.17 203.72 619.17 207.36 615.94 205.53"/>
                  <path className="cls-10" d="M616.14,202.23l2.83,1.6V207l-2.83-1.6v-3.19m-.4-.68v4.1l3.63,2v-4.1l-3.63-2Z"/>
                  <polygon className="cls-8" points="607.5 200.79 607.5 197.15 610.72 198.98 610.72 202.62 607.5 200.79"/>
                  <path className="cls-10" d="M607.7,197.49l2.82,1.6v3.19l-2.82-1.6v-3.19m-.4-.68v4.1l3.62,2.05v-4.1l-3.62-2.05Z"/>
                  <polygon className="cls-8" points="607.3 212.15 607.3 205.39 610.88 207.42 610.88 214.18 607.3 212.15"/>
                  <path className="cls-10" d="M607.5,205.73l3.18,1.8v6.3L607.5,212v-6.3m-.4-.68v7.22l4,2.25V207.3l-4-2.25Z"/>
                  <path className="cls-53" d="M609.11,183.24a7.57,7.57,0,0,1,3.5,5.95c0,2.19-1.57,3.08-3.5,2a7.58,7.58,0,0,1-3.51-5.95C605.6,183,607.18,182.15,609.11,183.24Z"/>
                  <polygon className="cls-8" points="606.95 186.48 609.59 187.97 609.59 184.93 608.91 184.55 608.91 186.82 606.95 185.71 606.95 186.48"/>
                  <polygon className="cls-9" points="599.78 187.15 594.29 184.1 601.78 179.85 601.78 188.25 599.78 187.15"/>
                  <path className="cls-10" d="M601.65,180.07v8l-1.8-1-5.29-2.94,7.09-4m.26-.44L594,184.11l5.69,3.15,2.17,1.2h0v-8.82Z"/>
                  <polygon className="cls-8" points="620.43 299.74 620.43 283.36 635.08 291.66 635.08 308.04 620.43 299.74"/>
                  <path className="cls-10" d="M620.6,283.66l14.31,8.1v16l-14.31-8.1v-16m-.35-.59v16.77l15,8.49V291.56l-15-8.49Z"/>
                  <polygon className="cls-8" points="629.46 295.26 629.46 292.59 631.82 293.93 631.82 296.59 629.46 295.26"/>
                  <path className="cls-10" d="M629.63,292.89l2,1.14v2.27l-2-1.14v-2.27m-.34-.59v3.06l2.7,1.53v-3.06l-2.7-1.53Z"/>
                  <polygon className="cls-8" points="623.69 291.99 623.69 289.32 626.04 290.66 626.04 293.32 623.69 291.99"/>
                  <path className="cls-10" d="M623.86,289.62l2,1.14V293l-2-1.14v-2.27m-.35-.59v3.06l2.71,1.53v-3.06L623.51,289Z"/>
                  <polygon className="cls-9" points="635.42 291.66 650.08 283.36 650.08 299.74 635.42 308.04 635.42 291.66"/>
                  <path className="cls-10" d="M649.91,283.66v16l-14.31,8.1v-16l14.31-8.1m.34-.59-15,8.49v16.77l15-8.49V283.07Z"/>
                  <polygon className="cls-8" points="626.44 303.13 626.44 298.21 629.06 299.69 629.06 304.62 626.44 303.13"/>
                  <path className="cls-10" d="M626.61,298.5l2.28,1.29v4.53L626.61,303V298.5m-.34-.59v5.32l3,1.68v-5.32l-3-1.68Z"/>
                  <polygon className="cls-9" points="620.6 283.07 635.23 274.76 649.9 283.07 635.25 291.36 620.6 283.07"/>
                  <path className="cls-10" d="M635.23,275l14.32,8.11-14.3,8.09L621,283.07,635.23,275m0-.39-15,8.5,15,8.49,15-8.49-15-8.5Z"/>
                  <polygon className="cls-8" points="536.23 301.77 536.25 282.69 543.25 278.78 550.35 290.84 550.32 309.75 536.23 301.77"/>
                  <path className="cls-9" d="M543.18,279.06l7,11.84,0,18.49-13.69-7.74,0-18.84,6.72-3.75m.15-.55-7.28,4.06,0,19.32,14.51,8.21,0-19.31-7.23-12.28Z"/>
                  <polygon className="cls-9" points="543.56 278.57 557.31 270.73 564.37 282.73 550.62 290.56 543.56 278.57"/>
                  <path className="cls-10" d="M557.25,271l6.9,11.71-13.47,7.68-6.9-11.71L557.25,271m.12-.45-14,8,7.23,12.28,14-8-7.23-12.29Z"/>
                  <polygon className="cls-8" points="541.68 291.38 541.68 287.74 544.9 289.56 544.9 293.21 541.68 291.38"/>
                  <path className="cls-10" d="M541.86,288.06l2.85,1.61v3.21l-2.85-1.61v-3.21m-.37-.65v4.08l3.6,2v-4.08l-3.6-2Z"/>
                  <polygon className="cls-8" points="541.42 304.52 541.42 297.57 545.16 299.69 545.16 306.63 541.42 304.52"/>
                  <path className="cls-10" d="M541.61,297.89,545,299.8v6.51l-3.36-1.9v-6.52m-.38-.64v7.38l4.12,2.32v-7.37l-4.12-2.33Z"/>
                  <polygon className="cls-8" points="550.77 310 550.8 290.92 557.79 287.01 564.89 299.07 564.87 317.97 550.77 310"/>
                  <path className="cls-9" d="M557.72,287.29l7,11.84,0,18.49L551,309.87,551,291l6.72-3.75m.15-.56-7.28,4.07,0,19.31,14.51,8.22,0-19.32-7.23-12.28Z"/>
                  <polygon className="cls-9" points="558.1 286.79 571.85 278.96 578.91 290.95 565.16 298.79 558.1 286.79"/>
                  <path className="cls-10" d="M571.79,279.18l6.9,11.71-13.47,7.67-6.89-11.71,13.46-7.67m.12-.45-14,8L565.1,299l14-8-7.23-12.28Z"/>
                  <polygon className="cls-8" points="556.22 299.61 556.22 295.96 559.44 297.79 559.44 301.43 556.22 299.61"/>
                  <path className="cls-10" d="M556.41,296.29l2.85,1.61v3.21l-2.85-1.61v-3.21m-.38-.65v4.08l3.6,2v-4.08l-3.6-2Z"/>
                  <polygon className="cls-8" points="555.96 312.74 555.96 305.8 559.7 307.91 559.7 314.86 555.96 312.74"/>
                  <path className="cls-10" d="M556.15,306.12l3.36,1.9v6.52l-3.36-1.91v-6.51m-.38-.65v7.38l4.12,2.33V307.8l-4.12-2.33Z"/>
                  <polygon className="cls-9" points="579.81 307.36 593.52 299.54 593.49 318.48 579.78 326.29 579.81 307.36"/>
                  <path className="cls-10" d="M593.35,299.83l0,18.55L579.94,326l0-18.55,13.38-7.62m.33-.57h0Zm0,0-14,8,0,19.31,14-8,0-19.31Z"/>
                  <polygon className="cls-8" points="565.31 318.24 565.34 299.17 572.34 295.25 579.44 307.31 579.41 326.22 565.31 318.24"/>
                  <path className="cls-9" d="M572.26,295.53l7,11.84,0,18.5-13.69-7.75,0-18.83,6.72-3.76m.15-.55L565.13,299l0,19.32,14.51,8.21,0-19.31L572.41,295Z"/>
                  <polygon className="cls-9" points="572.64 295.04 586.39 287.2 593.46 299.2 579.7 307.03 572.64 295.04"/>
                  <path className="cls-10" d="M586.33,287.43l6.9,11.71-13.47,7.67-6.89-11.71,13.46-7.67m.12-.45-14,8,7.23,12.28,14.05-8L586.45,287Z"/>
                  <polygon className="cls-8" points="570.76 307.85 570.76 304.21 573.98 306.03 573.98 309.68 570.76 307.85"/>
                  <path className="cls-10" d="M571,304.53l2.85,1.61v3.22L571,307.74v-3.21m-.38-.65V308l3.6,2v-4.08l-3.6-2Z"/>
                  <polygon className="cls-8" points="570.5 320.99 570.5 314.04 574.24 316.16 574.24 323.1 570.5 320.99"/>
                  <path className="cls-10" d="M570.69,314.36l3.36,1.91v6.51l-3.36-1.9v-6.52m-.38-.64v7.38l4.12,2.33v-7.38l-4.12-2.33Z"/>
                  <polygon className="cls-9" points="788 325.73 794.87 321.98 794.87 326.75 788 330.68 788 325.73"/>
                  <path className="cls-10" d="M794.8,322.09v4.59l-6.73,3.89v-4.76l6.73-3.72m.13-.22-7,3.86v5.06l7-4v-4.88Z"/>
                  <polygon className="cls-8" points="780.97 326.69 781 321.83 784.6 319.86 787.88 325.76 787.87 330.68 780.97 326.69"/>
                  <path className="cls-10" d="M784.52,320l3.29,5.78v4.81l-6.74-3.89v-4.77l3.45-1.93m.05-.18-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-9" points="784.62 319.77 791.38 316 794.88 321.9 787.96 325.64 784.62 319.77"/>
                  <path className="cls-10" d="M791.3,316.12l3.45,5.7L788,325.55l-3.25-5.7,6.56-3.73m0-.18-6.77,3.86,3.37,5.93,7-3.86-3.59-5.93Z"/>
                  <polygon className="cls-8" points="785.32 327.64 785.35 326.26 786.63 326.98 786.6 328.36 785.32 327.64"/>
                  <path className="cls-10" d="M785.42,326.37l1.11.63v1.25l-1.11-.63v-1.25m-.13-.22v1.55l1.37.77v-1.55l-1.37-.77Z"/>
                  <polygon className="cls-8" points="782.37 327.5 782.4 324.87 783.87 325.7 783.84 328.34 782.37 327.5"/>
                  <path className="cls-10" d="M782.47,325l1.3.74v2.51l-1.3-.74V325m-.13-.22v2.8l1.56.89v-2.81l-1.56-.88Z"/>
                  <polygon className="cls-9" points="735.38 258.39 742.72 254.38 742.72 259.48 735.38 263.68 735.38 258.39"/>
                  <path className="cls-10" d="M742.65,254.5v4.9l-7.2,4.16v-5.09l7.2-4m.13-.24-7.47,4.13v5.41l7.47-4.32v-5.22Z"/>
                  <polygon className="cls-8" points="727.87 259.42 727.9 254.22 731.74 252.12 735.25 258.42 735.24 263.68 727.87 259.42"/>
                  <path className="cls-10" d="M731.66,252.24l3.51,6.19v5.13L728,259.4v-5.09l3.69-2.07m.05-.18-3.88,2.17v5.25l7.48,4.32v-5.41l-3.6-6.33Z"/>
                  <polygon className="cls-9" points="731.77 252.02 738.98 247.99 742.69 254.24 735.34 258.3 731.77 252.02"/>
                  <path className="cls-10" d="M738.9,248.12l3.69,6.09-7.23,4-3.46-6.09,7-4m.05-.19-7.24,4.13,3.6,6.33,7.47-4.13L739,247.93Z"/>
                  <polygon className="cls-8" points="732.52 260.43 732.55 258.95 733.91 259.72 733.88 261.2 732.52 260.43"/>
                  <path className="cls-10" d="M732.62,259.07l1.19.67v1.34l-1.19-.67v-1.34m-.14-.24v1.66l1.47.83v-1.66l-1.47-.83Z"/>
                  <polygon className="cls-8" points="729.36 260.29 729.4 257.47 730.97 258.36 730.93 261.18 729.36 260.29"/>
                  <path className="cls-10" d="M729.47,257.59l1.39.79v2.68l-1.39-.79v-2.68m-.14-.24v3l1.67.94v-3l-1.67-.95Z"/>
                  <polygon className="cls-9" points="781 321.65 787.87 317.89 787.87 322.67 781 326.6 781 321.65"/>
                  <path className="cls-10" d="M787.81,318v4.59l-6.74,3.89v-4.76l6.74-3.72m.13-.22-7,3.87v5.06l7-4v-4.89Z"/>
                  <polygon className="cls-8" points="773.97 322.61 774 317.75 777.6 315.77 780.88 321.68 780.87 326.6 773.97 322.61"/>
                  <path className="cls-10" d="M777.52,315.89l3.29,5.79v4.8l-6.74-3.89v-4.77l3.45-1.93m.05-.17-3.63,2v4.92l7,4v-5.06l-3.37-5.93Z"/>
                  <polygon className="cls-9" points="777.63 315.69 784.38 311.91 787.88 317.82 780.96 321.56 777.63 315.69"/>
                  <path className="cls-10" d="M784.3,312l3.45,5.71L781,321.47l-3.25-5.7L784.3,312m.05-.17-6.78,3.86,3.37,5.93,7-3.87-3.59-5.92Z"/>
                  <polygon className="cls-8" points="778.33 323.56 778.36 322.17 779.63 322.89 779.6 324.28 778.33 323.56"/>
                  <path className="cls-10" d="M778.42,322.29l1.11.62v1.26l-1.11-.63v-1.25m-.13-.23v1.55l1.37.78v-1.55l-1.37-.78Z"/>
                  <polygon className="cls-8" points="775.37 323.42 775.4 320.78 776.87 321.62 776.84 324.25 775.37 323.42"/>
                  <path className="cls-10" d="M775.47,320.89l1.31.74v2.51l-1.31-.74v-2.51m-.13-.22v2.81l1.57.89v-2.81l-1.57-.89Z"/>
                  <polygon className="cls-9" points="634.98 233.49 643.56 228.8 643.56 234.77 634.98 239.68 634.98 233.49"/>
                  <path className="cls-10" d="M643.48,228.94v5.74l-8.42,4.86v-5.95l8.42-4.65m.16-.28-8.75,4.83v6.33l8.75-5v-6.11Z"/>
                  <polygon className="cls-8" points="626.18 234.7 626.23 228.62 630.65 226.19 634.82 233.53 634.81 239.68 626.18 234.7"/>
                  <path className="cls-10" d="M630.62,226.3l4.11,7.24v6l-8.42-4.86v-6l4.31-2.41m.06-.22-4.54,2.54v6.15l8.75,5v-6.33l-4.21-7.41Z"/>
                  <polygon className="cls-9" points="630.79 226.11 639.13 221.36 643.53 228.63 634.92 233.38 630.79 226.11"/>
                  <path className="cls-10" d="M639.1,221.47l4.32,7.13L635,233.27l-4.06-7.13,8.2-4.67m0-.22-8.47,4.83,4.21,7.41,8.75-4.83-4.49-7.41Z"/>
                  <polygon className="cls-8" points="631.63 235.88 631.66 234.15 633.26 235.06 633.22 236.79 631.63 235.88"/>
                  <path className="cls-10" d="M631.75,234.29l1.39.79v1.57l-1.39-.79v-1.57m-.16-.28V236l1.71,1V235l-1.71-1Z"/>
                  <polygon className="cls-8" points="627.93 235.72 627.97 232.41 629.81 233.45 629.77 236.75 627.93 235.72"/>
                  <path className="cls-10" d="M628.06,232.55l1.63.93v3.14l-1.63-.93v-3.14m-.17-.27v3.51l2,1.1v-3.51l-2-1.1Z"/>
                  <polygon className="cls-9" points="643.73 238.49 652.31 233.8 652.31 239.77 643.73 244.68 643.73 238.49"/>
                  <path className="cls-10" d="M652.23,233.94v5.74l-8.42,4.86v-5.95l8.42-4.65m.16-.28-8.75,4.83v6.33l8.75-5v-6.11Z"/>
                  <polygon className="cls-8" points="634.93 239.7 634.98 233.62 639.4 231.19 643.57 238.53 643.56 244.68 634.93 239.7"/>
                  <path className="cls-10" d="M639.37,231.3l4.11,7.23v6l-8.42-4.86v-6l4.31-2.41m.06-.22-4.54,2.54v6.15l8.75,5v-6.33l-4.21-7.41Z"/>
                  <polygon className="cls-9" points="639.54 231.11 647.88 226.36 652.28 233.63 643.67 238.38 639.54 231.11"/>
                  <path className="cls-10" d="M647.85,226.47l4.32,7.13-8.46,4.67-4.06-7.13,8.2-4.67m0-.22-8.47,4.83,4.21,7.41,8.75-4.83-4.49-7.41Z"/>
                  <polygon className="cls-8" points="640.38 240.88 640.41 239.15 642.01 240.05 641.97 241.78 640.38 240.88"/>
                  <path className="cls-10" d="M640.5,239.29l1.39.79v1.56l-1.39-.78v-1.57m-.16-.28V241l1.71,1V240l-1.71-1Z"/>
                  <polygon className="cls-8" points="636.68 240.71 636.72 237.41 638.56 238.45 638.52 241.75 636.68 240.71"/>
                  <path className="cls-10" d="M636.81,237.55l1.63.93v3.13l-1.63-.92v-3.14m-.17-.28v3.51l2,1.11v-3.51l-2-1.11Z"/>
                  <polygon className="cls-9" points="652.48 243.6 661.06 238.91 661.06 244.88 652.48 249.79 652.48 243.6"/>
                  <path className="cls-10" d="M661,239v5.74l-8.42,4.87v-6L661,239m.16-.27-8.75,4.83v6.33l8.75-5.05v-6.11Z"/>
                  <polygon className="cls-8" points="643.68 244.81 643.73 238.72 648.15 236.29 652.32 243.64 652.31 249.79 643.68 244.81"/>
                  <path className="cls-10" d="M648.12,236.41l4.11,7.23v6l-8.42-4.87v-6l4.31-2.41m.06-.22-4.54,2.53v6.16l8.75,5.05V243.6l-4.21-7.41Z"/>
                  <polygon className="cls-9" points="648.29 236.22 656.63 231.47 661.03 238.74 652.42 243.49 648.29 236.22"/>
                  <path className="cls-10" d="M656.6,231.58l4.32,7.13-8.46,4.67-4.06-7.13,8.2-4.67m0-.22-8.47,4.83,4.21,7.41,8.75-4.83-4.49-7.41Z"/>
                  <polygon className="cls-8" points="649.13 245.99 649.16 244.26 650.76 245.16 650.72 246.89 649.13 245.99"/>
                  <path className="cls-10" d="M649.25,244.4l1.39.78v1.57l-1.39-.79V244.4m-.16-.28v1.94l1.71,1v-1.94l-1.71-1Z"/>
                  <polygon className="cls-8" points="645.43 245.82 645.47 242.52 647.31 243.56 647.27 246.86 645.43 245.82"/>
                  <path className="cls-10" d="M645.56,242.66l1.63.92v3.14l-1.63-.93v-3.13m-.17-.28v3.51l2,1.11v-3.51l-2-1.11Z"/>
                  <polygon className="cls-9" points="643.73 238.49 652.31 233.8 652.31 239.77 643.73 244.68 643.73 238.49"/>
                  <path className="cls-10" d="M652.23,233.94v5.74l-8.42,4.86v-5.95l8.42-4.65m.16-.28-8.75,4.83v6.33l8.75-5v-6.11Z"/>
                  <polygon className="cls-8" points="634.93 239.7 634.98 233.62 639.4 231.19 643.57 238.53 643.56 244.68 634.93 239.7"/>
                  <path className="cls-10" d="M639.37,231.3l4.11,7.23v6l-8.42-4.86v-6l4.31-2.41m.06-.22-4.54,2.54v6.15l8.75,5v-6.33l-4.21-7.41Z"/>
                  <polygon className="cls-9" points="639.54 231.11 647.88 226.36 652.28 233.63 643.67 238.38 639.54 231.11"/>
                  <path className="cls-10" d="M647.85,226.47l4.32,7.13-8.46,4.67-4.06-7.13,8.2-4.67m0-.22-8.47,4.83,4.21,7.41,8.75-4.83-4.49-7.41Z"/>
                  <polygon className="cls-8" points="640.38 240.88 640.41 239.15 642.01 240.05 641.97 241.78 640.38 240.88"/>
                  <path className="cls-10" d="M640.5,239.29l1.39.79v1.56l-1.39-.78v-1.57m-.16-.28V241l1.71,1V240l-1.71-1Z"/>
                  <polygon className="cls-8" points="636.68 240.71 636.72 237.41 638.56 238.45 638.52 241.75 636.68 240.71"/>
                  <path className="cls-10" d="M636.81,237.55l1.63.93v3.13l-1.63-.92v-3.14m-.17-.28v3.51l2,1.11v-3.51l-2-1.11Z"/>
                </g>
                <g id="community_bg" data-name="community bg" className="cls-11">
                  <rect className="cls-12" x="511.02" y="132.28" width="342.62" height="276.61"/>
                </g>
              </g>
            </g>
          </svg>
        </div>
      )
    }
  }
}

export default MapSvg;
