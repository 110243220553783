import React, { Component } from 'react';
import Chart from './Chart';
import Provocation from './Provocation';
import Hex from './Hex';
import ReactDOM from 'react-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { Controller, Scene } from 'react-scrollmagic';
import { TimelineMax } from "gsap";
import { Tween, Timeline } from 'react-gsap';
import './Section.scss';

const zoomSpeed = 0.6;

let sectionColors = [
  {id: 'home', main: "#328fce", neon: "#00ffff", light: "#93c1e7", dark: "#0060a0"},
  {id: 'individual', main: "#00aaa7", neon: "#00ffff", light: "#63c7c9", dark: "#007e84"},
  {id: 'retirement', main: "#9f87e8", neon: "#ff00ff", light: "#c8b5ff", dark: "#57448d"},
  {id: 'school', main: "#2cb34b", neon: "#00ff00", light: "#82c562", dark: "#008343"},
  {id: 'work', main: "#fbb163", neon: "#fcee21", light: "#fed07f", dark: "#f15a22"},
  {id: 'community', main: "#e0c200", neon: "#ffff00", light: "#fcee21", dark: "#a18b00"},
  {id: 'care', main: "#f16f8e", neon: "#ff459d", light: "#f7b0c4", dark: "#ed175e"},
];

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hearThisMarginTop: 0
    };
  }

  getColor(colorType) {
    var currentSectionColorObject = sectionColors.find(arrayItem => arrayItem.id === this.props.data.id);
    return currentSectionColorObject[colorType];
  }

  lottieHotSpotClick() {
    if (this.props.data.id === 'home') {
      this.props.mapClick('individual');
    }
  }

  revealSeqence() {
    var tl = new TimelineMax({delay: 0.5});
    tl.to(this.provocation1Container, zoomSpeed, {opacity: 1});
    tl.to(this.provocation2Container, zoomSpeed, {opacity: 1});
    tl.to(this.buildThisContainer, zoomSpeed, {opacity: 1});
    tl.to(this.todaysContainer, zoomSpeed, {opacity: 1});
  }

  calcMarginTops() {
    var statsColEl = ReactDOM.findDOMNode(this.statsContent);
    var buildColEl = ReactDOM.findDOMNode(this.buildThisCol);
    var hearThisNewMarginTop = -Math.abs(buildColEl.clientHeight - statsColEl.clientHeight - 500);
    this.setState({hearThisMarginTop: hearThisNewMarginTop});
  }

  hearThisMarginTop() {
    var hearThisStyle = {marginTop: this.state.hearThisMarginTop};
    return hearThisStyle;
  }

  componentDidMount() {
    this.calcMarginTops();
    this.revealSeqence();
  }

  render() {
    return (
      <div className={"section " + this.props.data.id}>

        <Container fluid={true}>
          <Row noGutters={false}>
            <Col className="section-column" lg={{span: 6, order: 1}}>
              <div className="lottie-hotspot" 
                onClick={() => this.lottieHotSpotClick()}
                onMouseEnter={this.props.animateDoor} 
                onMouseLeave={this.props.animateDoor}
              >
              </div>
            </Col>

            <Col className="section-column" lg={{span: 5, order: 2}}>
              {/* BEGIN Intro Content */}
              <Container fluid={true} className="section-intro-container">
                <Row noGutters={false} className="intro-row">
                  <Col sm={12}>
                    <div className="section-content-container">
                      <div className="section-title">{this.props.data.title}</div>
                      <div className="section-intro">{this.props.data.intro}</div>
                    </div>
                  </Col>
                </Row>
              </Container>
              {/* END Intro Content */}
            </Col>

            <Col id="build-this-col" ref={div => this.buildThisCol = div} className="section-column" lg={{span: 5, order: 3, offset: 1}}>
              {/* BEGIN Provocations */}
              <Row className="provocations-container">
                <Col lg={5} className="first-provocation-container provocation-container">
                  <div ref={div => this.provocation1Container = div} className="provocations-reveal">
                    <Provocation data={this.props.data.provocations.items[0]} colorLight={this.getColor('light')} colorDark={this.getColor('dark')}></Provocation>
                  </div>
                </Col>
                <Col lg={5} className="second-provocation-container provocation-container">
                  <div ref={div => this.provocation2Container = div} className="provocations-reveal">
                    <Provocation data={this.props.data.provocations.items[1]} colorLight={this.getColor('light')} colorDark={this.getColor('dark')}></Provocation>
                  </div>
                </Col>
              </Row>
              {/* END Provocations */}

              {/* BEGIN Build This */}
              <div ref={div => this.buildThisContainer = div} className="build-this-container-container">
                <Row noGutters={false} className="build-this-content-row">
                  <div className="build-this-subtitle">Hey, Innovators! Start your thinking with these creative prompts</div>
                  <Col lg={9} className="build-this-content-col">
                    <Hex copy="Build This!" opacity="80" color={this.getColor('dark')} size="large"></Hex>
                    <div className="build-item-line"></div>
                    {this.props.data.build.items.map((buildItem, index) => (

                      <Row className="build-row" key={index.toString()}>
                        <Col sm={4} className="build-item-img-container">
                          {buildItem.imgUrl &&
                            <img className="build-item-img" alt="Build This!" src={require(`./img/dbimages/${buildItem.imgUrl}`)} />
                          }
                        </Col>
                        <Col sm={8}>
                          <div className="build-item-title">{buildItem.title}</div>
                          <div className="build-item-copy">{buildItem.copy}</div>
                        </Col>
                      </Row>

                    ))}
                  </Col>
                  <Col lg={3}></Col>
                </Row>
              </div>
              {/* END Build this */}
            </Col>

            <Col className="section-column" lg={{span: 5, order: 7}}>
              {/* BEGIN Hear This */}
              <Controller>
                <Scene duration={400} offset={-200} triggerHook="0.9">
                  <Timeline wrapper={<div id="hearThisFadeIn" />}>
                    <Tween from={{ opacity: 0}} to={{ opacity: 1}}>
                      <div>
                        <div className="hear-this-section" style={this.hearThisMarginTop()}>
                          <Hex copy="Hear This!" size="medium" color={this.getColor('dark')}></Hex>
                          <div className="hear-this-subtitle">Voices of families and experts across the country</div>
                          <div className="hear-this-line"></div>
                          {this.props.data.hear.items.map((hearItem, index) => (
                            <Row className="hear-this-container" key={index.toString()}>
                              <Col sm={3} className="text-center">
                                {hearItem.imgUrl &&
                                  <img className="hear-item-img" alt="Hear This!" src={require(`./img/dbimages/${hearItem.imgUrl}`)} />
                                }
                              </Col>
                              <Col sm={9}>
                                <div className="hear-item-name">
                                  {hearItem.name}
                                  <span className="hear-item-location">{hearItem.location}</span>
                                </div>
                                {hearItem.context &&
                                  <div className="hear-item-context">{hearItem.context}</div>
                                }
                                <div className="hear-item-quote">{hearItem.quote}</div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </Tween>
                  </Timeline>
                </Scene>
              </Controller>
              {/* END Hear This */}
            </Col>

            <Col id="watch-this-col" className="section-column" lg={{span: 5, order: 6, offset: 1}}>
              {/* BEGIN Watch This */}
              <Controller>
                <Scene duration={400} offset={-100} triggerHook="0.9">
                  <Timeline wrapper={<div id="watchThisFadeIn" />}>
                    <Tween from={{ opacity: 0}} to={{ opacity: 1}}>

                      <div className="hear-this-container-container">
                        <Row noGutters={false} className="watch-this-content-row">
                          <Col sm={2}></Col>
                          <Col sm={8} className="watch-this-content-col">
                            <div className="watch-this-subtitle">Trends and curated content to get you inspired</div>
                            <Hex copy="Watch This!" color={this.getColor('dark')} size="small"></Hex>
                            {this.props.data.watch.items.map((watchItem, index) => (
                              <Row className="watch-row" key={index.toString()}>
                                <Col sm={12} className="watch-item-img-container">
                                  {watchItem.imgUrl &&
                                    <a target="_blank" rel="noopener noreferrer" href={watchItem.linkUrl}>
                                      <img className="build-item-img" alt="Watch This!" src={require(`./img/dbimages/${watchItem.imgUrl}`)} />
                                    </a>
                                  }
                                </Col>
                                <Col sm={12}>
                                  <div className="watch-item-title">{watchItem.title}</div>
                                  <div className="watch-item-content">{watchItem.copy}</div>
                                  {watchItem.linkUrl &&
                                    <a className="build-item-copy section-link" target="_blank" rel="noopener noreferrer" href={watchItem.linkUrl}>click to visit</a>
                                  }
                                  {index < this.props.data.watch.items.length-1 &&
                                    <div className="watch-row-border"></div>
                                  }
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </Row>
                      </div>

                    </Tween>
                  </Timeline>
                </Scene>
              </Controller>
              {/* END Watch This */}
            </Col>
                                
            <Col className="section-column" lg={{span: 5, order: 4}}>
              {/* BEGIN Today's Reality */}
              <div ref={div => this.todaysContainer = div} className="top-content-container-container">
                <div ref={ (div) => this.statsContent = div} className="top-content-container">
                  <svg className="stat-background" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 579.65 112.52">
                      <g id="010aa6ed-e35b-4b0c-9dd9-0d5004498f6c" data-name="Layer 2">
                          <g id="06010386-424e-45c5-a854-7649813bf9cf" data-name="category template content">
                              <g id="a3b56009-1246-40bb-8ce5-104c34649221" data-name="86cbe806-d4ff-49ae-a61f-0026841fa86b">
                                  <g id="5f0025da-9380-448e-b6bc-11e5d3eaaa38" data-name="a1cbd13e-c90d-4b23-b728-42291b5dd937">
                                      <path d="M.5,111.58V66.16L98,10.21l147.9,84.14L415.62.58,579.15,96.21v16.31" fill="#f2f2f2" fillOpacity="0" stroke={this.getColor('dark')} strokeMiterlimit="10" />
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                  <Hex opacity="80" copy="Today's Reality" size="tiny" color={this.getColor('dark')}></Hex>
                  <div className="todays-subtitle">A numerical snapshot of where we are right now</div>
                  <div className="todays-reality-content-container">
                  <Row>
                  {this.props.data.statistics.items.map((statistic, index) => (
                    <Col className="stat-item" key={index.toString()} xl={6}>
                        <Row noGutters={true}>
                          <Col xs={4}>
                            {statistic.unit === "%" &&
                              <Chart chartData={statistic} colorLight={this.getColor('light')} colorDark={this.getColor('dark')}></Chart>
                            }
                            {statistic.unit !== "%" &&
                              // <div className="stat-circle">{statistic.value} <span className="stat-unit">{statistic.unit}</span></div>

                              <div className="stat-circle-container">
                                  <div className="stat-circle-helper"></div>
                                  <div className="stat-circle">
                                    {statistic.value} <span className="stat-unit">{statistic.unit}</span>
                                  </div>
                              </div>

                            }
                          </Col>
                          <Col xs={8} className="quotes-and-stats">
                            {statistic.copy}
                            <a href={statistic.attributionUrl} target="_blank" rel="noopener noreferrer" className="attribution">{statistic.attribution}</a>
                          </Col>
                        </Row>
                    </Col>
                  ))}
                  </Row>
                  </div>
                  <svg className="stat-background-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 579.65 196.74">
                      <g id="30ff455d-a01a-47da-bd07-993e92bb62bb" data-name="Layer 2">
                          <g id="b42e32e1-d743-4e51-afd4-056ff35cc35d" data-name="category template content">
                              <g id="38175d87-211f-4f05-9ba0-8d2eaeecc9ce" data-name="a1cbd13e-c90d-4b23-b728-42291b5dd937">
                                  <polyline points="579.15 0 579.15 3.33 250.41 196.16 0.5 49.93 0.5 0" fill="#f2f2f2" fillOpacity="0" stroke={this.getColor('dark')} strokeMiterlimit="10" />
                              </g>
                          </g>
                      </g>
                  </svg>
                </div>
              </div>
              {/* END Today's Reality */}
            </Col>
          
          </Row>
        </Container>

        <Controller>
          <Scene duration={400} offset={-100} triggerHook="0.9">
            <Timeline wrapper={<div id="watchThisFadeIn" />}>
              <Tween from={{ scale: 0.5}} to={{ scale: 1}}>
                <div>
                  <Link className="next-button" to='/'>Back To Map</Link>
                </div>
              </Tween>
            </Timeline>
          </Scene>
        </Controller>

      </div>
    );
  }
}

export default Section;
