import React, { Component } from 'react';
import './Chart.scss';
import PieChart from 'react-minimal-pie-chart';

class Chart extends Component {

  render() {

    return (
      <div>
        <PieChart className="donut-chart"
          animate
          data={[
            { title: 'One', value: this.props.chartData.value, color: this.props.colorDark },
            { title: 'Two', value: 100 - this.props.chartData.value, color: this.props.colorLight },
          ]}
          lineWidth={30}
          totalValue={100}

          label={({ data, dataIndex }) =>
            Math.round(data[dataIndex].percentage) + '%'
          }
          // labelStyle={{
          //   fontSize: '20px',
          //   fontFamily: 'sans-serif'
          // }}
          labelPosition={0}    
        />
      </div>
    );
  }
}

export default Chart;
