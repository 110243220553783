import React, { Component } from 'react';
import './HexOverlay.scss';

class HexOverlay extends Component {

  render() {
    let hexStyle = {fill: this.props.color};
    let sizeClass = this.props.size ? ('hex-overlay-container ' + this.props.size) : 'hex-overlay-container small';

    return (
      <div className={sizeClass}>
        <div className="hex-content">
          <div className="hex-title">{this.props.title}</div>
          <div className="hex-copy">{this.props.copy}</div>
        </div>
        <svg className="hex-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.41 164.43">
            <g id="85a6880a-de7f-4b0c-a217-62e1c73202db" data-name="Layer 2">
                <g id="154e1450-e49f-4dd9-9421-59a5fdfbf927" data-name="CATEGORY HOVER">
                    <g opacity="0.85">
                        <polygon points="0.5 123.03 0.5 41.4 71.21 0.58 141.91 41.4 141.91 123.03 71.21 163.86 0.5 123.03" style={hexStyle} />
                        <path d="M71.21,1.15l70.2,40.54v81.05l-70.2,40.54L1,122.74v-81L71.21,1.15m0-1.15L0,41.11v82.21l71.21,41.11,71.2-41.11V41.11L71.21,0Z" fill={this.props.outlineColor} />
                    </g>
                </g>
            </g>
        </svg>
      </div>
    );
  }
}

export default HexOverlay;
