import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal';
import './MobileMenu.scss';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';

// For documentation about react-player
// Visit https://www.npmjs.com/package/react-player

class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.aboutClick = this.aboutClick.bind(this);
    this.introClick = this.introClick.bind(this);
    this.categoryClick = this.categoryClick.bind(this);
  }

  aboutClick() {
    this.props.hideMobileMenu();
    this.props.showAbout();
  }
  
  introClick() {
    this.props.hideMobileMenu();
    this.props.showIntro();
  }

  categoryClick(category) {
    this.props.hideMobileMenu();
    this.props.mapClick(category);
  }

  render() {
    return (
      <div id="mobile-menu">
        <Modal
          size="lg"
          centered
          show={this.props.mobileMenuOn}
          onHide={this.props.hideMobileMenu}
          dialogClassName="mobile-menu-modal">
          <Container fluid={true}>
          <div className="close-icon" onClick={this.props.hideMobileMenu}><svg id="e59a3761-b5b6-4050-aff0-c44dcb13a07e" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><title>Close Icon</title><path d="M50,71.88A21.88,21.88,0,1,1,71.88,50,21.91,21.91,0,0,1,50,71.88M50,25A25,25,0,1,1,25,50,25,25,0,0,1,50,25ZM60.48,41.73a1.56,1.56,0,1,0-2.21-2.21L50,47.79l-8.27-8.27a1.56,1.56,0,1,0-2.21,2.21L47.79,50l-8.27,8.27a1.56,1.56,0,1,0,2.21,2.21L50,52.21l8.27,8.27a1.56,1.56,0,1,0,2.21-2.21L52.21,50Z" transform="translate(-25 -25)" fill="#7f7f7f" fill-rule="evenodd"/></svg></div>
            <Row noGutters={true}>
              <Col xl="12">
                <div className={this.props.mobileMenuClassNames}>
                  <div className="menu-content">
                    <div className="menu-subtitle">I'd like to visit:</div>
                    <div onClick={() => this.categoryClick('map')} className={this.props.systemClassNames}>The Care System</div>
                    <div onClick={() => this.categoryClick('community')} className={this.props.communityClassNames}>Community</div>
                    <div onClick={() => this.categoryClick('work')} className={this.props.workClassNames}>Work</div>
                    <div onClick={() => this.categoryClick('school')} className={this.props.schoolClassNames}>School</div>
                    <div onClick={() => this.categoryClick('care')} className={this.props.careClassNames}>Child Care</div>
                    <div onClick={() => this.categoryClick('retirement')} className={this.props.retirementClassNames}>Retirement</div>
                    <div onClick={() => this.categoryClick('home')} className={this.props.homeClassNames}>Home</div>
                    <div onClick={() => this.categoryClick('individual')} className={this.props.individualClassNames}>Individual</div>
                    <div className="menu-subtitle">I'd like to read:</div>
                    <div className="white-space"></div>
                    <div className="intro-link" onClick={this.introClick}>The introduction</div>
                    <div className="about-link" onClick={this.aboutClick}>About this project</div>
                  </div>

                  <div className="share-links">
                    <div className="menu-subtitle">I'd like to share:</div>
                    <FacebookShareButton
                      url={this.props.shareUrl}
                      quote={this.props.shareQuote}
                      className="share-link share-link-one">
                      <FacebookIcon
                        size={this.props.shareIconSize}
                        square />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={this.props.shareUrl}
                      title={this.props.shareQuote}
                      className="share-link share-link-two">
                      <TwitterIcon
                        size={this.props.shareIconSize}
                        square />
                    </TwitterShareButton>

                    <LinkedinShareButton
                      url={this.props.shareUrl}
                      title={this.props.shareQuote}
                      className="share-link share-link-three">
                      <LinkedinIcon
                        size={this.props.shareIconSize}
                        square />
                    </LinkedinShareButton>

                    <EmailShareButton
                      subject={this.props.shareQuote}
                      body={this.props.shareUrl}
                      className="share-link share-link-four">
                      <EmailIcon
                        size={this.props.shareIconSize}
                        square />
                    </EmailShareButton>
                  </div>

                  <div className="copyright">The Care System &#169;</div>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}

export default MobileMenu;
