import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {BrowserRouter, Route} from 'react-router-dom';



import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import initReactFastclick from 'react-fastclick';
initReactFastclick();

const tracker = ({location}) => {
  ReactGA.set({page: location.pathname})
  ReactGA.pageview(location.pathname)
  return null
}

ReactGA.initialize('UA-138862390-1')

ReactDOM.render((
  <BrowserRouter>
    <App />
    <Route render={tracker} />
  </BrowserRouter>
), document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
